import { useForm } from 'react-hook-form'
import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'
import { Helmet } from 'react-helmet'

import { FIREBASE_ERRORS_TRANSLATIONS } from '../../helpers/translation-constants'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import { signInWithEmailPassword } from '../../firebase/auth/emailPasswordAuth'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { convertQrCodeToImage } from '../../helpers/functions'
import { googleSignIn } from '../../firebase/auth/googleAuth'
import { useSendEmailSendgridMutation } from '../../api/api'
import { useAuthContext } from '../../context/AuthContext'
import { useViewport } from '../../hooks/useViewport'

import ResetPasswordSendModal from '../../components/ResetPasswordSendModal/ResetPasswordSendModal'
import ResetPasswordModal from '../../components/ResetPasswordModal/ResetPasswordModal'
import AuthSwitch from '../../components/AuthSwitch/AuthSwitch'

import newAuthMobileCloseSvg from '../../assets/icons/new-auth-mobile-close.svg'
import googleHeaderSvg from '../../assets/icons/googleHeader.svg'
import signUpFrame from '../../assets/icons/sign-up-frame.svg'
import newLogo from '../../assets/icons/newlogo.svg'

const SignIn = ({ signUpFrameBackgroundSvg }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const [showResetModal, setShowResetModal] = useState(false)
    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false)
    const [withUpgrade, setWithUpgrade] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const [ownerLoginFromExpired, setOwnerLoginFromExpired] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isPassword, setIsPassword] = useState(false)

    const registerRef = useRef()

    const { isMobile } = useViewport()
    const { t } = useTranslation()
    const navigate = useCustomNavigate()

    const { generateCodeDashboard, canvasRef, imageSettings, canvasCreationRef,
        canvasStyles, backgroundColor, foregroundColor, scanColor, scanFont,
        scanText, publicId, isDynamic, staticValue
    } = useUserCodeCreationContext()
    const { setFlow, setGoogleWithDownload } = useAuthContext()

    const { register, handleSubmit } = useForm();

    const [sendRegistrationEmail] = useSendEmailSendgridMutation()

    const handlePasswordChange = (value) => {
        if (value.length > 0) {
            setIsPassword(true)
        } else {
            setIsPassword(false)
        }
    }

    const convertCodeAndSignIn = async () => {
        if (withUpgrade) {
            setFlow(true)
        }

        if (isDownload) {
            await setGoogleWithDownload(true)
        }

        const { error, isNew } = await handleGoogleSignIn()

        if (!error) {
            if (ownerLoginFromExpired && !isNew) {
                setSearchParams({ redirect: 'upgrade' })
            } else if (ownerLoginFromExpired && isNew) {
                setSearchParams({ redirect: 'dashboard' })
            }

            if (isDownload) {
                const url = await convertQrCodeToImage(withUpgrade ? canvasCreationRef : canvasRef, imageSettings, 8, isMobile)

                generateCodeDashboard(() => { }, url)

                setGoogleWithDownload(false)
            }

            if (withUpgrade && isNew) {
                setFlow(true)
                navigate('/upgrade-plan-new')
            } else {
                setFlow(false)
            }

            if (isNew) {
                setTimeout(() => { sendRegistrationEmail() }, 1000);

                window.gtag('event', 'sign_up', {
                    method: 'Google'
                })
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-11350401889/i_miCJTMueoYEOHGpaQq'
                });

                navigate('/upgrade-plan-new?icon=close')
            } else {
                window.gtag('event', 'login', {
                    method: 'Google'
                })

                navigate('/dashboard?justLoggedIn=true')
            }
        } else {
            setError(true)
            setErrorMessage(error)
        }
    }

    const handleNavigateToReset = async () => {
        setShowResetModal(true)
    }

    const handleNavigateToSignUp = () => {
        navigate(`/sign-up?${withUpgrade ? 'upgrade=true&' : ''}${isDownload ? 'download=true&' : ''}${ownerLoginFromExpired ? 'ownerLogin=true' : ''}`)
    }

    const handleSignIn = async (data) => {
        let url = '';

        setFlow(false)

        if (isDownload) {
            url = await convertQrCodeToImage(withUpgrade ? canvasCreationRef : canvasRef, imageSettings, 8, isMobile)
        }

        const { error } = await signInWithEmailPassword(data)

        if (!error) {
            if (ownerLoginFromExpired) {
                setSearchParams({ redirect: 'upgrade' })
            }

            if (isDownload) {
                await generateCodeDashboard(() => { }, url)
            }

            window.gtag('event', 'login', {
                method: 'Email'
            })

            navigate('/dashboard?justLoggedIn=true')
        } else {
            setError(true)
            setErrorMessage(error)
        }
    }

    const handleGoogleSignIn = async () => {
        const { error, isNew, user } = await googleSignIn()

        return { error, isNew, user }
    }

    const handleNavigateToHome = () => {
        navigate('/')
    }

    useEffect(() => {
        const upgrade = searchParams.get('upgrade')
        const download = searchParams.get('download')
        const ownerLogin = searchParams.get('ownerLogin')

        if (upgrade === 'true') {
            setWithUpgrade(true)
        } else if (upgrade === 'false') {
            setWithUpgrade(false)
        }

        if (download === 'true') {
            setIsDownload(true)
        } else if (download === 'false') {
            setIsDownload(false)
        }

        if (ownerLogin === 'true') {
            setOwnerLoginFromExpired(true)
        } else if (ownerLogin === 'false') {
            setOwnerLoginFromExpired(false)
        }

        window.history.replaceState(null, null, window.location.pathname);
    }, [searchParams, setSearchParams])

    return (
        <div className='sign-up-in-form-wrapper'>
            <Helmet>
                <link rel="canonical" href="https://qrcodeveloper.com/sign-in" />
                <link rel="alternate" href="https://qrcodeveloper.com/pt/sign-in" hreflang="pt"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/de/sign-in" hreflang="de"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/es/sign-in" hreflang="es"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/fr/sign-in" hreflang="fr"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/dk/sign-in" hreflang="dk"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/it/sign-in" hreflang="it"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/hu/sign-in" hreflang="hu"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/mx/sign-in" hreflang="mx"></link>
            </Helmet>
            <div className='please-signup-text-block'>
                <div className='flex items-center justify-between'>
                    <span onClick={handleNavigateToHome} className='please-signup-small-text cursor-pointer'>
                        <img src={newLogo} alt="" />
                        <span className='header-logo-text'>QR Code Developer</span>
                    </span>
                </div>
                <form onSubmit={handleSubmit(handleSignIn)} className='flex flex-col new-sign-in-up-form'>
                    <span className='please-signup-big-purple-text mb-6 new-sign-up-in-page-title-wrapper-mobile'>
                        <span className='new-sign-up-in-page-title'>{t("logInToDownloadYourQrCode")}</span>
                    </span>
                    <AuthSwitch
                        type="sign-in"
                        withUpgrade={withUpgrade}
                        isDownload={isDownload}
                        ownerLoginFromExpired={ownerLoginFromExpired}
                    />
                    <button type="button" onClick={convertCodeAndSignIn} className='new-sign-up-in-page-google-btn flex justify-center mobile-hidden'>
                        <img src={googleHeaderSvg} alt="" />
                        {t("continueWithGoogle")}
                    </button>
                    <div className='please-signup-or-line mobile-hidden'>
                        <span className='please-signup-line'></span>
                        <span className='please-signup-or-text'>{t("or")}</span>
                    </div>
                    <div className={`new-sign-up-input-block-wrapper first ${isEmail ? 'filled' : ''}`}>
                        <span className='new-sign-up-label'>{t("emailAddress")}</span>
                        <input
                            type='text'
                            className={`new-sign-up-in-input`}
                            autoComplete="new-password"
                            {...register("email", {
                                onChange: (e) => {
                                    setError(false)
                                    setIsEmail(e.target.value.length > 0 ? true : false)
                                }
                            })}
                        />
                    </div>
                    <div className={`new-sign-up-input-block-wrapper second  ${isPassword ? 'filled' : ''}`}>
                        <span className='new-sign-up-label'>{t("password")}</span>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={`new-sign-up-in-input`}
                            autoComplete="new-password"
                            {...register("password", {
                                onChange: (e) => {
                                    setError(false)
                                    handlePasswordChange(e.target.value)
                                }
                            })}
                        />
                        {showPassword ? (
                            <AiOutlineEye onClick={() => setShowPassword(false)} fill='#7D8898' size={23} className='please-signup-password-eye-icon' />
                        ) : (
                            <AiOutlineEyeInvisible onClick={() => setShowPassword(true)} fill='#7D8898' size={23} className='password-eye-icon' />
                        )}
                    </div>
                    {error && <p className='error-text mt-2'>
                        {errorMessage ? `${t("anErrorOccurred")} ${t(FIREBASE_ERRORS_TRANSLATIONS[errorMessage])}` : t("invalidCredentialsProvidedTryAgain")}
                    </p>}
                    <div className='please-sign-up-additional-actions'>
                        <div className='flex items-center gap-2'>
                            <input className='checkbox-remember-me' type="checkbox" />
                            <span className='please-sign-up-remember'>{t("rememberMe")}</span>
                        </div>
                        <span onClick={handleNavigateToReset} className='please-sign-up-forgot-password cursor-pointer'>{t("forgotPassword")}</span>
                    </div>
                    <button disabled={!isPassword || !isEmail ? true : false} ref={registerRef} className='new-sign-up-in-page-btn mt-6'>
                        {t("logInLowercase")}
                    </button>
                    <span className='already-have-an-account-text'>
                        {t("dontHaveAnAccount")} <span onClick={handleNavigateToSignUp} className='cursor-pointer' style={{ color: '#9747FF' }}>
                            {t("createAnAccountLowercase")}
                        </span>
                    </span>
                    <div className='please-signup-or-line desktop-hidden'>
                        <span className='please-signup-line'></span>
                        <span className='please-signup-or-text'>{t("or")}</span>
                    </div>
                    <button type="button" onClick={convertCodeAndSignIn} className='new-sign-up-in-page-google-btn flex justify-center desktop-hidden'>
                        <img src={googleHeaderSvg} alt="" />
                        {t("continueWithGoogle")}
                    </button>
                    <img onClick={() => navigate('/')} className='new-auth-page-close-mobile' src={newAuthMobileCloseSvg} alt="" />
                </form>
                <div></div>
            </div>
            <div className='sign-up-modal-right-side-wrapper'>
                <div style={{ background: `url(${signUpFrameBackgroundSvg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className='sign-up-modal-right-side-background-image'>
                    <div className='please-signup-girl-image'>
                        <img src={signUpFrame} alt="" />
                    </div>
                </div>
            </div>
            <ResetPasswordModal
                show={showResetModal}
                onClose={() => setShowResetModal(false)}
                setShowResetSuccessModal={setShowResetSuccessModal}
            />
            <ResetPasswordSendModal
                show={showResetSuccessModal}
                onClose={() => setShowResetSuccessModal(false)}
            />
            <div style={{ position: 'absolute', top: '-3110px' }} className='p-2 mt-6 w-fit' ref={canvasCreationRef}>
                <div style={{ ...canvasStyles, backgroundColor: backgroundColor, color: scanColor, fontFamily: scanFont }} className='qr-code-result-wrapper'>
                    <QRCodeSVG
                        value={isDynamic ? `${window.location.origin}/code/${publicId}` : staticValue}
                        bgColor={backgroundColor}
                        fgColor={foregroundColor}
                        level='L'
                        size={256}
                        imageSettings={imageSettings}
                    />
                    {scanText}
                </div>
            </div>
        </div>
    )
}

export default SignIn