import { QRCodeSVG } from 'qrcode.react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useCustomLoggerMutation, useGetCodesListQuery, useHandleConvertCodeMutation } from '../../api/api'
import { ACTIVE_TAB_TRANSLATIONS, USER_QR_TYPE_TRANSLATIONS } from '../../helpers/translation-constants'
import { base64ToBlob, convertQrCodeToImage } from '../../helpers/functions'
import { DASHBOARD_KIND_FILTER_LIST } from '../../helpers/constants'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import useDashboardActions from '../../hooks/useDashboardActions'
import useTrialDaysLeft from '../../hooks/useTrialDaysLeft'
import usePreloadImage from '../../hooks/usePreloadImage'
import usePagination from '../../hooks/usePagination'
import { useViewport } from '../../hooks/useViewport'
import useToasts from '../../hooks/useToasts'

import Pagination from '../../components/Pagination/Pagination'
import FaqSection from '../../components/FaqSection/FaqSection'
import CodeBlock from '../../components/DashboardComponents/CodeBlock'
import TrialEndModal from '../../components/TrialEndModal/TrialEndModal'
import OverviewPanel from '../../components/OverviewPanel/OverviewPanel'
import ActionsBlock from '../../components/DashboardComponents/ActionBlock'
import OnboardingModal from '../../components/OnboardingModal/OnboardingModal'
import UserCreateCode from '../../components/DashboardComponents/UserCreateCode'
import TrialPeriodBlock from '../../components/TrialPeriodBlock/TrialPeriodBlock'
import UserFeedbackModal from '../../components/UserFeedbackModal/UserFeedbackModal'
import DeleteConfirmModal from '../../components/DeleteConfirmModal/DeleteConfirmModal'
import MobileCreationFlow from '../../components/MobileCreationFlow/MobileCreationFlow'
import DashboardNavigation from '../../components/DashboardNavigation/DashboardNavigation'
import ArchiveConfrimModal from '../../components/ArchiveConfirmModal/ArchiveConfrimModal'
import ConvertStaticCodesToDynamicModal from '../../components/ConvertStaticCodesToDynamicModal/ConvertStaticCodesToDynamicModal'

import analyticsSettingsArrowRight from '../../assets/icons/analytics-settings-arrow-right.svg'
import dashboardNavigationDownload from '../../assets/icons/dashboard-navigation-download.svg'
import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import dashboardNavigationPlus from '../../assets/icons/dashboard-navigation-plus.svg'
import OnboardingWelcomeMobile from '../../assets/icons/onboarding/welcome-mobile.svg'
import userDashboardConvert from '../../assets/icons/dashboard-navigation-convert.svg'
import dashboardMobileSettings from '../../assets/icons/dashboard-mobile-settings.svg'
import userDashboardListView from '../../assets/icons/user-dashboard-list-view.svg'
import userDashboardGridView from '../../assets/icons/user-dashboard-grid-view.svg'
import dashboardMobileMore from '../../assets/icons/dashboard-mobile-more.svg'
import dashboardSortCheck from '../../assets/icons/dashboard-sort-check.svg'
import OnboardingWelcome from '../../assets/icons/onboarding/welcome.svg'
import FaqOpen from '../../assets/icons/faq/open.svg'
import FaqHide from '../../assets/icons/faq/hide.svg'

const UserDashboard = () => {
    const subscription = useSelector((state) => state.app.subscription)
    const settings = useSelector((state) => state.app.settings)
    const [showAlert, setShowAlert] = useState(true)
    const [canvasStyles, setCanvasStyles] = useState(null)
    const [showTrialEndModal, setShowTrialEndModal] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [selectedKindsList, setSelectedKindsList] = useState([])
    const [appliedKindFilters, setAppliedKindFilters] = useState([])
    const [selectedSortFilter, setSelectedSortFilter] = useState('newest_to_oldest')
    const [appliedSortFilter, setAppliedSortFIlter] = useState('newest_to_oldest')
    const [isMultipleConvertion, setIsMultipleConvertion] = useState(false)
    const [isConverting, setIsConverting] = useState(false)
    const [showMobileFilters, setShowMobileFilters] = useState(false)
    const [showMobileSort, setShowMobileSort] = useState(false)
    const [showMobileSettings, setShowMobileSettings] = useState(false)
    const [convertData, setConvertData] = useState({})
    const [showOnboardingModal, setShowOnboardingModal] = useState(false)
    const [showFaq, setShowFaq] = useState(false)
    const [showConvertToDynamicModal, setShowConvertToDynamicModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showHelpModal, setShowHelpModal] = useState(false)

    const canvasRef = useRef(null)
    const triggerValidationRef = useRef()
    const mobileFiltersRef = useRef(null)
    const isConvertingRef = useRef(isConverting);
    const mobileSortRef = useRef(null)
    const codesBlockRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()
    const { trialDaysLeft } = useTrialDaysLeft()
    const { activePage, pages, handlePage, handlePagesCount } = usePagination(isMobile ? 10 : 25, codesBlockRef)
    const { convertToDynamicToast, errorToast, limitToast, downloadedMultipleToast, allConvertedToast } = useToasts()
    const {
        downloadCode, handleEditQrCode, handleArchiveDynamicCode,
        handleDeleteCode, showArchiveConfirmModal, showDeleteConfirmModal,
        archiveId, setShowArchiveConfirmModal, setShowDeleteConfirmModal,
        setArchiveId, setDeleteId, handleArchiveCode, handleArchiveCodeConfirmed,
        handleDeleteCodeConfirmed, overviewCode, setOverviewCode,
    } = useDashboardActions()
    usePreloadImage({
        images: [
            OnboardingWelcomeMobile,
            OnboardingWelcome
        ]
    })

    const {
        activeTab, publicId, codeName, generateCodeDashboard,
        mobileCreation, setMobileCreation, desktopCreation,
        setDesktopCreation, view, setView, showFeedbackModal,
        setShowFeedbackModal, searchFilter,
    } = useUserCodeCreationContext()

    const { data: codesList } = useGetCodesListQuery({ activeTab, searchFilter, appliedSortFilter, appliedKindFilters, activePage, itemsPerPage: isMobile ? 10 : 25 }, { skip: !subscription })
    const [convertCode] = useHandleConvertCodeMutation()
    const [customLogger] = useCustomLoggerMutation()

    const staticCodes = useMemo(() => {
        return codesList?.items?.filter((item) => item.type === 'static')
    }, [codesList])

    const handleClickOnCode = (code) => {
        if (trialDaysLeft > 0) {
            setOverviewCode(code)
        }
    }

    const triggerValidation = useCallback((trigger) => {
        if (trigger) {
            triggerValidationRef.current = trigger
        }
    }, [])

    const handleTriggerAndGenerate = async () => {
        if (triggerValidationRef.current) {
            const result = await triggerValidationRef.current()

            if (result) {
                generateCodeDashboard(handleConvertData)
            }
        }
    }

    const handleSelectFilterKind = (kind) => {
        if (selectedKindsList.includes(kind)) {
            const result = selectedKindsList.filter((item) => item !== kind)
            setSelectedKindsList(result)
        } else {
            setSelectedKindsList([...selectedKindsList, kind])
        }
    }

    const handleClearKindFilters = () => {
        setSelectedKindsList([])
        setAppliedKindFilters([])

        if (isMobile) {
            setShowMobileFilters(false)
        }
    }

    const handleApplyKindFilters = () => {
        setAppliedKindFilters(selectedKindsList)

        if (isMobile) {
            setShowMobileFilters(false)
        }
    }

    const handleClearSortFilters = () => {
        setSelectedSortFilter('newest_to_oldest')
        setAppliedSortFIlter('newest_to_oldest')

        if (isMobile) {
            setShowMobileSort(false)
        }
    }

    const handleApplySortFilters = () => {
        setAppliedSortFIlter(selectedSortFilter)

        if (isMobile) {
            setShowMobileSort(false)
        }
    }

    const handleConvertData = async (code) => {
        setIsConverting(true)
        setConvertData(code)
    }

    const handleConvertAllCodesToDynamic = async () => {
        if (trialDaysLeft > 0) {
            const staticList = codesList?.items?.filter((item) => item.type === 'static')

            if (staticList?.length > 0) {
                setIsMultipleConvertion(true)

                for (let i = 0; i < staticList.length; i++) {
                    setConvertData(staticList[i])

                    await new Promise((resolve) => {
                        let timerId;

                        timerId = setInterval(() => {
                            if (!isConvertingRef.current) {
                                clearInterval(timerId)
                                resolve()
                            }
                        }, 1000)
                    })
                }

                allConvertedToast()
                setIsMultipleConvertion(false)
                customLogger({
                    action: 'click',
                    description: 'Dashboard Convert All to Dynamic',
                })
            }
        }
    }

    const handleConvertSelectedCodesToDynamic = async (staticList) => {
        if (trialDaysLeft > 0) {
            if (staticList?.length > 0) {
                setIsMultipleConvertion(true)

                for (let i = 0; i < staticList.length; i++) {
                    setConvertData(staticList[i])

                    await new Promise((resolve) => {
                        let timerId;

                        timerId = setInterval(() => {
                            if (!isConvertingRef.current) {
                                clearInterval(timerId)
                                resolve()
                            }
                        }, 1000)
                    })
                }

                allConvertedToast()
                setIsMultipleConvertion(false)
            }
        }
    }

    const handleDownloadAllCodes = async () => {
        if (trialDaysLeft > 0) {
            if (codesList?.items?.length > 0) {
                await codesList?.items?.forEach((item) => {
                    downloadCode(item, true)
                })
                downloadedMultipleToast()
                customLogger({
                    action: 'click',
                    description: 'Dashboard Download All Codes',
                })
            }
        } else {
            setShowTrialEndModal(true)
        }
    }

    const handleNavigateToPreviewCode = (id) => {
        window.open(`${window.location.origin}/code/${id}`, '_blank')
    }

    const handleSetView = (viewType) => {
        setView(viewType)
        localStorage.setItem('view', viewType)
        customLogger({
            action: 'click',
            description: `Dashboard Set ${viewType} View`,
        })
    }

    const handleShowFaq = () => {
        setShowFaq(true)
        customLogger({
            action: 'click',
            description: 'Dashboard FAQ',
        })
    }

    const handleCodeCreation = () => {
        if (trialDaysLeft > 0) {
            if (isMobile) {
                setMobileCreation(true)
            } else {
                setDesktopCreation(true)
            }
            customLogger({
                action: 'click',
                description: 'Dashboard Create Code',
            })
        } else {
            setShowTrialEndModal(true)
        }
    }

    const handleApplySettingsFilters = () => {
        setShowMobileSettings(false)
        setShowMobileSort(false)
        setShowMobileFilters(false)
        setAppliedKindFilters(selectedKindsList)
        setAppliedSortFIlter(selectedSortFilter)
    }

    const handleClearAllSettings = () => {
        setSelectedKindsList([])
        setSelectedSortFilter('newest_to_oldest')
        setAppliedKindFilters([])
        setAppliedSortFIlter('newest_to_oldest')
        setShowMobileSettings(false)
        setShowMobileSort(false)
        setShowMobileFilters(false)
    }

    useEffect(() => {
        if (searchParams.get('justLoggedIn') === 'true' && staticCodes?.length > 0 && settings && !settings?.dont_show_convert_modal) {
            setShowConvertToDynamicModal(true)
            setSearchParams((params) => {
                params.delete('justLoggedIn')
                return params
            })
        } else if (searchParams.get('justLoggedIn') === 'true' && ((staticCodes && staticCodes?.length === 0) || (settings && settings?.dont_show_convert_modal))) {
            setSearchParams((params) => {
                params.delete('justLoggedIn')
                return params
            })
        }
    }, [searchParams, staticCodes, setSearchParams, settings])

    useEffect(() => {
        if (!subscription) return;

        if (subscription?.plan === 'Free') {
            window.gtag('event', 'user_visit_dashboard', {
                type: 'free_user',
            })
        } else {
            window.gtag('event', 'user_visit_dashboard', {
                type: 'paid_user',
            })
        }
    }, [subscription])

    useEffect(() => {
        isConvertingRef.current = isConverting;
    }, [isConverting]);

    useEffect(() => {
        if (codesList?.total > 0) {
            handlePagesCount(codesList?.total)
        }
    }, [codesList, handlePagesCount])

    useEffect(() => {
        if (settings && settings.onboarding_finished === false) {
            setShowOnboardingModal(true)
        }

        if (settings && settings.creation_help_completed === false) {
            setShowHelpModal(true)
        }
    }, [settings])

    useEffect(() => {
        const fromPreview = searchParams.get('from_preview')

        if (fromPreview && codesList && codesList?.items?.length > 0) {
            const element = codesList?.items?.find((item) => item?.public_id === fromPreview)

            if (element) {
                setOverviewCode(element)
                setSearchParams({})
            }
        }
    }, [searchParams, codesList, setSearchParams])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileSortRef.current && !mobileSortRef.current.contains(event.target)) {
                setShowMobileSort(false)
            }

            if (mobileFiltersRef.current && !mobileFiltersRef.current.contains(event.target)) {
                setShowMobileSettings(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    useEffect(() => {
        if (convertData.id && canvasStyles && ((!isConverting && isMultipleConvertion) || (isConverting && !isMultipleConvertion))) {
            (async () => {
                setIsConverting(true)

                const url = await convertQrCodeToImage(canvasRef, {
                    src: convertData?.styles?.icon ? convertData?.styles?.icon : '',
                    height: 48,
                    width: 48,
                    excavate: false,
                })
                const blobImage = base64ToBlob(url, 'image/png')

                const formData = new FormData()
                let payloadData;

                formData.append('image', blobImage)

                if (convertData.data.kind === 'website_url') {
                    payloadData = JSON.stringify({
                        styles: {
                            ...convertData?.styles,
                        },
                        public_id: publicId,
                        name: convertData?.name,
                        data: {
                            content: {
                                website_url: convertData?.data?.content,
                                primary_color: '',
                                secondary_color: '',
                                text_color: '',
                                button_color: '',
                            },
                            kind: convertData.data.kind
                        }
                    })
                } else if (convertData.data.kind === 'text') {
                    payloadData = JSON.stringify({
                        styles: {
                            ...convertData?.styles,
                        },
                        public_id: publicId,
                        name: convertData?.name,
                        data: {
                            content: {
                                text: convertData?.data?.content,
                                primary_color: '',
                                secondary_color: '',
                                text_color: '',
                                button_color: '',
                            },
                            kind: convertData.data.kind
                        }
                    })
                } else if (convertData.data.kind === 'wifi') {
                    const wifi = convertData?.data?.content
                    const typeWifi = wifi.split(':')[2].replace(';S', '')
                    const network_name = wifi.split(':')[3].replace(';P', '')
                    const password = wifi.split(':')[4].replace(';H', '')
                    const hidden = wifi.split(':')[5].replace(';;', '')

                    payloadData = JSON.stringify({
                        styles: {
                            ...convertData?.styles,
                        },
                        public_id: publicId,
                        name: convertData?.name,
                        data: {
                            content: {
                                network_name,
                                type: typeWifi,
                                password,
                                hidden: hidden && hidden !== 'undefined' ? hidden : false,
                                primary_color: '',
                                secondary_color: '',
                                text_color: '',
                                button_color: '',
                            },
                            kind: convertData.data.kind
                        }
                    })
                }

                formData.append('payload', payloadData)

                convertCode({ data: formData, id: convertData.id }).then((res) => {
                    if ('error' in res) {
                        if (res?.error?.data?.detail === 'Your account quota for dynamic qr codes exceeded. Please, upgrade your plan.') {
                            limitToast()
                        } else {
                            errorToast('convert')
                        }
                        setConvertData({})
                        setCanvasStyles(null)
                        setIsConverting(false)
                    } else {
                        setConvertData({})
                        setCanvasStyles(null)
                        if (!isMultipleConvertion) {
                            convertToDynamicToast()
                        }
                        setIsConverting(false)
                    }
                })
            })()
        }
    }, [convertData, canvasStyles, convertCode, publicId, convertToDynamicToast, isConverting, isMultipleConvertion])

    useEffect(() => {
        if (convertData?.styles?.frame === 1) {
            setCanvasStyles({})
        } else if (convertData?.styles?.frame === 2) {
            setCanvasStyles({
                border: '8px solid black',
            })
        } else if (convertData?.styles?.frame === 3) {
            setCanvasStyles({
                border: '8px solid black',
                borderRadius: '25px'
            })
        } else if (convertData?.styles?.frame === 4) {
            setCanvasStyles({
                border: '8px solid black',
                width: '306px',
                height: '306px',
                borderRadius: '100%',
                padding: convertData?.styles?.scanText ? '40px' : '50px',
            })
        }
    }, [convertData])

    return (
        <div style={{ backgroundColor: '#1B003E', width: '100%', display: 'flex' }}>
            <div style={{ borderRadius: '16px' }} className='user-dashboard-wrapper user-dashboard-mobile-pointer'>
                <DashboardNavigation setOverviewCode={setOverviewCode} />
                <div style={{ backgroundColor: '#FFFFFF' }} className='user-dashboard-content user-dashboard-content-new'>
                    {subscription?.plan === 'Free' && showAlert && !desktopCreation && (
                        <TrialPeriodBlock trialDaysLeft={trialDaysLeft} setShowAlert={setShowAlert} isNewUser={subscription?.new_flow} scansLeft={subscription?.scans} />
                    )}
                    {!codeName && !desktopCreation && !isMobile ? (
                        <ActionsBlock
                            activeTab={activeTab}
                            handleCodeCreation={handleCodeCreation}
                            dashboardNavigationPlus={dashboardNavigationPlus}
                            isMultipleConvertion={isMultipleConvertion}
                            handleConvertAllCodesToDynamic={handleConvertAllCodesToDynamic}
                            userDashboardConvert={userDashboardConvert}
                            handleDownloadAllCodes={handleDownloadAllCodes}
                            dashboardNavigationDownload={dashboardNavigationDownload}
                            view={view}
                            appliedKindFilters={appliedKindFilters}
                            handleClearKindFilters={handleClearKindFilters}
                            handleSelectFilterKind={handleSelectFilterKind}
                            selectedKindsList={selectedKindsList}
                            handleApplyKindFilters={handleApplyKindFilters}
                            appliedSortFilter={appliedSortFilter}
                            setSelectedSortFilter={setSelectedSortFilter}
                            selectedSortFilter={selectedSortFilter}
                            handleClearSortFilters={handleClearSortFilters}
                            handleApplySortFilters={handleApplySortFilters}
                            handleSetView={handleSetView}
                        />
                    ) : null}
                    <div className='dashboard-second-navigation-filters-mobile'>
                        <div className="dashboard-second-navigation-mobile-header">
                            <span className='content-codes-list-title text-left'>{t(`${ACTIVE_TAB_TRANSLATIONS[activeTab]}`)}</span>
                            <div className='dashboard-second-navigation-mobile-header-actions'>
                                <div onClick={() => setShowMobileSettings(true)} className={`user-dashboard-second-navigation-button`}>
                                    <img width={17} src={dashboardMobileSettings} alt="" />
                                </div>
                                {view === 'Grid' ? (
                                    <div onClick={() => handleSetView('List')} className={`user-dashboard-second-navigation-button ${view === 'List' ? 'active' : ''}`}>
                                        <img width={21} src={userDashboardListView} alt="" />
                                    </div>
                                ) : (
                                    <div onClick={() => handleSetView('Grid')} className={`user-dashboard-second-navigation-button ${view === 'Grid' ? 'active' : ''}`}>
                                        <img width={17} src={userDashboardGridView} alt="" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='dashboard-second-navigation-mobile-code-actions'>
                            <button onClick={handleCodeCreation} className='user-dashboard-second-navigation-create-code'>
                                <img width={16} src={dashboardNavigationPlus} alt="" />
                                {t("createCode")}
                            </button>
                            <Dropdown className='w-full'>
                                <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown dropdown-disabled w-full' id="dropdown-basic">
                                    <div className='user-dashboard-second-navigation-default-button'>
                                        <img width={16} src={dashboardMobileMore} alt="" />
                                        {t("more")}
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='filters-dropdown-menu filters-dropdown-menu-padding-mobile'>
                                    <Dropdown.Item
                                        disabled={isMultipleConvertion ? true : false}
                                        onClick={handleConvertAllCodesToDynamic}
                                        style={{ borderRadius: '8px' }}
                                        className='bootstrap-default-item bootstrap-bold-item p-0'
                                    >
                                        <div style={{ height: '36px' }} className='dashboard-more-options-item-wrapper justify-between px-3 border-0'>
                                            {t("convertAllToDynamic")}
                                            <img width={16} src={userDashboardConvert} alt="" />
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={handleDownloadAllCodes}
                                        style={{ borderRadius: '8px' }}
                                        className='bootstrap-default-item bootstrap-bold-item p-0'
                                    >
                                        <div style={{ height: '36px' }} className='dashboard-more-options-item-wrapper justify-between px-3 border-0'>
                                            {t("downloadAll")}
                                            <img width={16} src={dashboardNavigationDownload} alt="" />
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#FFFFFF', position: 'relative' }} className='dashboard-content-container-wrapper'>
                        {overviewCode && (
                            <OverviewPanel
                                trialDaysLeft={trialDaysLeft}
                                downloadCode={downloadCode}
                                code={overviewCode}
                                handleEditQrCode={handleEditQrCode}
                                handleArchiveDynamicCode={handleArchiveDynamicCode}
                                handleDeleteCode={handleDeleteCode}
                                handleNavigateToPreviewCode={handleNavigateToPreviewCode}
                                setOverviewCode={setOverviewCode}
                                handleConvertData={handleConvertData}
                                handleArchiveCode={handleArchiveCode}
                                setArchiveId={setArchiveId}
                                handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                                handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                                setDeleteId={setDeleteId}
                            />
                        )}
                        {(codeName || desktopCreation) && !isMobile ? (
                            <UserCreateCode
                                handleTriggerAndGenerate={handleTriggerAndGenerate}
                                triggerValidation={triggerValidation}
                                setIsValid={setIsValid}
                                isValid={isValid}
                                showHelpModal={showHelpModal}
                                setShowHelpModal={setShowHelpModal}
                            />
                        ) : (
                            <div ref={codesBlockRef} className='dashboard-content-container-left-side'>
                                <div style={{ paddingTop: '0px' }} className='dashboard-content-codes-list'>
                                    {view === 'List' ? (
                                        <>
                                            {isMobile ? (
                                                <>
                                                    {codesList && codesList?.items?.length > 0 && codesList?.items?.map((code) => {
                                                        return (
                                                            <CodeBlock
                                                                key={code.id}
                                                                trialDaysLeft={trialDaysLeft}
                                                                view={view}
                                                                code={code}
                                                                handleClickOnCode={handleClickOnCode}
                                                                handleArchiveCode={handleArchiveCode}
                                                                handleDeleteCode={handleDeleteCode}
                                                                downloadCode={downloadCode}
                                                                handleArchiveDynamicCode={handleArchiveDynamicCode}
                                                                handleConvertData={handleConvertData}
                                                                setArchiveId={setArchiveId}
                                                                setDeleteId={setDeleteId}
                                                                handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                                                                handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                                                                archiveId={archiveId}
                                                                isMobile={isMobile}
                                                            />
                                                        )
                                                    })}
                                                </>
                                            ) : (
                                                <table className='dashboard-content-line-table'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ paddingLeft: '8px' }}>{t("name")}</th>
                                                            <th>{t("contentType")}</th>
                                                            <th>{t("codeType")}</th>
                                                            <th>{t("totalScans")}</th>
                                                            <th>{t("status")}</th>
                                                            <th>{t("trend")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {codesList && codesList?.items?.length > 0 && codesList?.items?.map((code) => {
                                                            return (
                                                                <CodeBlock
                                                                    key={code.id}
                                                                    trialDaysLeft={trialDaysLeft}
                                                                    view={view}
                                                                    code={code}
                                                                    handleClickOnCode={handleClickOnCode}
                                                                    handleArchiveCode={handleArchiveCode}
                                                                    handleDeleteCode={handleDeleteCode}
                                                                    downloadCode={downloadCode}
                                                                    handleArchiveDynamicCode={handleArchiveDynamicCode}
                                                                    handleConvertData={handleConvertData}
                                                                    setArchiveId={setArchiveId}
                                                                    setDeleteId={setDeleteId}
                                                                    handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                                                                    handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                                                                    archiveId={archiveId}
                                                                    isMobile={isMobile}
                                                                />
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </>
                                    ) : (
                                        <div className='content-codes-list-wrapper'>
                                            {codesList && codesList?.items?.length > 0 && codesList?.items?.map((code) => {
                                                return (
                                                    <CodeBlock
                                                        key={code.id}
                                                        trialDaysLeft={trialDaysLeft}
                                                        view={view}
                                                        code={code}
                                                        handleClickOnCode={handleClickOnCode}
                                                        handleArchiveCode={handleArchiveCode}
                                                        handleDeleteCode={handleDeleteCode}
                                                        downloadCode={downloadCode}
                                                        handleArchiveDynamicCode={handleArchiveDynamicCode}
                                                        handleConvertData={handleConvertData}
                                                        setArchiveId={setArchiveId}
                                                        setDeleteId={setDeleteId}
                                                        handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                                                        handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                                                        archiveId={archiveId}
                                                        isMobile={isMobile}
                                                    />
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                                {((isMobile && codesList?.total > 10) || (!isMobile && codesList?.total > 25)) && !desktopCreation && !mobileCreation && (
                                    <Pagination
                                        activePage={activePage}
                                        pages={pages}
                                        handlePage={handlePage}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {
                    convertData?.id && canvasStyles && (
                        <div style={{ position: 'absolute', top: '-2111px' }} className='p-2 mt-6 w-fit h-fit' ref={canvasRef}>
                            <div style={{ ...canvasStyles, backgroundColor: convertData?.styles?.background, color: convertData?.styles?.scanColor, fontFamily: convertData?.styles?.scanFont }} className='qr-code-result-wrapper'>
                                <QRCodeSVG
                                    value={`${window.location.origin}/code/${publicId}`}
                                    bgColor={convertData?.styles?.background}
                                    fgColor={convertData?.styles?.foreground}
                                    level='L'
                                    size={256}
                                    imageSettings={{
                                        src: convertData?.styles?.icon ? convertData?.styles?.icon : '',
                                        height: 48,
                                        width: 48,
                                        excavate: false,
                                    }}
                                />
                                {convertData?.styles?.scanText}
                            </div>
                        </div>
                    )
                }
                {
                    showMobileSettings && (
                        <div className='link-filter-background'>
                            <div ref={mobileFiltersRef} className='link-filter-block'>
                                <div onClick={() => setShowMobileSettings(false)} className='mobile-tray-horizontal-bar'>
                                    <div className='mobile-tray-horizontal-bar-element'></div>
                                </div>
                                {showMobileSort ? (
                                    <>
                                        <div className='mobile-filter-expand-block-header'>
                                            <div onClick={() => setShowMobileSort(false)} className='analytics-mobile-filter-header-with-back'>
                                                <img src={overviewPanelMobileBack} alt="" />
                                                <span className='mobile-filter-expand-block-title'>{t("sort")}</span>
                                            </div>
                                            <span onClick={handleClearSortFilters} className='mobile-filter-expand-block-reset'>{t("clear")}</span>
                                        </div>
                                        <span className='link-filter-title-underline'></span>
                                        <div style={{ marginTop: '16px' }} className='sort-dropdown-menu-list'>
                                            <div onClick={() => setSelectedSortFilter('dynamic')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'dynamic' ? 'active' : ''}`}>
                                                {t("dynamic")}
                                                {selectedSortFilter === 'dynamic' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                            <div onClick={() => setSelectedSortFilter('static')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'static' ? 'active' : ''}`}>
                                                {t("static")}
                                                {selectedSortFilter === 'static' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                            <div onClick={() => setSelectedSortFilter('newest_to_oldest')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'newest_to_oldest' ? 'active' : ''}`}>
                                                {t("newest")}
                                                {selectedSortFilter === 'newest_to_oldest' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                            <div onClick={() => setSelectedSortFilter('oldest_to_newest')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'oldest_to_newest' ? 'active' : ''}`}>
                                                {t("oldest")}
                                                {selectedSortFilter === 'oldest_to_newest' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                            <div onClick={() => setSelectedSortFilter('edited')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'edited' ? 'active' : ''}`}>
                                                {t("edited")}
                                                {selectedSortFilter === 'edited' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                            <div onClick={() => setSelectedSortFilter('atoz')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'atoz' ? 'active' : ''}`}>
                                                A-Z
                                                {selectedSortFilter === 'atoz' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                            <div onClick={() => setSelectedSortFilter('ztoa')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'ztoa' ? 'active' : ''}`}>
                                                Z-A
                                                {selectedSortFilter === 'ztoa' && (
                                                    <img src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : showMobileFilters ? (
                                    <>
                                        <div className='mobile-filter-expand-block-header'>
                                            <div onClick={() => setShowMobileFilters(false)} className='analytics-mobile-filter-header-with-back'>
                                                <img src={overviewPanelMobileBack} alt="" />
                                                <span className='mobile-filter-expand-block-title'>{t("filter")}</span>
                                            </div>
                                            <span
                                                onClick={handleClearKindFilters}
                                                className={`mobile-filter-expand-block-reset ${selectedKindsList.length === 0 ? 'disabled' : ''}`}
                                            >
                                                {t("clear")}
                                            </span>
                                        </div>
                                        <span className='link-filter-title-underline'></span>
                                        <div style={{ marginTop: '16px' }} className='filters-dropdown-code-types-list'>
                                            {DASHBOARD_KIND_FILTER_LIST.map((kind, index) => (
                                                <span
                                                    key={index}
                                                    onClick={() => handleSelectFilterKind(kind)}
                                                    className={selectedKindsList.includes(kind) ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                                >
                                                    {t(`${USER_QR_TYPE_TRANSLATIONS[kind]}`)}
                                                </span>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='mobile-filter-expand-block-header'>
                                            <span className='mobile-filter-expand-block-title'>{t("settings")}</span>
                                            <span
                                                onClick={handleClearAllSettings}
                                                className={`mobile-filter-expand-block-reset ${selectedKindsList?.length === 0 && selectedSortFilter === 'newest_to_oldest' ? 'disabled' : ''}`}
                                            >
                                                {t("clearAll")}
                                            </span>
                                        </div>
                                        <span className='link-filter-title-underline'></span>
                                        <div className='dashboard-mobile-settings-options-list'>
                                            <div onClick={() => { setShowMobileFilters(true) }} className='analytics-mobile-settings-list-item'>
                                                <span className='analytics-header-option-label'>{t("filters")}</span>
                                                <img src={analyticsSettingsArrowRight} alt="" />
                                            </div>
                                            <div onClick={() => { setShowMobileSort(true) }} className='analytics-mobile-settings-list-item'>
                                                <span className='analytics-header-option-label'>{t("sort")}</span>
                                                <img src={analyticsSettingsArrowRight} alt="" />
                                            </div>
                                            <button
                                                disabled={selectedKindsList?.length > 0 || selectedSortFilter !== appliedSortFilter ? false : true}
                                                onClick={handleApplySettingsFilters}
                                                className={`analytics-filters-apply-btn ${selectedKindsList?.length > 0 || selectedSortFilter !== appliedSortFilter ? 'active' : ''}`}
                                            >
                                                {t("apply")}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                }
                {!mobileCreation && !desktopCreation && (
                    <>
                        {showFaq ? (
                            <img onClick={() => setShowFaq(false)} className='faq-open-image' src={FaqHide} alt="" />
                        ) : (
                            <img onClick={handleShowFaq} className='faq-open-image' src={FaqOpen} alt="" />
                        )}
                    </>
                )}
                {
                    mobileCreation && (
                        <MobileCreationFlow
                            handleConvertData={handleConvertData}
                            showHelpModal={showHelpModal}
                            setShowHelpModal={setShowHelpModal}
                        />
                    )
                }
                {
                    showTrialEndModal && (
                        <TrialEndModal show={showTrialEndModal} onClose={() => setShowTrialEndModal(false)} />
                    )
                }
                {
                    showArchiveConfirmModal && (
                        <ArchiveConfrimModal
                            show={showArchiveConfirmModal}
                            onClose={() => setShowArchiveConfirmModal(false)}
                            handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                            archiveId={archiveId}
                            setOverviewCode={setOverviewCode}
                        />
                    )
                }
                {
                    showDeleteConfirmModal && (
                        <DeleteConfirmModal
                            show={showDeleteConfirmModal}
                            onClose={() => setShowDeleteConfirmModal(false)}
                            handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                            setOverviewCode={setOverviewCode}
                        />
                    )
                }
                {
                    showFeedbackModal && (
                        <UserFeedbackModal
                            show={showFeedbackModal}
                            onClose={() => setShowFeedbackModal(false)}
                        />
                    )
                }
                {
                    showOnboardingModal && (
                        <OnboardingModal
                            show={showOnboardingModal}
                            onClose={() => setShowOnboardingModal(false)}
                        />
                    )
                }
                {
                    showFaq && (
                        <FaqSection
                            setShowFaq={setShowFaq}
                        />
                    )
                }
            </div >
            {
                showConvertToDynamicModal && (
                    <ConvertStaticCodesToDynamicModal
                        show={showConvertToDynamicModal}
                        onClose={() => setShowConvertToDynamicModal(false)}
                        staticCodes={staticCodes}
                        handleConvertAllCodesToDynamic={handleConvertAllCodesToDynamic}
                        handleConvertSelectedCodesToDynamic={handleConvertSelectedCodesToDynamic}
                    />
                )
            }
        </div>
    )
}

export default UserDashboard