import React from 'react'
import { useTranslation } from 'react-i18next'

const NotificationItemTitle = ({ data, kind }) => {
    const { t } = useTranslation()

    return (
        <>
            {kind === 'first_scan' ? (
                <h6 className='notifications-panel-item-title'>“{data.qr_code_name}” {t("gotItsFirstScan")}</h6>
            ) : kind === 'track_analytics' ? (
                <h6 className='notifications-panel-item-title'>{t("trackYourAnalytics")}</h6>
            ) : kind === 'scan_limit' ? (
                <h6 className='notifications-panel-item-title'>{t("approachingScanLimit")}</h6>
            ) : kind === 'new_scan' ? (
                <h6 className='notifications-panel-item-title'>
                    “{data.qr_code_name}” {t("has")} {data.scans} {t("new")} {data.scans === 1 ? t("scan") : t("scansLowercase")}
                </h6>
            ) : kind === 'company_announcement' ? (
                <h6 className='notifications-panel-item-title'>{t("companyAnnouncement")}</h6>
            ) : null}
        </>
    )
}

export default NotificationItemTitle