import { useEffect, useState } from "react";
import { useUserCodeCreationContext } from "../context/UserQrCreationContext";

export const useViewport = () => {
    const { setShowMobilePreview, setMobileCreation, setMobileCreationStep } = useUserCodeCreationContext()

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
                setShowMobilePreview(false)
                setMobileCreation(false)
                setMobileCreationStep(1)
            }
        };

        handleWindowResize()
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [setShowMobilePreview, setMobileCreation, setMobileCreationStep]);

    return { isMobile };
}