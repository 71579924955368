import { GoogleAuthProvider, reauthenticateWithPopup } from 'firebase/auth'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'flowbite-react'

import { handleValidatePassword } from '../../helpers/functions'
import { auth } from '../../firebase/config'

const ConfirmAccountDeletionModal = ({ show, onClose, setShowAccountDeletionFeedbackModal }) => {
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const user = useSelector((state) => state.app.user)

    const rootRef = useRef(null)

    const { t } = useTranslation()

    const handleConfirmDelete = async () => {
        if (user?.providerData[0]?.providerId === 'password') {
            const res = await handleValidatePassword(password)

            if (res) {
                setShowAccountDeletionFeedbackModal(true)
                onClose()
            } else {
                setError(true)
            }
        } else {
            const currentUser = auth.currentUser;

            const googleProvider = new GoogleAuthProvider();

            googleProvider.setCustomParameters();

            reauthenticateWithPopup(currentUser, googleProvider)
                .then(function () {
                    setShowAccountDeletionFeedbackModal(true)
                    onClose()
                })
                .catch(function () {
                    setError(true)
                });
        }
    }

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="md"
                popup="false"
                position='center'
                onClose={onClose}
                style={{ height: '100vh' }}
                className='enter-password-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='trial-end-modal-body padding-top-24'>
                    <div className='confirm-account-deletion-modal-wrapper'>
                        <h5 className='confirm-account-deletion-modal-title'>{t("areYouSureYouWantToDeleteAccount")}</h5>
                        <p className='confirm-account-deletion-modal-description'>
                            {user?.providerData[0]?.providerId === 'password' ?
                                t("enterYourQRCodeDeveloperPasswordToProceedWithTheCancelation") : t("pleaseReAuthenticateWithGoogleToProceedDeletion")}
                        </p>
                        {user?.providerData[0]?.providerId === 'password' && (
                            <div className='flex flex-col'>
                                <div className='profile-settings-input-wrapper relative'>
                                    <input
                                        className='profile-settings-deletion-input h-full mb-0'
                                        placeholder={t("password")}
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value); setError(false) }}
                                    />
                                    {showPassword ? (
                                        <FiEye onClick={() => setShowPassword(false)} color={"#BEC5CF"} className='profile-settings-password-eye' size={20} />
                                    ) : (
                                        <FiEyeOff onClick={() => setShowPassword(true)} color={"#BEC5CF"} className='profile-settings-password-eye' size={20} />
                                    )}
                                </div>
                                {error && (
                                    <p className='error-text mt-2'>
                                        {t("invalidCredentialsProvidedPleaseTryAgain")}
                                    </p>
                                )}
                            </div>
                        )}
                        <button onClick={handleConfirmDelete} disabled={user?.providerData[0]?.providerId !== 'password' || password ? false : true} className='confirm-account-deletion-modal-button'>{t("deleteAccount")}</button>
                        <button onClick={onClose} className='confirm-account-deletion-modal-keep'>{t("keepAccount")}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ConfirmAccountDeletionModal