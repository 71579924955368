import React, { useMemo, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from 'react-device-detect'
import i18next from '../i18n/config'

import { useGetUserSettingsQuery, useGetUserSubscriptionQuery } from "../api/api";
import { EXISTING_TRANSLATIONS } from "../helpers/translation-constants";
import { setUser } from "../redux/appSlice";
import { auth } from "../firebase/config";

const AuthContext = React.createContext();

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const user = useSelector((state) => state.app.user)
    const settings = useSelector((state) => state.app.settings)
    const subscription = useSelector((state) => state.app.subscription)
    const [flow, setFlow] = useState(false)
    const [expandNavigation, setExpandNavigation] = useState(false)
    const [googleWithDownlaod, setGoogleWithDownload] = useState(false)
    const [fromEngine, setFromEngine] = useState('')
    const [engineClickId, setEngineClickId] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const dispatch = useDispatch()

    const domainLanguage = useMemo(() => {
        // Get the language from the path
        const path = window.location.pathname
        const pathArray = path.split('/')
        const language = pathArray[1]

        // Check if the language exists in the translations
        const checkExisting = EXISTING_TRANSLATIONS[language]

        return checkExisting ? checkExisting : 'en'
    }, [])

    const { data } = useGetUserSubscriptionQuery({ fromEngine, isMobile, engineClickId, domainLanguage }, { skip: !user, refetchOnMountOrArgChange: true })
    useGetUserSettingsQuery(undefined, { skip: !user || !subscription })

    React.useEffect(() => {
        // Redirect from pt.qrcodeveloper website to new path translations structure
        if (window.location.origin.includes('pt.')) {
            const origin = window.location.origin.replace('pt.', '')
            const pathname = '/pt' + window.location.pathname

            window.location.href = origin + pathname
        }
    }, [])

    React.useEffect(() => {
        if (window.innerWidth > 900 && !window.location.pathname.includes('/upgrade-plan')) {
            setExpandNavigation(true)
        }
    }, [])

    React.useEffect(() => {
        if (settings) {
            i18next.changeLanguage(settings.language)

            if (localStorage.getItem("language") !== settings.language) {
                localStorage.setItem("language", settings.language)
            }
        } else {
            const browserLanguage = navigator.language.split("-")[0]
            const translationsBrowserLanguage = EXISTING_TRANSLATIONS[browserLanguage] ?? 'en'

            if (domainLanguage !== 'en') { // If the user is on a subdomain, change the language to the subdomain language
                i18next.changeLanguage(domainLanguage)
            } else if (translationsBrowserLanguage !== 'en') { // Change the language to the browser language
                i18next.changeLanguage(translationsBrowserLanguage)
            } else {
                i18next.changeLanguage('en')
            }
        }
    }, [settings, domainLanguage])

    React.useEffect(() => {
        if (data) {
            setFlow(false)
            setFromEngine('')
            setEngineClickId('')
            localStorage.removeItem('fromEngine')
            localStorage.removeItem('engineClickId')
        }
    }, [data])

    React.useEffect(() => {
        const googleClickId = searchParams.get('gclid')
        const bingClickId = searchParams.get('msclkid')
        const facebookClickId = searchParams.get('fbclid')

        if (googleClickId) {
            setFromEngine('Google')
            setEngineClickId(googleClickId)
            localStorage.setItem('fromEngine', 'Google')
            localStorage.setItem('engineClickId', googleClickId)
        } else if (bingClickId) {
            setFromEngine('Bing')
            setEngineClickId(bingClickId)
            localStorage.setItem('fromEngine', 'Bing')
            localStorage.setItem('engineClickId', bingClickId)
        } else if (facebookClickId) {
            setFromEngine('Facebook')
            setEngineClickId(facebookClickId)
            localStorage.setItem('fromEngine', 'Facebook')
            localStorage.setItem('engineClickId', facebookClickId)
        } else {
            const fromEngineStorage = localStorage.getItem('fromEngine')
            const engineClickIdStorage = localStorage.getItem('engineClickId')

            if (fromEngineStorage) {
                setFromEngine(fromEngineStorage)
            }

            if (engineClickIdStorage) {
                setEngineClickId(engineClickIdStorage)
            }
        }
    }, [searchParams])

    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(setUser({ user: user }))
            } else {
                dispatch(setUser({ user: null }))
            }

            const tokenRefreshInterval = 500000;
            const tokenRefreshTimer = setInterval(async () => {
                const currentUser = auth.currentUser

                if (currentUser) {
                    const tokenResult = await currentUser.getIdTokenResult();

                    if (tokenResult) {
                        const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();
                        const currentTime = new Date().getTime();

                        const timeToExpiration = tokenExpirationTime - currentTime;

                        if (timeToExpiration < tokenRefreshInterval) {
                            try {
                                const newToken = await currentUser.getIdToken(true);

                                dispatch(
                                    setUser({
                                        user: {
                                            ...user,
                                            accessToken: newToken
                                        }
                                    })
                                )
                            } catch (error) {
                                console.log('Error refreshing ID token:', error);
                            }
                        }
                    }
                }
            }, 200000);

            const fromEmail = searchParams.get('from_email')

            if (fromEmail) {
                window.gtag('event', fromEmail, {
                    clicked_link: `${window.location.href}`,
                    email: user?.email,
                    user: user?.uid,
                })

                setSearchParams({})
            }
            return () => {
                clearInterval(tokenRefreshTimer)
            }
        });
    }, [dispatch]);

    const value = {
        flow, setFlow, setGoogleWithDownload,
        googleWithDownlaod, expandNavigation,
        setExpandNavigation
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
