import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { accountPlans } from '../../helpers/constants'

import PlanCard from '../../components/PlanCard/PlanCard'

const UpgradePlan = () => {
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (subscription && subscription.plan && subscription.plan !== 'Free') {
            navigate('/')
        }

        if (subscription && subscription.new_flow) {
            navigate('upgrade-plan-new')
        }
    }, [subscription, navigate])

    return (
        <div className='upgrade-plan-wrapper'>
            <span className='upgrade-plan-title'>{t("upgradePlans")}</span>
            <div className='upgrade-plan-cards-list'>
                {accountPlans && accountPlans.map((plan, index) => (
                    <PlanCard key={index} plan={plan} />
                ))}
            </div>
        </div>
    )
}

export default UpgradePlan