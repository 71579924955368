import React, { useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useDeleteNotificationMutation, useUpdateNotificationMutation } from '../../api/api'
import useConvertTimeToTextFormat from '../../hooks/useConvertTimeToTextFormat'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import NotificationItemDescription from './NotificationItemDescription'
import NotificationItemTitle from './NotificationItemTitle'
import NotificationItemIcon from './NotificationItemIcon'

import NotificationsThreeDots from '../../assets/icons/notifications/three-dots.svg'
import NotificationsReadEye from '../../assets/icons/notifications/read-eye.svg'
import NotificationsDelete from '../../assets/icons/notifications/delete.svg'

const NotificationItem = ({ notification, setOpenNotificationsPanel }) => {
    const dropdownRef = useRef(null)

    const { t } = useTranslation()
    const { convertTimeToTextFormat } = useConvertTimeToTextFormat()

    const navigate = useCustomNavigate()

    const [readNotifications] = useUpdateNotificationMutation()
    const [deleteNotification] = useDeleteNotificationMutation()

    const handleNavigateToAnalytics = () => {
        readNotifications({ id: notification.id, read_all: false })
        navigate('/dashboard/analytics')
        setOpenNotificationsPanel(false)
    }

    const handleReadNotification = (id) => {
        readNotifications({ id, read_all: false })
    }

    const handleClickOnNotification = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target) && notification.qr_code) {
            if (!notification.read) {
                readNotifications({ id: notification.id, read_all: false })
            }
            navigate(`/overview-code/${notification.qr_code}`)
            setOpenNotificationsPanel(false)
        }
    }

    const handleNavigateToUpgrade = () => {
        navigate('/upgrade-plan-new')
        setOpenNotificationsPanel(false)
    }

    return (
        <div
            onClick={handleClickOnNotification}
            className={`
                notifications-panel-item 
                ${!notification.read ? 'unread' : 'just-read-notification'} 
                ${notification.qr_code ? 'cursor-pointer' : ''} 
            `}
        >
            <div className='notification-panel-item-main-info-block'>
                <NotificationItemIcon
                    kind={notification.kind}
                    data={notification.data}
                />
                <div className={`notifications-panel-item-information`}>
                    <NotificationItemTitle
                        data={notification.data}
                        kind={notification.kind}
                    />
                    <NotificationItemDescription
                        kind={notification.kind}
                        data={notification.data}
                        handleNavigateToUpgrade={handleNavigateToUpgrade}
                    />
                    <span className='notifications-panel-item-time'>{convertTimeToTextFormat(notification.created_at)}</span>
                    {!notification.read && (
                        <div className='notification-panel-item-unread'></div>
                    )}
                    {(notification.kind !== 'scan_limit' || !notification.read) && (
                        <Dropdown ref={dropdownRef} className='notification-panel-item-dropdown'>
                            <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                                <div className='notifications-panel-item-more-options-block'>
                                    <img src={NotificationsThreeDots} alt="" />
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleReadNotification(notification.id)} className='bootstrap-default-item'>
                                    {t("markAs")} {notification.read ? t("unreadLowercase") : t("read")}
                                    <img src={NotificationsReadEye} alt="" />
                                </Dropdown.Item>
                                {notification.kind !== 'scan_limit' && (
                                    <Dropdown.Item onClick={() => deleteNotification(notification.id)} className='bootstrap-default-item'>
                                        {t("removeThisNotification")}
                                        <img src={NotificationsDelete} alt="" />
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
            {notification.kind === 'track_analytics' && (
                <button disabled={notification.read} onClick={handleNavigateToAnalytics} className='notification-panel-item-button'>{t("goToStatistics")}</button>
            )}
        </div>
    )
}

export default NotificationItem