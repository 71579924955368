import React, { useCallback, useEffect, useState } from 'react'
import { BiArchiveIn, BiCopy, BiErrorCircle, BiTrashAlt } from 'react-icons/bi'
import { BsCheckCircleFill, BsLink45Deg } from 'react-icons/bs'
import { toast, cssTransition } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { CgClose } from 'react-icons/cg'

import dashboardDownload from '../assets/icons/dashboard-download.svg'
import savedDynamicSvg from '../assets/icons/saved-dynamic.svg'
import toastCheckSvg from '../assets/icons/toastcheck.svg'

const useToasts = () => {
    const [isMobile, setIsMobile] = useState(false);

    const { t } = useTranslation()

    const createdToastAnimation = cssTransition({
        enter: 'toast-enter',
        exit: 'toast-exit',
        collapse: false,
    });

    const archiveToast = useCallback((status) => {
        return toast(`${t("yourQRCodeWas")} ${status ? t("archivedLowercase") : t("activated")}.`, {
            icon: <BiArchiveIn size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const deleteToast = useCallback(() => {
        return toast(`${t("yourQRCodeWasDeleted")}`, {
            icon: <BiTrashAlt size={36} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const staticGeneratedToast = useCallback((codeData, handleConvertData) => {
        return toast(<div className='static-toast-block'>
            <span className='static-toast-block-text'>
                {codeData.name ? codeData.name : 'Untitled'} {t("wasSuccessfullyCreated")}
            </span>
            <button onClick={() => handleConvertData(codeData)} className='static-toast-convert-button'>{t("convertToDynamic")}</button>
        </div>, {
            icon: <BsCheckCircleFill size={36} fill="#7D8898" />,
            closeButton: <CgClose className='static-toast-close-button' size={25} color="#141315" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray static-created",
            hideProgressBar: false,
            autoClose: 5000,
            position: isMobile ? 'top-left' : 'bottom-right',
            transition: createdToastAnimation,
        })
    }, [isMobile, createdToastAnimation, t])

    const dynamicGeneratedToast = useCallback((codeName) => {
        return toast(<div style={{ lineHeight: '20px' }}>{codeName ? codeName : 'Untitled'} {t("wasSuccessfullyCreated")}</div>, {
            icon: <img src={toastCheckSvg} alt="" />,
            closeButton: <CgClose size={25} color="#141315" />,
            className: "toast-gradient dynamic-created",
            bodyClassName: "toast-body-gradient dynamic-created",
            hideProgressBar: false,
            autoClose: 5000,
            position: isMobile ? 'top-left' : 'bottom-right',
            transition: createdToastAnimation,
        })
    }, [isMobile, createdToastAnimation, t])

    const convertToDynamicToast = useCallback(() => {
        return toast(`${t("successYourQRCodeWasConvertedToDynamic")}`, {
            icon: <img src={toastCheckSvg} alt="" />,
            className: "toast-gradient",
            bodyClassName: "toast-body-gradient",
        })
    }, [t])

    const copiedToast = useCallback(() => {
        return toast(`${t("linkCopied")}`, {
            icon: <BiCopy size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const errorToast = useCallback((type) => {
        return toast(type === 'create' ? t("errorCreatingQRCode") : type === 'edit' ? t("errorSavingQRCode") : type === 'convert' ? t("errorConvertingQRCode") : t("somethingWentWrong"), {
            icon: <BiErrorCircle size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const limitToast = useCallback(() => {
        return toast(t("yourAccountQuotaForDynamicQRCodesExceeded"), {
            icon: <BiErrorCircle size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const contactEmailToast = useCallback(() => {
        return toast(t("yourEmailSent"), {
            icon: <BiErrorCircle size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const editedToast = useCallback(() => {
        return toast(t("yourQrCodeWasSaved"), {
            icon: <img src={savedDynamicSvg} alt="" />,
            className: "toast-gradient",
            bodyClassName: "toast-body-gradient",
        })
    }, [t])

    const copiedCodeToast = useCallback((isMobile) => {
        return toast(t("yourQRCodeWasCopied"), {
            icon: <BsLink45Deg size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
            position: isMobile ? 'top-right' : 'bottom-right',
        })
    }, [t])

    const downloadedToast = useCallback((isMobile) => {
        return toast(t("yourQRCodeWasDownloaded"), {
            icon: <img src={dashboardDownload} width={20} alt='' />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
            position: isMobile ? 'top-right' : 'bottom-right',
        })
    }, [t])

    const pauseToast = useCallback((status) => {
        return toast(`${t("yourQRCodeWas")} ${status ? t("pausedLowercase") : t("unpaused")}.`, {
            icon: <BiArchiveIn size={34} fill="#7D8898" />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
        })
    }, [t])

    const downloadedMultipleToast = useCallback(() => {
        return toast(t("yourQRCodesWereDownloaded"), {
            icon: <img src={dashboardDownload} width={20} alt='' />,
            className: "toast-gray",
            bodyClassName: "toast-body-gray",
            position: 'top-left',
        })
    }, [t])

    const allConvertedToast = useCallback(() => {
        return toast(t("successAllOfYourQRCodesHaveBeenConvertedToDynamic"), {
            icon: <img src={toastCheckSvg} alt="" />,
            className: "toast-gradient",
            bodyClassName: "toast-body-gradient",
        })
    }, [t])

    const profileInformationUpdatedToast = useCallback(() => {
        return toast(t("yourProfileInformationHasBeenUpdated"), {
            icon: <img src={toastCheckSvg} alt="" />,
            className: "toast-gradient",
            bodyClassName: "toast-body-gradient",
        })
    }, [t])

    const profileInformationUpdatedWithReloginToast = useCallback(() => {
        return toast(t("yourProfileInformationHasBeenUpdatedLoginWithNewCredentials"), {
            icon: <img src={toastCheckSvg} alt="" />,
            className: "toast-gradient",
            bodyClassName: "toast-body-gradient",
        })
    }, [t])

    useEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth < 900) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };

        handleWindowResize()
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return {
        archiveToast,
        deleteToast,
        staticGeneratedToast,
        dynamicGeneratedToast,
        convertToDynamicToast,
        copiedToast,
        errorToast,
        limitToast,
        contactEmailToast,
        editedToast,
        copiedCodeToast,
        downloadedToast,
        pauseToast,
        downloadedMultipleToast,
        allConvertedToast,
        profileInformationUpdatedToast,
        profileInformationUpdatedWithReloginToast,
    }
}

export default useToasts