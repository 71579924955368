import { useCallback, useEffect, useState } from "react";

import { useCreateQrCodeEventMutation } from "../api/api";

const useTrackUserBehavior = (data) => {
    const [startTime, setStartTime] = useState(Date.now());
    const [events, setEvents] = useState([]);

    const [trackEvents] = useCreateQrCodeEventMutation()

    const leavePage = useCallback(() => {
        const endTime = Date.now();
        const timeSpent = endTime - startTime;

        if (data?.id) {
            trackEvents({
                qr_code_id: data?.id,
                events: [...events, {
                    type: 'time',
                    info: timeSpent,
                }],
            })
        }
    }, [startTime, events, data?.id, trackEvents])

    const addEvent = useCallback((event) => {
        if (event.target.localName === 'img') {
            setEvents([...events, {
                type: 'image',
                info: event.target.src,
            }])
        } else if (event.target.localName === 'button') {
            setEvents([...events, {
                type: 'button',
                info: event.target.innerText,
            }])
        } else if (event.target.localName === 'span') {
            setEvents([...events, {
                type: 'text',
                info: event.target.innerText,
            }])
        } else if (event.target.localName === 'iframe') {
            setEvents([...events, {
                type: 'video',
                info: event.target.src,
            }])
        } else if (event.target.localName === 'video') {
            setEvents([...events, {
                type: 'video',
                info: event.target.src,
            }])
        }
    }, [events])


    useEffect(() => {
        window.addEventListener("beforeunload", leavePage);

        return () => {
            window.removeEventListener("beforeunload", leavePage);
        };
    }, [leavePage])

    useEffect(() => {
        document.addEventListener('click', addEvent)

        return () => {
            document.removeEventListener('click', addEvent)
        }
    }, [addEvent])

    return {
        startTime,
        setStartTime,
    }
}

export default useTrackUserBehavior