import React, { Fragment } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useViewport } from '../../hooks/useViewport'

import analyticsGreyArrow from '../../assets/icons/analytics-grey-arrow.svg'

const AnalyticsStatsBlock = ({
    uniqueScans,
    analyticsData,
    abTesting,
    selectedAbCode,
    selectedCode,
    handleExpandStatBlock,
    expandedStatBlocks,
    title,
    element,
    tableTitle
}) => {
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    return (
        <div className={`analytics-content-block ${abTesting && isMobile ? 'mobile' : ''}`}>
            {(analyticsData && analyticsData[element] && analyticsData[element]?.length > 0 && !abTesting) || (abTesting && (selectedCode || selectedAbCode) && analyticsData && analyticsData[element] && analyticsData[element]?.length > 0) ? (
                <h5 className='analytics-content-block-title'>
                    {uniqueScans ? t("unique") : t("total")} {title}
                    <img
                        onClick={() => handleExpandStatBlock(element)}
                        className={`analytics-content-block-expand-icon ${expandedStatBlocks[element] ? 'active' : ''}`}
                        src={analyticsGreyArrow}
                        alt=""
                    />
                </h5>
            ) : (
                <h5 className='analytics-content-block-title mobile'>
                    {uniqueScans ? t("unique") : t("total")} {title}
                    <span className='analytics-content-no-scans-text'>{abTesting && !selectedCode && !selectedAbCode ? t("noData") : t("noScans")}</span>
                </h5>
            )}
            {analyticsData && expandedStatBlocks[element] && ((analyticsData[element] && analyticsData[element]?.length > 0 && !abTesting) || (abTesting && (selectedCode || selectedAbCode) && analyticsData && analyticsData[element] && analyticsData[element]?.length > 0)) && (
                <>
                    {abTesting && (selectedCode || selectedAbCode) ? (
                        <>
                            {analyticsData[element]?.map((scan, idx) => (
                                <table key={idx} className='analytics-block-table analytics-block-table-compare'>
                                    <thead>
                                        <tr className='analytics-table-row'>
                                            <th className='analytics-table-header'>{scan?.name}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='analytics-table-row'>
                                            <td>
                                                <div className='analytics-table-cell-progress-block pe-0'>
                                                    <span className='analytics-table-cell-progress-count'>{selectedAbCode ? scan?.count_first : scan?.count}</span>
                                                    <div className='analytics-progress-bar-wrapper'>
                                                        <ProgressBar className='w-full' now={selectedAbCode ? scan?.percentage_first : scan?.percentage} />
                                                        {((selectedAbCode && scan?.percentage_first === 0) || (!selectedAbCode && scan?.percentage === 0)) && (
                                                            <div className='analytics-empty-progress-bar pink'></div>
                                                        )}
                                                    </div>
                                                    <span className='analytics-table-cell-percentage'>{selectedAbCode ? scan?.percentage_first : scan?.percentage}%</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='analytics-table-row'>
                                            <td>
                                                <div className='analytics-table-cell-progress-block pe-0'>
                                                    <span className='analytics-table-cell-progress-count'>
                                                        {selectedAbCode && scan?.count_second !== undefined ? scan?.count_second : 'N/A'}
                                                    </span>
                                                    <div className='analytics-progress-bar-wrapper'>
                                                        <ProgressBar className='w-full' now={selectedAbCode ? scan?.percentage_second : 0} />
                                                        {(!selectedAbCode || (selectedAbCode && scan?.percentage_second === 0)) && (
                                                            <div className='analytics-empty-progress-bar purple'></div>
                                                        )}
                                                    </div>
                                                    <span className='analytics-table-cell-percentage'>
                                                        {selectedAbCode && scan?.percentage_second !== undefined ? `${scan?.percentage_second}%` : 'N/A'}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))}
                        </>
                    ) : (
                        <>
                            {isMobile ? (
                                <div className='analytics-block-table-mobile'>
                                    <div className='analytics-block-table-mobile-header'>
                                        <span className='analytics-table-header'>{tableTitle}</span>
                                        <span className='analytics-table-header'>{t("scans")} %</span>
                                    </div>
                                    {analyticsData[element]?.map((scan, idx) => (
                                        <div key={idx} className='analytics-block-table-mobile-content-wrapper'>
                                            <span className='analytics-table-cell-title'>{scan.name}</span>
                                            <div className='analytics-table-cell-progress-block pr-0'>
                                                <span className='analytics-table-cell-progress-count'>{scan.count}</span>
                                                <ProgressBar className='w-full mr-2' now={scan.percentage} />
                                                <span className='analytics-table-cell-percentage'>{scan.percentage}%</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <table className='analytics-block-table'>
                                    <thead>
                                        <tr className='analytics-table-row'>
                                            <th style={{ width: '45%' }} className='analytics-table-header'>{tableTitle}</th>
                                            <th className='analytics-table-header'>{t("scans")}</th>
                                            <th className='analytics-table-header'>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {analyticsData[element]?.map((scan, idx) => (
                                            <tr key={idx} className='analytics-table-row'>
                                                <td className='analytics-table-cell-title'>{scan.name}</td>
                                                <td>
                                                    <div className='analytics-table-cell-progress-block'>
                                                        <span className='analytics-table-cell-progress-count'>{scan.count}</span>
                                                        <ProgressBar className='w-full' now={scan.percentage} />
                                                    </div>
                                                </td>
                                                <td className='analytics-table-cell-percentage'>{scan.percentage}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default AnalyticsStatsBlock