import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import Cookies from 'js-cookie'

import { useFacebookConversionPixelMutation, useSendEmailSendgridUpdatesYourAccountMutation } from '../../api/api'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import useAccountNewPlans from '../../hooks/useAccountNewPlans'
import { accountPlans } from '../../helpers/constants'

import paymentSuccessSvg from '../../assets/icons/payment-success.svg'

const UpgradeSuccess = () => {
    const subscription = useSelector((state) => state.app.subscription)
    const [searchParams, setSearchParams] = useSearchParams()

    const [sendEmailSendgridUpdatesYourAccount] = useSendEmailSendgridUpdatesYourAccountMutation()

    const { t } = useTranslation()
    const { accountNewPlans } = useAccountNewPlans()

    const navigate = useCustomNavigate()

    const [facebookPixel] = useFacebookConversionPixelMutation()

    useEffect(() => {
        const plan = searchParams.get('planName')

        if (plan && subscription) {

            sendEmailSendgridUpdatesYourAccount({
                plan_name: subscription.plan,
                plan_name_new: plan,
            })
            let planPrice;

            if (subscription?.new_flow) {
                planPrice = accountNewPlans.find((el) => el.shortName === plan)?.yearlyPrice

                window.gtag('event', 'conversion', {
                    'send_to': 'AW-11350401889/twi4CJrMueoYEOHGpaQq',
                    'value': planPrice['en'],
                    'currency': 'USD'
                });

                facebookPixel({
                    currency: 'usd',
                    value: planPrice['en'],
                    fbp: Cookies.get('_fbp'),
                    fbc: Cookies.get('_fbc'),
                })
            } else {
                planPrice = accountPlans.find((el) => el.shortName === plan)?.yearlyPrice

                window.gtag('event', 'conversion', {
                    'send_to': 'AW-11350401889/g8-dCJfMueoYEOHGpaQq',
                    'value': planPrice,
                    'currency': 'USD'
                });

                facebookPixel({
                    currency: 'usd',
                    value: planPrice,
                    fbp: Cookies.get('_fbp'),
                    fbc: Cookies.get('_fbc'),
                })
            }

            window.uetq = window.uetq || [];
            window.uetq.push(
                'event',
                'subscribe',
                {
                    "revenue_value": Number(subscription?.new_flow ? planPrice['en'] : planPrice) ?? 119.88,
                    "currency": "USD"
                }
            );

            if (!localStorage.getItem('paymentTime')) {
                localStorage.setItem('paymentTime', new Date())
            }

            setSearchParams({})
        }
    }, [searchParams, subscription])

    return (
        <div className='upgrade-success-wrapper'>
            <img className='upgrade-success-img' src={paymentSuccessSvg} alt="" />
            <span className='upgrade-success-text'>{t("yourPaymentWasSuccessful")}</span>
            <button onClick={() => navigate('/dashboard')} className='upgrade-success-button'>{t("continueToDashboard")}</button>
        </div>
    )
}

export default UpgradeSuccess