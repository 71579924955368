import React from 'react'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import WebsiteReload from '../../assets/icons/code-create/website-reload.svg'
import WebsiteMenu from '../../assets/icons/code-create/website-menu.svg'

const WebsitePreview = ({ data, isContentAdded = true }) => {
    return (
        <>
            {isContentAdded ? (
                <div className='dynamic-website-wrapper'>
                    <div className='dynamic-website-header'>
                        <img className='dynamic-website-menu' src={WebsiteMenu} alt="" />
                        <div className='website-preview-url-block'>
                            <span>
                                {data.website}
                            </span>
                        </div>
                        <img className='dynamic-website-reload' src={WebsiteReload} alt="" />
                    </div>
                    <div className='flex items-center gap-1 justify-center mt-[50px] w-full'>
                        <div className='w-[35%] bg-[#BEC5CF] h-[25px] rounded-lg'></div>
                        <div className='w-[25%] bg-[#BEC5CF] h-[25px] rounded-lg'></div>
                    </div>
                    <div className='mx-auto h-[25px] rounded-lg w-[70%] bg-[#BEC5CF] mt-[4px]'></div>
                    <div className='mx-auto h-[8px] rounded-lg w-[70%] bg-[#E2E8F0] mt-[20px]'></div>
                    <div className='mx-auto h-[8px] rounded-lg w-[90%] bg-[#E2E8F0] mt-[4px]'></div>
                    <div className='mx-auto h-[8px] rounded-lg w-[80%] bg-[#E2E8F0] mt-[4px]'></div>
                    <div className='w-full bg-[#EDF3F9] p-[12px] mt-[30px] rounded-lg pt-[80px]'>
                        <div className='w-[45%] h-[15px] bg-[#BEC5CF] rounded-[4px]'></div>
                        <div className='w-[60%] h-[10px] bg-[#E2E8F0] rounded-[4px] mt-[4px]'></div>
                    </div>
                    <div className='w-full bg-[#EDF3F9] p-[12px] mt-[10px] rounded-lg pt-[80px]'>
                        <div className='w-[45%] h-[15px] bg-[#BEC5CF] rounded-[4px]'></div>
                        <div className='w-[60%] h-[10px] bg-[#E2E8F0] rounded-[4px] mt-[4px]'></div>
                    </div>
                </div>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["URL"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["URL"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default WebsitePreview