import React from 'react'
import ReactCountryFlag from "react-country-flag"

import { findCountryCodeByName } from '../../helpers/functions'

import NotificationsCompanyAnnouncement from '../../assets/icons/notifications/company-announcement.svg'
import NotificationsTrackAnalytics from '../../assets/icons/notifications/track-analytics.svg'
import NotificationsFirstScan from '../../assets/icons/notifications/first-scan.svg'
import NotificationsScanLimit from '../../assets/icons/notifications/scan-limit.svg'

const NotificationItemIcon = ({ kind, data }) => {
    return (
        <span className='notification-item-icon'>
            {kind === 'first_scan' ? (
                <img src={NotificationsFirstScan} alt="" />
            ) : kind === 'track_analytics' ? (
                <img src={NotificationsTrackAnalytics} alt="" />
            ) : kind === 'scan_limit' ? (
                <img src={NotificationsScanLimit} alt="" />
            ) : kind === 'new_scan' ? (
                <div className='notifications-panel-item-many-countries-block'>
                    {data.location.length === 1 ? (
                        <ReactCountryFlag
                            countryCode={findCountryCodeByName(data.location[0])}
                            svg
                            style={{ width: '44px', height: '44px', objectFit: 'cover' }}
                        />
                    ) : (
                        <div className='notifications-panel-item-many-countries-block'>
                            {data.location.map((country, index) => (
                                <ReactCountryFlag
                                    key={index}
                                    countryCode={findCountryCodeByName(country)}
                                    svg
                                    style={{ width: '20px', height: '20px', objectFit: 'cover' }}
                                />
                            ))}
                        </div>
                    )}
                </div >
            ) : kind === 'company_announcement' ? (
                <img src={NotificationsCompanyAnnouncement} alt="" />
            ) : null}
        </span>
    )
}

export default NotificationItemIcon