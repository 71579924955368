import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowUp } from 'react-icons/io'

import { FAQ_TYPES_TRANSLATIONS } from '../../helpers/translation-constants'
import useReturnFaqQuestions from './useReturnFaqQuestions'
import { useViewport } from '../../hooks/useViewport'

import FaqMobileClose from '../../assets/icons/faq/mobile-close.svg'

export const FAQ_TYPES = ['Quick Start', 'Creating', 'Printing']

const FaqSection = ({ setShowFaq }) => {
    const [expandedFaqs, setExpandedFaqs] = useState([])
    const [faqType, setFaqType] = useState('Quick Start')

    const { t } = useTranslation()
    const { isMobile } = useViewport()
    const { FAQ_QUESTION_LIST } = useReturnFaqQuestions()

    const handleOpenFaqQuestion = (id) => {
        if (expandedFaqs.includes(id)) {
            setExpandedFaqs(expandedFaqs.filter((faqId) => faqId !== id))
        } else {
            setExpandedFaqs([...expandedFaqs, id])
        }
    }

    useEffect(() => {
        setExpandedFaqs([])
    }, [faqType])

    return (
        <div className='faq-section-wrapper'>
            <div className='faq-section-header'>
                <img onClick={() => setShowFaq(false)} className='faq-section-header-mobile-close' src={FaqMobileClose} alt="" />
                {isMobile ? 'FAQs' : t("frequentlyAskedQuestions")}
            </div>
            <div className='faq-section-content'>
                <div className='faq-content-question-types-filter-block'>
                    {FAQ_TYPES.map((type, index) => (
                        <div
                            key={index}
                            onClick={() => setFaqType(type)}
                            className={`faq-content-question-type-item ${faqType === type ? 'active' : ''}`}
                        >
                            {t(`${FAQ_TYPES_TRANSLATIONS[type]}`)}
                        </div>
                    ))}
                </div>
                <div className='faq-content-questions-list-block'>
                    {FAQ_QUESTION_LIST[faqType].map((faq) => (
                        <div onClick={() => handleOpenFaqQuestion(faq.id)} key={faq.id} className='faq-content-question-item'>
                            <div className={`faq-content-question-item-header ${expandedFaqs.includes(faq.id) ? 'opened' : ''}`}>
                                {faq.question}
                                <IoIosArrowUp />
                            </div>
                            {expandedFaqs.includes(faq.id) && (
                                <p className='faq-content-question-item-answer'>
                                    {faq.answer instanceof Array ? (
                                        <div>
                                            {faq.answer.map((answer, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <p key={index}>
                                                            {answer}
                                                        </p>
                                                    )
                                                } else {
                                                    return (
                                                        <li key={index}>
                                                            {answer}
                                                        </li>
                                                    )
                                                }
                                            })}
                                        </div>
                                    ) : (
                                        <>
                                            {faq.answer}
                                        </>
                                    )}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FaqSection