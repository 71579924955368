import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext'

const CodeNameInput = () => {
    const { codeName, setCodeName } = useUserCodeCreationContext()

    const { t } = useTranslation()

    return (
        <div className='flex flex-col block-inputs-column'>
            <label className='content-creation-input-label'>{t("qrCodeName")}</label>
            <input
                type='text'
                value={codeName}
                onChange={(e) => setCodeName(e.target.value)}
                placeholder={t("nameQrCode")}
                className="form-input-dashboard w-full"
            />
        </div>
    )
}

export default CodeNameInput