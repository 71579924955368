import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { ChromePicker } from 'react-color'
import { QRCodeSVG } from 'qrcode.react'

import { frames, icons } from '../CustomizeQrOption/CustomizeQrOption'

import { useEditQrCodeContext } from '../../context/EditQrCodeContext'

import qrExampleSvg from '../../assets/icons/qrExample.svg'

const EditCodeDesign = ({ mobile }) => {
    const pickerForegroundRef = useRef(null)
    const pickerBackgroundRef = useRef(null)

    const { t } = useTranslation()

    const {
        staticValue, backgroundColor, foregroundColor, imageSettings,
        canvasCreationRef, expandEditQrCode, setExpandEditQrCode,
        handleChangeForegroundColor, handleChangeBackgroundColor,
        handleForegroundColor, handleBackgroundColor, setForegroundColor,
        setBackgroundColor, selectForegroundColor, selectBackgroundColor,
        image, setImage, frame, setFrame, scanText, setScanText, scanColor,
        setScanColor, setSelectScanColor, selectScanColor, scanColorRef,
        scanFont, setScanFont, setSelectForegroundColor, setSelectBackgroundColor,
        canvasStyles,
    } = useEditQrCodeContext()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerForegroundRef.current && !pickerForegroundRef.current.contains(event.target)) {
                setSelectForegroundColor(false);
            } else if (pickerBackgroundRef.current && !pickerBackgroundRef.current.contains(event.target)) {
                setSelectBackgroundColor(false)
            } else if (scanColorRef.current && !scanColorRef.current.contains(event.target)) {
                setSelectScanColor(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [scanColorRef, setSelectForegroundColor, setSelectBackgroundColor, setSelectScanColor]);

    return (
        <div className='edit-design-section'>
            {!mobile && (
                <div className='edit-design-qr-code-header'>
                    <div className='qr-customize-option-header'>
                        <span className='qr-customize-option-title'>
                            {t("qrCode")}
                        </span>
                        <div className='qr-customize-option-header-actions'>
                            <div onClick={() => setExpandEditQrCode(!expandEditQrCode)} className='qr-option-customize-expand-button-with-background'>
                                {expandEditQrCode ? (
                                    <MdKeyboardArrowUp size={28} fill='#334155' />
                                ) : (
                                    <MdKeyboardArrowDown size={28} fill="#334155" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(expandEditQrCode || mobile) && (
                <>
                    <div className='dashboard-content-qr-code-block'>
                        {staticValue ? (
                            <div className='p-2 w-fit h-fit' ref={canvasCreationRef}>
                                <div style={{ ...canvasStyles, backgroundColor: backgroundColor, color: scanColor, fontFamily: scanFont }} className='qr-code-result-wrapper'>
                                    <QRCodeSVG
                                        value={staticValue}
                                        bgColor={backgroundColor}
                                        fgColor={foregroundColor}
                                        level='L'
                                        size={165}
                                        imageSettings={imageSettings}
                                    />
                                    {scanText}
                                </div>
                            </div>
                        ) : (
                            <div className='dashboard-content-qr-code-example'>
                                <img width={145} height={145} src={qrExampleSvg} alt="" />
                            </div>
                        )}
                    </div>
                    <div className='design-customize-colors'>
                        <span className='design-customize-section-title'>{t("colors")}</span>
                        <div className='flex flex-col gap-2'>
                            <span className='qr-customize-option-label'>{t("foregroundColor")}</span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={foregroundColor}
                                        onChange={(e) => setForegroundColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    onClick={handleForegroundColor}
                                    className='generator-color-block me-2'
                                    style={{ backgroundColor: `${foregroundColor}` }}
                                ></div>
                                {selectForegroundColor && (
                                    <div ref={pickerForegroundRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={handleChangeForegroundColor}
                                            color={foregroundColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span className='qr-customize-option-label flex items-center justify-between'>
                                {t("backgroundColor")}
                            </span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={backgroundColor}
                                        onChange={(e) => setBackgroundColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    className='generator-color-block me-2'
                                    onClick={handleBackgroundColor}
                                    style={{ backgroundColor: `${backgroundColor}` }}
                                ></div>
                                {selectBackgroundColor && (
                                    <div ref={pickerBackgroundRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={handleChangeBackgroundColor}
                                            color={backgroundColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='edit-design-underline'></div>
                    <div className='edit-design-social-icons'>
                        <span className='design-customize-section-title'>{t("socialIcons")}</span>
                        <div className='qr-color-picker-block edit-design-icons-block'>
                            {icons && icons.length > 0 && icons.map((el) => (
                                <div
                                    onClick={() => setImage(el.link)}
                                    key={el.id}
                                    className={el.link === image ? 'generator-image-block-selected generator-image-block' : 'generator-image-block'}
                                >
                                    {el.link === image ? el.selectSvgActive : el.selectSvg}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='edit-design-underline'></div>
                    <div className='edit-design-social-icons'>
                        <span className='design-customize-section-title'>{t("frames")}</span>
                        <div className='qr-color-picker-block edit-design-icons-block'>
                            {frames && frames.length > 0 && frames.map((el) => (
                                <div
                                    onClick={() => setFrame(el.id)}
                                    key={el.id}
                                    className={el.id === frame ? 'generator-image-block-selected generator-image-block' : 'generator-image-block'}
                                >
                                    {el.id === frame ? el.svgActive : el.svgDefault}
                                </div>
                            ))}
                        </div>
                        <div className='content-creation-input-wrapper'>
                            <label className='content-creation-input-label mb-1'>{t("text")}</label>
                            <div className='flex flex-col'>
                                <input
                                    type='text'
                                    className='form-input-dashboard w-full'
                                    placeholder={t("scanMe")}
                                    value={scanText}
                                    onChange={(e) => setScanText(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 relative'>
                            <span className='qr-customize-option-label'>{t("color")}</span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={scanColor}
                                        onChange={(e) => setScanColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    onClick={() => setSelectScanColor(true)}
                                    className='generator-color-block'
                                    style={{ backgroundColor: `${scanColor}` }}
                                ></div>
                                {selectScanColor && (
                                    <div ref={scanColorRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={(value) => setScanColor(value.hex)}
                                            color={scanColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span className='qr-customize-option-label flex items-center justify-between'>
                                {t("font")}
                            </span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block w-full me-0 dropdown-font-wrapper'>
                                    <Dropdown>
                                        <Dropdown.Toggle className='bootstrap-default-dropdown' id="dropdown-basic">
                                            {scanFont}  <MdKeyboardArrowDown size={28} fill="#334155" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setScanFont('Sans-Serif')} className='bootstrap-default-item'>Sans-Serif</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setScanFont('Inter')} className='bootstrap-default-item'>Inter</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setScanFont('Roboto')} className='bootstrap-default-item'>Roboto</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditCodeDesign