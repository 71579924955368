import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SlClose } from 'react-icons/sl'
import React from 'react'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

const PlanCard = ({ plan, isNew = false }) => {
    const user = useSelector((state) => state.app.user)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const handleChoosePlan = async (price_id) => {
        if (user) {
            navigate(`/upgrade-plan/checkout/${price_id}/${plan.shortName}`)
        } else {
            navigate('/sign-up')
        }
    }

    return (
        <div className={plan.best ? "upgrade-plan-card-block-best" : "upgrade-plan-card-block"}>
            <div className="flex flex-col">
                <span className="upgrade-plan-name">
                    {plan.name}
                </span>
                <div className='upgrade-plan-price-block-wrapper'>
                    <div className={`upgrade-plan-price-block ${isNew ? 'mb-4' : ''}`}>
                        <span className="upgrade-plan-cost">
                            ${plan.price}
                        </span>
                        <span className="upgrade-plan-period">
                            {plan.price_per_period}
                        </span>
                    </div>
                    {!isNew && (
                        <span style={{ color: plan.best ? '#334155' : '' }} className='upgrade-plan-billed-annually'>{t("billedAnnually")}</span>
                    )}
                </div>
                <ul className="upgrade-plan-options-list">
                    {plan.options.map((option, index) => {
                        if (option.available) {
                            return (
                                <li key={index} className="upgrade-plan-active-option">
                                    <BsFillCheckCircleFill className='min-width-upgrade-option-icon' size={24} fill='#9747FF' />
                                    <span className="upgrade-plan-active-option-text">
                                        {option.title}
                                    </span>
                                </li>
                            )
                        } else {
                            return (
                                <li key={index} className="upgrade-plan-active-option">
                                    <SlClose className='min-width-upgrade-option-icon' size={24} fill='#7D8898' />
                                    <span className="upgrade-plan-inactive-option-text">
                                        {option.title}
                                    </span>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
            <button onClick={() => handleChoosePlan(plan.price_id)} className="choose-plan-button">
                {user ? t("choosePlan") : t("upgrade")}
            </button>
            {
                plan.best && (
                    <div className='upgrade-plan-most-popular'>{t("mostPopular")}</div>
                )
            }
        </div>
    )
}

export default PlanCard