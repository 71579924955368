import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext';

import CreationContentDescription from '../../common/CreationContentDescription/CreationContentDescription';
import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';
import CodeNameInput from '../CodeNameInput/CodeNameInput';

const Text = ({ type, triggerValidation, kind, setIsValid }) => {
    const { setStaticValue, staticValue, dynamicTextStyles, setDynamicTextStyles, setIsContentAdded } = useUserCodeCreationContext()

    const { t } = useTranslation()

    const { register, getValues, trigger, formState: { errors, isValid } } = useForm({
        mode: 'onBlur',
    });

    const handleChangeText = async () => {
        setStaticValue(getValues('text'))
    }

    const handleChangeValue = async (name, value) => {
        setDynamicTextStyles({
            ...dynamicTextStyles,
            [name]: value,
        })
        setIsContentAdded(true)
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicTextStyles({
            ...dynamicTextStyles,
            logo: file,
        })
        setIsContentAdded(true)
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (setIsValid) {
            setIsValid(isValid)
        }
    }, [isValid, setIsValid])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            <CreationContentTitle title={t("text")} withStep={type === 'authorized' ? false : true} />
            <CreationContentDescription customStyles={{ marginTop: '-12px', marginBottom: '-8px' }} description={t("enterAMaximumOf500Characters")} />
            {kind === 'dynamic' ? (
                <div className='content-creation-form-container'>
                    <CodeNameInput />
                    <div className='content-creation-input-wrapper'>
                        <label className='content-creation-input-label'>{t("message")}</label>
                        <textarea
                            type='text'
                            value={dynamicTextStyles.text}
                            placeholder={t("message")}
                            className={errors.text ? 'form-input-dashboard-textarea form-input-error-border' : 'form-input-dashboard-textarea'}
                            {...register("text", {
                                onChange: (e) => handleChangeValue('text', e.target.value),
                                required: true,
                            })}
                        />
                        {errors.text && <p className='error-text'>{t("enterMessage")}</p>}
                    </div>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                    <div className='content-creation-input-wrapper'>
                        <label className='content-creation-input-label'>{t("subject")}</label>
                        <input
                            type='text'
                            value={dynamicTextStyles.subject}
                            placeholder={t("enterSubject")}
                            className={errors.password ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                            {...register("subject", {
                                onChange: (e) => handleChangeValue('subject', e.target.value),
                            })}
                        />
                    </div>
                    <div className='content-creation-input-wrapper'>
                        <label className='content-creation-input-label'>{t("company")}</label>
                        <input
                            type='text'
                            value={dynamicTextStyles.company}
                            placeholder={t("exTechAndCorp")}
                            className={errors.password ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                            {...register("company", {
                                onChange: (e) => handleChangeValue('company', e.target.value),
                            })}
                        />
                    </div>
                    {dynamicTextStyles?.logo ? (
                        <EditFileBlock
                            file={dynamicTextStyles.logo}
                            title={t("logoFile")}
                            onFileChanged={handleSaveUploadedLogoFile}
                            onDeleteFile={() => handleSaveUploadedLogoFile(null)}
                            deleteAllowed={true}
                        />
                    ) : (
                        <UploadLogoBlock
                            title={t("addLogoOptional")}
                            limit={"5MB"}
                            acceptList={"image/*"}
                            multiple={false}
                            onChangeFiles={handleSaveUploadedLogoFile}
                            uploadedFiles={dynamicTextStyles.logo ? 1 : 0}
                            isError={false}
                            errorText={t("logoFileMustBeUploaded")}
                        />
                    )}
                </div>
            ) : (
                <>
                    <CodeNameInput />
                    <div className='content-creation-input-wrapper'>
                        <label className='content-creation-input-label'>{type === 'authorized' ? t("enterText") : t("message")}</label>
                        <textarea
                            type='text'
                            placeholder={t("message")}
                            value={staticValue}
                            className={errors.text ? 'form-input-dashboard-textarea form-input-error-border' : 'form-input-dashboard-textarea'}
                            {...register("text", {
                                onChange: () => handleChangeText(),
                                required: true,
                            })}
                        />
                        {errors.text && <p className='error-text'>{t("enterMessage")}</p>}
                    </div>
                </>
            )}
        </form>
    )
}

export default Text