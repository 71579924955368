import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IoIosArrowUp } from 'react-icons/io'

import useReturnFaqQuestions from '../../components/FaqSection/useReturnFaqQuestions'
import { FAQ_TYPES_TRANSLATIONS } from '../../helpers/translation-constants'
import { FAQ_TYPES } from '../../components/FaqSection/FaqSection'

const FaqSection = () => {
    const [expandedFaq, setExpandedFaq] = useState(null)
    const [faqType, setFaqType] = useState('Quick Start')

    const navigate = useNavigate()

    const { FAQ_QUESTION_LIST } = useReturnFaqQuestions()

    const { t } = useTranslation()

    const handleNavigateToCreation = () => {
        navigate('/generate-code')
    }

    const handleOpenFaqQuestion = (id) => {
        if (expandedFaq === id) {
            setExpandedFaq(null)
        } else {
            setExpandedFaq(id)
        }
    }

    useEffect(() => {
        setExpandedFaq(null)
    }, [faqType])

    return (
        <div className='landing-faq-wrapper'>
            <h5 className='landing-faq-title'>{t("frequentlyAskedQuestions")}</h5>
            <div style={{ width: 'unset' }} className='faq-content-question-types-filter-block mb-[-8px] lg:mb-0'>
                {FAQ_TYPES.map((type, index) => (
                    <div
                        key={index}
                        onClick={() => setFaqType(type)}
                        className={`faq-content-question-type-item ${faqType === type ? 'active' : ''} px-[16px]`}
                    >
                        {t(`${FAQ_TYPES_TRANSLATIONS[type]}`)}
                    </div>
                ))}
            </div>
            <div className='faq-content-questions-list-block max-w-[1130px] w-full'>
                {FAQ_QUESTION_LIST[faqType].map((faq) => {
                    if (faqType === 'Quick Start' && faq.id === 6) {
                        return null
                    } else {
                        return (
                            <div onClick={() => handleOpenFaqQuestion(faq.id)} key={faq.id} className='faq-content-question-item cursor-pointer'>
                                <div className={`faq-content-question-item-header ${expandedFaq === faq.id ? 'opened' : ''}`}>
                                    {faq.question}
                                    <IoIosArrowUp size={20} color="#7D8898" />
                                </div>
                                {expandedFaq === faq.id && (
                                    <p className='faq-content-question-item-answer'>
                                        {faq.answer instanceof Array ? (
                                            <div>
                                                {faq.answer.map((answer, index) => {
                                                    if (index === 0) {
                                                        return (
                                                            <p key={index}>
                                                                {answer}
                                                            </p>
                                                        )
                                                    } else {
                                                        return (
                                                            <li key={index}>
                                                                {answer}
                                                            </li>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        ) : (
                                            <>
                                                {faq.answer}
                                            </>
                                        )}
                                    </p>
                                )}
                            </div>
                        )
                    }
                })}
            </div>
            <div className='w-full flex items-center justify-center'>
                <button onClick={handleNavigateToCreation} className='introducing-section-create-button mt-0'>
                    {t("createQRCode")}
                </button>
            </div>
        </div>
    )
}

export default FaqSection