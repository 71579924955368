import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import confirmDelete from '../../assets/icons/confirm-delete.svg'

const DeleteConfirmModal = ({ show, onClose, handleDeleteCodeConfirmed, setOverviewCode }) => {
    const { t } = useTranslation()

    const handleDeleteCode = () => {
        handleDeleteCodeConfirmed()
        setOverviewCode(null)
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            centered
            position='center'
            onClose={onClose}
            className='modal-please-signup'
        >
            <Modal.Body className='confirm-archive-modal-body'>
                <img className='confirm-modal-icon' src={confirmDelete} alt="" />
                <h5 className='confirm-modal-title'>{t("areYouSureYouWantToDeleteThisQRCode")}</h5>
                <div className='confirm-modal-actions-block'>
                    <button onClick={onClose} className='confirm-modal-action-cancel'>{t("cancel")}</button>
                    <button onClick={handleDeleteCode} className='confirm-modal-action-delete'>{t("deleteQRCode")}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteConfirmModal