import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import { UserCodeCreationProvider } from './context/UserQrCreationContext';
import { EditQrCodeProvider } from './context/EditQrCodeContext';
import { LanguageProvider } from './context/LanguageContext';
import { AuthProvider } from './context/AuthContext';
import { store } from './redux/store';

import App from './App';

import './i18n/config';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

let persistor = persistStore(store);

const RootComponent = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <LanguageProvider>
            <AuthProvider>
              <EditQrCodeProvider>
                <UserCodeCreationProvider>
                  <App />
                </UserCodeCreationProvider>
              </EditQrCodeProvider>
            </AuthProvider>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);