import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react'

import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import { useViewport } from '../../../hooks/useViewport';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle'
import EditListFilesBlock from '../../EditListFilesBlock/EditListFilesBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';

const EditPdf = ({ triggerValidation }) => {
    const { isMobile } = useViewport()

    const { t } = useTranslation()

    const { register, trigger, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const {
        dynamicPdfStyles, setDynamicPdfStyles
    } = useEditQrCodeContext()

    const handleChangeInput = (name, value) => {
        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            [name]: value,
        })
    }

    const handleChangeFiles = (files) => {
        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            files
        })
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            logo: file,
        })
    }

    const handleSaveUploadedCoverFile = (file) => {
        setDynamicPdfStyles({
            ...dynamicPdfStyles,
            image: file,
        })
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (dynamicPdfStyles) {
            setValue('company', dynamicPdfStyles.company)
            setValue('title', dynamicPdfStyles.title)
            setValue('description', dynamicPdfStyles.description)
            setValue('website', dynamicPdfStyles.website)
            setValue('button', dynamicPdfStyles.button)
        }
    }, [dynamicPdfStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            {!isMobile && (
                <CreationContentTitle title={"PDF(s)"} withStep={false} />
            )}
            <EditListFilesBlock
                files={dynamicPdfStyles?.files}
                onChangeFiles={handleChangeFiles}
                title={t("pdfFile")}
                type="pdf"
                acceptList={"application/pdf"}
            />
            {!isMobile && (
                <>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                </>
            )}
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("company")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.company}
                        placeholder={t("exTechAndCorp")}
                        className={errors.company ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("company", {
                            onChange: (e) => handleChangeInput('company', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.company && <p className='error-text'>{t("enterCompanyName")}</p>}
                </div>
                <EditFileBlock
                    file={dynamicPdfStyles.logo}
                    onFileChanged={handleSaveUploadedLogoFile}
                    title={t("logoFile")}
                />
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("pdfTitle")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.title}
                        placeholder={t("exManifesto")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterPdfTitle")}</p>}
                </div>
                <EditFileBlock
                    file={dynamicPdfStyles.image}
                    onFileChanged={handleSaveUploadedCoverFile}
                    title={t("coverFile")}
                />
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.description}
                        placeholder={t("exFullTechAndCorpManifesto")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicPdfStyles.button}
                        placeholder={t("exViewPdf")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
            </div>
        </form>
    )
}

export default EditPdf