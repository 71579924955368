import React from 'react'
import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext'

const CreationContentTitle = ({ title, withStep = true }) => {
    const { step } = useUserCodeCreationContext()

    return (
        <span className='selected-type-title'>
            {title}
            {withStep && (
                <span className={step === 2 ? 'creation-step-container' : 'creation-step-container-default'}>Step 2</span>
            )}
        </span>
    )
}

export default CreationContentTitle