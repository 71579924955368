import { useEffect } from "react";

const usePreloadImage = ({ images }) => {

    useEffect(() => {
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, [images])

    return {}
}

export default usePreloadImage