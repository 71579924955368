import React from 'react'

import TestimonialStar from '../../assets/icons/testimonial-star.svg'

const TestimonialItem = ({ testimonial }) => {
    return (
        <div className='testimonial-item'>
            <h6 className='testimonial-item-name'>{testimonial.user}</h6>
            <div className='testimonial-item-stars-block'>
                {
                    [1, 2, 3, 4, 5].map((star) => (
                        <img key={star} src={TestimonialStar} alt="" />
                    ))
                }
            </div>
            <span className='testimonial-item-underline'></span>
            <p className='testimonial-item-text'>
                {testimonial.text}
            </p>
        </div>
    )
}

export default TestimonialItem