import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, OverlayTrigger } from 'react-bootstrap'

import { ACTIVE_TAB_TRANSLATIONS, DASHBOARD_SORT_VALUES_TRANSLATIONS, VIEW_TRANSLATIONS } from '../../helpers/translation-constants'
import { SORT_VALUES_TO_NAME } from '../../helpers/constants'

import dashboardFilterCloseIconSvg from '../../assets/icons/dashboard-filter-close-icon.svg'
import userDashboardListView from '../../assets/icons/user-dashboard-list-view.svg'
import userDashboardGridView from '../../assets/icons/user-dashboard-grid-view.svg'
import dashboardFilterIconSvg from '../../assets/icons/dashboard-filter-icon.svg'
import dashboardSortCheck from '../../assets/icons/dashboard-sort-check.svg'
import dashboardSortIcon from '../../assets/icons/dashboard-sort-icon.svg'

const ActionsBlock = ({
    activeTab,
    handleCodeCreation,
    dashboardNavigationPlus,
    isMultipleConvertion,
    handleConvertAllCodesToDynamic,
    userDashboardConvert,
    handleDownloadAllCodes,
    dashboardNavigationDownload,
    view,
    appliedKindFilters,
    handleClearKindFilters,
    handleSelectFilterKind,
    selectedKindsList,
    handleApplyKindFilters,
    appliedSortFilter,
    setSelectedSortFilter,
    selectedSortFilter,
    handleClearSortFilters,
    handleApplySortFilters,
    handleSetView
}) => {
    const { t } = useTranslation()

    return (
        <div className='user-dashboard-second-navigation-block'>
            <span className='content-codes-list-title text-left'>{t(`${ACTIVE_TAB_TRANSLATIONS[activeTab]}`)}</span>
            <div className='user-dashboard-second-navigation-code-actions-block'>
                <button onClick={handleCodeCreation} className='user-dashboard-second-navigation-create-code'>
                    <img width={16} src={dashboardNavigationPlus} alt="" />
                    {t("createCode")}
                </button>
                <button disabled={isMultipleConvertion ? true : false} onClick={handleConvertAllCodesToDynamic} className='user-dashboard-second-navigation-default-button'>
                    <img width={16} src={userDashboardConvert} alt="" />
                    {t("convertAllToDynamic")}
                </button>
                <button onClick={handleDownloadAllCodes} className='user-dashboard-second-navigation-default-button'>
                    <img width={16} src={dashboardNavigationDownload} alt="" />
                    {t("downloadAll")}
                </button>
            </div>
            <div className='user-dashboard-second-navigation-actions-block'>
                <h3 className='user-dashboard-second-navigation-view-title'>{t(`${VIEW_TRANSLATIONS[`${view} View`]}`)}</h3>
                <div className='user-dashboard-second-navigation-filters'>
                    <Dropdown>
                        <Dropdown.Toggle className={`bootstrap-default-dropdown filters-dropdown-button ${appliedKindFilters.length > 0 ? 'selected' : ''}`} id="dropdown-basic">
                            <img src={dashboardFilterIconSvg} alt="" />
                            {t("filter")}
                            {appliedKindFilters.length > 0 && (
                                <img onClick={handleClearKindFilters} src={dashboardFilterCloseIconSvg} alt="" />
                            )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='filters-dropdown-menu'>
                            <div className='filters-dropdown-menu-wrapper'>
                                <h5 className='filters-dropdown-menu-title'>{t("filter")}</h5>
                                <span className='filters-dropdown-menu-types-title'>{t("codeType")}</span>
                                <div className='filters-dropdown-code-types-list'>
                                    <span
                                        onClick={() => handleSelectFilterKind('Website')}
                                        className={selectedKindsList.includes('Website') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        {t("website")}
                                    </span>
                                    <span
                                        onClick={() => handleSelectFilterKind('PDF')}
                                        className={selectedKindsList.includes('PDF') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        PDF
                                    </span>
                                    <span
                                        onClick={() => handleSelectFilterKind('Image')}
                                        className={selectedKindsList.includes('Image') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        {t("image")}
                                    </span>
                                    <span
                                        onClick={() => handleSelectFilterKind('Video')}
                                        className={selectedKindsList.includes('Video') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        {t("video")}
                                    </span>
                                    <span
                                        onClick={() => handleSelectFilterKind('MP3')}
                                        className={selectedKindsList.includes('MP3') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        MP3
                                    </span>
                                    <span
                                        onClick={() => handleSelectFilterKind('Wi-Fi')}
                                        className={selectedKindsList.includes('Wi-Fi') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        Wi-Fi
                                    </span>
                                    <span
                                        onClick={() => handleSelectFilterKind('Text')}
                                        className={selectedKindsList.includes('Text') ? 'filters-dropdown-code-list-active-item' : 'filters-dropdown-code-list-item'}
                                    >
                                        {t("text")}
                                    </span>
                                </div>
                                <div className='filters-dropdown-code-buttons'>
                                    <Dropdown.Item onClick={handleClearKindFilters} className='filters-dropdown-code-clear-button'>
                                        {t("clear")}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleApplyKindFilters} className='filters-dropdown-code-apply-button'>
                                        {t("apply")}
                                    </Dropdown.Item>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className='bootstrap-default-dropdown filters-dropdown-button' id="dropdown-basic">
                            <img src={dashboardSortIcon} alt="" />
                            {t("sort")} {t(`${DASHBOARD_SORT_VALUES_TRANSLATIONS[`${SORT_VALUES_TO_NAME[appliedSortFilter]}`]}`)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='filters-dropdown-menu'>
                            <div className='filters-dropdown-menu-wrapper'>
                                <h5 className='filters-dropdown-menu-title'>{t("sort")}</h5>
                                <div className='sort-dropdown-menu-list'>
                                    <div onClick={() => setSelectedSortFilter('dynamic')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'dynamic' ? 'active' : ''}`}>
                                        {t("dynamic")}
                                        {selectedSortFilter === 'dynamic' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                    <div onClick={() => setSelectedSortFilter('static')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'static' ? 'active' : ''}`}>
                                        {t("static")}
                                        {selectedSortFilter === 'static' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                    <div onClick={() => setSelectedSortFilter('newest_to_oldest')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'newest_to_oldest' ? 'active' : ''}`}>
                                        {t("newest")}
                                        {selectedSortFilter === 'newest_to_oldest' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                    <div onClick={() => setSelectedSortFilter('oldest_to_newest')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'oldest_to_newest' ? 'active' : ''}`}>
                                        {t("oldest")}
                                        {selectedSortFilter === 'oldest_to_newest' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                    <div onClick={() => setSelectedSortFilter('edited')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'edited' ? 'active' : ''}`}>
                                        {t("edited")}
                                        {selectedSortFilter === 'edited' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                    <div onClick={() => setSelectedSortFilter('atoz')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'atoz' ? 'active' : ''}`}>
                                        A-Z
                                        {selectedSortFilter === 'atoz' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                    <div onClick={() => setSelectedSortFilter('ztoa')} className={`sort-dropdown-menu-item ${selectedSortFilter === 'ztoa' ? 'active' : ''}`}>
                                        Z-A
                                        {selectedSortFilter === 'ztoa' && (
                                            <img src={dashboardSortCheck} alt="" />
                                        )}
                                    </div>
                                </div>
                                <div className='filters-dropdown-code-buttons'>
                                    <Dropdown.Item onClick={handleClearSortFilters} className='filters-dropdown-code-clear-button'>
                                        {t("clear")}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleApplySortFilters} className='filters-dropdown-code-apply-button'>
                                        {t("apply")}
                                    </Dropdown.Item>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<div className='tooltip-custom'>{t("listView")}</div>}
                    >
                        <div onClick={() => handleSetView('List')} className={`user-dashboard-second-navigation-button ${view === 'List' ? 'active' : ''}`}>
                            <img src={userDashboardListView} alt="" />
                        </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<div className='tooltip-custom'>{t("gridView")}</div>}
                    >
                        <div onClick={() => handleSetView('Grid')} className={`user-dashboard-second-navigation-button ${view === 'Grid' ? 'active' : ''}`}>
                            <img src={userDashboardGridView} alt="" />
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}

export default ActionsBlock