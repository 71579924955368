import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import {
    ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS
} from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import OnboardingWebsitePreview from '../../assets/icons/onboarding/website-preview.svg'
import OnboardingVideo from '../../assets/icons/onboarding/video.svg'
import OnboardingPhoto from '../../assets/icons/onboarding/photo.svg'
import OnboardingText from '../../assets/icons/onboarding/text.svg'
import OnboardingWifi from '../../assets/icons/onboarding/wifi.svg'
import OnboardingUrl from '../../assets/icons/onboarding/url.svg'
import OnboardingMp3 from '../../assets/icons/onboarding/mp3.svg'
import OnboardingPdf from '../../assets/icons/onboarding/pdf.svg'

const useOnboardingData = () => {
    const { t } = useTranslation()

    const CODES_LIST = [
        {
            id: 1,
            name: 'Website',
            icon: OnboardingUrl,
            isStatic: true,
        },
        {
            id: 2,
            name: t("video"),
            icon: OnboardingVideo,
            isStatic: false,
        },
        {
            id: 3,
            name: t("text"),
            icon: OnboardingText,
            isStatic: true,
        },
        {
            id: 4,
            name: 'Wi-Fi',
            icon: OnboardingWifi,
            isStatic: true,
        },
        {
            id: 5,
            name: 'MP3',
            icon: OnboardingMp3,
            isStatic: false,
        },
        {
            id: 6,
            name: 'PDF',
            icon: OnboardingPdf,
            isStatic: false,
        },
        {
            id: 7,
            name: t("photo"),
            icon: OnboardingPhoto,
            isStatic: false,
        }
    ]

    const ONBOARDING_ITEMS = useMemo(() => {
        return [
            {
                id: 1,
                name: 'Website',
                image: OnboardingWebsitePreview,
                text: t("guideYourAudienceToAWebsite")
            },
            {
                id: 2,
                name: t("video"),
                image: ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS[i18n.language]?.video
                    ?? ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS['en'].video,
                text: t("engageYourAudienceWithACompellingVideo")
            },
            {
                id: 3,
                name: t("text"),
                image: ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS[i18n.language]?.text
                    ?? ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS['en'].text,
                text: t("aConciseWayToShareMessages")
            },
            {
                id: 4,
                name: 'Wi-Fi',
                image: ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS[i18n.language]?.['wi-fi']
                    ?? ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS['en']['wi-fi'],
                text: t("effortlessConnectivityShareWifi")
            },
            {
                id: 5,
                name: 'MP3',
                image: ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS[i18n.language]?.mp3
                    ?? ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS['en'].mp3,
                text: t("deliverYourFavoriteTunes")
            },
            {
                id: 6,
                name: 'PDF',
                image: ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS[i18n.language]?.pdf
                    ?? ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS['en'].pdf,
                text: t("shareDocumentsSwiftly")
            },
            {
                id: 7,
                name: t("photo"),
                image: ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS[i18n.language]?.photos
                    ?? ONBOARDING_CAROUSEL_PREVIEW_IMAGES_TRANSLATIONS['en'].photos,
                text: t("shareCaptivatingImagesStraightToUser")
            }
        ]
    }, [t])

    return {
        CODES_LIST,
        ONBOARDING_ITEMS
    }
}

export default useOnboardingData