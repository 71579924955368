import { endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { createStaticRanges } from 'react-date-range';
import { useTranslation } from 'react-i18next';

const useAnalyticsData = () => {
    const { t } = useTranslation()

    const ANALYTICS_FILTER_BLOCKS = [
        {
            id: 1,
            name: 'os',
            label: t("operatingSystem")
        },
        {
            id: 2,
            name: 'browser',
            label: t("browser")
        },
        {
            id: 3,
            name: 'country',
            label: t("country")
        },
        {
            id: 4,
            name: 'city',
            label: t("city")
        },
        {
            id: 5,
            name: 'language',
            label: t("language")
        }
    ]

    const customStaticRanges = createStaticRanges([
        {
            label: t('today'),
            range: () => ({
                startDate: startOfDay(new Date()),
                endDate: new Date(),
            }),
        },
        {
            label: t("yesterday"),
            range: () => ({
                startDate: startOfDay(subDays(new Date(), 1)),
                endDate: startOfDay(subDays(new Date(), 1)),
            }),
        },
        {
            label: t("thisWeek"),
            range: () => ({
                startDate: startOfWeek(new Date()),
                endDate: new Date(),
            }),
        },
        {
            label: t("lastWeek"),
            range: () => ({
                startDate: startOfWeek(subWeeks(new Date(), 1)),
                endDate: endOfWeek(subWeeks(new Date(), 1)),
            }),
        },
        {
            label: t("thisMonth"),
            range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: new Date(),
            }),
        },
        {
            label: t("lastMonth"),
            range: () => ({
                startDate: startOfMonth(subMonths(new Date(), 1)),
                endDate: endOfMonth(subMonths(new Date(), 1)),
            }),
        },
        {
            label: t("last30Days"),
            range: () => ({
                startDate: subDays(new Date(), 29),
                endDate: new Date(),
            }),
        },
        {
            label: t("last90Days"),
            range: () => ({
                startDate: subDays(new Date(), 89),
                endDate: new Date(),
            }),
        },
        {
            label: t("last12Month"),
            range: () => ({
                startDate: subMonths(new Date(), 11),
                endDate: new Date(),
            }),
        },
        {
            label: t("thisYear"),
            range: () => ({
                startDate: startOfYear(new Date()),
                endDate: new Date(),
            }),
        },
        {
            label: t("lastYear"),
            range: () => ({
                startDate: startOfYear(subYears(new Date(), 1)),
                endDate: startOfYear(new Date()),
            }),
        },
        {
            label: t("allTime"),
            range: () => ({
                startDate: startOfYear(new Date(2022, 0, 1)),
                endDate: new Date(),
            }),
        },
    ]);

    return {
        customStaticRanges,
        ANALYTICS_FILTER_BLOCKS
    }
}

export default useAnalyticsData