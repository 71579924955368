import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

import { auth } from "../config";

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
    'prompt': "select_account"
});

export const googleSignIn = async () => {
    let error = null;
    let isNew = false;
    let user = null;

    try {
        const response = await signInWithPopup(auth, provider)

        user = response.user

        const now = new Date()
        const createdAt = response.user.metadata.createdAt
        const trashhold = 0.02 * 60 * 60 * 1000

        const isNewUser = (now - createdAt) < trashhold

        if (isNewUser) {
            isNew = true
        }
    } catch (e) {
        error = e
    }

    return { error, isNew, user }
}

export const userSignOut = async () => {
    let error = null;

    try {
        await signOut(auth)
    } catch (e) {
        error = e
    }

    return { error }
}