import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ChromePicker } from 'react-color';
import React, { useEffect } from 'react'

import { useEditQrCodeContext } from '../../context/EditQrCodeContext';

import EditCodeLandingPreview from '../EditCodeLandingPreview/EditCodeLandingPreview';
import EditDesignColorPalette from '../EditDesignLandingPage/EditDesignColorPalette';

import iphoneMockupPng from '../../assets/images/iphone-mockup.png'

const EditCodeLandingPage = ({ mobile }) => {
    const { t } = useTranslation()

    const {
        expandEditLanding, setExpandEditLanding, designPrimaryColor, setDesignPrimaryColor,
        designSecondaryColor, setDesignSecondaryColor, designTextColor,
        setDesignTextColor, designButtonColor, setDesignButtonColor,
        designPrimaryColorRef, designSecondaryColorRef, designTextColorRef,
        designButtonColorRef, selectDesignPrimaryColor, setSelectDesignPrimaryColor,
        selectDesignSecondaryColor, setSelectDesignSecondaryColor,
        selectDesignTextColor, setSelectDesignTextColor, type,
        selectDesignButtonColor, setSelectDesignButtonColor, selectedColorPalette,
        handleSelectPalette, codeVersion
    } = useEditQrCodeContext()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (designPrimaryColorRef.current && !designPrimaryColorRef.current.contains(event.target)) {
                setSelectDesignPrimaryColor(false);
            } else if (designSecondaryColorRef.current && !designSecondaryColorRef.current.contains(event.target)) {
                setSelectDesignSecondaryColor(false);
            } else if (designTextColorRef.current && !designTextColorRef.current.contains(event.target)) {
                setSelectDesignTextColor(false);
            } else if (designButtonColorRef.current && !designButtonColorRef.current.contains(event.target)) {
                setSelectDesignButtonColor(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [designPrimaryColorRef, designSecondaryColorRef, designTextColorRef, designButtonColorRef, setSelectDesignPrimaryColor, setSelectDesignSecondaryColor, setSelectDesignTextColor, setSelectDesignButtonColor]);

    return (
        <div className='edit-design-section'>
            {!mobile && (
                <div className='edit-design-qr-code-header'>
                    <div className='qr-customize-option-header'>
                        <span className='qr-customize-option-title'>
                            {t("landingPage")}
                        </span>
                        <div className='qr-customize-option-header-actions'>
                            <div onClick={() => setExpandEditLanding(!expandEditLanding)} className='qr-option-customize-expand-button-with-background'>
                                {expandEditLanding ? (
                                    <MdKeyboardArrowUp size={28} fill='#334155' />
                                ) : (
                                    <MdKeyboardArrowDown size={28} fill="#334155" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(expandEditLanding || mobile) && (
                <>
                    <div>
                        <div className='flex w-full justify-center'>
                            <div className='preview-section-phone-wrapper'>
                                <img className='preview-section-phone' src={iphoneMockupPng} alt="" />
                                <div className='preview-section-content'>
                                    <EditCodeLandingPreview />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='edit-design-social-icons'>
                        <span className='design-customize-section-color-title'>{t("color")}</span>
                        <EditDesignColorPalette
                            selectedColorPalette={selectedColorPalette}
                            handleSelectPalette={handleSelectPalette}
                        />
                        <div className='flex flex-col gap-2 relative'>
                            <span className='qr-customize-option-label'>{t("primary")}</span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={designPrimaryColor}
                                        placeholder='#000000'
                                        onChange={(e) => setDesignPrimaryColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    onClick={() => setSelectDesignPrimaryColor(true)}
                                    className='generator-color-block me-2'
                                    style={{ backgroundColor: `${designPrimaryColor}` }}
                                ></div>
                                {selectDesignPrimaryColor && (
                                    <div ref={designPrimaryColorRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={(value) => setDesignPrimaryColor(value.hex)}
                                            color={designPrimaryColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 relative'>
                            <span className='qr-customize-option-label'>
                                {codeVersion === 2 ? t("buttonTextColor") : t("secondary")}
                            </span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={designSecondaryColor}
                                        placeholder='#000000'
                                        onChange={(e) => setDesignSecondaryColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    onClick={() => setSelectDesignSecondaryColor(true)}
                                    className='generator-color-block me-2'
                                    style={{ backgroundColor: `${designSecondaryColor}` }}
                                ></div>
                                {selectDesignSecondaryColor && (
                                    <div ref={designSecondaryColorRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={(value) => setDesignSecondaryColor(value.hex)}
                                            color={designSecondaryColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 relative'>
                            <span className='qr-customize-option-label'>{t("text")}</span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={designTextColor}
                                        placeholder='#000000'
                                        onChange={(e) => setDesignTextColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    onClick={() => setSelectDesignTextColor(true)}
                                    className='generator-color-block me-2'
                                    style={{ backgroundColor: `${designTextColor}` }}
                                ></div>
                                {selectDesignTextColor && (
                                    <div ref={designTextColorRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={(value) => setDesignTextColor(value.hex)}
                                            color={designTextColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {type !== 'text' && (
                            <div className='flex flex-col gap-2 relative'>
                                <span className='qr-customize-option-label'>{t("button")}</span>
                                <div className='qr-color-picker-block'>
                                    <div className='qr-color-change-input-block'>
                                        <input
                                            type='text'
                                            className='form-input-dashboard'
                                            value={designButtonColor}
                                            placeholder='#000000'
                                            onChange={(e) => setDesignButtonColor(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        onClick={() => setSelectDesignButtonColor(true)}
                                        className='generator-color-block me-2'
                                        style={{ backgroundColor: `${designButtonColor}` }}
                                    ></div>
                                    {selectDesignButtonColor && (
                                        <div ref={designButtonColorRef} className='color-picker'>
                                            <ChromePicker
                                                onChange={(value) => setDesignButtonColor(value.hex)}
                                                color={designButtonColor}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default EditCodeLandingPage