import React from 'react'

const CreationContentDescription = ({ description, customStyles }) => {
    return (
        <div style={customStyles} className='selected-type-description-authorized'>
            {description}
        </div>
    )
}

export default CreationContentDescription