import { useTranslation } from 'react-i18next'
import React, { useRef } from 'react'

const UploadBlock = ({ title, limit, acceptList, multiple, onChangeFiles, uploadedFiles, isError, customSizeTitle }) => {
    const inputRef = useRef()

    const { t } = useTranslation()

    const handleUpload = () => {
        inputRef.current.click()
    }

    return (
        <div className={isError ? 'upload-block-wrapper-error' : 'uplaod-block-wrapper'}>
            <input onChange={(e) => onChangeFiles(e.target.files)} multiple={multiple} ref={inputRef} className='hidden' type="file" accept={acceptList} />
            <button onClick={handleUpload} className='uplaod-block-button'>{uploadedFiles > 0 ? `Uploaded ${uploadedFiles} file(s)` : title}</button>
            {isError ? (
                <span className='upload-block-error-text'>{t("atLeastOneFileMustBeUploaded")}</span>
            ) : (
                <span className='upload-block-limit-text'>{customSizeTitle ? customSizeTitle : t("maximumSize")} {limit}</span>
            )}
        </div>
    )
}

export default UploadBlock