import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrFormClose } from 'react-icons/gr'
import { Modal } from 'flowbite-react'

import { handleResetUserPassword } from '../../firebase/auth/resetPassword'
import newLogo from '../../assets/icons/newlogo.svg'

const ResetPasswordModal = ({ show, onClose, setShowResetSuccessModal }) => {
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(null)

    const rootRef = useRef(null);

    const { t } = useTranslation()

    const handleChangeEmail = (value) => {
        setEmail(value)
        setStatus(null)
    }

    const handleSendResetLink = async () => {
        const response = await handleResetUserPassword(email)

        if (response) {
            onClose()
            setShowResetSuccessModal(true)
        } else {
            setStatus(response)
        }
    }

    const handleCloseReset = () => {
        onClose()
    }

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                popup="false"
                size="md"
                position='center'
                onClose={onClose}
                root={rootRef.current ?? undefined}
                className='modal-please-signup manage-plan-modal h-[100vh] reset-password-modal'
                style={{ height: '100vh' }}
            >
                <Modal.Body className='manage-plan-modal-body mt-0 padding-top-24 h-fit'>
                    <div className='auth-form-mobile flex flex-col h-fit gap-y-6 w-full reset-password-modal-wrapper'>
                        <div className='reset-header-logo-mobile'>
                            <div onClick={handleCloseReset} className='flex items-center me-2 md:me-5'>
                                <img src={newLogo} alt="" />
                                <span className='header-logo-text'>QR Code Developer</span>
                            </div>
                        </div>
                        <div className='mobile-block'></div>
                        <div className='flex flex-col'>
                            <span className='please-signup-big-purple-text mb-6'>
                                <span className='please-signup-big-dark-text'>{t("confirmationEmail")}</span>
                                <GrFormClose onClick={handleCloseReset} className='reset-password-close' fill="#334155" />
                            </span>
                            <input
                                type='text'
                                placeholder={t("emailAddressLowercase")}
                                className={status === false ? 'form-input-error-border form-input-dashboard' : 'form-input-dashboard'}
                                value={email}
                                onChange={(e) => handleChangeEmail(e.target.value)}
                            />
                            {status === false && <p className='error-text'>{t("weCannotFindYourEmail")}</p>}
                        </div>
                        <button onClick={handleSendResetLink} className='header-sign-up-btn send-confirmation-btn'>{t("sendConfirmationEmail")}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ResetPasswordModal