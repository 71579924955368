import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react'

import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import { useViewport } from '../../../hooks/useViewport';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';
import EditListFilesBlock from '../../EditListFilesBlock/EditListFilesBlock';
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';

const EditImage = ({ triggerValidation }) => {
    const { isMobile } = useViewport()

    const { t } = useTranslation()

    const { register, trigger, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const {
        dynamicImageStyles, setDynamicImageStyles, codeVersion
    } = useEditQrCodeContext()

    const handleChangeInput = (name, value) => {
        setDynamicImageStyles({
            ...dynamicImageStyles,
            [name]: value,
        })
    }

    const handleChangeFiles = (files) => {
        setDynamicImageStyles({
            ...dynamicImageStyles,
            files
        })
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicImageStyles({
            ...dynamicImageStyles,
            logo: file,
        })
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (dynamicImageStyles) {
            setValue('title', dynamicImageStyles.title)
            setValue('description', dynamicImageStyles.description)
            setValue('button', dynamicImageStyles.button)
            setValue('redirect', dynamicImageStyles.redirect)
            setValue('website', dynamicImageStyles.website)
        }
    }, [dynamicImageStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            {!isMobile && (
                <CreationContentTitle title={t("images")} withStep={false} />
            )}
            {codeVersion === 2 && (
                <>
                    {dynamicImageStyles?.logo ? (
                        <EditFileBlock
                            file={dynamicImageStyles.logo}
                            title={t("logoFile")}
                            onFileChanged={handleSaveUploadedLogoFile}
                            onDeleteFile={() => handleSaveUploadedLogoFile(null)}
                            deleteAllowed={true}
                        />
                    ) : (
                        <UploadLogoBlock
                            title={t("addLogoOptional")}
                            limit={"5MB"}
                            acceptList={"image/*"}
                            multiple={false}
                            onChangeFiles={handleSaveUploadedLogoFile}
                            uploadedFiles={dynamicImageStyles.logo ? 1 : 0}
                            isError={false}
                            errorText={t("logoFileMustBeUploaded")}
                        />
                    )}
                </>
            )}
            <EditListFilesBlock
                files={dynamicImageStyles?.files}
                onChangeFiles={handleChangeFiles}
                title={t("imageFile")}
                type="image"
                acceptList={"image/*"}
            />
            {!isMobile && (
                <>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                </>
            )}
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("imagePhotoAlbumTitle")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.title}
                        placeholder={t("exMyPhotos")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterAnImageTitle")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.description}
                        placeholder={t("ex2018Cruise")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.button}
                        placeholder={t("exSeeAll")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("buttonRedirectUrl")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.redirect}
                        placeholder={t("exInstagramUrl")}
                        className={errors.redirect ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("redirect", {
                            onChange: (e) => handleChangeInput('redirect', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.redirect && <p className='error-text'>{t("enterAValidButtonRedirectUrl")}</p>}
                </div>
            </div>
        </form>
    )
}

export default EditImage