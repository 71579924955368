import { IoCloseOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { FiDownload } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import useReturnCodeTypesData from '../../hooks/useReturnCodeTypesData'
import { useUpdateUserSettingsMutation } from '../../api/api'
import usePreloadImage from '../../hooks/usePreloadImage'
import { useViewport } from '../../hooks/useViewport'
import i18n from '../../i18n/config'

import HelpCodeBlur from '../../assets/icons/code-create/help-code-blur.webp'
import HelpCode from '../../assets/icons/code-create/help-code.webp'

const CreateCodeHelpModal = ({ show, onClose }) => {
    const [step, setStep] = useState(1)
    const settings = useSelector((state) => state.app.settings)
    const user = useSelector((state) => state.app.user)

    const rootRef = useRef(null)

    usePreloadImage({
        images: [
            HelpCode,
            HelpCodeBlur
        ]
    })
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const { codesListData } = useReturnCodeTypesData()

    const [updateSettings] = useUpdateUserSettingsMutation()

    const handleNextStep = () => {
        if (step < 5) {
            setStep(step + 1)
        } else {
            if (settings?.creation_help_completed === false) {
                updateSettings({
                    data: {
                        creation_help_completed: true
                    }
                })
            } else if (!user) {
                localStorage.setItem('creation_help_completed', 'true')
            }

            onClose()
        }
    }

    const handleBackStep = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    const handleCloseModal = () => {
        if (settings?.creation_help_completed === false) {
            updateSettings({
                data: {
                    creation_help_completed: true
                }
            })
        } else if (!user) {
            localStorage.setItem('creation_help_completed', 'true')
        }

        onClose()
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            position='center'
            onClose={onClose}
            style={{ height: '100vh' }}
            className='create-code-help-modal'
            root={rootRef.current ?? undefined}
        >
            <Modal.Body className='w-full bg-[#EDF3F9] rounded-[24px] p-0'>
                <div
                    onClick={handleCloseModal}
                    className='absolute w-[32px] h-[32px] bg-[#FFFFFF] right-[-40px] top-[0] rounded-full flex items-center justify-center cursor-pointer mobile:right-[16px] mobile:top-[16px] mobile:bg-[#1B003E]'
                >
                    <IoCloseOutline
                        size={20}
                        color={isMobile ? '#FFFFFF' : '#141315'}
                    />
                </div>
                {step === 1 && (
                    <div className='p-[24px] grid grid-cols-2 gap-[8px]'>
                        {codesListData.map((code) => (
                            <div
                                className='h-[62px] rounded-[8px] border-solid border-[1px] border-[#E2E8F0] bg-white flex items-center gap-[12px] px-[12px] mobile:h-[54px]'
                                key={code.id}
                            >
                                <img
                                    className='w-[36px]'
                                    src={code.icon}
                                    alt=""
                                />
                                <p className='text-[14px] leading-[17px] font-semibold text-[#141315]'>
                                    {code.name}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
                {step === 2 && (
                    <div className='p-[24px] flex items-center justify-center min-h-[320px] mobile:min-h-[288px]'>
                        <img
                            src={
                                CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS[i18n.language].content
                                    ? CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS[i18n.language].content
                                    : CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS['en'].content
                            }
                            alt=""
                        />
                    </div>
                )}
                {step === 3 && (
                    <div className='p-[24px] min-h-[320px] mobile:min-h-[288px] flex items-center justify-center'>
                        <img
                            src={
                                CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS[i18n.language].styles
                                    ? CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS[i18n.language].styles
                                    : CREATION_CODE_HELP_MODAL_IMAGES_TRANSLATIONS['en'].styles
                            }
                            alt=""
                        />
                    </div>
                )}
                {step === 4 && (
                    <div className='flex items-center justify-center p-[24px] py-[70px] min-h-[320px] mobile:min-h-[288px] mobile:py-[40px]'>
                        <img
                            className='w-[180px]'
                            src={HelpCode}
                            alt=""
                        />
                    </div>
                )}
                {step === 5 && (
                    <div className='flex items-center justify-center p-[24px] py-[70px] min-h-[320px] mobile:min-h-[288px] mobile:py-[40px]'>
                        <div className='relative'>
                            <img
                                className='w-[180px]'
                                src={HelpCodeBlur}
                                alt=""
                            />
                            <div className='absolute min-h-[77px] p-[16px] bg-[#1B003E] gap-[8px] rounded-[12px] flex flex-col left-[16px] bottom-[-16px] w-[180px]'>
                                <FiDownload size={20} color="#FFFFFF" />
                                <span className='text-[14px] leading-[17px] font-semibold text-white'>
                                    {t("downloadAll")}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div className='p-[24px] flex flex-col gap-[12px] bg-white rounded-b-[24px] min-h-[208px] justify-between mobile:min-h-[232px]'>
                    <div className='flex flex-col gap-[8px] w-full'>
                        <h5 className='flex items-start justify-between text-[19px] leading-[23px] tracking-[-0.02em] text-[#141315] font-bold gap-[8px]'>
                            {
                                step === 1
                                    ? t("selectYourQRCodeType")
                                    : step === 2
                                        ? t("addDetailsToYourLandingPage")
                                        : step === 3
                                            ? t("customizeYourQRCode")
                                            : step === 4
                                                ? t("previewYourQRCode")
                                                : t("printAndShareYourQRCode")
                            }
                            <div className='h-[24px] rounded-full px-[8px] bg-[#F6EDFF] text-[12px] leading-[18px] text-[#9747FF] flex items-center justify-center w-[34px] font-normal'>
                                {step}/5
                            </div>
                        </h5>
                        <p className='text-base text-[#7D8897]'>
                            {
                                step === 1
                                    ? t("previewAWideVarietyOfQRCodeTypes")
                                    : step === 2
                                        ? t("buildYourLandingPageByEasilyAddingYourInformation")
                                        : step === 3
                                            ? t("changeTheColorAddSocialIconsOrText")
                                            : step === 4
                                                ? t("effortlesslyPreviewYourQRCodeAlongWithItsCorresponding")
                                                : t("downloadAndPrintYourQRCodeToBeginSharingWithTheWorld")
                            }
                        </p>
                    </div>
                    <div className='flex items-center gap-[8px]'>
                        {step > 1 && (
                            <button
                                className='w-full border-solid border-[1px] border-[#E2E8F0] h-[40px] rounded-[8px] text-base font-semibold text-[#141315]'
                                onClick={handleBackStep}
                            >
                                {t("back")}
                            </button>
                        )}
                        <button
                            className='w-full bg-[#9747FF] h-[40px] rounded-[8px] text-base font-semibold text-white hover:bg-[#7f2fe7] transition-colors duration-300'
                            onClick={handleNextStep}
                        >

                            {step !== 5 ? t("next") : t("finish")}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CreateCodeHelpModal