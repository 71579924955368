import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'
import DashboardCreationPreview from '../DashboardCreationPreview/DashboardCreationPreview'
import iphoneMockupPng from '../../assets/images/iphone-mockup.png'
import qrExampleSvg from '../../assets/icons/qrExample.svg'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'

const DashboardPreviewBlock = () => {
    const {
        canvasCreationRef, staticValue, backgroundColor,
        foregroundColor, imageSettings, isDynamic, canvasStyles,
        scanColor, scanFont, scanText, isContentAdded
    } = useUserCodeCreationContext()

    return (
        <>
            <div className='dashboard-content-qr-code-block'>
                {staticValue ? (
                    <div className='p-2 w-fit h-fit' ref={canvasCreationRef}>
                        <div style={{ ...canvasStyles, backgroundColor: backgroundColor, color: scanColor, fontFamily: scanFont }} className='qr-code-result-wrapper'>
                            <QRCodeSVG
                                value={staticValue}
                                bgColor={backgroundColor}
                                fgColor={foregroundColor}
                                level='L'
                                size={165}
                                imageSettings={imageSettings}
                            />
                            {scanText}
                        </div>
                    </div>
                ) : (
                    <div className='dashboard-content-qr-code-example'>
                        <img width={145} height={145} src={qrExampleSvg} alt="" />
                    </div>
                )}
            </div>
            {isDynamic && (
                <>
                    <AiOutlineArrowDown style={{ marginTop: '-12px' }} fill='#BEC5CF' size={35} />
                    <div className='flex w-full justify-center'>
                        <div className={`preview-section-phone-wrapper ${isContentAdded ? '' : 'h-auto'}`}>
                            {isContentAdded && (
                                <img className='preview-section-phone' src={iphoneMockupPng} alt="" />
                            )}
                            <DashboardCreationPreview />
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default DashboardPreviewBlock