import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

import { checkIfCurrentDateInRange } from '../../helpers/functions';
import useDateTranslations from '../../hooks/useDateTranslations';
import { useViewport } from '../../hooks/useViewport';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const AnalyticsGraph = ({ graphData, uniqueScans, abTesting }) => {
    const [gradient, setGradient] = useState('')
    const [mobileTicks, setMobileTicks] = useState([])

    const graphRef = useRef(null)

    const { translateAnalyticsDate } = useDateTranslations()
    const { isMobile } = useViewport()
    const { t } = useTranslation()

    const labels = useMemo(() => {
        return graphData?.map((data) => translateAnalyticsDate(data.name))
    }, [graphData]);

    const options = useMemo(() => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                },
                title: {
                    display: false,
                    text: 'Chart.js Line Chart',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        },
                    }],
                },
                tooltip: {
                    intersect: isMobile ? false : true,
                    borderColor: '#E2E8F0',
                    backgroundColor: '#FFFFFF',
                    titleColor: '#000000',
                    titleFont: {
                        size: 28,
                        weight: 700,
                        lineHeight: 0.9,
                    },
                    bodyColor: '#000000',
                    bodyFont: {
                        size: 16,
                        weight: 600,
                    },
                    footerColor: '#7D8898',
                    footerFont: {
                        size: 16,
                        weight: 400,
                    },
                    borderWidth: 1,
                    callbacks: {
                        title: function (context) {
                            return `${context[0]?.formattedValue}`
                        },
                        beforeBody: function () {
                            return uniqueScans ? t("uniqueScansLowercase") : t("totalScansLowercase");
                        },
                        beforeFooter: function (context) {
                            return `${context[0]?.label}`;
                        },
                        label: function () {
                            return null
                        }
                    },
                },
            },
            elements: {
                point: {
                    pointBackgroundColor: '#FFFFFF',
                    radius: 7,
                    hoverRadius: 7,
                    pointHoverBackgroundColor: '#BEC5CF',
                    pointBorderColor: '#BEC5CF',
                    pointBorderWidth: 2,
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: (ctx) => {
                            const res = checkIfCurrentDateInRange(ctx?.tick?.label)
                            return res ? '#141315' : '#BEC5CF'
                        },
                        font: {
                            family: "Inter",
                            size: '12',
                            weight: '600',
                            letterSpacing: '0.08em',
                        },
                        maxRotation: 0,
                        minRotation: 0,
                        autoSkip: true,
                    },
                    border: {
                        display: false,
                        font: {
                            family: 'Inter',
                            size: '12',
                            weight: '600',
                            lineHeight: '18px'
                        }
                    },
                    offset: isMobile ? true : false,
                },
                y: {
                    grid: {
                        display: true,
                    },
                    ticks: {
                        color: '#BEC5CF',
                        count: 10,
                        display: isMobile ? false : true,
                    },
                    border: {
                        display: false,
                    },
                    position: 'right',
                    min: 0,
                    offset: true,
                },
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        }
    }, [uniqueScans, isMobile, t, labels, translateAnalyticsDate])

    const datasets = useMemo(() => {
        if (abTesting) {
            if (graphData[0]?.count !== undefined && graphData?.some((data) => data.count > 0)) {
                return [
                    {
                        label: uniqueScans ? t("totalScans") : t("uniqueScans"),
                        data: graphData?.map((data) => data.count),
                        borderColor: "#EB72A5",
                        lineTension: 0.5,
                    },
                ]
            } else {
                if (graphData && graphData.length > 0 && graphData?.some((data) => data.count_first > 0) && graphData?.some((data) => data.count_second > 0)) {
                    return [
                        {
                            label: uniqueScans ? t("totalScans") : t("uniqueScans"),
                            data: graphData?.map((data) => data.count_first),
                            borderColor: "#EB72A5",
                            lineTension: 0.5,
                        },
                        {
                            label: uniqueScans ? t("totalScans") : t("uniqueScans"),
                            data: graphData?.map((data) => data.count_second),
                            borderColor: "#9747FF",
                            lineTension: 0.5,
                        },
                    ]
                } else if (graphData && graphData.length > 0 && graphData?.some((data) => data.count_first > 0)) {
                    return [
                        {
                            label: uniqueScans ? t("totalScans") : t("uniqueScans"),
                            data: graphData?.map((data) => data.count_first),
                            borderColor: "#EB72A5",
                            lineTension: 0.5,
                        },
                    ]
                } else if (graphData && graphData.length > 0 && graphData?.some((data) => data.count_second > 0)) {
                    return [
                        {
                            label: uniqueScans ? t("totalScans") : t("uniqueScans"),
                            data: graphData?.map((data) => data.count_second),
                            borderColor: "#9747FF",
                            lineTension: 0.5,
                        },
                    ]
                }
            }
        } else {
            return [
                {
                    label: uniqueScans ? t("totalScans") : t("uniqueScans"),
                    data: graphData?.map((data) => data.count),
                    borderColor: gradient,
                    lineTension: 0.5,
                },
            ]
        }
    }, [abTesting, uniqueScans, gradient, graphData, t])

    const data = useMemo(() => {
        return {
            labels,
            datasets: datasets,
        }
    }, [labels, datasets]);

    useEffect(() => {
        if (graphRef.current) {
            if (!graphRef.current.scales.y.ticks.length) return

            setMobileTicks(graphRef.current.scales.y.ticks.map((tick) => tick.label).reverse())
        }
    }, [data])

    useEffect(() => {
        const ctx = document.getElementById('analytics-graph').getContext('2d');
        const width = ctx.canvas.scrollWidth;

        if (ctx) {
            let gradientStroke = ctx.createLinearGradient(0, 0, width, 0);
            gradientStroke.addColorStop(0, "#EB72A5");
            gradientStroke.addColorStop(1, "#9747FF");

            setGradient(gradientStroke)
        }
    }, [])

    return (
        <div style={{ overflowX: 'auto', width: '100%', height: '260px', overflowY: 'hidden' }} className='analytics-graph-wrapper'>
            <div style={{ width: `${isMobile && labels?.length > 0 ? `${labels[0].length > 15 ? labels.length * labels[0].length * 8 : labels.length * labels[0].length * 9}px` : '100%'}`, minWidth: '100%', height: '260px' }}>
                <Line ref={graphRef} id="analytics-graph" options={options} data={data} />
            </div>
            <div className='analytics-graph-mobile-static-ticks'>
                {mobileTicks.map((tick, index) => (
                    <span key={index}>{tick}</span>
                ))}
            </div>
        </div>
    )
}

export default AnalyticsGraph