import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { useEffect } from "react"

import { useGetPaymentsDateInfoQuery } from "../../api/api"

import Calendar from '../../assets/icons/calendar.svg'

const Charge = () => {
    const user = useSelector((state) => state.app.user)

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { data } = useGetPaymentsDateInfoQuery()

    const handleNavigateToContactUs = () => {
        navigate('/contact-us')
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={`purple-corners-block-wrapper ${user ? '' : 'no-user'}`}>
            <Helmet>
                <link rel="canonical" href="https://qrcodeveloper.com/charge" />
                <link rel="alternate" href="https://qrcodeveloper.com/pt/charge" hreflang="pt"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/de/charge" hreflang="de"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/es/charge" hreflang="es"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/fr/charge" hreflang="fr"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/dk/charge" hreflang="dk"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/it/charge" hreflang="it"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/hu/charge" hreflang="hu"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/mx/charge" hreflang="mx"></link>
            </Helmet>
            <main className={`product-information-main p-[8px] mobile:p-0 ${user ? 'pt-[8px]' : 'pt-0'}`}>
                <section className="flex flex-col gap-[32px] w-full mobile:gap-0">
                    <div className="charge-page-background">
                        <div className="charge-page-background-top-layer">
                        </div>
                        <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] flex flex-col gap-[40px] items-center mobile:w-[calc(100%-48px)]">
                            <h5 className="text-[48px] leading-[56px] text-white font-bold text-center max-w-[450px] mobile:text-[28px] mobile:leading-[34px] mobile:max-w-[272px]">
                                {t("howOurBillingWorks")}
                            </h5>
                            <button onClick={handleNavigateToContactUs} className="h-[66px] bg-white rounded-lg w-fit px-[24px] text-[23px] leading-[28px] font-bold tracking-[-0.02em] text-[#141315] hover:bg-[#f7fafe] mobile:h-[44px] mobile:w-full mobile:text-base mobile:font-semibold">
                                {t("contactUs")}
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-[24px] items-start justify-center px-[24px] pb-[120px] pt-[100px] mobile:flex-col-reverse mobile:px-[16px] mobile:py-[16px]">
                        <div className="flex flex-col max-w-[745px] gap-[32px]">
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("whyIsThereAChargedOnMyCard")}
                                </h4>
                                <p className="text-base">
                                    {t("itsAChargeForOneOfOurSubscriptionProducts")}
                                </p>
                            </div>
                            <div className="min-h-[1px] w-full bg-[#E2E8F0]"></div>
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("2ContactUs")}
                                </h4>
                                <p className="text-base">
                                    {t("weAreHereToHelpWhetherYouAreFacingBillingIssueOrDifficulties")}
                                </p>
                            </div>
                            <div className="min-h-[1px] w-full bg-[#E2E8F0]"></div>
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("whatIsQRCodeDeveloper")}
                                </h4>
                                <p className="text-base">
                                    {t("qrCodeDeveloperIsAServiceThatHelpsGenerateQRCodes")}
                                </p>
                            </div>
                            <div className="min-h-[1px] w-full bg-[#E2E8F0]"></div>
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("whyAreYouChargingMe")}
                                </h4>
                                <p className="text-base">
                                    {t("ifYouDidNotRegisterWithQRCodeDeveloperAndBelieveYouAreBeingChargedIncorrectly")}
                                </p>
                            </div>
                            <div className="min-h-[1px] w-full bg-[#E2E8F0]"></div>
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("doesNotRingABell")}
                                </h4>
                                <p className="text-base">
                                    {t("ifYouUnsureOfAChargeOrDidNotSignUpContactUs")}
                                </p>
                            </div>
                            <div className="min-h-[1px] w-full bg-[#E2E8F0]"></div>
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("howDoICancelMyAccount")}
                                </h4>
                                <p className="mb-2 text-base">
                                    {t("weDoNotHaveLongTermContractsOrTerminationFees")} <Link style={{ color: '#9747FF', cursor: 'pointer', textDecoration: 'underline' }} to={user ? '/dashboard' : '/sign-in'}>({t("signInHere")})</Link>, {t("goToAccountSettingsTabAndClickCancel")}
                                </p>
                                <p className="text-base">
                                    {t("onceYouHaveCanceledYouWillNotBeChargedAgain")}
                                </p>
                            </div>
                            <div className="min-h-[1px] w-full bg-[#E2E8F0]"></div>
                            <div className="flex flex-col gap-[12px]">
                                <h4 className="text-[28px] leading-[24px] tracking-[-0.02em] font-bold text-[#141315] mobile:text-[19px] mobile:leading-[23px]">
                                    {t("anyFurtherQuestions")}
                                </h4>
                                <p className="mb-2 text-base">
                                    {t("forTheQuickestResponseSendAnEmail")}
                                </p>
                                <p className="text-base">
                                    {t("weWillGetBackToYouWithinTwoBusinessDays")}
                                </p>
                            </div>
                        </div>
                        {data && (
                            <div className="max-w-[360px] rounded-lg border-solid border-[1px] border-[#E2E8F0] w-full">
                                <div className="w-full flex flex-col gap-[8px] p-[16px] border-solid border-b-[1px] border-[#E2E8F0] mobile:p-[12px] mobile:gap-[4px]">
                                    <span className="text-base text-[#141315] mobile:text-[12px] mobile:leading-[18px]">
                                        {t("recentPayment")}
                                    </span>
                                    <p className="flex items-center gap-[8px] text-bold text-[#141315] text-[19px] leading-[23px] tracking-[-0.02em] font-bold mobile:text-[14px] mobile:leading-[20px]">
                                        <img src={Calendar} alt="" />
                                        {data?.last_payment}
                                    </p>
                                </div>
                                <div className="w-full flex flex-col gap-[8px] p-[16px] mobile:p-[12px] mobile:gap-[4px]">
                                    <span className="text-base text-[#141315] mobile:text-[12px] mobile:leading-[18px]">
                                        {t("nextScheduledPaymentDue")}
                                    </span>
                                    <p className="flex items-center gap-[8px] text-bold text-[#141315] text-[19px] leading-[23px] tracking-[-0.02em] font-bold mobile:text-[14px] mobile:leading-[20px]">
                                        <img src={Calendar} alt="" />
                                        {data?.next_payment}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Charge