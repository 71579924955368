import { useTranslation } from 'react-i18next'
import React from 'react'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import EmptyVideo from '../../assets/icons/code-create/empty-video.svg'
import EmptyLogo from '../../assets/icons/code-create/empty-logo.svg'

const VideoPreview = ({ data, isContentAdded = true, version = 1 }) => {
    const { t } = useTranslation()

    const videoCode = data.link.split('?v=')[1] ? data.link.split('?v=')[1] : data.link.split('be/')[1]
    const decreaseElementsSize = window.location.pathname.includes('/code') ? false : true

    const onClickRedirect = (link) => {
        const editedLink = link.includes('https://') || link.includes('http://') ? link : `https://${link}`
        const linkWithoutSpaces = editedLink.replaceAll(' ', '')

        window.open(linkWithoutSpaces, '_blank')
    }

    return (
        <>
            {isContentAdded ? (
                <>
                    {version === 1 ? (
                        <div className='video-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
                            <div className='video-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                                <div className='video-company-logo-block'>
                                    {data.logo ? (
                                        <img className='video-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                                    ) : (
                                        <div className='video-logo-empty'></div>
                                    )}
                                </div>
                                <span className='video-company-name' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.company ? data.company : t("companyName")}
                                </span>
                                <h5 className='video-title-text' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.title ? data.title : t("videoTitle")}
                                </h5>
                                <span className='video-description' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.description ? data.description : t("description")}
                                </span>
                                <button onClick={() => onClickRedirect(data.redirect)} className='video-button' style={{ backgroundColor: data.designButtonColor }}>
                                    {data.button ? data.button : 'CTA'}
                                </button>
                            </div>
                            <div className='video-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                                <div className={(data.link || data.files.length > 0) ? 'video-preview-block-no-background' : 'video-preview-block'}>
                                    {data.link ? (
                                        <div className='video-preview-item-wrapper'>
                                            <iframe
                                                width={"100%"}
                                                allowFullScreen='allowfullscreen'
                                                title="123"
                                                src={data.autoplayVideo ? `https://www.youtube.com/embed/${videoCode}?autoplay=1` : `https://www.youtube.com/embed/${videoCode}`}
                                            ></iframe>
                                        </div>
                                    ) : data.files && data.files.length > 0 && Array.from(data.files).map((video, idx) => {
                                        return (
                                            <div key={idx} className='video-preview-item-wrapper'>
                                                <video src={video.file ? video.file : URL.createObjectURL(video)} controlsList='nodownload' controls></video>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ backgroundColor: `${data.designPrimaryColor}` }}
                            className={`flex flex-col w-full items-center ${decreaseElementsSize ? 'absolute top-[0px] left-0 pt-[20px] min-h-full' : 'min-h-[100dvh]'}`}
                        >
                            <div
                                className={`flex items-center justify-center px-2 border-solid border-b-[1px] border-[#E2E8F0] gap-2 w-full ${decreaseElementsSize ? 'h-[38px]' : 'h-[44px]'}`}
                            >
                                <img
                                    className={`${decreaseElementsSize ? 'h-[20px]' : 'h-[28px]'}`}
                                    src={
                                        data.logo ?
                                            (typeof (data.logo) === 'string'
                                                ? data.logo
                                                : URL.createObjectURL(data.logo))
                                            : EmptyLogo
                                    }
                                    alt=""
                                />
                                <h6
                                    style={{ color: `${data.company ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`tracking-[-0.02em] font-bold ${decreaseElementsSize ? 'text-[16px] leading-[20px]' : 'text-[19px] leading-[23px]'}`}
                                >
                                    {data.company ? data.company : t("company")}
                                </h6>
                            </div>
                            <div className={`max-w-[600px] flex flex-col items-center w-full ${decreaseElementsSize ? 'p-[8px] pt-[20px] gap-[8px]' : 'p-[16px] pt-[40px] gap-[16px]'}`}>
                                <h6
                                    style={{ color: `${data.title ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`text-center tracking-[-0.04em] break-words ${decreaseElementsSize ? 'text-[20px] leading-[28px] font-semibold' : 'text-[34px] leading-[40px] font-bold'}`}
                                >
                                    {data.title ? data.title : t("videoTitle")}
                                </h6>
                                <p
                                    style={{ color: `${data.description ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`break-words text-center ${decreaseElementsSize ? 'text-[14px] leading-[17px]' : 'text-[20px] leading-[24px]'}`}
                                >
                                    {data.description ? data.description : t("description")}
                                </p>
                                <button
                                    onClick={() => onClickRedirect(data.redirect)}
                                    style={{
                                        backgroundColor: data.designButtonColor,
                                        color: data.designSecondaryColor
                                    }}
                                    className={`flex items-center justify-center gap-[6px] w-full rounded-lg font-semibold ${decreaseElementsSize ? 'text-[12px] h-[26px] mt-[4px] mb-[20px]' : 'text-[16px] h-[40px] mt-[8px] mb-[40px]'}`}
                                >
                                    {data.button ? data.button : t("button")}
                                </button>
                                <div className={`w-full`} style={{ backgroundColor: data.designSecondaryColor }}>
                                    {(data.link || data.files.length > 0) ? (
                                        <div className={`flex flex-col ${decreaseElementsSize ? 'gap-[8px]' : 'gap-[16px]'}`}>
                                            {data.link ? (
                                                <div className='w-full rounded-lg overflow-hidden'>
                                                    <iframe
                                                        width={"100%"}
                                                        className='youtube-aspect-ratio'
                                                        allowFullScreen='allowfullscreen'
                                                        title="123"
                                                        src={data.autoplayVideo ? `https://www.youtube.com/embed/${videoCode}?autoplay=1` : `https://www.youtube.com/embed/${videoCode}`}
                                                    ></iframe>
                                                </div>
                                            ) : data.files && data.files.length > 0 && Array.from(data.files).map((video, idx) => {
                                                return (
                                                    <div key={idx} className='w-full rounded-lg overflow-hidden'>
                                                        <video src={video.file ? video.file : URL.createObjectURL(video)} controlsList='nodownload' controls></video>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <div className={`flex flex-col ${decreaseElementsSize ? 'gap-[8px]' : 'gap-[16px]'}`}>
                                            <img src={EmptyVideo} alt="" />
                                            <img src={EmptyVideo} alt="" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["Video"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["Video"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default VideoPreview