import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

const AuthSwitch = ({ type, withUpgrade, isDownload, ownerLoginFromExpired }) => {
    const navigate = useCustomNavigate()

    const { t } = useTranslation()

    const handleNavigate = (path) => () => {
        if (path === type) return;

        if (path === 'sign-up') {
            navigate(`/sign-up?${withUpgrade ? 'upgrade=true' : ''}${isDownload ? '&download=true' : ''}${ownerLoginFromExpired ? '&ownerLogin=true' : ''}`)
        } else if (path === 'sign-in') {
            navigate(`/sign-in?${withUpgrade ? 'upgrade=true' : ''}${isDownload ? '&download=true' : ''}${ownerLoginFromExpired ? '&ownerLogin=true' : ''}`)
        }
    }

    return (
        <div className='auth-switch-wrapper'>
            <div onClick={handleNavigate('sign-up')} className={`auth-switch-item ${type === 'sign-up' ? 'active' : ''}`}>
                {t("createAccount")}
            </div>
            <div onClick={handleNavigate('sign-in')} className={`auth-switch-item ${type === 'sign-in' ? 'active' : ''}`}>
                {t("alreadyHaveAnAccountLogIn")}
            </div>
        </div>
    )
}

export default AuthSwitch