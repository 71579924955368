import { useEffect } from 'react'
import { useState } from 'react'

const usePlanName = (subscription) => {
    const [planName, setPlanName] = useState('')

    useEffect(() => {
        if (subscription) {
            if (subscription.plan === 'Free') {
                setPlanName('Free')
            } else if (subscription.plan.includes('Starter')) {
                setPlanName('Starter')
            } else if (subscription.plan.includes('Advanced')) {
                setPlanName('Advanced')
            } else if (subscription.plan.includes('Professional')) {
                setPlanName('Professional')
            } else if (subscription.plan.includes('Limited')) {
                setPlanName('Limited')
            } else if (subscription.plan.includes('Full')) {
                setPlanName('Full')
            } else if (subscription.plan.includes('Yearly')) {
                setPlanName('Yearly')
            } else if (subscription.plan.includes('Unlimited')) {
                setPlanName('Unlimited')
            }
        }
    }, [subscription])

    return {
        planName
    }
}

export default usePlanName