import React from 'react'
import { useParams } from 'react-router-dom'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import useDashboardActions from '../../hooks/useDashboardActions'
import useConvertToDynamic from '../../hooks/useConvertToDynamic'
import useTrialDaysLeft from '../../hooks/useTrialDaysLeft'
import { useGetCodeByIdQuery } from '../../api/api'

import ArchiveConfrimModal from '../../components/ArchiveConfirmModal/ArchiveConfrimModal'
import DeleteConfirmModal from '../../components/DeleteConfirmModal/DeleteConfirmModal'
import OverviewPanel from '../../components/OverviewPanel/OverviewPanel'

const OverviewCode = () => {
    const navigate = useCustomNavigate()

    const { id } = useParams()
    const { trialDaysLeft } = useTrialDaysLeft()
    const { setCodeToConvert, convertCodeJsx, setIsConverting } = useConvertToDynamic()
    const {
        downloadCode, handleEditQrCode, handleArchiveDynamicCode,
        handleDeleteCode, handleArchiveCode,
        setArchiveId, handleArchiveCodeConfirmed, handleDeleteCodeConfirmed,
        setDeleteId, showArchiveConfirmModal, showDeleteConfirmModal,
        setShowArchiveConfirmModal, archiveId, setOverviewCode,
        setShowDeleteConfirmModal,
    } = useDashboardActions()

    const { data: code } = useGetCodeByIdQuery(id)

    const handleConvertData = () => {
        setIsConverting(true)
        setCodeToConvert(code)
    }

    return (
        <div className='overview-code-page-wrapper'>
            <div className='overview-code-page-content'>
                <OverviewPanel
                    trialDaysLeft={trialDaysLeft}
                    downloadCode={downloadCode}
                    code={code}
                    handleEditQrCode={handleEditQrCode}
                    handleArchiveDynamicCode={handleArchiveDynamicCode}
                    handleDeleteCode={handleDeleteCode}
                    handleArchiveCode={handleArchiveCode}
                    setOverviewCode={() => navigate(-1)}
                    handleConvertData={handleConvertData}
                    setArchiveId={setArchiveId}
                    handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                    handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                    setDeleteId={setDeleteId}
                    isFullPage={true}
                />
            </div>
            {showArchiveConfirmModal && (
                <ArchiveConfrimModal
                    show={showArchiveConfirmModal}
                    onClose={() => setShowArchiveConfirmModal(false)}
                    handleArchiveCodeConfirmed={handleArchiveCodeConfirmed}
                    archiveId={archiveId}
                    setOverviewCode={setOverviewCode}
                />
            )}
            {showDeleteConfirmModal && (
                <DeleteConfirmModal
                    show={showDeleteConfirmModal}
                    onClose={() => setShowDeleteConfirmModal(false)}
                    handleDeleteCodeConfirmed={handleDeleteCodeConfirmed}
                    setOverviewCode={setOverviewCode}
                />
            )}
            {convertCodeJsx}
        </div>
    )
}

export default OverviewCode