import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowLeft } from 'react-icons/md'

import { handleResetUserPassword } from '../../firebase/auth/resetPassword'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import paymentSuccessSvg from '../../assets/icons/payment-success.svg'

const ResetPassword = () => {
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(null)

    const navigate = useCustomNavigate()

    const { t } = useTranslation()

    const handleChangeEmail = (value) => {
        setEmail(value)
        setStatus(null)
    }

    const handleSendResetLink = async () => {
        const response = await handleResetUserPassword(email)
        setStatus(response)
    }

    return (
        <div className='main-content-wrapper-centered'>
            {!status ? (
                <div className='auth-form-mobile flex flex-col h-fit gap-y-6 block-shadow max-w-lg w-full p-4 md:p-8 rounded-md'>
                    <span className='please-signup-big-purple-text mb-0'>
                        <span className='please-signup-big-dark-text'>{t("confirmationEmail")}</span>
                    </span>
                    <div className='flex flex-col'>
                        <input
                            type='text'
                            placeholder='Email address'
                            className={status === false ? 'form-input-error-border form-input-dashboard' : 'form-input-dashboard'}
                            value={email}
                            onChange={(e) => handleChangeEmail(e.target.value)}
                        />
                        {status === false && <p className='error-text'>{t("weCannotFindYourEmail")}</p>}
                    </div>
                    <button onClick={handleSendResetLink} className='header-sign-up-btn'>{t("sendConfirmationEmail")}</button>
                    <div onClick={() => navigate('/dashboard')} className='reset-password-back-btn'>
                        <MdKeyboardArrowLeft size={28} fill="#9747FF" />
                        {t("back")}
                    </div>
                </div>
            ) : (
                <div className='reset-password-sent-block'>
                    <img className='upgrade-success-img' src={paymentSuccessSvg} alt="" />
                    <span className='upgrade-success-text'>{t("emailWithResetLinkWasSentToEmail")}</span>
                    <button onClick={() => navigate('/dashboard')} className='upgrade-success-button'>{t("continueToLogin")}</button>
                </div>
            )}
        </div>
    )
}

export default ResetPassword