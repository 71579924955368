import { useTranslation } from 'react-i18next'
import { FiCopy } from 'react-icons/fi'
import React from 'react'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import EmptyImage from '../../assets/icons/code-create/empty-image.svg'
import EmptyLogo from '../../assets/icons/code-create/empty-logo.svg'
import wifiCopySvg from '../../assets/icons/wifi-cope.svg'

const WifiPreview = ({ data, isContentAdded = true, version = 1 }) => {
    const { t } = useTranslation()

    const decreaseElementsSize = window.location.pathname.includes('/code') ? false : true

    const handleCopyPassword = (password) => {
        navigator.clipboard.writeText(password)
    }

    return (
        <>
            {isContentAdded ? (
                <>
                    {version === 1 ? (
                        <div className='wifi-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
                            <div className='wifi-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor, paddingBottom: `${data.cover ? '160px' : '40px'}` }}>
                                <div className='wifi-logo-block'>
                                    {data.logo && (
                                        <img className='wifi-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                                    )}
                                </div>
                                <span className='wifi-preview-title' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.network_name ? data.network_name : t("networkName")}
                                </span>
                                <span className='wifi-preview-network-name' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.type ? data.type : t("networkType")}
                                </span>
                            </div>
                            <div className='wifi-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                                {data.cover && (
                                    <div className='wifi-preview-block-img-wrapper-no-shadow'>
                                        <img className='wifi-preview-img' src={typeof (data.cover) === 'string' ? data.cover : URL.createObjectURL(data.cover)} alt="" />
                                    </div>
                                )}
                                <div className='wifi-preview-password-block'>
                                    <input value={data.password} className='wifi-preview-password-input' placeholder={t("wifiPassword")} readOnly />
                                    <button style={{ backgroundColor: data.designButtonColor }} onClick={() => handleCopyPassword(data.password)} className='wifi-preview-cope-btn'>
                                        <img src={wifiCopySvg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ backgroundColor: `${data.designPrimaryColor}` }}
                            className={`flex flex-col w-full items-center ${decreaseElementsSize ? 'absolute top-[0px] left-0 pt-[20px] min-h-full' : 'min-h-[100dvh]'}`}
                        >
                            {(data.logo || decreaseElementsSize) && (
                                <div
                                    className={`flex items-center justify-center px-2 border-solid border-b-[1px] border-[#E2E8F0] gap-2 w-full ${decreaseElementsSize ? 'h-[38px]' : 'h-[44px]'}`}
                                >
                                    <img
                                        className={`${decreaseElementsSize ? 'h-[20px]' : 'h-[28px]'}`}
                                        src={
                                            data.logo ?
                                                (typeof (data.logo) === 'string'
                                                    ? data.logo
                                                    : URL.createObjectURL(data.logo))
                                                : EmptyLogo
                                        }
                                        alt=""
                                    />
                                </div>
                            )}
                            <div className={`max-w-[600px] flex flex-col w-full`}>
                                {(data.cover || decreaseElementsSize) && (
                                    <img
                                        className={`w-full ${data.cover ? '' : 'square-aspect-ratio'}`}
                                        src={data.cover ? (typeof (data.cover) === 'string' ? data.cover : URL.createObjectURL(data.cover)) : EmptyImage}
                                        alt=""
                                    />
                                )}
                                <div className={`max-w-[600px] flex flex-col w-full ${decreaseElementsSize ? 'gap-[8px] p-[8px] pt-[16px]' : 'gap-[16px] p-[16px] pt-[48px]'}`}>
                                    <h6
                                        style={{ color: `${data.network_name ? `${data.designTextColor}` : '#14131580'}` }}
                                        className={`tracking-[-0.04em] break-words text-center ${decreaseElementsSize ? 'text-[20px] leading-[28px] font-semibold' : 'text-[34px] leading-[40px] font-bold'}`}
                                    >
                                        {data.network_name ? data.network_name : t("networkName")}
                                    </h6>
                                    <div className={`flex flex-col border-solid border-[1px] border-[#E2E8F0] ${decreaseElementsSize ? 'p-[8px] gap-[4px] rounded-[6px]' : 'p-[16px] gap-[8px] rounded-xl'}`}>
                                        <span className={`text-[#7D8897] ${decreaseElementsSize ? 'text-[11px] leading-[15px]' : 'text-[14px] leading-[17px]'}`}>
                                            {t("wifiPasswordNoDots")}
                                        </span>
                                        <p
                                            style={{ color: `${data.password ? `${data.designTextColor}` : '#14131580'}` }}
                                            className={`tracking-[-0.02em] font-semibold ${decreaseElementsSize ? 'text-[12px] leading-[15px]' : 'text-[19px] leading-[23px]'}`}
                                        >
                                            {data.password ? data.password : t("password")}
                                        </p>
                                        <button
                                            onClick={() => handleCopyPassword(data.password)}
                                            style={{
                                                backgroundColor: data.designButtonColor,
                                                color: data.designSecondaryColor
                                            }}
                                            className={`flex items-center justify-center gap-[6px] w-full rounded-lg font-semibold ${decreaseElementsSize ? 'text-[12px] h-[26px] mt-[4px]' : 'text-[16px] h-[40px] mt-[8px]'}`}
                                        >
                                            <FiCopy
                                                size={decreaseElementsSize ? 13 : 16}
                                                color={data.designSecondaryColor}
                                            />
                                            {t("copyPassword")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["Wi-Fi"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["Wi-Fi"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default WifiPreview