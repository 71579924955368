import React from 'react'

import CarouselControlIcon from '../../assets/icons/carousel-control.svg'

const CarouselControl = ({ position, onClick, refElement }) => {
    return (
        <button ref={refElement} onClick={onClick} className={`carousel-control-item ${position}`}>
            <img src={CarouselControlIcon} alt="" />
        </button>
    )
}

export default CarouselControl