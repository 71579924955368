import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../config";

export const handleResetUserPassword = (email) => {
    const result = sendPasswordResetEmail(auth, email)
        .then(() => {
            return true
        })
        .catch(() => {
            return false
        });

    return result
}
