import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


const createLinearGradient = (ctx, chartArea, code, trialDaysLeft) => {
    let gradient;

    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    if (code.archived || code.data.paused || trialDaysLeft <= 0) {
        gradient.addColorStop(1, '#E2E8F0');
        gradient.addColorStop(0.15, 'rgba(226, 232, 240, 0)');
    } else {
        gradient.addColorStop(1, '#E0C8FF');
        gradient.addColorStop(0.15, 'rgba(224, 200, 255, 0)');
    }


    return gradient;
}

const DashboardCodeTrend = ({ code, trialDaysLeft }) => {
    const options = useMemo(() => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                    display: false,
                },
                title: {
                    display: false,
                    text: 'Chart.js Line Chart',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        },
                    }],
                },
                tooltip: {
                    enabled: false,
                },
            },
            elements: {
                point: {
                    pointBackgroundColor: '#9747FF',
                    radius: 0,
                    hoverRadius: 0,
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                    border: {
                        display: false,
                    }
                },
                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    offset: true,
                    min: 0,
                },
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        }
    }, [])

    const labels = useMemo(() => {
        if (code?.data?.trend?.length < 2) {
            return [0, ...code?.data?.trend]
        } else {
            return code.data.trend.map((el) => el)
        }
    }, [code.data.trend])

    const data = useMemo(() => {
        return {
            labels,
            datasets: [
                {
                    label: 'Total Scans',
                    lineTension: 0.2,
                    data: code?.data?.trend?.length < 2 ? [0, ...code?.data?.trend] : code.data.trend,
                    borderColor: code.archived || code.data.paused || trialDaysLeft <= 0 ? '#7D8898' : '#9747FF',
                    backgroundColor: function (context) {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        if (!chartArea) {
                            return;
                        }
                        return createLinearGradient(ctx, chartArea, code, trialDaysLeft)
                    },
                    fill: true,
                },
            ],
        }
    }, [code, labels, trialDaysLeft]);

    return (
        <div className='dashboard-code-trend-wrapper'>
            <Line options={options} data={data} />
        </div>
    )
}

export default DashboardCodeTrend