import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { useCustomLoggerMutation } from '../../api/api'

import trialExpiredSvg from '../../assets/icons/trialexpired.svg'
import trialSvg from '../../assets/icons/trial.svg'

const TrialPeriodBlock = ({ trialDaysLeft, setShowAlert, isNewUser, scansLeft }) => {
    const navigate = useCustomNavigate()

    const { t } = useTranslation()

    const [customLogger] = useCustomLoggerMutation()

    const showTrialAlert = useMemo(() => {
        const localStorageTrial = localStorage.getItem('showTrialAlert')

        return localStorageTrial === 'false' ? false : true
    }, [])

    const handleNavigateToUpgrade = () => {
        if (isNewUser) {
            navigate('/upgrade-plan-new')
        } else {
            navigate('/upgrade-plan')
        }
        customLogger({
            action: 'click',
            description: 'Dashboard Upgrade Banner'
        })
    }

    return (
        <>
            {showTrialAlert && (
                <div>
                    {isNewUser ? (
                        <>
                            {scansLeft <= 0 ? (
                                <div className='dashboard-content-trial-end-block'>
                                    <div className='trial-end-block-left-side'>
                                        <img src={trialExpiredSvg} alt="" />
                                        <span className='trial-end-block-left-desktop-text'>{t("youHaveReachedYourFreeScansLimit")}</span>
                                        <div className='trial-end-block-left-texts-mobile'>
                                            <span className='trial-end-mobile-left-text-bold'>{t("youHaveReachedYourFreeQRCodeScansLimit")}</span>
                                            <span className='trial-end-mobile-left-text-normal'>{t("upgradeYourAccountToReactivateYourQRCodes")}</span>
                                        </div>
                                    </div>
                                    <button onClick={handleNavigateToUpgrade} className='trial-end-block-right-upgrade'>
                                        {t("upgrade")}
                                    </button>
                                </div>
                            ) : (
                                <div className='dashboard-content-trial-block'>
                                    <div className='trial-block-left-side'>
                                        <img src={trialSvg} alt="" />
                                        <span className='trial-block-left-text'>
                                            {t("scansOutOfLeftBanner", { scansLeft })}. {t("upgradeToGetUnlimitedAccess")}
                                        </span>
                                    </div>
                                    <div className='trial-block-right-side'>
                                        <button onClick={handleNavigateToUpgrade} className='trial-block-right-upgrade'>
                                            {t("upgrade")}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {trialDaysLeft >= 1 ? (
                                <div className='dashboard-content-trial-block'>
                                    <div className='trial-block-left-side'>
                                        <img src={trialSvg} alt="" />
                                        <span className='trial-block-left-text'>{t("youHaveStartedYour7DayFreeTrial")}</span>
                                        <div className='trial-block-left-days'>
                                            {trialDaysLeft === 1 ? t("1day") : `${trialDaysLeft} ${t("days")}`} {t("left")}
                                        </div>
                                    </div>
                                    <div className='trial-block-right-side'>
                                        <button onClick={handleNavigateToUpgrade} className='trial-block-right-upgrade'>
                                            {t("upgrade")}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className='dashboard-content-trial-end-block'>
                                    <div className='trial-end-block-left-side'>
                                        <img src={trialExpiredSvg} alt="" />
                                        <span className='trial-end-block-left-desktop-text'>
                                            {t("your7dayFreeTrialExpiredUpgradeYourAccount")}
                                        </span>
                                        <div className='trial-end-block-left-texts-mobile'>
                                            <span className='trial-end-mobile-left-text-bold'>{t("your7dayFreeTrialExpired")}</span>
                                            <span className='trial-end-mobile-left-text-normal'>{t("upgradeYourAccountToReactivateYourQRCodes")}</span>
                                        </div>
                                    </div>
                                    <button onClick={handleNavigateToUpgrade} className='trial-end-block-right-upgrade'>
                                        {t("upgrade")}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default TrialPeriodBlock