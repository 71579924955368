import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useTrialDaysLeft from '../../hooks/useTrialDaysLeft'
import { useViewport } from '../../hooks/useViewport'

import PaymentAndPlanSettings from '../../components/SettingsComponents/PaymentAndPlanSettings'
import NotificationSettings from '../../components/SettingsComponents/NotificationSettings'
import DashboardNavigation from '../../components/DashboardNavigation/DashboardNavigation'
import SettingsNavigation from '../../components/SettingsComponents/SettingsNavigation'
import ProfileSettings from '../../components/SettingsComponents/ProfileSettings'
import QrCodeSettings from '../../components/SettingsComponents/QrCodeSettings'
import TermsOfService from '../TermsOfService/TermsOfService'
import PrivicyPolicy from '../PrivicyPolicy/PrivicyPolicy'
import ContactUs from '../ContactUs/ContactUs'

const Settings = () => {
    const [activeTab, setActiveTab] = useState('Profile')
    const settings = useSelector((state) => state.app.settings)
    const [searchParams, setSearchParams] = useSearchParams()

    const { trialDaysLeft } = useTrialDaysLeft()
    const { isMobile } = useViewport()

    useEffect(() => {
        if (isMobile) {
            setActiveTab('')
        } else {
            setActiveTab('Profile')
        }
    }, [isMobile])

    useEffect(() => {
        const tab = searchParams.get('tab')

        if (tab && tab === 'plans') {
            setActiveTab('Payments and Plan')
            setSearchParams({})
        }
    }, [searchParams, setSearchParams])

    return (
        <div className='settings-wrapper'>
            <DashboardNavigation setOverviewCode={() => null} />
            <div className='settings-content-wrapper'>
                {(!isMobile || (isMobile && !activeTab)) && (
                    <SettingsNavigation
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        isMobile={isMobile}
                    />
                )}
                {activeTab && (
                    <div className='settings-content-tabs'>
                        {activeTab === 'Profile' ? (
                            <ProfileSettings
                                isMobile={isMobile}
                                setActiveTab={setActiveTab}
                            />
                        ) : activeTab === 'Notifications' ? (
                            <NotificationSettings
                                isMobile={isMobile}
                                setActiveTab={setActiveTab}
                                settings={settings}
                            />
                        ) : activeTab === 'QR Code Settings' ? (
                            <QrCodeSettings
                                isMobile={isMobile}
                                setActiveTab={setActiveTab}
                                settings={settings}
                            />
                        ) : activeTab === 'Payments and Plan' ? (
                            <PaymentAndPlanSettings
                                isMobile={isMobile}
                                trialDaysLeft={trialDaysLeft}
                                setActiveTab={setActiveTab}
                                settings={settings}
                            />
                        ) : activeTab === 'Contact Us' ? (
                            <ContactUs
                                fromSettings={true}
                                isMobile={isMobile}
                                setActiveTab={setActiveTab}
                            />
                        ) : activeTab === 'Terms and Conditions' ? (
                            <TermsOfService
                                fromSettings={true}
                                setActiveTab={setActiveTab}
                                isMobile={isMobile}
                            />
                        ) : activeTab === 'Privacy Policy' ? (
                            <PrivicyPolicy
                                fromSettings={true}
                                setActiveTab={setActiveTab}
                                isMobile={isMobile}
                            />
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Settings