import html2canvas from 'html2canvas'
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

import { phoneNumbersList } from './constants';
import { auth } from '../firebase/config';

export const base64ToBlob = (base64Data, contentType = '') => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new File(byteArrays, 'image.jpg', { type: contentType });
};

export const fileToBlob = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            if (reader.readyState === FileReader.DONE) {
                const blob = new Blob([reader.result], { type: file.type });
                resolve(blob);
            }
        };

        reader.onerror = () => {
            reader.abort();
            reject(new Error('Failed to convert image to Blob.'));
        };

        reader.readAsArrayBuffer(file);
    });
}

export const convertToBlob = (imageSrc) => {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;

            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        };

        image.onerror = () => {
            reject(new Error('Failed to load image'));
        };

        image.src = imageSrc;
    });
};

export const convertAudioToBlob = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const blob = new Blob([event.target.result], { type: 'audio/mp3' });
            resolve(blob)
        };

        reader.onerror = () => {
            reject(new Error('Failed to load audio'));
        };

        reader.readAsArrayBuffer(file);
    })
};

export const handleFileChange = (fileBlob) => {
    return new Promise((resolve, reject) => {
        const file = fileBlob;
        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const context = canvas.getContext('2d');
                context.drawImage(image, 0, 0);

                const base64 = canvas.toDataURL('image/jpeg');
                resolve(base64)
            };

            image.onerror = () => {
                reject(new Error('Failed to load image'));
            }
        };

        reader.readAsDataURL(file);
    })
};

export const convertImageToBase64 = async (image) => {
    const imageBlob = await convertToBlob(image)
    const result = await handleFileChange(imageBlob)

    return result
}

export const isValidURL = (url) => {
    const urlPattern = /^((http|https):\/\/)?(www\.)?(?!.*(http|https|www\.))[a-zA-Z0-9_.-]+(\.[a-zA-Z]+)+(\/[\w-_%/@?:&=.#,]*)*(#[\w-]+,)?(\?[\w-_%&=.+,]*)?$/;

    return urlPattern.test(url);
}

export function convertDate(date_str, months) {
    const temp_date = new Date(date_str);

    return `${months[Number(temp_date.getMonth())]} ${temp_date.getDate()}, ${temp_date.getFullYear()}`
}

export const trialPeriodEndDate = (dateEnd) => {
    const date = new Date(dateEnd)
    const now = new Date()

    const timeDifference = date.getTime() - now.getTime();
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysLeft;
}

export const convertQrCodeToImage = async (canvasRef, imageSettings, borderWidth = 8, isMobile = false) => {
    const div = canvasRef.current.children[0]
    const image = canvasRef.current.children[0].children[0].children[2]

    const isBorder = div.style.border

    const img = document.createElement('img');

    if (imageSettings.src) {
        const divPosition = div.getBoundingClientRect()
        const logoPosition = image.getBoundingClientRect()

        const x = isBorder ? logoPosition.x - divPosition.x + logoPosition.width / 2 - borderWidth : logoPosition.x - divPosition.x + logoPosition.width / 2;
        const y = isBorder ? logoPosition.y - divPosition.y + logoPosition.height / 2 - borderWidth : logoPosition.y - divPosition.y + logoPosition.height / 2;

        img.src = imageSettings.src
        img.width = logoPosition.width
        img.height = logoPosition.height
        img.id = "remove"
        img.style.position = 'absolute'
        img.style.top = `${y}px`
        img.style.left = `${x}px`
        img.style.transform = 'translate(-50%, -50%)'

        div.appendChild(img)
        image.style.display = 'none'
    }

    const canvas = await html2canvas(canvasRef.current, { scale: 5, allowTaint: true, useCORS: false, logging: false })

    const screenshotUrl = canvas.toDataURL('image/png');

    if (imageSettings.src) {
        div.removeChild(img)
        image.style.display = 'unset'
    }

    return screenshotUrl
}

export const formatDate = (startDate, endDate) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    let startDateFormatted;
    let endDateFormatted;

    if (startDateObj.getFullYear() === endDateObj.getFullYear()) {
        startDateFormatted = startDateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        endDateFormatted = endDateObj.toLocaleDateString('en-US', options);
    } else {
        startDateFormatted = startDateObj.toLocaleDateString('en-US', options);
        endDateFormatted = endDateObj.toLocaleDateString('en-US', options);
    }

    return `${startDateFormatted} - ${endDateFormatted}`;
}

export const convertFormattedDateToRequest = (date) => {
    if (!date) return '';

    const dateArray = date.split(' - ');
    const startDateString = dateArray[0];
    const startDate = new Date(dateArray[0]);
    const endDate = new Date(dateArray[1]);

    if (!startDateString?.split(' ')[2]) {
        startDate.setFullYear(endDate.getFullYear());
    }

    return `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()} - ${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`
}

export const convertFormattedDateToNormal = (date) => {
    if (!date) return '';

    const dateArray = date.split(' - ');
    const startDateString = dateArray[0];
    const startDate = new Date(dateArray[0]);
    const endDate = new Date(dateArray[1]);

    if (!startDateString?.split(' ')[2]) {
        startDate.setFullYear(endDate.getFullYear());
    }

    return { startDate, endDate }
}

export const convertRequestDateToFormatted = (date) => {
    const splittedDate = date.split(' - ')
    const dateFromSplitted = splittedDate[0].split('/')
    const dateToSplitted = splittedDate[1].split('/')

    const dateFrom = new Date(dateFromSplitted[2], dateFromSplitted[0] - 1, dateFromSplitted[1])
    const dateTo = new Date(dateToSplitted[2], dateToSplitted[0] - 1, dateToSplitted[1])

    const res = formatDate(dateFrom, dateTo)

    return res
}

export const checkIfCurrentDateInRange = (date) => {
    const dateArray = date.split(' - ')

    if (dateArray.length > 1) {
        const splittedStartDate = dateArray[0].split(' ')
        let startDate;

        if (splittedStartDate.length === 3) {
            startDate = new Date(dateArray[0])
        } else {
            startDate = new Date(dateArray[0]).setFullYear(new Date(dateArray[1]).getFullYear())
        }

        const currentDate = new Date().setHours(0, 0, 0, 0)
        const endDate = new Date(dateArray[1])

        if (currentDate >= startDate && currentDate <= endDate) {
            return true
        } else {
            return false
        }
    } else {
        if (dateArray[0].length === 8) {
            const date = new Date().toLocaleString('en-US', { month: 'short', year: 'numeric' })
            if (dateArray[0].toLowerCase() === date.toLowerCase()) {
                return true
            } else {
                return false
            }
        } else {
            const currentDate = new Date().toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
            const date = new Date(dateArray[0]).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })

            if (currentDate === date) {
                return true
            } else {
                return false
            }
        }
    }
}

export const getUserTimezone = () => {
    const date = new Date();
    const utcOffsetMinutes = date.getTimezoneOffset();

    const utcOffsetHours = Math.abs(Math.floor(utcOffsetMinutes / 60));
    const utcOffsetMinutesRemainder = Math.abs(utcOffsetMinutes % 60);

    const utcOffsetString = 'UTC' + (utcOffsetMinutes >= 0 ? '-' : '+') +
        (utcOffsetHours < 10 ? '0' : '') + utcOffsetHours +
        (utcOffsetMinutesRemainder < 10 ? ':0' : ':') + utcOffsetMinutesRemainder;

    return utcOffsetString
}

export const handleValidatePassword = async (password) => {
    try {
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            password
        )

        const { user } = await reauthenticateWithCredential(
            auth.currentUser,
            credential
        )

        if (user) {
            return true
        }
    } catch (error) {
        return false
    }
}

export const findCountryCodeByName = (name) => {
    const country = phoneNumbersList.find(country => country.name === name)

    if (country) {
        return country.code
    } else {
        return 'US'
    }
}

export const convert24HourTo12Hour = (time) => {
    const date = new Date(time).toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });

    return date
}

export const convert12HourTo24Hour = (time, format) => {
    const date = new Date()

    if (Number(time.split(':')[0]) >= '12') {
        date.setHours(Number(time.split(':')[0]) - 12)
        date.setMinutes(time.split(':')[1])
        date.setSeconds(0)
    } else if (format === 'AM') {
        date.setHours(time.split(':')[0])
        date.setMinutes(time.split(':')[1])
        date.setSeconds(0)
    } else {
        date.setHours(Number(time.split(':')[0]) + 12)
        date.setMinutes(time.split(':')[1])
        date.setSeconds(0)
    }

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    const formattedMonth = month < 10 ? `0${month}` : month
    const formattedDay = day < 10 ? `0${day}` : day
    const formattedHours = hours < 10 ? `0${hours}` : hours
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

    const formattedDate = `${year}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}:${formattedSeconds}Z`

    return formattedDate
}