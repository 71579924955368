import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react'

import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import { useViewport } from '../../../hooks/useViewport';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle'
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';

const EditWifi = ({ triggerValidation }) => {
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const { register, trigger, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const {
        dynamicWifiStyles, setDynamicWifiStyles
    } = useEditQrCodeContext()

    const handleChangeInput = (name, value) => {
        setDynamicWifiStyles({
            ...dynamicWifiStyles,
            [name]: value,
        })
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicWifiStyles({
            ...dynamicWifiStyles,
            logo: file,
        })
    }

    const handleSaveUploadedCoverFile = (file) => {
        setDynamicWifiStyles({
            ...dynamicWifiStyles,
            cover: file,
        })
    }

    const handleChangeWififType = (type) => {
        handleChangeInput('network_type', type === 'WEP' ? 'WEP' : type === 'WPA/WPA2' ? 'WPA' : 'nopass')
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (dynamicWifiStyles) {
            setValue('network_name', dynamicWifiStyles.network_name)
            setValue('password', dynamicWifiStyles.password)
        }
    }, [dynamicWifiStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            {!isMobile && (
                <CreationContentTitle title={"Wi-Fi"} withStep={false} />
            )}
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("networkName")}</label>
                    <input
                        type='text'
                        placeholder={t("enterWifiNetworkName")}
                        className={errors.network_name ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("network_name", {
                            onChange: (e) => handleChangeInput('network_name', e.target.value),
                            value: dynamicWifiStyles.network_name,
                            required: true,
                        })}
                    />
                    {errors.network_name && <p className='error-text'>{t("enterNetworkName")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("networkType")}</label>
                    <Dropdown className='w-full'>
                        <Dropdown.Toggle style={{ height: '44px' }} className='bootstrap-default-dropdown w-full' id="dropdown-basic">
                            {dynamicWifiStyles?.network_type === 'WEP' ? 'WEP' : dynamicWifiStyles?.network_type === 'WPA' ? 'WPA/WPA2' : t("noEncryption")} <MdKeyboardArrowDown size={28} fill="#334155" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='w-full'>
                            <Dropdown.Item onClick={() => handleChangeWififType('WEP')} className='bootstrap-default-item'>
                                WEP
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeWififType('WPA/WPA2')} className='bootstrap-default-item'>
                                WPA/WPA2
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeWififType('No encryption')} className='bootstrap-default-item'>
                                {t("noEncryption")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("password")}</label>
                    <input
                        type='text'
                        placeholder={t("enterWifiPassword")}
                        className={errors.password ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("password", {
                            onChange: (e) => handleChangeInput('password', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.password && <p className='error-text'>{t("enterPassword")}</p>}
                </div>
            </div>
            {!isMobile && (
                <>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                </>
            )}
            {dynamicWifiStyles?.logo ? (
                <EditFileBlock
                    file={dynamicWifiStyles.logo}
                    title={t("logoFile")}
                    onFileChanged={handleSaveUploadedLogoFile}
                    onDeleteFile={() => handleSaveUploadedLogoFile(null)}
                    deleteAllowed={true}
                />
            ) : (
                <UploadLogoBlock
                    title={t("addLogoOptional")}
                    limit={"100MB"}
                    acceptList={"image/*"}
                    multiple={false}
                    onChangeFiles={handleSaveUploadedLogoFile}
                    uploadedFiles={dynamicWifiStyles.logo ? 1 : 0}
                    isError={false}
                    errorText={t("logoFileMustBeUploaded")}
                />
            )}
            {dynamicWifiStyles?.cover ? (
                <EditFileBlock
                    file={dynamicWifiStyles.cover}
                    title={t("coverFile")}
                    onFileChanged={handleSaveUploadedCoverFile}
                    onDeleteFile={() => handleSaveUploadedCoverFile(null)}
                    deleteAllowed={true}
                />
            ) : (
                <UploadLogoBlock
                    title={t("addCoverOptional")}
                    limit={"100MB"}
                    acceptList={"image/*"}
                    multiple={false}
                    onChangeFiles={handleSaveUploadedCoverFile}
                    uploadedFiles={dynamicWifiStyles.cover ? 1 : 0}
                    isError={false}
                    errorText={t("coverFileMustBeUploaded")}
                />
            )}
        </form>
    )
}

export default EditWifi