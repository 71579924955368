import React from 'react'
import wifiLinesSvg from '../../../assets/images/wifilines.svg'
import wifiCircleLinesSvg from '../../../assets/images/wificirclelines.svg'
import batterySvg from '../../../assets/images/battery.svg'

const PreviewNavigationPhone = ({ color }) => {
    return (
        <div className='preview-navigation-block'>
            <div style={{ color: color }} className='preview-navigation-time'>9:41</div>
            <div className='preview-navigation-black-camera'></div>
            <div className='preview-navigation-icons'>
                <img width={12} src={wifiLinesSvg} alt="" />
                <img width={12} src={wifiCircleLinesSvg} alt="" />
                <img width={12} src={batterySvg} alt="" />
            </div>
        </div>
    )
}

export default PreviewNavigationPhone