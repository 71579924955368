import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from 'flowbite-react'
import Rating from "react-rating";

import { useCreateUserFeedbackMutation } from '../../api/api';

import FeedbackSent from '../../assets/icons/feedback/feedback-sent.svg'
import StarActive from '../../assets/icons/feedback/star-active.svg'
import Star from '../../assets/icons/feedback/star.svg'

const UserFeedbackModal = ({ show, onClose }) => {
    const [rating, setRating] = useState(0)
    const [characters, setCharacters] = useState(0)
    const [feedback, setFeedback] = useState('')
    const user = useSelector((state) => state.app.user)

    const rootRef = useRef(null)

    const { t } = useTranslation()

    const [sendFeedback, { isSuccess }] = useCreateUserFeedbackMutation()

    const ratingChanged = (newRating) => {
        setRating(newRating)
    }

    const handleChangeFeedback = (e) => {
        setFeedback(e.target.value)
        setCharacters(e.target.value.length)
    }

    const handleSendFeedback = (isSkip) => {
        sendFeedback({
            email: user?.email,
            rating,
            text: feedback,
            is_skipped: isSkip,
        })
    }

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="md"
                popup="false"
                position='center'
                onClose={onClose}
                style={{ height: '100vh' }}
                className='user-feedback-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='user-feedback-modal-body padding-top-24'>
                    {isSuccess ? (
                        <div className='user-feedback-sent-block'>
                            <img width={80} src={FeedbackSent} alt="" />
                            <h5 className='user-feedback-sent-title'>{t("thankYouForYourFeedback")}</h5>
                            <button onClick={onClose} className='user-feedback-send-button mt-2'>{t("done")}</button>
                        </div>
                    ) : (
                        <>
                            <div className='user-feedback-header-block'>
                                <h5 className='user-feedback-header-title'>
                                    {t("howAreYouEnjoying")} <br /> QR Code Developer?
                                </h5>
                                <Rating
                                    initialRating={rating}
                                    className='user-feedback-rating-block'
                                    count={5}
                                    onChange={ratingChanged}
                                    emptySymbol={<img src={Star} alt="" />}
                                    fullSymbol={<img src={StarActive} alt="" />}
                                />
                            </div>
                            <div className='user-feedback-input-wrapper'>
                                <textarea
                                    value={feedback}
                                    onChange={handleChangeFeedback}
                                    className='user-feedback-input'
                                    placeholder={t("tellUsMoreAboutYourExperience")}
                                    maxLength={500}
                                />
                                <span className='user-feedback-characters-used'>{characters}/500 {t("characters")}</span>
                            </div>
                            <div className='user-feedback-buttons-wrapper'>
                                <button onClick={() => handleSendFeedback(false)} disabled={!feedback || rating === 0 ? true : false} className='user-feedback-send-button'>
                                    {t("send")}
                                </button>
                                <button onClick={() => { handleSendFeedback(true); onClose() }} className='user-feedback-later-button'>{t("maybeLater")}</button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UserFeedbackModal