import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const AnalyticsMobileExportData = ({
    setShowMobileExportData,
    handleExportData
}) => {
    const [isCsv, setIsCsv] = useState(false)
    const [isXlsx, setIsXlsx] = useState(false)

    const mobileExportDataRef = useRef(null)

    const { t } = useTranslation()

    const handleDownloadFiles = () => {
        if (isCsv) {
            handleExportData('csv')
        }

        if (isXlsx) {
            handleExportData('xlsx')
        }
    }

    useEffect(() => {
        const handleClick = (e) => {
            if (mobileExportDataRef.current && !mobileExportDataRef.current.contains(e.target)) {
                setShowMobileExportData(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [setShowMobileExportData])

    return (
        <div style={{ zIndex: '101' }} className='link-filter-background analytics-mobile-settings'>
            <div ref={mobileExportDataRef} className='link-filter-block'>
                <div onClick={() => setShowMobileExportData(false)} className='mobile-tray-horizontal-bar'>
                    <div className='mobile-tray-horizontal-bar-element'></div>
                </div>
                <div className='mobile-filter-expand-block-header'>
                    <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>{t("exportData")}</span>
                </div>
                <span className='link-filter-title-underline'></span>
                <div style={{ marginTop: '16px' }} className='analytics-selected-filter-options-list'>
                    <div className='analytics-selected-filter-options-item-block'>
                        <span className='analytics-selected-filter-options-item-text'>CSV</span>
                        <input
                            className='analytics-selected-filter-options-item-select'
                            onChange={(e) => setIsCsv(e.target.checked)}
                            checked={isCsv}
                            type="checkbox"
                        />
                    </div>
                    <div className='analytics-selected-filter-options-item-block'>
                        <span className='analytics-selected-filter-options-item-text'>XLSX</span>
                        <input
                            className='analytics-selected-filter-options-item-select'
                            onChange={(e) => setIsXlsx(e.target.checked)}
                            checked={isXlsx}
                            type="checkbox"
                        />
                    </div>
                </div>
                <button
                    onClick={handleDownloadFiles}
                    disabled={isCsv || isXlsx ? false : true}
                    className={`analytics-filters-apply-btn mt-3 ${isCsv || isXlsx ? 'active' : ''}`}
                >
                    {t("export")}
                </button>
            </div>
        </div>
    )
}

export default AnalyticsMobileExportData