import React from 'react'
import { useTranslation } from 'react-i18next'

const NotificationItemDescription = ({ kind, data, handleNavigateToUpgrade }) => {
    const { t } = useTranslation()

    return (
        <p className='notifications-panel-item-description'>
            {kind === 'first_scan'
                ? t("congratulationsOnYourFirstScan")
                : kind === 'track_analytics'
                    ? t("youHaveNotViewedYourCodeAnalyticsInAWhile")
                    : kind === 'scan_limit'
                        ? (
                            <>
                                {t("only")} {data.scans_left} {t("scansRemaining")} <span onClick={handleNavigateToUpgrade} style={{ fontWeight: '600', color: '#9747FF', cursor: 'pointer' }}>{t("upgrade")}</span> {t("forMoreScans")}
                            </>
                        )
                        : kind === 'new_scan'
                            ? (
                                <>
                                    {data.scans === 1 ? (
                                        `${t("someoneFrom")} ${t(data.location[0])} ${t("scannedYourCode")}`
                                    ) : data.scans > 1 && data.location.length === 1 ? (
                                        `${t("severalPeopleFrom")} ${t(data.location[0])} ${t("haveScannedYourQRCode")}`
                                    ) : (
                                        `${t("newScanActivityInThe")} ${data.location.map(el => t(el)).join(', ')} ${t("andMore")}`
                                    )}
                                </>
                            )
                            : kind === 'company_announcement'
                                ? `${data.content}`
                                : null
            }
        </p>
    )
}

export default NotificationItemDescription