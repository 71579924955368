import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../config";

export const signUpWithEmailPassword = async ({ email, password }) => {
    let error = null;

    try {
        await createUserWithEmailAndPassword(auth, email, password)
    } catch (e) {
        error = e.code
    }

    return { error }
}

export const signInWithEmailPassword = async ({ email, password }) => {
    let error = null;

    try {
        await signInWithEmailAndPassword(auth, email, password)
    } catch (e) {
        error = e.code
    }

    return { error }
}
