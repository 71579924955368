import { useTranslation } from 'react-i18next'

const useReturnTestimonials = () => {
    const { t } = useTranslation()

    const TESTIMONIALS_LIST = [
        {
            user: 'Susan T.',
            text: t("iNewNothingAboutQrCodeAndWasTerrified")
        },
        {
            user: 'Marianna D.',
            text: t("itWasReallyEasyToMakeItAndLoveIt")
        },
        {
            user: t("anonymous"),
            text: t("soEasyAndAffordable")
        },
        {
            user: t("anonymous"),
            text: t("itIsSoSimpleAndWorksPerfectlyEveryTime")
        },
        {
            user: t("anonymous"),
            text: t("thisIsVeryFantasticQuickAndVeryAccurate")
        },
        {
            user: t("anonymous"),
            text: t("itWasGreatINeededToGenerateAQRCodeForAUrlAndWasAble")
        },
        {
            user: t("anonymous"),
            text: t("absolutelyBrilliantThankYou")
        },
        {
            user: t("anonymous"),
            text: t("thankYouForMakingItAnEasyProcess")
        },
        {
            user: 'Shrina L.',
            text: t("iLoveTheEfficiencyOfJustTwoOrThreeClicks")
        },
        {
            user: 'Luis',
            text: t("greatEasyToUseAndGreatResults")
        }
    ]

    return {
        TESTIMONIALS_LIST
    }
}

export default useReturnTestimonials