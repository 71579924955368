import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { QRCodeSVG } from 'qrcode.react'
import { Helmet } from 'react-helmet'

import { FIREBASE_ERRORS_TRANSLATIONS } from '../../helpers/translation-constants'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import { signUpWithEmailPassword } from '../../firebase/auth/emailPasswordAuth'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { convertQrCodeToImage } from '../../helpers/functions'
import { googleSignIn } from '../../firebase/auth/googleAuth'
import { useSendEmailSendgridMutation } from '../../api/api'
import { useAuthContext } from '../../context/AuthContext'
import { useViewport } from '../../hooks/useViewport'

import AuthSwitch from '../../components/AuthSwitch/AuthSwitch'

import signUpCircleCheckedSvg from '../../assets/icons/sign-up-circle-checked.svg'
import newAuthMobileCloseSvg from '../../assets/icons/new-auth-mobile-close.svg'
import signUpCircleSvg from '../../assets/icons/sign-up-circle.svg'
import googleHeaderSvg from '../../assets/icons/googleHeader.svg'
import signUpFrame from '../../assets/icons/sign-up-frame.svg'
import newLogo from '../../assets/icons/newlogo.svg'

const SignUp = ({ signUpFrameBackgroundSvg }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const [withUpgrade, setWithUpgrade] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const [ownerLoginFromExpired, setOwnerLoginFromExpired] = useState(false)
    const [atLeastOneLetter, setAtLeastOneLetter] = useState(false)
    const [numberOrSymbol, setNumberOrSymbol] = useState(false)
    const [eightCharacters, setEightCharacters] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isPassword, setIsPassword] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const registerRef = useRef()

    const { isMobile } = useViewport()
    const { t } = useTranslation()
    const navigate = useCustomNavigate()

    const { canvasRef, imageSettings, canvasCreationRef,
        canvasStyles, backgroundColor, foregroundColor, scanColor, scanFont,
        scanText, publicId, generateCodeDashboard, isDynamic, staticValue
    } = useUserCodeCreationContext()
    const { setFlow, setGoogleWithDownload } = useAuthContext()

    const { register, handleSubmit } = useForm();

    const [sendRegistrationEmail] = useSendEmailSendgridMutation()

    const handlePasswordChange = (value) => {
        if (value.length > 0) {
            setIsPassword(true)
        } else {
            setIsPassword(false)
        }

        // check if includes at least one letter
        const letterRegex = /[A-Z]/g
        const letterMatch = value.match(letterRegex)

        if (letterMatch) {
            setAtLeastOneLetter(true)
        } else {
            setAtLeastOneLetter(false)
        }

        //check if includes at least one number
        const numberOrSymbolRegex = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g
        const numberOrSymbolMatch = value.match(numberOrSymbolRegex)

        if (numberOrSymbolMatch) {
            setNumberOrSymbol(true)
        } else {
            setNumberOrSymbol(false)
        }

        //check if includes at least 8 characters
        if (value.length >= 8) {
            setEightCharacters(true)
        } else {
            setEightCharacters(false)
        }
    }

    const convertCodeAndSignIn = async () => {
        if (withUpgrade) {
            setFlow(true)
        }

        if (isDownload) {
            await setGoogleWithDownload(true)
        }

        const { error, isNew, user } = await handleGoogleSignIn()

        if (!error) {
            if (ownerLoginFromExpired && !isNew) {
                setSearchParams({ redirect: 'upgrade' })
            } else if (ownerLoginFromExpired && isNew) {
                setSearchParams({ redirect: 'dashboard' })
            }

            if (isDownload) {
                const url = await convertQrCodeToImage(withUpgrade ? canvasCreationRef : canvasRef, imageSettings, 8, isMobile)

                generateCodeDashboard(() => { }, url)

                setGoogleWithDownload(false)

                if (isNew) {
                    window.gtag('event', 'generate_sign_up', {
                        email: user.email,
                    })
                }
            }

            if (withUpgrade && isNew) {
                setFlow(true)
                navigate('/upgrade-plan-new?icon=close')
            } else {
                setFlow(false)
                navigate('/dashboard?justLoggedIn=true')
            }

            if (isNew) {
                setTimeout(() => { sendRegistrationEmail() }, 1000);

                window.gtag('event', 'sign_up', {
                    method: 'Google'
                })
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-11350401889/i_miCJTMueoYEOHGpaQq'
                });
            } else {
                window.gtag('event', 'login', {
                    method: 'Google'
                })
            }
        }

    }

    const handleNavigateToSignIn = () => {
        navigate(`/sign-in?${withUpgrade ? 'upgrade=true&' : ''}${isDownload ? 'download=true&' : ''}${ownerLoginFromExpired ? 'ownerLogin=true' : ''}`)
    }

    const handleGoogleSignIn = async () => {
        const { error, isNew, user } = await googleSignIn()

        return { error, isNew, user }
    }

    const handleSignUp = async (data) => {
        setIsClicked(true)

        let url = '';

        if (withUpgrade) {
            setFlow(true)
        }

        if (isDownload) {
            url = await convertQrCodeToImage(withUpgrade ? canvasCreationRef : canvasRef, imageSettings, 8, isMobile)
        }

        const payload = {
            email: data.email,
            password: data.password
        }

        const { error } = await signUpWithEmailPassword(payload)

        if (!error) {
            if (ownerLoginFromExpired) {
                setSearchParams({ redirect: 'dashboard' })
            }

            window.gtag('event', 'sign_up', {
                method: 'Email'
            })
            window.gtag('event', 'conversion', {
                'send_to': 'AW-11350401889/i_miCJTMueoYEOHGpaQq'
            });

            navigate('/upgrade-plan-new?icon=close')

            if (isDownload) {
                window.gtag('event', 'generate_sign_up', {
                    email: data.email,
                })
                await generateCodeDashboard(() => { }, url)
            }

            setTimeout(() => { sendRegistrationEmail() }, 1000);
            setIsClicked(false)
        } else {
            setError(true)
            setFlow(false)
            setErrorMessage(error)
            setIsClicked(false)
        }
    }

    const handleNavigateToHome = () => {
        navigate('/')
    }

    useEffect(() => {
        window.scrollTo({ top: '0', behavior: 'instant' })
    }, [])

    useEffect(() => {
        const upgrade = searchParams.get('upgrade')
        const download = searchParams.get('download')
        const ownerLogin = searchParams.get('ownerLogin')

        if (upgrade === 'true') {
            setWithUpgrade(true)
        } else if (upgrade === 'false') {
            setWithUpgrade(false)
        }

        if (download === 'true') {
            setIsDownload(true)
        } else if (download === 'false') {
            setIsDownload(false)
        }

        if (ownerLogin === 'true') {
            setOwnerLoginFromExpired(true)
        } else if (ownerLogin === 'false') {
            setOwnerLoginFromExpired(false)
        }

        window.history.replaceState(null, null, window.location.pathname);
    }, [searchParams, setSearchParams])

    return (
        <div className='sign-up-in-form-wrapper'>
            <Helmet>
                <link rel="canonical" href="https://qrcodeveloper.com/sign-up" />
                <link rel="alternate" href="https://qrcodeveloper.com/pt/sign-up" hreflang="pt"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/de/sign-up" hreflang="de"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/es/sign-up" hreflang="es"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/fr/sign-up" hreflang="fr"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/dk/sign-up" hreflang="dk"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/it/sign-up" hreflang="it"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/hu/sign-up" hreflang="hu"></link>
                <link rel="alternate" href="https://qrcodeveloper.com/mx/sign-up" hreflang="mx"></link>
            </Helmet>
            <div className='please-signup-text-block'>
                <div className='flex items-center justify-between'>
                    <span onClick={handleNavigateToHome} className='please-signup-small-text cursor-pointer'>
                        <img src={newLogo} alt="" />
                        <span className='header-logo-text'>QR Code Developer</span>
                    </span>
                </div>
                <form onSubmit={handleSubmit(handleSignUp)} className='flex flex-col new-sign-in-up-form'>
                    <span className='please-signup-big-purple-text new-sign-up-in-page-title-wrapper-mobile mb-6'>
                        <span className='new-sign-up-in-page-title'>{t("createAnAccountToDownloadYourQrCode")}</span>
                    </span>
                    <AuthSwitch
                        type="sign-up"
                        withUpgrade={withUpgrade}
                        isDownload={isDownload}
                        ownerLoginFromExpired={ownerLoginFromExpired}
                    />
                    <button type="button" onClick={convertCodeAndSignIn} className='new-sign-up-in-page-google-btn flex justify-center mobile-hidden'>
                        <img src={googleHeaderSvg} alt="" />
                        {t("continueWithGoogle")}
                    </button>
                    <div className='please-signup-or-line mobile-hidden'>
                        <span className='please-signup-line'></span>
                        <span className='please-signup-or-text'>{t("or")}</span>
                    </div>
                    <div className={`new-sign-up-input-block-wrapper first ${isEmail ? 'filled' : ''}`}>
                        <span className='new-sign-up-label'>{t("emailAddress")}</span>
                        <input
                            type='text'
                            className={`new-sign-up-in-input`}
                            {...register("email", {
                                onChange: (e) => {
                                    setError(false)
                                    setIsEmail(e.target.value.length > 0 ? true : false)
                                }
                            })}
                        />
                    </div>
                    <div className={`new-sign-up-input-block-wrapper second  ${isPassword ? 'filled' : ''}`}>
                        <span className='new-sign-up-label'>{t("password")}</span>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={`new-sign-up-in-input`}
                            {...register("password", {
                                onChange: (e) => {
                                    setError(false)
                                    handlePasswordChange(e.target.value)
                                }
                            })}
                        />
                        {showPassword ? (
                            <AiOutlineEye onClick={() => setShowPassword(false)} fill='#7D8898' size={23} className='please-signup-password-eye-icon' />
                        ) : (
                            <AiOutlineEyeInvisible onClick={() => setShowPassword(true)} fill='#7D8898' size={23} className='password-eye-icon' />
                        )}
                    </div>
                    <div className='new-sign-up-checks-wrapper'>
                        <div className='new-sign-up-check-item'>
                            {atLeastOneLetter ? (
                                <img src={signUpCircleCheckedSvg} alt="" />
                            ) : (
                                <img src={signUpCircleSvg} alt="" />
                            )}
                            <span className='new-sign-up-check-text'>{t("atLeastOneUppercaseLetter")}</span>
                        </div>
                        <div className='new-sign-up-check-item'>
                            {numberOrSymbol ? (
                                <img src={signUpCircleCheckedSvg} alt="" />
                            ) : (
                                <img src={signUpCircleSvg} alt="" />
                            )}
                            <span className='new-sign-up-check-text'>{t("aNumberOrSymbol")}</span>
                        </div>
                        <div className='new-sign-up-check-item'>
                            {eightCharacters ? (
                                <img src={signUpCircleCheckedSvg} alt="" />
                            ) : (
                                <img src={signUpCircleSvg} alt="" />
                            )}
                            <span className='new-sign-up-check-text'>{t("atLeast8Characters")}</span>
                        </div>
                    </div>
                    {error && <p className='error-text mt-2'>
                        {errorMessage ? `${t("anErrorOccurred")} ${t(FIREBASE_ERRORS_TRANSLATIONS[errorMessage])}` : t("invalidCredentialsProvidedTryAgain")}
                    </p>}
                    <button disabled={isClicked || !atLeastOneLetter || !numberOrSymbol || !eightCharacters || !isEmail ? true : false} ref={registerRef} className='new-sign-up-in-page-btn mt-6'>
                        {t("createAccount")}
                    </button>
                    <div className='please-signup-or-line desktop-hidden'>
                        <span className='please-signup-line'></span>
                        <span className='please-signup-or-text'>{t("or")}</span>
                    </div>
                    <button type="button" onClick={convertCodeAndSignIn} className='new-sign-up-in-page-google-btn flex justify-center desktop-hidden'>
                        <img src={googleHeaderSvg} alt="" />
                        {t("continueWithGoogle")}
                    </button>
                    <span className='already-have-an-account-text'>
                        {t("alreadyHaveAnAccount")} <span onClick={handleNavigateToSignIn} className='cursor-pointer' style={{ color: '#9747FF' }}>{t("alreadyHaveAnAccountLogIn")}</span>
                    </span>
                    <img onClick={() => navigate('/')} className='new-auth-page-close-mobile' src={newAuthMobileCloseSvg} alt="" />
                </form>
                <div></div>
            </div>
            <div className='sign-up-modal-right-side-wrapper'>
                <div style={{ background: `url(${signUpFrameBackgroundSvg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className='sign-up-modal-right-side-background-image'>
                    <div className='please-signup-girl-image'>
                        <img src={signUpFrame} alt="" />
                    </div>
                </div>
            </div>
            <div style={{ position: 'absolute', top: '-3110px' }} className='p-2 mt-6 w-fit' ref={canvasCreationRef}>
                <div style={{ ...canvasStyles, backgroundColor: backgroundColor, color: scanColor, fontFamily: scanFont }} className='qr-code-result-wrapper'>
                    <QRCodeSVG
                        value={isDynamic ? `${window.location.origin}/code/${publicId}` : staticValue}
                        bgColor={backgroundColor}
                        fgColor={foregroundColor}
                        level='L'
                        size={256}
                        imageSettings={imageSettings}
                    />
                    {scanText}
                </div>
            </div>
        </div>
    )
}

export default SignUp