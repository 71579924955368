import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useGetUserNotificationsQuery, useUpdateNotificationMutation } from '../../api/api'

import NotificationItem from './NotificationItem'

import NotificationsPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import NotificationsEmpty from '../../assets/icons/notifications/no-notifications.svg'
import NotificationsThreeDots from '../../assets/icons/notifications/three-dots.svg'
import NotificationsPanelBack from '../../assets/icons/overview-panel-close.svg'
import NotificationsReadEye from '../../assets/icons/notifications/read-eye.svg'

const NotificationsPanel = ({ blockRef, setOpenNotificationsPanel }) => {
    const [showAll, setShowAll] = useState(true)

    const { t } = useTranslation()

    const { data: notifications } = useGetUserNotificationsQuery(showAll)
    const [readNotifications] = useUpdateNotificationMutation()

    const unreadNotificationsLength = notifications?.filter((el) => !el.read).length || 0

    const handleMarkAllAsRead = () => {
        readNotifications({ id: null, read_all: true })
    }

    return (
        <div ref={blockRef} className='notifications-panel-wrapper'>
            <div className='notifications-panel-header'>
                <div className='notification-panel-header-top-block'>
                    <div onClick={() => setOpenNotificationsPanel(false)} className='notifications-panel-header-mobile-back'>
                        <img src={NotificationsPanelMobileBack} alt="" />
                    </div>
                    <div className='notification-panel-header-title-block'>
                        <h5 className='notification-panel-header-title'>{t("notifications")}</h5>
                        {notifications && notifications.length > 0 && unreadNotificationsLength > 0 && (
                            <div className='notification-panel-header-count'>{unreadNotificationsLength}</div>
                        )}
                    </div>
                    <div className='notification-panel-header-mark-as-read-block'>
                        <button
                            disabled={notifications?.some((el) => !el.read) ? false : true}
                            onClick={handleMarkAllAsRead}
                            className='notification-panel-header-mark-as-read-btn'
                        >
                            {t("markAllAsRead")}
                        </button>
                        <div onClick={() => setOpenNotificationsPanel(false)} className='notification-panel-header-back-button'>
                            <img src={NotificationsPanelBack} alt="" />
                        </div>
                    </div>
                    <Dropdown style={{ position: 'unset', transform: 'unset' }} className='notification-panel-item-dropdown desktop-hide'>
                        <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                            <div className='notifications-panel-item-more-options-block flex'>
                                <img src={NotificationsThreeDots} alt="" />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleMarkAllAsRead} className='bootstrap-default-item'>
                                {t("markAllAsRead")}
                                <img src={NotificationsReadEye} alt="" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='notification-panel-header-bottom-block'>
                    <div onClick={() => setShowAll(true)} className={`notification-panel-type-switch-item ${showAll ? 'active' : ''}`}>{t("all")}</div>
                    <div onClick={() => setShowAll(false)} className={`notification-panel-type-switch-item ${!showAll ? 'active' : ''}`}>{t("unread")}</div>
                </div>
            </div>
            <div className={`notifications-panel-content ${!notifications || notifications.length === 0 ? 'empty' : ''}`}>
                {(notifications && notifications.length > 0) ? (
                    <div className='notifications-panel-list-items'>
                        {notifications.map((notification) => (
                            <NotificationItem
                                key={notification.id}
                                notification={notification}
                                setOpenNotificationsPanel={setOpenNotificationsPanel}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='notifications-panel-empty'>
                        <img src={NotificationsEmpty} alt="" />
                        <span className='notifications-panel-empty-title'>{showAll ? t("noNotifications") : t("noUnreadNotifications")}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default NotificationsPanel