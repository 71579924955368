import staticUrlSvg from '../assets/icons/static-url.svg'
import staticUrlBlackSvg from '../assets/icons/static-url-black.svg'
import staticWifiSvg from '../assets/icons/staticwifi.svg'
import staticWifiBlackSvg from '../assets/icons/static-wifi-black.svg'
import staticTextSvg from '../assets/icons/static-text.svg'
import staticTextBlackSvg from '../assets/icons/static-text-black.svg'
import dynamicUrlSvg from '../assets/icons/dynamic-url.svg'
import dynamicUrlBlackSvg from '../assets/icons/dynamic-url-black.svg'
import dynamicPdfSvg from '../assets/icons/dynamic-pdf.svg'
import dynamicPdfBlackSvg from '../assets/icons/dynamic-pdf-black.svg'
import dynamicVidoeSvg from '../assets/icons/dynamic-video.svg'
import dynamicVideoBlackSvg from '../assets/icons/dynamic-video-black.svg'
import dynamicMp3Svg from '../assets/icons/dynamic-mp3.svg'
import dynamicMp3BlackSvg from '../assets/icons/dynamic-mp3-black.svg'
import dynamicImageSvg from '../assets/icons/dynamic-image.svg'
import dynamicImageBlackSvg from '../assets/icons/dynamic-image-black.svg'
import dynamicTextSvg from '../assets/icons/dynamic-text.svg'
import dynamicTextBlackSvg from '../assets/icons/dynamic-text-black.svg'
import dynamicWifiSvg from '../assets/icons/dynamic-wifi.svg'
import dynamicWifiBlackSvg from '../assets/icons/dynamic-wifi-black.svg'

const useReturnCodeSvg = () => {
    const staticIcons = {
        'website_url': staticUrlSvg,
        'wifi': staticWifiSvg,
        'text': staticTextSvg,
    }

    const dynamicIcons = {
        'website_url': dynamicUrlSvg,
        'pdf': dynamicPdfSvg,
        'video': dynamicVidoeSvg,
        'mp3': dynamicMp3Svg,
        'images': dynamicImageSvg,
        'text': dynamicTextSvg,
        'wifi': dynamicWifiSvg,
    }

    const staticBlackIcons = {
        'website_url': staticUrlBlackSvg,
        'wifi': staticWifiBlackSvg,
        'text': staticTextBlackSvg,
    }

    const dynamicBlockIcons = {
        'website_url': dynamicUrlBlackSvg,
        'pdf': dynamicPdfBlackSvg,
        'video': dynamicVideoBlackSvg,
        'mp3': dynamicMp3BlackSvg,
        'images': dynamicImageBlackSvg,
        'text': dynamicTextBlackSvg,
        'wifi': dynamicWifiBlackSvg,
    }

    return {
        staticIcons,
        dynamicIcons,
        staticBlackIcons,
        dynamicBlockIcons,
    }
}

export default useReturnCodeSvg