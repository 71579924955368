import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlay } from 'react-icons/fa'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import EmptyImage from '../../assets/icons/code-create/empty-image.svg'
import EmptyLogo from '../../assets/icons/code-create/empty-logo.svg'

const Mp3Preview = ({ data, isContentAdded = true, version = 1 }) => {
    const [isPlaying, setIsPlaying] = useState(false)

    const { t } = useTranslation()

    const audioRef = useRef(null)

    const decreaseElementsSize = window.location.pathname.includes('/code') ? false : true

    const handlePlaySong = () => {
        if (!audioRef?.current) return;

        if (isPlaying) {
            audioRef.current.pause()
            setIsPlaying(false)
        } else {
            setIsPlaying(true)
            audioRef.current.play()
        }
    }

    const onClickRedirect = (link) => {
        const editedLink = link.includes('https://') || link.includes('http://') ? link : `https://${link}`
        const linkWithoutSpaces = editedLink.replaceAll(' ', '')

        window.open(linkWithoutSpaces, '_blank')
    }

    return (
        <>
            {isContentAdded ? (
                <>
                    {version === 1 ? (
                        <div className='mp3-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
                            <div className='mp3-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                                <h5 className='mp3-preview-title' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.title ? data.title : t("audioTitle")}
                                </h5>
                                <span className='mp3-preview-description' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.description ? data.description : t("description")}
                                </span>
                                <button
                                    onClick={() => onClickRedirect(data.redirect)}
                                    className='mp3-preview-button'
                                    style={{ backgroundColor: data.designButtonColor }}
                                >
                                    {data.button ? data.button : 'CTA'}
                                </button>
                            </div>
                            <div className='mp3-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                                <div className='mp3-image-block'>
                                    {data.logo && (
                                        <img className='mp3-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt="" />
                                    )}
                                </div>
                                <audio controlsList={data.allowDownload ? "" : "nodownload"} className='mp3-audio' src={typeof (data.file) === 'string' ? data.file : URL.createObjectURL(data.file)} controls></audio>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ backgroundColor: `${data.designPrimaryColor}` }}
                            className={`flex flex-col w-full items-center ${decreaseElementsSize ? 'absolute top-[0px] left-0 pt-[20px] min-h-full' : 'min-h-[100dvh]'}`}
                        >
                            {(data.companyLogo || decreaseElementsSize) && (
                                <div
                                    className={`flex items-center justify-center px-2 border-solid border-b-[1px] border-[#E2E8F0] gap-2 w-full ${decreaseElementsSize ? 'h-[38px]' : 'h-[44px]'}`}
                                >
                                    <img
                                        className={`${decreaseElementsSize ? 'h-[20px]' : 'h-[28px]'}`}
                                        src={
                                            data.companyLogo ?
                                                (typeof (data.companyLogo) === 'string'
                                                    ? data.companyLogo
                                                    : URL.createObjectURL(data.companyLogo))
                                                : EmptyLogo
                                        }
                                        alt=""
                                    />
                                </div>
                            )}
                            <div className={`max-w-[600px] flex flex-col items-center w-full ${decreaseElementsSize ? 'p-[8px] pt-[20px] gap-[8px]' : 'p-[16px] pt-[40px] gap-[16px]'}`}>
                                <h6
                                    style={{ color: `${data.title ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`text-center tracking-[-0.04em] break-words ${decreaseElementsSize ? 'text-[20px] leading-[28px] font-semibold' : 'text-[34px] leading-[40px] font-bold'}`}
                                >
                                    {data.title ? data.title : t("audioTitle")}
                                </h6>
                                <p
                                    style={{ color: `${data.description ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`break-words text-center ${decreaseElementsSize ? 'text-[14px] leading-[17px]' : 'text-[20px] leading-[24px]'}`}
                                >
                                    {data.description ? data.description : t("description")}
                                </p>
                                <button
                                    onClick={() => onClickRedirect(data.redirect)}
                                    style={{
                                        backgroundColor: data.designButtonColor,
                                        color: data.designSecondaryColor
                                    }}
                                    className={`flex items-center justify-center gap-[6px] w-full rounded-lg font-semibold ${decreaseElementsSize ? 'text-[12px] h-[26px] mt-[4px] mb-[20px]' : 'text-[16px] h-[40px] mt-[8px] mb-[40px]'}`}
                                >
                                    {data.button ? data.button : t("button")}
                                </button>
                                <div className='w-full flex flex-col gap-[16px] rounded-lg overflow-hidden relative'>
                                    <img
                                        className='w-full'
                                        src={
                                            data.logo
                                                ?
                                                typeof (data.logo) === "string"
                                                    ? data.logo
                                                    : URL.createObjectURL(data.logo)
                                                : EmptyImage
                                        }
                                        alt=""
                                    />
                                    <button onClick={handlePlaySong} className={`absolute bottom-[8px] right-[8px] flex items-center justify-center gap-[4px] bg-[#FFFFFF] ${decreaseElementsSize ? 'h-[26px] rounded-[4px] px-[4px]' : 'h-[36px] rounded-lg px-[8px]'}`}>
                                        <FaPlay size={decreaseElementsSize ? 14 : 18} color="#141315" />
                                        <span className={`text-[#141315] ${decreaseElementsSize ? 'text-[12px] leading-[15px] font-medium' : 'text-[14px] leading-[17px] font-semibold'}`}>
                                            {isPlaying ? t("stopAudio") : t("playAudio")}
                                        </span>
                                    </button>
                                    <audio
                                        ref={audioRef}
                                        controlsList={data.allowDownload ? "" : "nodownload"}
                                        className='hidden'
                                        src={typeof (data.file) === 'string' ? data.file : URL.createObjectURL(data.file)}
                                        controls
                                    ></audio>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["MP3"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["MP3"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default Mp3Preview