import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'flowbite-react'
import React from 'react'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'

import paymentErrorSvg from '../../assets/icons/payment-error.svg'

const TrialEndModal = ({ show, onClose }) => {
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const handleNavigateToUpgrade = () => {
        navigate('/upgrade-plan')
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            position='center'
            onClose={onClose}
            className='modal-please-signup'
        >
            <Modal.Body className='trial-end-modal-body padding-top-24'>
                <img className='trial-end-image' src={paymentErrorSvg} alt="" />
                <h2 className='trial-end-heading'>{subscription?.new_flow ? t("youHaveReachedYourFreeQRCodeScansLimit") : t("your7dayFreeTrialExpired")}</h2>
                <p className='trial-end-text'>{t("upgradeYourAccountToReactivateYourQRCodes")}</p>
                <button onClick={handleNavigateToUpgrade} className='trial-end-view-plans-btn'>{t("viewUpgradePlans")}</button>
                <button onClick={onClose} className='trial-end-cancel-btn'>{t("cancel")}</button>
            </Modal.Body>
        </Modal>
    )
}

export default TrialEndModal