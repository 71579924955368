import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateUserSettingsMutation } from '../../api/api'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'

const QrCodeSettings = ({ isMobile, setActiveTab, settings }) => {
    const [updateSettings] = useUpdateUserSettingsMutation()

    const { t } = useTranslation()

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    const handleChangeCodesSettings = (type) => {
        if (settings?.dynamic_qr_codes && settings?.static_qr_codes) {
            if (type === 'dynamic') {
                updateSettings({ data: { ...settings, dynamic_qr_codes: !settings?.dynamic_qr_codes, static_qr_codes: settings?.static_qr_codes } })
            } else {
                updateSettings({ data: { ...settings, dynamic_qr_codes: settings?.dynamic_qr_codes, static_qr_codes: !settings?.static_qr_codes } })
            }
        } else if (type === 'dynamic' && !settings?.dynamic_qr_codes) {
            updateSettings({ data: { ...settings, dynamic_qr_codes: !settings?.dynamic_qr_codes, static_qr_codes: settings?.static_qr_codes } })
        } else if (type === 'static' && !settings?.static_qr_codes) {
            updateSettings({ data: { ...settings, dynamic_qr_codes: settings?.dynamic_qr_codes, static_qr_codes: !settings?.static_qr_codes } })
        } else {
            updateSettings({ data: { ...settings, dynamic_qr_codes: !settings?.dynamic_qr_codes, static_qr_codes: !settings?.static_qr_codes } })
        }
    }

    return (
        <div className='settings-tab-wrapper'>
            {isMobile ? (
                <div className='settings-mobile-tab-header'>
                    <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                        {isMobile && (
                            <img width={20} src={overviewPanelMobileBack} alt="" />
                        )}
                        {t("qrCodeSettings")}
                        {isMobile && (
                            <div style={{ width: '20px' }} />
                        )}
                    </h5>
                    <span className='settings-tab-description'>
                        {t("setUpYourPreferredQRCodeSettings")}
                    </span>
                </div>
            ) : (
                <>
                    <h5 className='settings-tab-title'>
                        {t("qrCodeSettings")}
                    </h5>
                    <span className='settings-tab-description'>
                        {t("setUpYourPreferredQRCodeSettings")}
                    </span>
                </>
            )}
            <h6 className='setting-tab-sub-title'>{t("qrCodes")}</h6>
            <div className='notification-settings-switch-group-block'>
                <div className='notification-settings-switch-group-block-item'>
                    <div className='notification-settings-left-side'>
                        <h5 className='notification-settings-title'>{t("dynamicQRCodesSettings")}</h5>
                        <span className='notification-settings-description'>
                            {t("showOptionsToCreateDynamicQRCodes")}
                        </span>
                    </div>
                    <div className='notification-settings-right-side'>
                        <div
                            onClick={() => handleChangeCodesSettings('dynamic')}
                            className={`overview-panel-stats-header-unique-scans-switch ${settings?.dynamic_qr_codes ? 'active' : ''}`}
                        >
                            <div className={`overview-panel-switch-circle ${settings?.dynamic_qr_codes ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
                <div className='notification-settings-switch-group-block-item'>
                    <div className='notification-settings-left-side'>
                        <h5 className='notification-settings-title'>{t("staticQRCodes")}</h5>
                        <span className='notification-settings-description'>
                            {t("showOptionsToCreateStaticQRCodes")}
                        </span>
                    </div>
                    <div className='notification-settings-right-side'>
                        <div
                            onClick={() => handleChangeCodesSettings('static')}
                            className={`overview-panel-stats-header-unique-scans-switch ${settings?.static_qr_codes ? 'active' : ''}`}
                        >
                            <div className={`overview-panel-switch-circle ${settings?.static_qr_codes ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QrCodeSettings