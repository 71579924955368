import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useConvertToDynamic from '../../hooks/useConvertToDynamic'
import { useGetQRCodeByIdDirectMutation } from '../../api/api'
import useReturnCodeSvg from '../../hooks/useReturnCodeSvg'
import { useViewport } from '../../hooks/useViewport'

import analyticsArrowPurple from '../../assets/icons/analytics-arrow-purple.svg'
import dashboardSortCheck from '../../assets/icons/dashboard-sort-check.svg'
import analyticsAllCodes from '../../assets/icons/analytics-all-codes.svg'
import analyticsSearch from '../../assets/icons/analytics-search.svg'
import analyticsArrow from '../../assets/icons/analytics-arrow.svg'

const AnalyticsSelectCode = ({
    refElement,
    abTesting,
    selectedCode,
    openCodesDropdown,
    setOpenCodesDropdown,
    codesSearch,
    setCodesSearch,
    handleSelectCode,
    filterData,
    otherSelectedCode,
    dotColor,
    codeLetter,
}) => {
    const [expandWhereMyData, setExpandWhereMyData] = useState(false)
    const [nameWidth, setNameWidth] = useState(100)

    const mobileSelectCodeRef = useRef(null)
    const selectCodeBlockRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()
    const { staticIcons, dynamicIcons } = useReturnCodeSvg()
    const { isConverting, setCodeToConvert, convertCodeJsx, setIsConverting, codeToConvert } = useConvertToDynamic()

    const [getCode] = useGetQRCodeByIdDirectMutation()

    const handleConvertToDynamic = async (code) => {
        setIsConverting(true)

        const response = await getCode(code?.id)

        setCodeToConvert(response.data)
    }

    useEffect(() => {
        setNameWidth(selectCodeBlockRef?.current?.offsetWidth)
    }, [selectedCode])

    useEffect(() => {
        const handleClick = (e) => {
            if (mobileSelectCodeRef.current && !mobileSelectCodeRef.current.contains(e.target)) {
                setOpenCodesDropdown(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [setOpenCodesDropdown])

    return (
        <div ref={refElement} className='analytics-content-codes-select'>
            <button
                ref={selectCodeBlockRef}
                onClick={() => setOpenCodesDropdown(!openCodesDropdown)}
                className={`analytics-content-codes-select-btn ${openCodesDropdown ? 'active' : ''} ${nameWidth >= 200 ? 'oversized' : ''}`}
            >
                {abTesting && (
                    <span className={`analytics-content-select-code-${dotColor}-dot`}></span>
                )}
                <span className='analytics-selected-code-name-text'>
                    {!selectedCode && !abTesting ? t("allCodes") : !selectedCode && abTesting ? `${t("selectCode")} ${codeLetter}` : selectedCode?.name}
                </span>
                <img className='analytics-content-codes-select-arrow' src={analyticsArrow} alt="" />
            </button>
            {openCodesDropdown && !isMobile && (
                <div className='analytics-select-codes-menu'>
                    <div className='analytics-select-codes-list-block'>
                        <div className='analytics-select-codes-search-block'>
                            <img src={analyticsSearch} alt="" />
                            <input
                                onChange={(e) => setCodesSearch(e.target.value)}
                                value={codesSearch}
                                className='analytics-select-codes-search-input'
                                placeholder={t("search")}
                            />
                        </div>
                        <div className='analytics-select-codes-list-block-items'>
                            {!abTesting && (
                                <div onClick={() => handleSelectCode(null)} className='analytics-select-codes-list-item-block dynamic'>
                                    <img src={analyticsAllCodes} alt="" />
                                    <div className='analytics-select-codes-list-item-info'>
                                        <span className='analytics-select-codes-list-item-name'>{t("allCodes")}</span>
                                        <span className='analytics-select-codes-list-item-type'>{t("dynamicAndStatic")}</span>
                                    </div>
                                </div>
                            )}
                            {filterData?.qr_codes?.map((code) => {
                                if (code?.name.toLowerCase().includes(codesSearch.toLowerCase())) {
                                    if (code?.type === 'dynamic') {
                                        return (
                                            <div key={code?.id} onClick={() => handleSelectCode(code)} className={`analytics-select-codes-list-item-block dynamic ${otherSelectedCode?.id === code?.id ? 'disabled-code' : ''}`}>
                                                <div className='global-search-dynamic-image-gradient-wrapper'>
                                                    <div className='global-search-dynamic-image-wrapper'>
                                                        <img className='global-search-qr-code-icon' src={dynamicIcons[code?.kind]} alt="" />
                                                    </div>
                                                </div>
                                                <div className='analytics-select-codes-list-item-info'>
                                                    <span className='analytics-select-codes-list-item-name'>{code?.name}</span>
                                                    <span className='analytics-select-codes-list-item-type'>{t("dynamic")}</span>
                                                </div>
                                                {selectedCode?.id === code?.id && (
                                                    <img className='analytics-select-code-check-icon' src={dashboardSortCheck} alt="" />
                                                )}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={code?.id} className='analytics-select-codes-list-item-static-wrapper'>
                                                <div className='analytics-select-codes-list-item-block'>
                                                    <div className='global-search-static-image-wrapper'>
                                                        <img className='global-search-qr-code-icon' src={staticIcons['text']} alt="" />
                                                    </div>
                                                    <div className='analytics-select-codes-list-item-info'>
                                                        <span className='analytics-select-codes-list-item-name'>{code?.name}</span>
                                                        <span className='analytics-select-codes-list-item-type'>{t("static")}</span>
                                                    </div>
                                                </div>
                                                <button disabled={isConverting} onClick={() => handleConvertToDynamic(code)} className='analytics-select-codes-convert-to-dynamic relative'>
                                                    {isConverting && codeToConvert?.id === code?.id && (
                                                        <svg
                                                            style={{ position: 'absolute', left: 'calc(50% - 110px)', top: '5px' }}
                                                            className='stripe-loader'
                                                            width={25}
                                                            height={25}
                                                            fill="#9747FF"
                                                            viewBox="25 25 50 50"
                                                        >
                                                            <circle r="20" cy="50" cx="50"></circle>
                                                        </svg>
                                                    )}
                                                    {t("convertToDynamic")}
                                                </button>
                                            </div>
                                        )
                                    }
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </div>
                    <div className='analytics-select-codes-menu-where-my-data-block'>
                        <span onClick={() => setExpandWhereMyData(!expandWhereMyData)} className={`select-codes-menu-where-my-data-title ${expandWhereMyData ? 'active' : ''}`}>
                            {t("whereIsMyData")}
                            <img src={analyticsArrowPurple} alt="" />
                        </span>
                        {expandWhereMyData && (
                            <span className='select-codes-menu-where-my-data-text'>
                                {t("newlyConvertedStaticCodesWillNotHaveDataImmediately")}
                            </span>
                        )}
                    </div>
                </div>
            )}
            {openCodesDropdown && isMobile && (
                <div style={{ zIndex: '101' }} className='link-filter-background'>
                    <div ref={mobileSelectCodeRef} className='link-filter-block pb-0'>
                        <div onClick={() => setOpenCodesDropdown(false)} className='mobile-tray-horizontal-bar'>
                            <div className='mobile-tray-horizontal-bar-element'></div>
                        </div>
                        <div className='analytics-select-codes-list-block p-0 pt-2 pb-4'>
                            <div className='analytics-select-codes-search-block'>
                                <img src={analyticsSearch} alt="" />
                                <input
                                    onChange={(e) => setCodesSearch(e.target.value)}
                                    value={codesSearch}
                                    className='analytics-select-codes-search-input'
                                    placeholder={t("search")}
                                />
                            </div>
                            <div className='analytics-select-codes-list-block-items'>
                                <div onClick={() => handleSelectCode(null)} className='analytics-select-codes-list-item-block dynamic'>
                                    <img src={analyticsAllCodes} alt="" />
                                    <div className='analytics-select-codes-list-item-info'>
                                        <span className='analytics-select-codes-list-item-name'>{t("allCodes")}</span>
                                        <span className='analytics-select-codes-list-item-type'>{t("dynamicAndStatic")}</span>
                                    </div>
                                </div>
                                {filterData?.qr_codes?.map((code) => {
                                    if (code?.name.toLowerCase().includes(codesSearch.toLowerCase())) {
                                        if (code?.type === 'dynamic') {
                                            return (
                                                <div key={code?.id} onClick={() => handleSelectCode(code)} className={`analytics-select-codes-list-item-block dynamic ${otherSelectedCode?.id === code?.id ? 'disabled-code' : ''}`}>
                                                    <div className='global-search-dynamic-image-gradient-wrapper'>
                                                        <div className='global-search-dynamic-image-wrapper'>
                                                            <img className='global-search-qr-code-icon' src={dynamicIcons[code?.kind]} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='analytics-select-codes-list-item-info'>
                                                        <span className='analytics-select-codes-list-item-name'>{code?.name}</span>
                                                        <span className='analytics-select-codes-list-item-type'>{t("dynamic")}</span>
                                                    </div>
                                                    {selectedCode?.id === code?.id && (
                                                        <img className='analytics-select-code-check-icon' src={dashboardSortCheck} alt="" />
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={code?.id} className='analytics-select-codes-list-item-static-wrapper'>
                                                    <div className='analytics-select-codes-list-item-block'>
                                                        <div className='global-search-static-image-wrapper'>
                                                            <img className='global-search-qr-code-icon' src={staticIcons['text']} alt="" />
                                                        </div>
                                                        <div className='analytics-select-codes-list-item-info'>
                                                            <span className='analytics-select-codes-list-item-name'>{code?.name}</span>
                                                            <span className='analytics-select-codes-list-item-type'>{t("static")}</span>
                                                        </div>
                                                    </div>
                                                    <button disabled={isConverting} onClick={() => handleConvertToDynamic(code)} className='analytics-select-codes-convert-to-dynamic relative'>
                                                        {isConverting && codeToConvert?.id === code?.id && (
                                                            <svg
                                                                style={{ position: 'absolute', left: 'calc(50% - 110px)', top: '5px' }}
                                                                className='stripe-loader'
                                                                width={25}
                                                                height={25}
                                                                fill="#9747FF"
                                                                viewBox="25 25 50 50"
                                                            >
                                                                <circle r="20" cy="50" cx="50"></circle>
                                                            </svg>
                                                        )}
                                                        {t("convertToDynamic")}
                                                    </button>
                                                </div>
                                            )
                                        }
                                    } else {
                                        return null
                                    }
                                })}
                            </div>
                        </div>
                        <div className='analytics-select-codes-menu-where-my-data-block'>
                            <span onClick={() => setExpandWhereMyData(!expandWhereMyData)} className={`select-codes-menu-where-my-data-title ${expandWhereMyData ? 'active' : ''}`}>
                                {t("whereIsMyData")}
                                <img src={analyticsArrowPurple} alt="" />
                            </span>
                            {expandWhereMyData && (
                                <span className='select-codes-menu-where-my-data-text'>
                                    {t("newlyConvertedStaticCodesWillNotHaveDataImmediately")}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {convertCodeJsx}
        </div>
    )
}

export default AnalyticsSelectCode