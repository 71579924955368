import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { SlClose } from 'react-icons/sl'

import { api, useChangeUserPlanMutation, useCheckIfUserUsedTrialQuery, useCustomLoggerMutation } from '../../api/api'
import { PRICE_SIGN_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import useAccountNewPlans from '../../hooks/useAccountNewPlans'
import { useAuthContext } from '../../context/AuthContext'
import { useViewport } from '../../hooks/useViewport'
import i18n from '../../i18n/config'

import DashboardNavigation from '../../components/DashboardNavigation/DashboardNavigation'
import PlanCardNew from '../../components/PlanCardNew/PlanCardNew'

import CheckoutStepActive from '../../assets/icons/checkout-step-active.svg'
import CheckoutStepDone from '../../assets/icons/checkout-step-done.svg'
import CheckoutStepNext from '../../assets/icons/checkout-step-next.svg'

const UpgradePlanNew = () => {
    const [mobilePlanSelected, setMobilePlanSelected] = useState('Full')
    const [selectedPlanOptions, setSelectedPlanOptions] = useState({})
    const [searchParams, setSearchParams] = useSearchParams()
    const subscription = useSelector((state) => state.app.subscription)
    const user = useSelector((state) => state.app.user)

    const { t } = useTranslation()
    const { isMobile } = useViewport()
    const { accountNewPlans } = useAccountNewPlans()
    const { setExpandNavigation } = useAuthContext()

    const navigate = useCustomNavigate()
    const dispatch = useDispatch()

    const { data } = useCheckIfUserUsedTrialQuery()
    const [changePlan] = useChangeUserPlanMutation()
    const [customLogger] = useCustomLoggerMutation()

    const handleChoosePlanMobile = () => {
        if (!user) {
            navigate('/sign-in')
            return;
        }

        if (subscription?.plan === 'Free') {
            navigate(`/upgrade-plan/checkout/${selectedPlanOptions.price_id}/${selectedPlanOptions.shortName}?isNewUser=${searchParams.get('icon') === 'close'}`)

            if (searchParams.get('icon') === 'close') {
                window.gtag('event', 'click_choose_plan_button', {
                    type: 'new_user',
                })
            } else {
                window.gtag('event', 'click_choose_plan_button', {
                    type: 'existing_user',
                })
            }

            customLogger({
                action: 'click',
                description: `Upgrade Plan Select Plan ${selectedPlanOptions.name}`
            })
        } else {
            const splittedPrice = selectedPlanOptions.price_id.split(',')

            if (subscription?.plan !== selectedPlanOptions.name) {
                changePlan({
                    price_id_stripe: splittedPrice[0],
                    price_id_paypal: selectedPlanOptions.paypal_plan_id,
                    plan_name: selectedPlanOptions.name,
                })
            }
        }
    }

    useEffect(() => {
        setExpandNavigation(false)
    }, [setExpandNavigation])

    useEffect(() => {
        customLogger({
            action: 'pageView',
            description: 'Upgrade Plan Page View'
        })
    }, [customLogger])

    useEffect(() => {
        const paymentTime = localStorage.getItem('paymentTime')

        if (paymentTime && new Date() - new Date(paymentTime) < 1000000) {
            dispatch(api.util.invalidateTags(['UserSubscription']))
            navigate('/dashboard')
        } else if (paymentTime && new Date() - new Date(paymentTime) > 1000000) {
            localStorage.removeItem('paymentTime')
        }
    }, [navigate, dispatch])

    useEffect(() => {
        if (searchParams.get('icon') === 'close') {
            window.gtag('event', 'land_on_upgrade_plan_page', {
                type: 'new_user',
            })
        } else {
            window.gtag('event', 'land_on_upgrade_plan_page', {
                type: 'existing_user',
            })
        }
    }, [searchParams])

    useEffect(() => {
        if (data) {
            localStorage.setItem('ip_address', data?.ip_address)

            if (data?.trial) {
                setMobilePlanSelected('Unlimited')
            }
        }
    }, [data])

    useEffect(() => {
        const planName = searchParams.get('planName')

        if (planName) {
            setMobilePlanSelected(planName)
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        const plan = accountNewPlans.find((plan) => plan.shortName === mobilePlanSelected)

        if (plan) {
            setSelectedPlanOptions(plan)
        }
    }, [mobilePlanSelected, accountNewPlans])

    return (
        <div style={{ paddingTop: '0px', paddingLeft: searchParams.get('icon') === 'close' && !isMobile ? '8px' : '0px', paddingBottom: '0px' }} className='purple-corners-block-wrapper'>
            {searchParams.get('icon') !== 'close' && (
                <DashboardNavigation setOverviewCode={() => { }} />
            )}
            <div
                style={{
                    top: `${user ? '76px' : 'unset'}`,
                    borderRadius: `${user ? '16px' : '0px'}`,
                }}
                className={`upgrade-plan-wrapper upgrade-plan-purple-corners mobile:px-[4px] px-[24px] ${isMobile ? 'upgrade-plan-new-small-padding' : ''}`}
            >
                {isMobile ? (
                    <div className='upgrade-plan-new-mobile-block'>
                        <div className='upgrade-plan-new-plans-list'>
                            {accountNewPlans && accountNewPlans.map((plan, index) => {
                                if (!data?.trial && (!plan.isAfterTrial || plan.isAfterTrial === 'both')) {
                                    if (plan.shortName === mobilePlanSelected) {
                                        return (
                                            <div onClick={() => setMobilePlanSelected(plan.shortName)} key={index} className='upgrade-plan-new-mobile-item-active'>
                                                <div className='upgrade-plan-new-mobile-item-text-block'>
                                                    <div className='upgrade-plan-new-mobile-item-price-block'>
                                                        <span className='upgrade-plan-new-mobile-item-price'>
                                                            {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}
                                                            {plan.price[i18n.language] ?? plan.price['en']}{plan.price_per_period}
                                                        </span>
                                                        {plan.best && (
                                                            <span className='upgrade-plan-new-mobile-item-best'>{t("mostPopular")}</span>
                                                        )}
                                                        {plan.shortName === 'Yearly' && (
                                                            <span className='upgrade-plan-new-mobile-item-save'>{t("save50Percents")}</span>
                                                        )}
                                                    </div>
                                                    <span className='upgrade-plan-new-mobile-item-name'>{plan.name}</span>
                                                </div>
                                                <BsFillCheckCircleFill size={20} fill='#9747FF' />
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div onClick={() => setMobilePlanSelected(plan.shortName)} key={index} className='upgrade-plan-new-mobile-item'>
                                                <div className='upgrade-plan-new-mobile-item-text-block'>
                                                    <div className='upgrade-plan-new-mobile-item-price-block'>
                                                        <span className='upgrade-plan-new-mobile-item-price'>
                                                            {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}
                                                            {plan.price[i18n.language] ?? plan.price['en']}{plan.price_per_period}
                                                        </span>
                                                        {plan.best && (
                                                            <span className='upgrade-plan-new-mobile-item-best'>{t("mostPopular")}</span>
                                                        )}
                                                        {plan.shortName === 'Yearly' && (
                                                            <span className='upgrade-plan-new-mobile-item-save'>{t("save50Percents")}</span>
                                                        )}
                                                    </div>
                                                    <span className='upgrade-plan-new-mobile-item-name'>{plan.name}</span>
                                                </div>
                                                <div className='upgrade-plan-new-mobile-select-icon-inactive'></div>
                                            </div>
                                        )
                                    }
                                } else if (data?.trial && (plan.isAfterTrial || plan.isAfterTrial === 'both')) {
                                    if (plan.shortName === mobilePlanSelected) {
                                        return (
                                            <div onClick={() => setMobilePlanSelected(plan.shortName)} key={index} className='upgrade-plan-new-mobile-item-active'>
                                                <div className='upgrade-plan-new-mobile-item-text-block'>
                                                    <div className='upgrade-plan-new-mobile-item-price-block'>
                                                        <span className='upgrade-plan-new-mobile-item-price'>
                                                            {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}
                                                            {plan.price[i18n.language] ?? plan.price['en']}{plan.price_per_period}
                                                        </span>
                                                        {plan.best && (
                                                            <span className='upgrade-plan-new-mobile-item-best'>{t("mostPopular")}</span>
                                                        )}
                                                        {plan.shortName === 'Yearly' && (
                                                            <span className='upgrade-plan-new-mobile-item-save'>{t("save50Percents")}</span>
                                                        )}
                                                    </div>
                                                    <span className='upgrade-plan-new-mobile-item-name'>{plan.name}</span>
                                                </div>
                                                <BsFillCheckCircleFill size={20} fill='#9747FF' />
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div onClick={() => setMobilePlanSelected(plan.shortName)} key={index} className='upgrade-plan-new-mobile-item'>
                                                <div className='upgrade-plan-new-mobile-item-text-block'>
                                                    <div className='upgrade-plan-new-mobile-item-price-block'>
                                                        <span className='upgrade-plan-new-mobile-item-price'>
                                                            {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}
                                                            {plan.price[i18n.language] ?? plan.price['en']}{plan.price_per_period}
                                                        </span>
                                                        {plan.best && (
                                                            <span className='upgrade-plan-new-mobile-item-best'>{t("mostPopular")}</span>
                                                        )}
                                                        {plan.shortName === 'Yearly' && (
                                                            <span className='upgrade-plan-new-mobile-item-save'>{t("save50Percents")}</span>
                                                        )}
                                                    </div>
                                                    <span className='upgrade-plan-new-mobile-item-name'>{plan.name}</span>
                                                </div>
                                                <div className='upgrade-plan-new-mobile-select-icon-inactive'></div>
                                            </div>
                                        )
                                    }
                                } else {
                                    return null
                                }
                            })}
                        </div >
                        <ul style={{ padding: '16px' }} className="upgrade-plan-options-list w-full">
                            {selectedPlanOptions?.options?.map((option, index) => {
                                return (
                                    <li key={index} className="upgrade-plan-active-option">
                                        {
                                            option.available ? (
                                                <BsFillCheckCircleFill className='min-width-upgrade-option-icon' size={24} fill='#9747FF' />
                                            ) : (
                                                <SlClose className='min-width-upgrade-option-icon' size={24} fill='#7D8898' />
                                            )
                                        }
                                        <span className="upgrade-plan-active-option-text">
                                            {option.title}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                        <div style={{ padding: '0 16px', width: '100%' }}>
                            <span style={{ borderTop: '1px solid #E2E8F0', padding: '16px 0', height: 'unset' }} className='upgrade-plan-new-note m-0'>
                                {selectedPlanOptions.note}
                            </span>
                        </div>
                        <div className='upgrade-plan-new-mobile-choose-button-block'>
                            <button onClick={handleChoosePlanMobile} className='upgrade-plan-new-mobile-choose-button'>{t("choosePlan")}</button>
                        </div>
                    </div >
                ) : (
                    <>
                        <div className='upgrade-plan-new-plan-header'>
                            <h5 className='upgrade-plan-new-plan-header__title'>
                                {t("selectAPlanThatSuitsYourNeeds")}
                            </h5>
                            <div className='upgrade-plan-new-plan-header__steps'>
                                <div className='upgrade-plan-new-plan-header__step-item'>
                                    <img src={CheckoutStepDone} alt="" />
                                    <span className='upgrade-plan-new-plan-header__step-item-text'>
                                        {t("qrCodeReady")}
                                    </span>
                                </div>
                                <div className='upgrade-plan-new-plan-header__step-item-active'>
                                    <img src={CheckoutStepActive} alt="" />
                                    <span className='upgrade-plan-new-plan-header__step-item-text'>
                                        {t("selectAPlan")}
                                    </span>
                                </div>
                                <div className='upgrade-plan-new-plan-header__step-item'>
                                    <img src={CheckoutStepNext} alt="" />
                                    <span className='upgrade-plan-new-plan-header__step-item-next-text'>
                                        {t("completed")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='upgrade-plan-cards-list'>
                            {accountNewPlans && accountNewPlans.map((plan, index) => {
                                if (!data?.trial && (!plan.isAfterTrial || plan.isAfterTrial === 'both')) {
                                    return (
                                        <PlanCardNew key={index} plan={plan} isNew={true} newUser={searchParams.get('icon') === 'close' ? true : false} />
                                    )
                                } else if (data?.trial && (plan.isAfterTrial || plan.isAfterTrial === 'both')) {
                                    return (
                                        <PlanCardNew key={index} plan={plan} isNew={true} newUser={searchParams.get('icon') === 'close' ? true : false} />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </>
                )}
            </div>
            {searchParams.get('icon') !== 'close' && !isMobile && user && (
                <div className="upgrade-plan-navigation-line">
                    <div className='upgrade-plan-navigation-line-top'>
                        <div className='upgrade-plan-navigation-line-top-inside'></div>
                    </div>
                    <div className='upgrade-plan-navigation-line-bottom'>
                        <div className='upgrade-plan-navigation-line-bottom-inside'></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UpgradePlanNew