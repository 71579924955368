import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'
import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'
import { DateRangePicker } from 'react-date-range'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'

import {
    ANALYTICS_FILTER_BLOCK_TO_LABEL_TRANSLATIONS,
    LANGUAGE_DATE_RANGE_FORMAT
} from '../../helpers/translation-constants'
import { ANALYTICS_FILTER_BLOCK_TO_LABEL } from '../../helpers/constants'
import { useCustomLoggerMutation, useExportScansDataMutation } from '../../api/api'
import { useViewport } from '../../hooks/useViewport'
import useAnalyticsData from './useAnalyticsData'

import AnalyticsMobileExportData from './AnalyticsMobileExportData'
import AnalyticsMobileSettings from './AnalyticsMobileSettings'

import analyticsFiltersMobile from '../../assets/icons/analytics-filters-mobile.svg'
import analyticsFilterPurple from '../../assets/icons/analytics-filter-purple.svg'
import analyticsFilterClose from '../../assets/icons/analytics-filter-close.svg'
import analyticsFilterReset from '../../assets/icons/analytics-filter-reset.svg'
import analyticsFilterBack from '../../assets/icons/analytics-filter-back.svg'
import analyticsCalendar from '../../assets/icons/analytics-calendar.svg'
import analyticsDownload from '../../assets/icons/analytics/download.svg'
import analyticsFilter from '../../assets/icons/analytics-filter.svg'
import analyticsSearch from '../../assets/icons/analytics-search.svg'

const AnalyticsContentHeader = ({
    setAbTesting,
    abTesting,
    uniqueScans,
    setUniqueScans,
    setSelectedFilters,
    selectedFilters,
    setAppliedFilters,
    appliedFilters,
    setDateFilter,
    dateFilter,
    isAnyFilterApplied,
    filterData,
}) => {
    const [openedFilterBlock, setOpenedFilterBlock] = useState(null)
    const [showDateCalendar, setShowDateCalendar] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [filtersSearch, setFiltersSearch] = useState('')
    const [showMobileSettings, setShowMobileSettings] = useState(false)
    const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false)
    const [showMobileExportData, setShowMobileExportData] = useState(false)
    const [ranges, setRanges] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const calendarBlockRef = useRef(null)
    const filterBlockRef = useRef(null)

    const { t, i18n } = useTranslation()
    const { isMobile } = useViewport()
    const { customStaticRanges, ANALYTICS_FILTER_BLOCKS } = useAnalyticsData()

    const [exportScansData] = useExportScansDataMutation()
    const [customLogger] = useCustomLoggerMutation()

    const handleClearAllFilterOptions = () => {
        setSelectedFilters({
            os: null,
            browser: null,
            country: null,
            city: null,
            language: null
        })
    }

    const handleClearFilterSelectedOption = (selectedOption) => {
        setSelectedFilters({
            ...selectedFilters,
            [selectedOption]: null
        })
        setOpenedFilterBlock(null)
    }

    const handleApplyFilters = () => {
        setAppliedFilters(selectedFilters)
        setShowFilter(false)
        setOpenedFilterBlock(null)
    }

    const handleSwitchAbTesting = () => {
        setAbTesting(!abTesting)

        customLogger({
            action: 'click',
            description: `Statistics ${abTesting ? 'turn off' : 'turn on'} A/B Testing`
        })
    }

    const handleSwitchUniqueScans = () => {
        setUniqueScans(!uniqueScans)

        customLogger({
            action: 'click',
            description: `Statistics ${uniqueScans ? 'turn off' : 'turn on'} Unique Scans`
        })
    }

    const handleClearAppliedFilter = (optionName) => {
        setAppliedFilters({
            ...appliedFilters,
            [optionName]: null
        })
        setSelectedFilters({
            ...selectedFilters,
            [optionName]: null
        })
    }

    const handleResetAllFilters = (e) => {
        e.preventDefault()
        setAppliedFilters({
            os: null,
            browser: null,
            country: null,
            city: null,
            language: null
        })
        setSelectedFilters({
            os: null,
            browser: null,
            country: null,
            city: null,
            language: null
        })
    }

    const handleChangeFilterOptionSelect = (event, option) => {
        const { checked } = event.target

        if (!checked && selectedFilters[openedFilterBlock]?.includes(option)) {
            const filteredOptions = selectedFilters[openedFilterBlock]?.filter((item) => item !== option)

            setSelectedFilters({
                ...selectedFilters,
                [openedFilterBlock]: filteredOptions,
            })
        } else {
            setSelectedFilters({
                ...selectedFilters,
                [openedFilterBlock]: selectedFilters[openedFilterBlock]?.length > 0 ? [...selectedFilters[openedFilterBlock], option] : [option]
            })
        }
    }

    const handleClearRanges = () => {
        setRanges([
            {
                startDate: null,
                endDate: null,
                key: 'selection'
            }
        ])
        setDateFilter('All Time')
    }

    const handleApplyDateFilter = () => {
        const startDate = ranges[0].startDate
        const endDate = ranges[0].endDate

        if (startDate && endDate) {
            setDateFilter(`${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()} - ${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`)
            setShowDateCalendar(false)
        }
        setShowDateCalendar(false)
    }

    const handleExportData = async (type) => {
        const response = await exportScansData(type)

        let file;
        if (type === 'csv') {
            file = new File([response.data], `data`, { type: `text/${type}` })
            customLogger({
                action: 'click',
                description: 'Statistics Export Data CSV'
            })
        } else {
            file = new Blob([response.data], { type: 'application/octet-stream' });
            customLogger({
                action: 'click',
                description: 'Statistics Export Data XLSX'
            })
        }

        const url = URL.createObjectURL(file);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `data.${type}`);

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        setFiltersSearch('')
    }, [openedFilterBlock])

    useEffect(() => {
        if (selectedFilters?.os?.length > 0 || selectedFilters?.browser?.length > 0 || selectedFilters?.country?.length > 0 || selectedFilters?.city?.length > 0 || selectedFilters?.language?.length > 0) {
            setIsAnyFilterSelected(true)
        } else {
            setIsAnyFilterSelected(false)
        }
    }, [selectedFilters])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarBlockRef.current && !calendarBlockRef.current.contains(event.target)) {
                setShowDateCalendar(false)
            }

            if (filterBlockRef.current && !filterBlockRef.current.contains(event.target)) {
                setShowFilter(false)
                setOpenedFilterBlock(null)
                setSelectedFilters(appliedFilters)
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [appliedFilters, setSelectedFilters])

    return (
        <div className='analytics-content-header'>
            <div className='analytics-content-header-top'>
                <h5 className='analytics-header-title'>{t("statistics")}</h5>
                {isMobile ? (
                    <div className='analytics-header-options'>
                        <div onClick={() => setShowMobileSettings(!showMobileSettings)} className='analytics-header-mobile-setting-button'>
                            <img src={analyticsFiltersMobile} alt="" />
                        </div>
                        <div onClick={() => setShowMobileExportData(!showMobileSettings)} className='analytics-header-mobile-setting-button'>
                            <img src={analyticsDownload} alt="" />
                        </div>
                    </div>
                ) : (
                    <div className='analytics-header-options'>
                        <div className='analytics-header-option-block'>
                            <span className='analytics-header-option-label'>{t("abTesting")}</span>
                            <div onClick={handleSwitchAbTesting} className={`overview-panel-stats-header-unique-scans-switch ${abTesting ? 'active' : ''}`}>
                                <div className={`overview-panel-switch-circle ${abTesting ? 'active' : ''}`}></div>
                            </div>
                        </div>
                        <div style={{ marginLeft: '8px' }} className='analytics-header-option-block'>
                            <span className='analytics-header-option-label'>{t("uniqueScans")}</span>
                            <div onClick={handleSwitchUniqueScans} className={`overview-panel-stats-header-unique-scans-switch ${uniqueScans ? 'active' : ''}`}>
                                <div className={`overview-panel-switch-circle ${uniqueScans ? 'active' : ''}`}></div>
                            </div>
                        </div>
                        <div ref={calendarBlockRef} className='analytics-header-date-option-block'>
                            <button onClick={() => setShowDateCalendar(!showDateCalendar)} className={`analytics-header-date-option-button ${showDateCalendar ? 'active' : ''}`}>
                                <img src={analyticsCalendar} alt="" />
                                {dateFilter === 'All Time' ? t("allTime") : dateFilter}
                            </button>
                            {showDateCalendar && (
                                <div className='analytics-header-date-option-calendar'>
                                    <div className={`analytics-header-date-calendar-wrapper ${!ranges[0]?.startDate && !ranges[0]?.endDate ? 'empty-range' : ''}`}>
                                        <DateRangePicker
                                            onChange={item => setRanges([item.selection])}
                                            months={2}
                                            ranges={ranges}
                                            direction="horizontal"
                                            inputRanges={[]}
                                            rangeColors={["#141315"]}
                                            showDateDisplay={false}
                                            showSelectionPreview={false}
                                            showPreview={false}
                                            staticRanges={customStaticRanges}
                                            locale={LANGUAGE_DATE_RANGE_FORMAT[i18n.language]}
                                        />
                                        <div className='analytics-header-calendar-buttons'>
                                            <button onClick={handleClearRanges} className='analytics-header-calendar-clear-btn'>{t("clear")}</button>
                                            <button onClick={handleApplyDateFilter} className='analytics-header-calendar-done-btn'>{t("apply")}</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div ref={filterBlockRef} className='analytics-header-date-option-block ml-0'>
                            <button onClick={() => setShowFilter(!showFilter)} className={`analytics-header-date-option-button ${showFilter ? 'active' : ''} ${isAnyFilterApplied ? 'applied' : ''}`}>
                                {isAnyFilterApplied ? (
                                    <img src={analyticsFilterPurple} alt="" />
                                ) : (
                                    <img src={analyticsFilter} alt="" />
                                )}
                                {t("filter")}
                                {isAnyFilterApplied && (
                                    <img onClick={handleResetAllFilters} src={analyticsFilterReset} alt="" />
                                )}
                            </button>
                            {showFilter && (
                                <div className='analytics-header-filter-block-menu'>
                                    {openedFilterBlock ? (
                                        <>
                                            <div className='analytics-selected-filter-block-header'>
                                                <div onClick={() => setOpenedFilterBlock(null)} className='analytics-selected-filter-back-block'>
                                                    <img src={analyticsFilterBack} alt="" />
                                                    <span className='analytics-selected-filter-back-block-title'>
                                                        {t(ANALYTICS_FILTER_BLOCK_TO_LABEL_TRANSLATIONS[ANALYTICS_FILTER_BLOCK_TO_LABEL[openedFilterBlock]])}
                                                    </span>
                                                </div>
                                                <span
                                                    onClick={() => { selectedFilters[openedFilterBlock]?.length > 0 && handleClearFilterSelectedOption(openedFilterBlock) }}
                                                    className={`analytics-filter-block-header-clear ${selectedFilters[openedFilterBlock]?.length > 0 ? 'active' : ''}`}
                                                >
                                                    {t("clear")}
                                                </span>
                                            </div>
                                            {(openedFilterBlock === 'country' || openedFilterBlock === 'city' || openedFilterBlock === 'language') && (
                                                <div className='analytics-select-codes-search-block mb-0'>
                                                    <img src={analyticsSearch} alt="" />
                                                    <input
                                                        onChange={(e) => setFiltersSearch(e.target.value)}
                                                        value={filtersSearch}
                                                        className='analytics-select-codes-search-input'
                                                        placeholder={t("search")}
                                                    />
                                                </div>
                                            )}
                                            <div className='analytics-selected-filter-options-list'>
                                                {filterData?.filters[openedFilterBlock]?.map((option, idx) => {
                                                    if (option.toLowerCase().includes(filtersSearch.toLowerCase())) {
                                                        return (
                                                            <div className='analytics-selected-filter-options-item-block' key={idx}>
                                                                <span className='analytics-selected-filter-options-item-text'>{option}</span>
                                                                <input
                                                                    className='analytics-selected-filter-options-item-select'
                                                                    onChange={(e) => handleChangeFilterOptionSelect(e, option)}
                                                                    checked={selectedFilters[openedFilterBlock]?.includes(option)}
                                                                    type="checkbox"
                                                                />
                                                            </div>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='analytics-filter-block-header'>
                                                <span className='analytics-filter-block-header-title'>{t("filterBy")}</span>
                                                <span onClick={handleClearAllFilterOptions} className={`analytics-filter-block-header-clear ${isAnyFilterSelected ? 'active' : ''}`}>
                                                    {t("clearAll")}
                                                </span>
                                            </div>
                                            {ANALYTICS_FILTER_BLOCKS.map((block) => {
                                                return (
                                                    <div onClick={() => setOpenedFilterBlock(block.name)} key={block.id} className='analytics-filter-item-block'>
                                                        <div className='analytics-filter-item-block-info text-truncate'>
                                                            <span className='analytics-filter-item-block-info-name'>{block.label}</span>
                                                            <span className='analytics-filter-item-block-info-value text-truncate'>
                                                                {selectedFilters[block.name]?.length > 0 ? selectedFilters[block.name].join(', ') : t("none")}
                                                            </span>
                                                        </div>
                                                        <IoIosArrowForward size={20} fill={"#7D8898"} />
                                                    </div>
                                                )
                                            })}
                                            <button onClick={handleApplyFilters} className={`analytics-filters-apply-btn ${isAnyFilterSelected ? 'active' : ''}`}>
                                                {t("apply")}
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        <Dropdown className='analytics-header-dropdown'>
                            <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                                <button className='analytics-header-export-btn'>
                                    {t("exportData")}
                                    <IoIosArrowDown size={22} />
                                </button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='analytics-header-dropdown-menu'>
                                <Dropdown.Item onClick={() => handleExportData('csv')} className='bootstrap-default-item'>
                                    {t("download")} CSV
                                    <MdOutlineFileDownload color="#141315" size={22} />
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleExportData('xlsx')} className='bootstrap-default-item'>
                                    {t("download")} XLSX
                                    <MdOutlineFileDownload color="#141315" size={22} />
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
            </div>
            {
                isAnyFilterApplied && !isMobile && (
                    <div className='analytics-content-header-bottom'>
                        {ANALYTICS_FILTER_BLOCKS.map((block) => {
                            if (appliedFilters[block.name]) {
                                return (
                                    <div key={block.id} className='analytics-content-header-applied-filter-block'>
                                        <span className='analytics-content-header-applied-filter-text'>{block.label}: {appliedFilters[block.name].join(', ')}</span>
                                        <span onClick={() => handleClearAppliedFilter(block.name)} className='analytics-content-header-applied-filter-clear'>
                                            <img src={analyticsFilterClose} alt="" />
                                        </span>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </div>
                )
            }
            {
                showMobileSettings && isMobile && (
                    <AnalyticsMobileSettings
                        setShowMobileSettings={setShowMobileSettings}
                        isAnyFilterSelected={isAnyFilterSelected}
                        handleClearAllFilterOptions={handleClearAllFilterOptions}
                        handleApplyFilters={handleApplyFilters}
                        setAbTesting={setAbTesting}
                        setUniqueScans={setUniqueScans}
                        abTesting={abTesting}
                        uniqueScans={uniqueScans}
                        openedFilterBlock={openedFilterBlock}
                        setOpenedFilterBlock={setOpenedFilterBlock}
                        selectedFilters={selectedFilters}
                        filtersSearch={filtersSearch}
                        setFiltersSearch={setFiltersSearch}
                        filterData={filterData}
                        handleChangeFilterOptionSelect={handleChangeFilterOptionSelect}
                        setDateFilter={setDateFilter}
                        dateFilter={dateFilter}
                        handleClearFilterSelectedOption={handleClearFilterSelectedOption}
                    />
                )
            }
            {
                showMobileExportData && isMobile && (
                    <AnalyticsMobileExportData
                        setShowMobileExportData={setShowMobileExportData}
                        handleExportData={handleExportData}
                    />
                )
            }
        </div >
    )
}

export default AnalyticsContentHeader