import { GoEye, GoEyeClosed } from 'react-icons/go'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrFormClose } from 'react-icons/gr'
import { useDispatch } from 'react-redux'
import { Modal } from 'flowbite-react'

import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { handleValidatePassword } from '../../helpers/functions'
import { userSignOut } from '../../firebase/auth/googleAuth'
import { signOut } from '../../redux/appSlice'
import useToasts from '../../hooks/useToasts'
import { api } from '../../api/api'

const EnterPasswordModal = ({ show, onClose, handleSaveChanges }) => {
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const rootRef = useRef(null)

    const { t } = useTranslation()

    const dispatch = useDispatch()
    const navigate = useCustomNavigate()

    const { profileInformationUpdatedWithReloginToast } = useToasts()

    const handleSubmitData = async () => {
        const res = await handleValidatePassword(password)

        if (res) {
            handleSaveChanges()
            profileInformationUpdatedWithReloginToast()
            await userSignOut()
            dispatch(signOut())
            dispatch(api.util.resetApiState());
            localStorage.removeItem('showTrialAlert')
            navigate('/sign-in')
            onClose()
        }
    }

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="md"
                popup="false"
                position='center'
                onClose={onClose}
                style={{ height: '100vh' }}
                className='enter-password-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='trial-end-modal-body padding-top-24'>
                    <div className='enter-password-modal-content-wrapper'>
                        <h5 className='enter-password-modal-content-title'>{t("enterPasswordToVerifyActions")}</h5>
                        <div className='profile-settings-input-wrapper relative'>
                            <input
                                className='profile-settings-input h-full mb-0'
                                placeholder={t("password")}
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {showPassword ? (
                                <GoEye onClick={() => setShowPassword(false)} color={"#BEC5CF"} className='profile-settings-password-eye' size={20} />
                            ) : (
                                <GoEyeClosed onClick={() => setShowPassword(true)} color={"#BEC5CF"} className='profile-settings-password-eye' size={20} />
                            )}
                        </div>
                        <button onClick={handleSubmitData} className='enter-password-modal-send-button'>{t("confirm")}</button>
                        <GrFormClose onClick={onClose} size={20} className='enter-password-modal-close' fill="#334155" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EnterPasswordModal