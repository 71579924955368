import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext'

import CreationContentDescription from '../../common/CreationContentDescription/CreationContentDescription';
import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';
import EditListFilesBlock from '../../EditListFilesBlock/EditListFilesBlock';
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import UploadBlock from '../../common/UploadBlock/UploadBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';
import CodeNameInput from '../CodeNameInput/CodeNameInput';

const UserVideo = ({ triggerValidation, setIsValid }) => {
    const [filesErrors, setFilesErrors] = useState([])

    const { t } = useTranslation()

    const {
        dynamicVideoStyles, setDynamicVideoStyles, setIsContentAdded
    } = useUserCodeCreationContext()

    const { register, trigger, getValues, clearErrors, formState: { errors, isValid } } = useForm({
        mode: 'onBlur',
    });

    const handleChangeLogoFile = (file) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'Logo')
        })

        setDynamicVideoStyles({
            ...dynamicVideoStyles,
            logo: file,
        })
        setIsContentAdded(true)
    }

    const handleChangeFiles = (files) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'Videos')
        })
        clearErrors('link')

        setDynamicVideoStyles({
            ...dynamicVideoStyles,
            files: files,
        })
        setIsContentAdded(true)
    }

    const handleChangeInput = (name, value) => {
        setDynamicVideoStyles({
            ...dynamicVideoStyles,
            [name]: value,
        })
        setIsContentAdded(true)
    }

    useEffect(() => {
        if (setIsValid) {
            setIsValid(isValid)
        }
    }, [isValid, setIsValid])

    useEffect(() => {
        if (triggerValidation) {

            const triggerFunction = async () => {
                let result = true;

                result = await trigger()

                if (!dynamicVideoStyles.files || !dynamicVideoStyles.files.length === 0) {
                    setFilesErrors((prev) => {
                        return [...prev, 'Videos']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'Videos')
                    })
                }

                if (!dynamicVideoStyles.logo) {
                    setFilesErrors((prev) => {
                        return [...prev, 'Logo']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'Logo')
                    })
                }

                return result
            }

            triggerValidation(triggerFunction)
        }
    }, [triggerValidation, trigger, dynamicVideoStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            <CreationContentTitle title={t("videos")} withStep={false} />
            <CreationContentDescription customStyles={{ marginTop: '-12px', marginBottom: '-8px' }} description={t("uploadOrAddLinksToUpTo10Videos")} />
            <CodeNameInput />
            <div className='flex flex-col block-inputs-column'>
                <label className='content-creation-input-label'>{t("videoUrl")}</label>
                <input
                    type='text'
                    value={dynamicVideoStyles.link}
                    placeholder={t("enterVideoUrl")}
                    className={errors.link ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                    {...register("link", {
                        onChange: (e) => handleChangeInput('link', e.target.value),
                        required: dynamicVideoStyles.files.length === 0 ? true : false,
                    })}
                />
                {errors.link && <p className='error-text'>{t("enterVideoUrlsOrUploadVideos")}</p>}
            </div>
            {dynamicVideoStyles.files.length > 0 ? (
                <EditListFilesBlock
                    files={Array.from(dynamicVideoStyles.files)}
                    onChangeFiles={handleChangeFiles}
                    type={"video"}
                    acceptList={"video/mp4,video/x-m4v,video/*"}
                    allowDeleteLast={true}
                />
            ) : (
                <UploadBlock
                    title={t("uploadVideos")}
                    limit={'100MB'}
                    acceptList={'video/mp4,video/x-m4v,video/*'}
                    multiple={true}
                    onChangeFiles={handleChangeFiles}
                    uploadedFiles={dynamicVideoStyles.files.length}
                    isError={filesErrors.includes('Videos') && !getValues('link')}
                />
            )}
            <span className='dynamic-form-underline'></span>
            <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("company")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.company}
                        placeholder={t("exTechAndCorp")}
                        className={errors.company ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("company", {
                            onChange: (e) => handleChangeInput('company', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.company && <p className='error-text'>{t("enterCompanyName")}</p>}
                </div>
                {dynamicVideoStyles.logo ? (
                    <EditFileBlock
                        file={dynamicVideoStyles.logo}
                        onFileChanged={handleChangeLogoFile}
                        title={dynamicVideoStyles?.logo.name ? dynamicVideoStyles?.logo.name : t("logoFile")}
                    />
                ) : (
                    <UploadLogoBlock
                        title={t("addLogo")}
                        limit={"5MB"}
                        acceptList={"image/*"}
                        multiple={false}
                        onChangeFiles={handleChangeLogoFile}
                        uploadedFiles={dynamicVideoStyles.logo ? 1 : 0}
                        isError={filesErrors.includes('Logo')}
                        errorText={t("logoFileMustBeUploaded")}
                    />
                )}
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("videoTitle")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.title}
                        placeholder={t("exTechAndCorpManifesto")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterAVideoTitle")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.description}
                        placeholder={t("exLeadingTechnologyCompanyInTheSector")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.button}
                        placeholder={t("exWatchNow")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("buttonRedirectUrl")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.redirect}
                        placeholder={t("exVideoPlaylistUrl")}
                        className={errors.redirect ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("redirect", {
                            onChange: (e) => handleChangeInput('redirect', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.redirect && <p className='error-text'>{t("enterAValidButtonRedirectUrl")}</p>}
                </div>
            </div>
        </form>
    )
}

export default UserVideo