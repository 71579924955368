import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react'

import { useViewport } from '../../../hooks/useViewport';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle'
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';

const EditText = ({ triggerValidation }) => {
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const { register, trigger, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const {
        dynamicTextStyles, setDynamicTextStyles
    } = useEditQrCodeContext()

    const handleChangeText = async (name, value) => {
        setDynamicTextStyles({
            ...dynamicTextStyles,
            [name]: value,
        })
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicTextStyles({
            ...dynamicTextStyles,
            logo: file,
        })
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (dynamicTextStyles) {
            setValue('text', dynamicTextStyles.text)
            setValue('subject', dynamicTextStyles.subject)
            setValue('company', dynamicTextStyles.company)
        }
    }, [dynamicTextStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            {!isMobile && (
                <CreationContentTitle title={t("text")} withStep={false} />
            )}
            <div className='content-creation-input-wrapper'>
                <label className='content-creation-input-label'>{t("message")}</label>
                <textarea
                    type='text'
                    value={dynamicTextStyles.text}
                    placeholder={t("message")}
                    className={errors.text ? 'form-input-dashboard-textarea form-input-error-border' : 'form-input-dashboard-textarea'}
                    {...register("text", {
                        onChange: (e) => handleChangeText('text', e.target.value),
                        required: true,
                    })}
                />
                {errors.text && <p className='error-text'>{t("enterMessage")}</p>}
            </div>
            {!isMobile && (
                <>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                </>
            )}
            <div className='content-creation-input-wrapper'>
                <label className='content-creation-input-label'>{t("subject")}</label>
                <input
                    type='text'
                    value={dynamicTextStyles.subject}
                    placeholder={t('enterSubject')}
                    className={errors.password ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                    {...register("subject", {
                        onChange: (e) => handleChangeText('subject', e.target.value),
                    })}
                />
            </div>
            <div className='content-creation-input-wrapper'>
                <label className='content-creation-input-label'>{t("company")}</label>
                <input
                    type='text'
                    value={dynamicTextStyles.company}
                    placeholder={t("exTechAndCorp")}
                    className={errors.password ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                    {...register("company", {
                        onChange: (e) => handleChangeText('company', e.target.value),
                    })}
                />
            </div>
            {dynamicTextStyles?.logo ? (
                <EditFileBlock
                    file={dynamicTextStyles.logo}
                    title={t("logoFile")}
                    onFileChanged={handleSaveUploadedLogoFile}
                    onDeleteFile={() => handleSaveUploadedLogoFile(null)}
                    deleteAllowed={true}
                />
            ) : (
                <UploadLogoBlock
                    title={t("addLogoOptional")}
                    limit={"100MB"}
                    acceptList={"image/*"}
                    multiple={false}
                    onChangeFiles={handleSaveUploadedLogoFile}
                    uploadedFiles={dynamicTextStyles.logo ? 1 : 0}
                    isError={false}
                    errorText={t("logoFileMustBeUploaded")}
                />
            )}
        </form>
    )
}

export default EditText