import { useNavigate } from 'react-router-dom';

import { useLanguageContext } from '../context/LanguageContext'
import { useCallback } from 'react';

export const useCustomNavigate = () => {
    const { currentLanguage } = useLanguageContext()

    const navigate = useNavigate();

    const generateUrl = useCallback((path) => {
        if (typeof path === 'number') {
            return path;
        }

        if (currentLanguage === 'en') {
            return path;
        }
        return `/${currentLanguage}${path}`;
    }, [currentLanguage]);

    const customNavigate = useCallback((path, options) => {
        const url = generateUrl(path);
        navigate(url, options);
    }, [navigate, generateUrl]);


    return customNavigate
}