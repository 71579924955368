import React, { useRef } from 'react'
import { Modal } from 'flowbite-react'
import { CgClose } from 'react-icons/cg'
import { useTranslation } from 'react-i18next'

import { useViewport } from '../../hooks/useViewport'

const ViewFullscreenCodeModal = ({ code, show, onClose, downloadCode }) => {
    const rootRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="lg"
                popup="false"
                position='center'
                onClose={onClose}
                style={{ height: '100vh' }}
                className='view-fullscreen-code-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='view-fullscreen-code-modal-body padding-top-24'>
                    <div className='view-fullscreen-code-header'>
                        {!isMobile && (
                            <>
                                {t("preview")}
                                <div onClick={onClose} className='view-fullscreen-code-close'>
                                    <CgClose className='cursor-pointer' color={"#7D8898"} />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={`view-fullscreen-code-preview-block ${code?.type === 'static' ? 'mb-10' : ''}`}>
                        <img src={code?.image} alt="" />
                        <button className='view-fullscreen-code-download' onClick={() => downloadCode(code)}>{t("download")}</button>
                    </div>
                    {(code?.type === 'dynamic' || isMobile) && (
                        <div className='view-fullscreen-code-link-block'>
                            {isMobile ? (
                                <button onClick={onClose} className={`view-fullscreen-code-exit-preview ${code?.type === 'static' ? 'w-full' : ''}`}>{t("exitPreview")}</button>
                            ) : (
                                <span className='view-fullscreen-code-link-url'>
                                    {'https://qrcodeveloper/code/' + code?.public_id}
                                </span>
                            )}
                            {code.type === 'dynamic' && (
                                <a className={`${isMobile ? 'w-full' : ''}`} href={`${window.location.origin}/code/${code?.public_id}`} target='_blank' rel="noreferrer" >
                                    <button className='view-fullscreen-code-landing-page-btn'>{t("viewLandingPage")}</button>
                                </a>
                            )}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ViewFullscreenCodeModal