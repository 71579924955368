import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import React from 'react'

import { PAYPAL_TRANSLATION_CODES } from "../../helpers/translation-constants";
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import i18n from '../../i18n/config';

const PayPalButton = ({ height, priceId, planName, selectedPlan }) => {
    const [searchParams] = useSearchParams();
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)

    const navigate = useCustomNavigate();

    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '';

    const createSubscription = (data, actions) => {
        if (searchParams.get('isNewUser') === 'true') {
            window.gtag('event', 'click_purchase_button', {
                type: 'new_user',
            })
        } else {
            window.gtag('event', 'click_purchase_button', {
                type: 'existing_user',
            })
        }

        return actions.subscription.create({
            'plan_id': selectedPlan.paypal_plan_id[i18n.language] ?? selectedPlan.paypal_plan_id['en'],
            'quantity': 1,
            'custom_id': `${user.uid},${selectedPlan.nameEnglish},${localStorage.getItem('ip_address') || ''},${i18n.language},${subscription?.increased_prices ? 'e' : 'd'}`,
        })
    };

    const onApprove = (_, actions) => {
        actions.subscription.get().then(function (_) {
            navigate(`/upgrade-plan/success?priceId=${priceId}&planName=${planName}`)
        });
    };

    const onError = (_) => {
        navigate(`/upgrade-plan/error?priceId=${priceId}&planName=${planName}&isNewUser=${searchParams.get('isNewUser')}`)
    };

    return (
        <PayPalScriptProvider
            options={{
                "client-id": CLIENT_ID,
                "disable-funding": "card,credit",
                "vault": "true",
                "locale": `${PAYPAL_TRANSLATION_CODES[i18n.language] ?? 'en_US'}`,
            }}
        >
            <PayPalButtons
                className="w-100"
                onApprove={onApprove}
                onError={onError}
                createSubscription={createSubscription}

                style={{
                    disableMaxWidth: true,
                    height: height ? 50 : 52,
                }}
            />
        </PayPalScriptProvider>
    )
}

export default PayPalButton