import React from 'react'
import { useTranslation } from 'react-i18next'

import { useViewport } from '../../hooks/useViewport'

import AnalyticsUniqueScans from '../../assets/icons/analytics/unique-scans.svg'
import AnalyticsTotalCodes from '../../assets/icons/analytics/total-codes.svg'
import AnalyticsTotalScans from '../../assets/icons/analytics/total-scans.svg'

const AnalyticsTotalInfo = ({ totalData }) => {
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    return (
        <div className='analytics-total-info-wrapper'>
            <div className='analytics-total-info-block-item'>
                <img src={AnalyticsTotalCodes} alt="" />
                <div className='analytics-total-info-block-information'>
                    <h5 className='analytics-total-info-block-title'>{totalData?.total_number_qr_codes || 0}</h5>
                    <p className='analytics-total-info-block-description'>
                        {isMobile ? t("qrCodesTotal") : t("totalNumberOfQrCodes")}
                    </p>
                </div>
            </div>
            <div className='analytics-total-info-block-item'>
                <img src={AnalyticsTotalScans} alt="" />
                <div className='analytics-total-info-block-information'>
                    <h5 className='analytics-total-info-block-title'>{totalData?.total_scans || 0}</h5>
                    <p className='analytics-total-info-block-description'>
                        {t("totalScans")}
                    </p>
                </div>
            </div>
            <div className='analytics-total-info-block-item'>
                <img src={AnalyticsUniqueScans} alt="" />
                <div className='analytics-total-info-block-information'>
                    <h5 className='analytics-total-info-block-title'>{totalData?.unique_scans || 0}</h5>
                    <p className='analytics-total-info-block-description'>
                        {t("uniqueScans")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsTotalInfo