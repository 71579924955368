import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal } from 'flowbite-react'
import Hammer from 'hammerjs';

import {
    ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS,
    ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS
} from '../../helpers/image-translations';
import { useUpdateUserSettingsMutation } from '../../api/api'
import usePreloadImage from '../../hooks/usePreloadImage';
import { useViewport } from '../../hooks/useViewport'
import useOnboardingData from './useOnboardingData';
import i18n from '../../i18n/config';

import OnboardingTrackingMobilePt from '../../assets/icons/onboarding/tracking-mobile-pt.svg'
import OnboardingWelcomeMobilePt from '../../assets/icons/onboarding/welcome-mobile-pt.svg'
import OnboardingAdvancedFilters from '../../assets/icons/onboarding/advanced-filters.svg'
import OnboardingScansByBrowser from '../../assets/icons/onboarding/scans-by-browser.svg'
import OnboardingWebsitePreview from '../../assets/icons/onboarding/website-preview.svg'
import OnboardingTrackingMobile from '../../assets/icons/onboarding/tracking-mobile.svg'
import OnboardingWelcomeMobile from '../../assets/icons/onboarding/welcome-mobile.svg'
import OnboardingScansActivity from '../../assets/icons/onboarding/scans-activity.svg'
import OnboardingImagePreview from '../../assets/icons/onboarding/image-preview.svg'
import OnboardingNotification from '../../assets/icons/onboarding/notification.svg'
import OnboardingGreyCodePt from '../../assets/icons/onboarding/grey-code-pt.svg'
import OnboardingTrackingPt from '../../assets/icons/onboarding/tracking-pt.svg'
import OnboardingNavigation from '../../assets/icons/onboarding/navigation.svg'
import OnboardingAbTesting from '../../assets/icons/onboarding/ab-testing.svg'
import OnboardingWelcomePt from '../../assets/icons/onboarding/welcome-pt.svg'
import OnboardingGreyCode from '../../assets/icons/onboarding/grey-code.svg'
import OnboardingTracking from '../../assets/icons/onboarding/tracking.svg'
import OnboardingTrendPt from '../../assets/icons/onboarding/trend-pt.svg'
import OnboardingWelcome from '../../assets/icons/onboarding/welcome.svg'
import OnboardingSearch from '../../assets/icons/onboarding/search.svg'
import OnboardingTrend from '../../assets/icons/onboarding/trend.svg'
import OnboardingStats from '../../assets/icons/onboarding/stats.svg'
import OnboardingEdit from '../../assets/icons/onboarding/edit.svg'

const OnboardingModal = ({ show, onClose }) => {
    const [step, setStep] = useState('welcome')
    const [activeCode, setActiveCode] = useState(1)

    const rootRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()
    const { CODES_LIST, ONBOARDING_ITEMS } = useOnboardingData()

    usePreloadImage({
        images: [
            OnboardingTrackingMobilePt,
            OnboardingWelcomeMobilePt,
            OnboardingAdvancedFilters,
            OnboardingScansByBrowser,
            OnboardingWebsitePreview,
            OnboardingTrackingMobile,
            OnboardingWelcomeMobile,
            OnboardingScansActivity,
            OnboardingImagePreview,
            OnboardingNotification,
            OnboardingNavigation,
            OnboardingGreyCodePt,
            OnboardingTrackingPt,
            OnboardingAbTesting,
            OnboardingWelcomePt,
            OnboardingGreyCode,
            OnboardingTracking,
            OnboardingTrendPt,
            OnboardingWelcome,
            OnboardingSearch,
            OnboardingTrend,
            OnboardingStats,
            OnboardingEdit,
        ]
    })

    const [updateSettings] = useUpdateUserSettingsMutation()

    const handleSkipOnboarding = () => {
        updateSettings({
            data: {
                onboarding_finished: true
            }
        })
        onClose()
    }

    const handlePrevStep = () => {
        if (step === 'first') {
            setStep('welcome')
        } else if (step === 'second') {
            setStep('first')
        }
    }

    const handleNextFinish = () => {
        if (step === 'first') {
            setStep('second')
        } else if (step === 'second') {
            updateSettings({
                data: {
                    onboarding_finished: true
                }
            })
            onClose()
        }
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    useEffect(() => {
        if (isMobile) return;

        let interval;

        if (step === 'first') {
            interval = setInterval(() => {
                setActiveCode((prev) => (prev === 7 ? 1 : prev + 1))
            }, [3000])
        }

        return () => clearInterval(interval)
    }, [step, activeCode, isMobile])

    useEffect(() => {
        const swipeContainer = document.getElementById('mobile-first-step');

        if (!swipeContainer) return;

        const hammer = new Hammer(swipeContainer);

        hammer.on('swipeleft', () => {
            if (activeCode === 7) {
                setActiveCode(1)
            } else {
                setActiveCode((prev) => prev + 1)
            }
        });

        hammer.on('swiperight', () => {
            if (activeCode === 1) {
                setActiveCode(7)
            } else {
                setActiveCode((prev) => prev - 1)
            }
        });

        return () => {
            hammer.destroy();
        };
    }, [activeCode, step]);

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                size="xl"
                popup="false"
                position='center'
                onClose={onClose}
                style={{ height: '100vh' }}
                className='onboarding-modal'
                root={rootRef.current ?? undefined}
            >
                <Modal.Body className='onboarding-modal-body'>
                    <div className='onboarding-modal-content-block'>
                        <div className='onboarding-content-text-block'>
                            {step === 'welcome' ? (
                                <>
                                    <h6 className='onboarding-content-text-title'>{t("welcomeToYourQRCodeDashboard")}</h6>
                                    <p className='onboarding-content-text-description'>
                                        {t("createCustomQRCodesTrackScansAndDownload")}
                                    </p>
                                </>
                            ) : step === 'first' ? (
                                <>
                                    <h6 className='onboarding-content-text-title'>{t("customize7DynamicQrCodeTypes")}</h6>
                                    <p className='onboarding-content-text-description'>
                                        {t("createACustomLandingPageForYourQRCode")}
                                    </p>
                                    <div className='onboarding-content-codes-block'>
                                        {CODES_LIST.map((code) => (
                                            <div onClick={() => setActiveCode(code.id)} key={code.id} className={`onboarding-content-code-item ${code.id === activeCode ? 'active' : ''}`}>
                                                <img src={code.icon} alt="" />
                                                <p className='onboarding-content-code-name'>{code.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : step === 'second' ? (
                                <>
                                    <h6 className='onboarding-content-text-title'>{t("trackAnaCompareScanStatistics")}</h6>
                                    <p className='onboarding-content-text-description'>
                                        {t("getMoreInsightsWithScansBy")}
                                    </p>
                                    <div className='onboarding-content-abilities-block'>
                                        <div className='onboarding-content-ability-item'>
                                            <img src={OnboardingAbTesting} alt="" />
                                            <p className='onboarding-content-ability-text'>
                                                {t("conductABTestingToCompareThePerformance")}
                                            </p>
                                        </div>
                                        <div className='onboarding-content-ability-item'>
                                            <img src={OnboardingAdvancedFilters} alt="" />
                                            <p className='onboarding-content-ability-text'>
                                                {t("utilizeAdvancedFilteringOptionsToSegmentData")}
                                            </p>
                                        </div>
                                        <div className='onboarding-content-ability-item'>
                                            <img src={OnboardingStats} alt="" />
                                            <p className='onboarding-content-ability-text'>
                                                {t("trackUniqueScansToSeeTrueEngagement")}
                                            </p>
                                        </div>
                                        <div className='onboarding-content-ability-item'>
                                            <img src={OnboardingSearch} alt="" />
                                            <p className='onboarding-content-ability-text'>
                                                {t("reviewClickThroughRatesCtr")}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className={`onboarding-content-images-block ${step === 'second' ? 'second' : ''}`}>
                            {step === 'welcome' ? (
                                <>
                                    <img
                                        className='onboarding-welcome-image-mobile'
                                        src={
                                            ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS[i18n.language]?.mobile
                                            ?? ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS['en']?.mobile
                                        }
                                        alt=""
                                    />
                                    <img
                                        className='onboarding-welcome-image-desktop'
                                        src={
                                            ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS[i18n.language]?.desktop
                                            ?? ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS['en']?.desktop
                                        }
                                        alt=""
                                    />
                                    <img className='onboarding-content-images-edit-icon' src={OnboardingEdit} alt="" />
                                    <img className='onboarding-content-images-navigation-icon' src={OnboardingNavigation} alt="" />
                                    <img className='onboarding-content-images-notification-icon' src={OnboardingNotification} alt="" />
                                    <img
                                        className='onboarding-content-images-trend-icon'
                                        src={
                                            ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS[i18n.language]?.trend
                                            ?? ONBOARDING_MODAL_WELCOME_IMAGES_TRANSLATIONS['en']?.trend
                                        }
                                        alt=""
                                    />
                                </>
                            ) : step === 'first' ? (
                                <div id="mobile-first-step" className='onboarding-content-first-step-block'>
                                    {isMobile ? (
                                        <>
                                            <div className='onboarding-content-mobile-first-step-header'>
                                                <div className='onboarding-mobile-first-step-code-type-block'>
                                                    <img src={CODES_LIST[activeCode - 1].icon} alt="" />
                                                    <p className='onboarding-mobile-first-step-code-type'>{CODES_LIST[activeCode - 1].name}</p>
                                                </div>
                                                <div className='onboarding-mobile-first-step-active-code'>
                                                    {activeCode}/7
                                                </div>
                                            </div>
                                            {activeCode !== 1 && (
                                                <img className='onboarding-preview-mobile-left-switch-image' src={ONBOARDING_ITEMS[activeCode - 2].image} alt="" />
                                            )}
                                            <img className='onboarding-preview-image' src={ONBOARDING_ITEMS[activeCode - 1].image} alt="" />
                                            {activeCode !== 7 && (
                                                <img className='onboarding-preview-mobile-right-switch-image' src={ONBOARDING_ITEMS[activeCode].image} alt="" />
                                            )}
                                            <p className='onboarding-first-step-description'>{ONBOARDING_ITEMS[activeCode - 1].text}</p>
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                style={{
                                                    marginLeft: `calc(-${174.5 * activeCode}px)`
                                                }}
                                                className='onboarding-first-step-image small'
                                                src={OnboardingImagePreview}
                                                alt=""
                                            />
                                            {ONBOARDING_ITEMS.map((item) => (
                                                <Fragment key={item.id}>
                                                    <img
                                                        className={`onboarding-first-step-image ${item.id !== activeCode ? 'small' : ''}`}
                                                        src={item.image}
                                                        alt=""
                                                    />
                                                    {item.id === activeCode && (
                                                        <p className='onboarding-first-step-description'>{item.text}</p>
                                                    )}
                                                </Fragment>
                                            ))}
                                            <img className='onboarding-first-step-image small' src={OnboardingWebsitePreview} alt="" />
                                        </>
                                    )}
                                    <div className='onboarding-first-step-progress-block'>
                                        {CODES_LIST.map((code) => (
                                            <div key={code.id} className={`onboarding-first-step-progress-item ${code.id === activeCode ? 'active' : ''}`}>
                                                {code.id === activeCode && (
                                                    <span className='onboarding-first-step-progress-item-fill'></span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : step === 'second' ? (
                                <>
                                    {isMobile ? (
                                        <img
                                            className='onboarding-content-images-tracking-image'
                                            src={
                                                ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS[i18n.language]?.mobile ?? ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS['en']?.mobile
                                            }
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className='onboarding-content-images-tracking-image'
                                            src={
                                                ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS[i18n.language]?.desktop ?? ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS['en']?.desktop
                                            }
                                            alt=""
                                        />
                                    )}
                                    {i18n.language === 'en' && (
                                        <img
                                            className='onboarding-content-images-scans-activity-icon'
                                            src={OnboardingScansActivity}
                                            alt=""
                                        />
                                    )}
                                    {i18n.language !== 'en' && isMobile ? (
                                        <></>
                                    ) : (
                                        <img
                                            className='onboarding-content-images-grey-code-icon'
                                            src={
                                                ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS[i18n.language]?.code ?? ONBOARDING_MODAL_SECOND_STEP_IMAGES_TRANSLATIONS['en']?.code
                                            }
                                            alt=""
                                        />
                                    )}
                                    {i18n.language === 'en' && (
                                        <img
                                            className='onboarding-content-images-scans-by-browser-icon'
                                            src={OnboardingScansByBrowser}
                                            alt=""
                                        />
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className={`onboarding-modal-footer-block ${step === 'first' || step === 'second' ? 'with-padding-top' : ''}`}>
                        <button onClick={handleSkipOnboarding} className='onboarding-footer-skip-button'>{t("skipForNow")}</button>
                        {step === 'welcome' ? (
                            <button onClick={() => setStep('first')} className='onboarding-footer-get-started-button'>{t("getStarted")}</button>
                        ) : step === 'first' || step === 'second' ? (
                            <>
                                <div className='onboarding-footer-steps-indicator'>
                                    <div
                                        onClick={() => setStep('first')}
                                        className={`onboarding-footer-step-indicator-circle cursor-pointer ${step === 'first' ? 'active' : ''}`}
                                    ></div>
                                    <div
                                        onClick={() => setStep('second')}
                                        className={`onboarding-footer-step-indicator-circle cursor-pointer ${step === 'second' ? 'active' : ''}`}
                                    ></div>
                                </div>
                                <div className='onboarding-footer-first-step-buttons'>
                                    <button onClick={handlePrevStep} className='onboarding-footer-back-button'>{t("back")}</button>
                                    <button onClick={handleNextFinish} className='onboarding-footer-next-button'>
                                        {step === 'first' ? t("next") : t("finish")}
                                    </button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default OnboardingModal