import React, { useMemo } from 'react'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import Link from '../ContentForms/Link/Link'
import Text from '../ContentForms/Text/Text'
import UserWifi from '../ContentForms/UserWifi/UserWifi'
import PdfForm from '../ContentForms/PdfForm/PdfForm'
import UserMp3 from '../ContentForms/UserMp3/UserMp3'
import UserVideo from '../ContentForms/UserVideo/UserVideo'
import UserImage from '../ContentForms/UserImage/UserImage'

const DashboardCreationContent = ({ triggerValidation, setIsValid }) => {
    const { userQrType, isDynamic } = useUserCodeCreationContext()

    const content = useMemo(() => {
        switch (userQrType) {
            case 'URL': {
                return <Link type='authorized' kind={isDynamic ? "dynamic" : "static"} triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            case 'Text': {
                return <Text type='authorized' kind={isDynamic ? "dynamic" : "static"} triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            case 'Wi-Fi': {
                return <UserWifi type='authorized' kind={isDynamic ? "dynamic" : "static"} triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            case 'PDF': {
                return <PdfForm triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            case 'MP3': {
                return <UserMp3 triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            case 'Video': {
                return <UserVideo triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            case 'Image': {
                return <UserImage triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
            default: {
                return <Link type='authorized' triggerValidation={triggerValidation} setIsValid={setIsValid} />
            }
        }
    }, [userQrType, isDynamic, triggerValidation])

    return (
        <div className='w-full'>{content}</div>
    )
}

export default DashboardCreationContent