import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { useAddPaymentMethodMutation, useSendEmailSendgridPaymentMethodUpdatesMutation } from '../../api/api'

import Cvc from '../../assets/icons/settings/cvc.svg'

const inputStyle = {
    color: "#141315",
    fontSize: "16px",
    fontWeight: "400",
    backgroundColor: 'transparent',
    lineHeight: '24px',
}

const CreditCardForm = ({ isEdit, cardToEdit, handleCancelAddMethod }) => {
    const [makeDefault, setMakeDefault] = useState(false)
    const [error, setError] = useState('')

    const { t } = useTranslation()

    const elements = useElements()
    const stripe = useStripe()

    const [addPaymentMethod] = useAddPaymentMethodMutation()
    const [sendEmailSendgridPaymentMethodUpdates] = useSendEmailSendgridPaymentMethodUpdatesMutation()

    const handleAddCreditCard = async () => {
        if (!stripe || !elements) {
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
        });

        if (payload.error) {
            setError(payload.error.message)
        } else {
            addPaymentMethod({
                payment_method_id: payload.paymentMethod.id,
                set_default: makeDefault,
            })
            //new trigger
            sendEmailSendgridPaymentMethodUpdates()
            handleCancelAddMethod()
        }
    }

    return (
        <div style={{ gap: '8px' }} className='flex flex-col'>
            <div className='credit-card-form-wrapper'>
                <div className='credit-card-input-block'>
                    <span className='credit-card-input-label'>{t("creditCardNumber")}</span>
                    <div className='add-payment-input-wrapper'>
                        <CardNumberElement
                            placeholder={isEdit ? `**** **** **** ${cardToEdit.card.last4}` : ''}
                            options={{
                                style: {
                                    base: inputStyle,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className='credit-card-input-block'>
                    <span className='credit-card-input-label'>{t("expiration")}</span>
                    <div className='add-payment-input-wrapper'>
                        <CardExpiryElement
                            options={{
                                style: {
                                    base: inputStyle,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className='credit-card-input-block relative'>
                    <span className='credit-card-input-label'>CVC</span>
                    <div className='add-payment-input-wrapper'>
                        <CardCvcElement
                            options={{
                                style: {
                                    base: inputStyle,
                                },
                            }}
                        />
                    </div>
                    <img className='credit-card-cvc-image' src={Cvc} alt="" />
                </div>
            </div>
            <div className='settings-edit-method-make-default-block'>
                <input
                    onChange={(e) => setMakeDefault(e.target.checked)}
                    className='settings-edit-method-default-checkbox'
                    type="checkbox"
                    checked={makeDefault}
                />
                <span className='settings-edit-method-make-default-label'>
                    {t("setAsDefaultPaymentMethod")}
                </span>
            </div>
            <div className='settings-tab-payment-method-edit-actions-block'>
                <button onClick={handleCancelAddMethod} className='settings-tab-payment-edit-cancel'>{t("cancel")}</button>
                <button onClick={handleAddCreditCard} className='settings-tab-payment-edit-save'>{t("addCard")}</button>
            </div>
        </div>
    )
}

export default CreditCardForm