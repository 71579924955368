import { useTranslation } from 'react-i18next'

const useConvertTimeToTextFormat = () => {
    const { t } = useTranslation()

    const convertTimeToTextFormat = (date) => {
        const time = new Date(date)
        const now = new Date()

        const timeDifference = now.getTime() - time.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4);
        const years = Math.floor(months / 12);

        let timeString = '';

        if (years > 0) {
            timeString = years === 1 ? t("1yearAgo") : `${t("yearsAgo", { count: years })}`
        } else if (months > 0) {
            timeString = months === 1 ? t("1monthAgo") : `${t("monthsAgo", { count: months })}`
        } else if (weeks > 0) {
            timeString = weeks === 1 ? t("1weekAgo") : `${t("weeksAgo", { count: weeks })}`
        } else if (days > 0) {
            timeString = days === 1 ? t("1dayAgo") : `${t("daysAgo", { count: days })}`
        } else if (hours > 0) {
            timeString = hours === 1 ? t("1hourAgo") : `${t("hoursAgo", { count: hours })}`
        } else if (minutes > 0) {
            timeString = minutes === 1 ? t("1minuteAgo") : `${t("minutesAgo", { count: minutes })}`
        } else {
            timeString = seconds === 1 ? t("1sAgo") : `${t("sAgo", { count: seconds })}`
        }

        return timeString
    }

    return { convertTimeToTextFormat }
}

export default useConvertTimeToTextFormat