import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext'

import CreationContentDescription from '../../common/CreationContentDescription/CreationContentDescription';
import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';
import EditListFilesBlock from '../../EditListFilesBlock/EditListFilesBlock';
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';
import UploadBlock from '../../common/UploadBlock/UploadBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';
import CodeNameInput from '../CodeNameInput/CodeNameInput';

const UserImage = ({ triggerValidation, setIsValid }) => {
    const [filesErrors, setFilesErrors] = useState([])

    const { t } = useTranslation()

    const {
        dynamicImageStyles, setDynamicImageStyles, setIsContentAdded
    } = useUserCodeCreationContext()

    const { register, trigger, formState: { errors, isValid } } = useForm({
        mode: 'onBlur',
    });

    const handleChangeFiles = (files) => {
        setFilesErrors((prev) => {
            return prev.filter((el) => el !== 'Images')
        })

        setDynamicImageStyles({
            ...dynamicImageStyles,
            files: files,
        })
        setIsContentAdded(true)
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicImageStyles({
            ...dynamicImageStyles,
            logo: file,
        })
        setIsContentAdded(true)
    }

    const handleChangeInput = (name, value) => {
        setDynamicImageStyles({
            ...dynamicImageStyles,
            [name]: value,
        })
        setIsContentAdded(true)
    }

    useEffect(() => {
        if (setIsValid) {
            setIsValid(isValid)
        }
    }, [isValid, setIsValid])

    useEffect(() => {
        if (triggerValidation) {

            const triggerFunction = async () => {
                let result = true;

                result = await trigger()

                if (!dynamicImageStyles.files || dynamicImageStyles.files.length === 0) {
                    setFilesErrors((prev) => {
                        return [...prev, 'Images']
                    })
                    result = false
                } else {
                    setFilesErrors((prev) => {
                        return prev.filter((el) => el !== 'Images')
                    })
                }

                return result
            }

            triggerValidation(triggerFunction)
        }
    }, [triggerValidation, trigger, dynamicImageStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            <CreationContentTitle title={t("images")} withStep={false} />
            <CreationContentDescription customStyles={{ marginTop: '-12px', marginBottom: '-8px' }} description={t("uploadUpTo10Images")} />
            <CodeNameInput />
            {dynamicImageStyles?.logo ? (
                <EditFileBlock
                    file={dynamicImageStyles.logo}
                    title={t("logoFile")}
                    onFileChanged={handleSaveUploadedLogoFile}
                    onDeleteFile={() => handleSaveUploadedLogoFile(null)}
                    deleteAllowed={true}
                />
            ) : (
                <UploadLogoBlock
                    title={t("addLogoOptional")}
                    limit={"5MB"}
                    acceptList={"image/*"}
                    multiple={false}
                    onChangeFiles={handleSaveUploadedLogoFile}
                    uploadedFiles={dynamicImageStyles.logo ? 1 : 0}
                    isError={false}
                    errorText={t("logoFileMustBeUploaded")}
                />
            )}
            {dynamicImageStyles.files.length > 0 ? (
                <EditListFilesBlock
                    files={Array.from(dynamicImageStyles?.files)}
                    onChangeFiles={handleChangeFiles}
                    title={t("imageFile")}
                    type="image"
                    acceptList={"image/*"}
                    allowDeleteLast={true}
                />
            ) : (
                <UploadBlock
                    title={t("uploadImages")}
                    limit={'5MB'}
                    acceptList={'image/*'}
                    multiple={true}
                    onChangeFiles={handleChangeFiles}
                    uploadedFiles={dynamicImageStyles.files.length}
                    isError={filesErrors.includes('Images')}
                    customSizeTitle={t("maximumSizePerImage")}
                />
            )}
            <span className='dynamic-form-underline'></span>
            <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("imagePhotoAlbumTitle")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.title}
                        placeholder={t("exMyPhotos")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterAnImageTitle")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.description}
                        placeholder={t("ex2018Cruise")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.button}
                        placeholder={t("exSeeAll")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("buttonRedirectUrl")}</label>
                    <input
                        type='text'
                        value={dynamicImageStyles.redirect}
                        placeholder={t("exInstagramUrl")}
                        className={errors.redirect ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("redirect", {
                            onChange: (e) => handleChangeInput('redirect', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.redirect && <p className='error-text'>{t("enterAValidButtonRedirectUrl")}</p>}
                </div>
            </div>
        </form>
    )
}

export default UserImage