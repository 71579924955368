import { useTranslation } from 'react-i18next'
import React from 'react'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import EmptyLogo from '../../assets/icons/code-create/empty-logo.svg'

const TextPreview = ({
    data,
    creationPreview,
    isContentAdded = true,
    version = 1
}) => {
    const { t } = useTranslation()

    const decreaseElementsSize = window.location.pathname.includes('/code') ? false : true

    return (
        <>
            {isContentAdded ? (
                <>
                    {version === 1 ? (
                        <div className='text-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
                            <div className='text-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                                {data.logo ? (
                                    <div className='text-preview-logo-block'>
                                        <img className='text-preview-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                                    </div>
                                ) : creationPreview ? (
                                    <div className='pdf-logo-empty'></div>
                                ) : null}
                                {(data.company || creationPreview) && (
                                    <span style={{ color: data.designTextColor, wordBreak: 'break-all' }} className='text-preview-company'>
                                        {data.company ? data.company : t("company")}
                                    </span>
                                )}
                                {(data.subject || creationPreview) && (
                                    <span style={{ color: data.designTextColor, wordBreak: 'break-all' }} className='text-preview-subject'>
                                        {data.subject ? data.subject : t("subject")}
                                    </span>
                                )}
                                <span className='text-preview-title' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.text ? data.text : t("message")}
                                </span>
                            </div>
                            <div className='text-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>

                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ backgroundColor: `${data.designPrimaryColor}` }}
                            className={`flex flex-col w-full items-center ${decreaseElementsSize ? 'absolute top-[0px] left-0 pt-[20px] min-h-full' : 'min-h-[100dvh]'}`}
                        >
                            {(data.logo || data.company || decreaseElementsSize) && (
                                <div
                                    className={`flex items-center justify-center px-2 border-solid border-b-[1px] border-[#E2E8F0] gap-2 w-full ${decreaseElementsSize ? 'h-[38px]' : 'h-[44px]'}`}
                                >
                                    {(data.logo || decreaseElementsSize) && (
                                        <img
                                            className={`${decreaseElementsSize ? 'h-[20px]' : 'h-[28px]'}`}
                                            src={
                                                data.logo ?
                                                    (typeof (data.logo) === 'string'
                                                        ? data.logo
                                                        : URL.createObjectURL(data.logo))
                                                    : EmptyLogo
                                            }
                                            alt=""
                                        />
                                    )}
                                    {(data.company || decreaseElementsSize) && (
                                        <h6
                                            style={{ color: `${data.company ? `${data.designTextColor}` : '#14131580'}` }}
                                            className={`tracking-[-0.02em] font-bold ${decreaseElementsSize ? 'text-[16px] leading-[20px]' : 'text-[19px] leading-[23px]'}`}
                                        >
                                            {data.company ? data.company : t("company")}
                                        </h6>
                                    )}
                                </div>
                            )}
                            <div className={`max-w-[600px] flex flex-col w-full ${decreaseElementsSize ? 'gap-[8px] p-[8px] pt-[16px]' : 'gap-[16px] p-[16px] pt-[48px]'}`}>
                                {(data.subject || decreaseElementsSize) && (
                                    <h6
                                        style={{ color: `${data.subject ? `${data.designTextColor}` : '#14131580'}` }}
                                        className={`tracking-[-0.04em] break-words ${decreaseElementsSize ? 'text-[20px] leading-[28px] font-semibold' : 'text-[34px] leading-[40px] font-bold'}`}
                                    >
                                        {data.subject ? data.subject : t("subject")}
                                    </h6>
                                )}
                                <p
                                    style={{ color: `${data.text ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`break-words ${decreaseElementsSize ? 'text-[14px] leading-[17px]' : 'text-[20px] leading-[28px]'}`}
                                >
                                    {data.text ? data.text : t("message")}
                                </p>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["Text"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["Text"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default TextPreview