import React from 'react'
import { useTranslation } from 'react-i18next'

const Pagination = ({ pages, activePage, handlePage }) => {
    const { t } = useTranslation()

    return (
        <div className='pagination-wrapper'>
            {pages.length < 5 ? (
                <>
                    <button
                        onClick={() => handlePage(String(Number(activePage) - 1))}
                        disabled={activePage === '1'}
                        className='pagination-prev-next-button'
                    >
                        {t("prev")}
                    </button>
                    {pages.map((item, idx) => (
                        <button
                            onClick={() => handlePage(item)}
                            key={idx}
                            className={`pagination-number ${activePage === item ? 'active' : ''}`}
                        >
                            {item}
                        </button>
                    ))}
                    <button
                        onClick={() => handlePage(String(Number(activePage) + 1))}
                        disabled={String(pages.length) === activePage ? true : false}
                        className='pagination-prev-next-button'
                    >
                        {t("next")}
                    </button>
                </>
            ) : (
                <>
                    <button
                        onClick={() => handlePage(String(Number(activePage) - 1))}
                        disabled={activePage === '1'}
                        className='pagination-prev-next-button'
                    >
                        {t("prev")}
                    </button>
                    {activePage === '1' || activePage === '2' ? (
                        <>
                            <button
                                onClick={() => handlePage('1')}
                                className={`pagination-number ${activePage === '1' ? 'active' : ''}`}
                            >
                                1
                            </button>
                            <button
                                onClick={() => handlePage('2')}
                                className={`pagination-number ${activePage === '2' ? 'active' : ''}`}
                            >
                                2
                            </button>
                            <button
                                onClick={() => handlePage('3')}
                                className={`pagination-number ${activePage === '3' ? 'active' : ''}`}
                            >
                                3
                            </button>
                            <button className={`pagination-number`}>...</button>
                            <button
                                onClick={() => handlePage(String(pages.length))}
                                className={`pagination-number ${activePage === pages.length ? 'active' : ''}`}
                            >
                                {pages.length}
                            </button>
                        </>
                    ) : (
                        <>
                            {pages.map((item, idx) => {
                                if (String(idx + 1) === String(pages.length)) {
                                    return null
                                } else if (Number(activePage) - 1 <= Number(item) && Number(activePage) + 1 >= Number(item)) {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => handlePage(item)}
                                            className={`pagination-number ${activePage === item ? 'active' : ''}`}
                                        >
                                            {item}
                                        </button>
                                    )
                                } else {
                                    return null
                                }
                            })}
                            <button className={`pagination-number`}>...</button>
                            <button
                                onClick={() => handlePage(String(pages.length))}
                                className={`pagination-number ${activePage === String(pages.length) ? 'active' : ''}`}
                            >
                                {pages.length}
                            </button>
                        </>
                    )}
                    <button
                        onClick={() => handlePage(String(Number(activePage) + 1))}
                        disabled={String(pages.length) === activePage ? true : false}
                        className='pagination-prev-next-button'
                    >
                        {t("next")}
                    </button>
                </>
            )}
        </div>
    )
}

export default Pagination