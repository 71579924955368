import React from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircle } from 'react-icons/io5'

import { COLOR_PALETTE_LIST } from '../../helpers/constants'

const EditDesignColorPalette = ({ selectedColorPalette, handleSelectPalette }) => {
    const { t } = useTranslation()

    const handleSelectColorPalette = (palette) => {
        handleSelectPalette(palette)
    }

    return (
        <div className='edit-design-color-palette-block'>
            <span className='qr-customize-option-label'>{t("colorPalette")}</span>
            <div className='color-palette-list'>
                {COLOR_PALETTE_LIST.map((palette) => (
                    <div
                        key={palette.id}
                        onClick={() => handleSelectColorPalette(palette)}
                        className={`color-palette-item ${selectedColorPalette === palette.id ? 'active' : ''}`}
                    >
                        <div style={{ backgroundColor: palette.primary_color }} className='color-palette-item-color-block'></div>
                        <div style={{ backgroundColor: palette.secondary_color }} className='color-palette-item-color-block'></div>
                        <div style={{ backgroundColor: palette.text_color }} className='color-palette-item-color-block'></div>
                        <div style={{ backgroundColor: palette.button_color }} className='color-palette-item-color-block'></div>
                        {selectedColorPalette === palette.id && <IoCheckmarkCircle size={20} fill="#9747FF" className='color-palette-item-selected-icon' />}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EditDesignColorPalette