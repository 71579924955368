import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import EmptyImage from '../../assets/icons/code-create/empty-image.svg'
import EmptyLogo from '../../assets/icons/code-create/empty-logo.svg'

const ImagePreview = ({ data, isContentAdded = true, version = 1 }) => {
    const { t } = useTranslation()

    const decreaseElementsSize = window.location.pathname.includes('/code') ? false : true

    const onClickRedirect = (link) => {
        const editedLink = link.includes('https://') || link.includes('http://') ? link : `https://${link}`
        const linkWithoutSpaces = editedLink.replaceAll(' ', '')

        window.open(linkWithoutSpaces, '_blank')
    }

    const imagesMemo = useMemo(() => {
        if (data?.files?.length > 0) {
            return Array.from(data.files).map((image, idx) => (
                <div key={idx} className='image-preview-block-img-wrapper'>
                    <img className='image-preview-img' src={image.image ? image.image : URL.createObjectURL(image)} alt="" />
                </div>
            ))
        }
    }, [data.files])

    return (
        <>
            {isContentAdded ? (
                <>
                    {version === 1 ? (
                        <div className='image-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
                            <div className='image-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                                <span className='image-previwe-title' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.title ? data.title : t("albumTitle")}
                                </span>
                                <span className='image-preview-description' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.description ? data.description : t("description")}
                                </span>
                                <button onClick={() => onClickRedirect(data.redirect)} className='image-preview-button' style={{ backgroundColor: data.designButtonColor }}>
                                    {data.button ? data.button : 'CTA'}
                                </button>
                            </div>
                            <div className='image-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                                {data.files && data.files.length > 0 ? (
                                    imagesMemo
                                ) : (
                                    <div className='image-preview-block-img-wrapper'></div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ backgroundColor: `${data.designPrimaryColor}` }}
                            className={`flex flex-col w-full items-center ${decreaseElementsSize ? 'absolute top-[0px] left-0 pt-[20px] min-h-full' : 'min-h-[100dvh]'}`}
                        >
                            {(data.logo || decreaseElementsSize) && (
                                <div
                                    className={`flex items-center justify-center px-2 border-solid border-b-[1px] border-[#E2E8F0] gap-2 w-full ${decreaseElementsSize ? 'h-[38px]' : 'h-[44px]'}`}
                                >
                                    <img
                                        className={`${decreaseElementsSize ? 'h-[20px]' : 'h-[28px]'}`}
                                        src={
                                            data.logo ?
                                                (typeof (data.logo) === 'string'
                                                    ? data.logo
                                                    : URL.createObjectURL(data.logo))
                                                : EmptyLogo
                                        }
                                        alt=""
                                    />
                                </div>
                            )}
                            <div className={`max-w-[600px] flex flex-col items-center w-full ${decreaseElementsSize ? 'p-[8px] pt-[20px] gap-[8px]' : 'p-[16px] pt-[40px] gap-[16px]'}`}>
                                <h6
                                    style={{ color: `${data.title ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`text-center tracking-[-0.04em] break-words ${decreaseElementsSize ? 'text-[20px] leading-[28px] font-semibold' : 'text-[34px] leading-[40px] font-bold'}`}
                                >
                                    {data.title ? data.title : t("imageTitle")}
                                </h6>
                                <p
                                    style={{ color: `${data.description ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`break-words text-center ${decreaseElementsSize ? 'text-[14px] leading-[17px]' : 'text-[20px] leading-[24px]'}`}
                                >
                                    {data.description ? data.description : t("description")}
                                </p>
                                <button
                                    onClick={() => onClickRedirect(data.redirect)}
                                    style={{
                                        backgroundColor: data.designButtonColor,
                                        color: data.designSecondaryColor
                                    }}
                                    className={`flex items-center justify-center gap-[6px] w-full rounded-lg font-semibold ${decreaseElementsSize ? 'text-[12px] h-[26px] mt-[4px] mb-[20px]' : 'text-[16px] h-[40px] mt-[8px] mb-[40px]'}`}
                                >
                                    {data.button ? data.button : t("button")}
                                </button>
                                {Array.from(data.files).length > 0 ? Array.from(data.files).map((image, idx) => (
                                    <div key={idx} className='w-full flex flex-col gap-[16px]'>
                                        <img
                                            className='w-full'
                                            src={
                                                image.image
                                                    ? image.image
                                                    : URL.createObjectURL(image)
                                            }
                                            alt=""
                                        />
                                    </div>
                                )) : (
                                    <div className='w-full flex flex-col gap-[16px]'>
                                        <img
                                            className='w-full rounded-lg'
                                            src={EmptyImage}
                                            alt=""
                                        />
                                        <img
                                            className='w-full rounded-lg'
                                            src={EmptyImage}
                                            alt=""
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["Image"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["Image"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default ImagePreview