import React, { useMemo } from 'react'

import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'

import PreviewNavigationPhone from '../common/PreviewNavigationPhone/PreviewNavigationPhone'
import WebsitePreview from '../PreviewForms/WebsitePreview'
import VideoPreview from '../PreviewForms/VideoPreview'
import ImagePreview from '../PreviewForms/ImagePreview'
import TextPreview from '../PreviewForms/TextPreview'
import WifiPreview from '../PreviewForms/WifiPreview'
import Mp3Preview from '../PreviewForms/Mp3Preview'
import PdfPreview from '../PreviewForms/PdfPreview'

const DashboardCreationPreview = () => {
    const {
        userQrType,
        dynamicWebsiteStyles,
        dynamicPdfStyles,
        dynamicMP3Styles,
        dynamicVideoStyles,
        dynamicImageStyles,
        dynamicTextStyles,
        dynamicWifiStyles,
        designPrimaryColor,
        designSecondaryColor,
        designTextColor,
        designButtonColor,
        isContentAdded,
    } = useUserCodeCreationContext()

    const pageContent = useMemo(() => {
        switch (userQrType) {
            case 'URL': {
                return <WebsitePreview
                    data={dynamicWebsiteStyles}
                    isContentAdded={isContentAdded}
                />
            }
            case 'PDF': {
                return <PdfPreview
                    data={{
                        ...dynamicPdfStyles,
                        designPrimaryColor,
                        designSecondaryColor,
                        designTextColor,
                        designButtonColor
                    }}
                    isContentAdded={isContentAdded}
                    version={2}
                />
            }
            case 'MP3': {
                return <Mp3Preview
                    data={{
                        ...dynamicMP3Styles,
                        designPrimaryColor,
                        designSecondaryColor,
                        designTextColor,
                        designButtonColor
                    }}
                    isContentAdded={isContentAdded}
                    version={2}
                />
            }
            case 'Video': {
                return <VideoPreview
                    data={{
                        ...dynamicVideoStyles,
                        designPrimaryColor,
                        designSecondaryColor,
                        designTextColor,
                        designButtonColor
                    }}
                    isContentAdded={isContentAdded}
                    version={2}
                />
            }
            case 'Image': {
                return <ImagePreview
                    data={{
                        ...dynamicImageStyles,
                        designPrimaryColor,
                        designSecondaryColor,
                        designTextColor,
                        designButtonColor
                    }}
                    isContentAdded={isContentAdded}
                    version={2}
                />
            }
            case 'Text': {
                return <TextPreview
                    creationPreview={true}
                    data={{
                        ...dynamicTextStyles,
                        designPrimaryColor,
                        designSecondaryColor,
                        designTextColor,
                        designButtonColor
                    }}
                    isContentAdded={isContentAdded}
                    version={2}
                />
            }
            case 'Wi-Fi': {
                return <WifiPreview
                    data={{
                        ...dynamicWifiStyles,
                        designPrimaryColor,
                        designSecondaryColor,
                        designTextColor,
                        designButtonColor
                    }}
                    isContentAdded={isContentAdded}
                    version={2}
                />
            }
            default: {
                return ''
            }
        }
    }, [userQrType, dynamicWebsiteStyles, dynamicPdfStyles, dynamicMP3Styles, dynamicVideoStyles, dynamicImageStyles, dynamicTextStyles, dynamicWifiStyles, designPrimaryColor, designSecondaryColor, designTextColor, designButtonColor, isContentAdded])

    return (
        <div className={`${isContentAdded ? 'preview-section-content' : 'overflow-hidden'}`}>
            {isContentAdded && (
                <PreviewNavigationPhone color={'#000'} />
            )}
            {pageContent}
        </div>
    )
}

export default DashboardCreationPreview