import { combineReducers } from "@reduxjs/toolkit"

// Reducers
import appReducer from './appSlice'

// RTK Query API
import { api } from "../api/api"

const rootReducers = combineReducers({
    app: appReducer,
    [api.reducerPath]: api.reducer,
})

export default rootReducers
