import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import rootReducers from "./rootReducer";
import { api } from "../api/api";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [api.reducerPath],
    stateReconciler: (state, incoming) => {
        let expirationTime = undefined;

        if (state?.app?.user?.stsTokenManager?.expirationTime) {
            expirationTime = new Date(state?.app?.user?.stsTokenManager?.expirationTime)
        }

        if (!expirationTime || expirationTime < new Date()) {
            return incoming
        } else {
            return { ...incoming, app: state.app }
        }
    }
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat([
        api.middleware,
    ]),
})
