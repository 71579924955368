import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { convert12HourTo24Hour, convert24HourTo12Hour } from '../../helpers/functions'
import { useUpdateUserSettingsMutation } from '../../api/api'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import ArrowBottom from '../../assets/icons/settings/arrow-bottom.svg'

const NotificationSettings = ({ isMobile, setActiveTab, settings }) => {
    const [notificationsTime, setNotificationsTime] = useState("")
    const [selectedTimezone, setSelectedTimezone] = useState("")
    const [showTimezoneMenu, setShowTimezoneMenu] = useState(false)

    const timezoneMenuRef = useRef(null)

    const { t } = useTranslation()

    const [updateSettings] = useUpdateUserSettingsMutation()

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    const handleChangeNotificationTime = (time) => {
        setNotificationsTime(time)
    }

    const handleChangeTimezone = (newTimezone) => {
        setSelectedTimezone(newTimezone)
        setShowTimezoneMenu(false)

        const res = convert12HourTo24Hour(notificationsTime, newTimezone)

        updateSettings({
            data: {
                scheduled_at: res,
            }
        })
    }

    const handleAnalyticsItemsChange = (type) => {
        if (type === 'allTime') {
            updateSettings({
                data: {
                    scheduled_notifications: false,
                }
            })
        } else {
            updateSettings({
                data: {
                    scheduled_notifications: true,
                }
            })
        }
    }

    const handleChangeNotifications = (field) => {
        updateSettings({
            data: {
                [field]: !settings[field]
            }
        })
    }

    useEffect(() => {
        const interval = setTimeout(() => {
            if (notificationsTime.length > 5 || notificationsTime.length < 4) return null

            const response = convert12HourTo24Hour(notificationsTime, selectedTimezone)

            updateSettings({
                data: {
                    scheduled_at: response,
                }
            })
        }, 2000)

        return () => clearTimeout(interval)
    }, [notificationsTime, selectedTimezone, updateSettings])

    useEffect(() => {
        if (settings?.scheduled_notifications) {
            const resultDate = convert24HourTo12Hour(settings?.scheduled_at)
            const [time, format] = resultDate.split(' ')

            setNotificationsTime(time)
            setSelectedTimezone(format)
        }
    }, [settings])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (timezoneMenuRef.current && !timezoneMenuRef.current.contains(event.target)) {
                setShowTimezoneMenu(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className='settings-tab-wrapper'>
            {isMobile ? (
                <div className='settings-mobile-tab-header'>
                    <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                        {isMobile && (
                            <img width={20} src={overviewPanelMobileBack} alt="" />
                        )}
                        {t("notifications")}
                        {isMobile && (
                            <div style={{ width: '20px' }} />
                        )}
                    </h5>
                    <span className='settings-tab-description'>
                        {t("qrCodeDeveloperMayStillSendYouImportantNotifications")}
                    </span>
                </div>
            ) : (
                <>
                    <h5 className='settings-tab-title'>
                        Notifications
                    </h5>
                    <span className='settings-tab-description'>
                        {t("qrCodeDeveloperMayStillSendYouImportantNotifications")}
                    </span>
                </>
            )}
            <div className='notification-settings-switch-block'>
                <div className='notification-settings-left-side'>
                    <h5 className='notification-settings-title'>{t("emailNotifications")}</h5>
                    <span className='notification-settings-description'>
                        {t("optInToReceiveNotificationsToYourEmailIfYouOpt")}
                    </span>
                </div>
                <div className='notification-settings-right-side'>
                    <div
                        onClick={() => handleChangeNotifications('email_notifications')}
                        className={`overview-panel-stats-header-unique-scans-switch ${settings?.email_notifications ? 'active' : ''}`}
                    >
                        <div className={`overview-panel-switch-circle ${settings?.email_notifications ? 'active' : ''}`}></div>
                    </div>
                </div>
            </div>
            <h6 className='setting-tab-sub-title'>{t("general")}</h6>
            <div className='notification-settings-switch-group-block'>
                <div className='notification-settings-switch-group-block-item'>
                    <div className='notification-settings-left-side'>
                        <h5 className='notification-settings-title'>{t("accountInformation")}</h5>
                        <span className='notification-settings-description'>
                            {t("optInToReceiveNotificationsForAllAccountRelatedUpdates")}
                        </span>
                    </div>
                    <div className='notification-settings-right-side'>
                        <div
                            onClick={() => handleChangeNotifications('account_notifications')}
                            className={`overview-panel-stats-header-unique-scans-switch ${settings?.account_notifications ? 'active' : ''}`}
                        >
                            <div className={`overview-panel-switch-circle ${settings?.account_notifications ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
                <div className='notification-settings-switch-group-block-item'>
                    <div className='notification-settings-left-side'>
                        <h5 className='notification-settings-title'>{t("generalCommunications")}</h5>
                        <span className='notification-settings-description'>
                            {t("optInToReceiveNotificationsOnImportantAnnouncements")}
                        </span>
                    </div>
                    <div className='notification-settings-right-side'>
                        <div
                            onClick={() => handleChangeNotifications('general_notifications')}
                            className={`overview-panel-stats-header-unique-scans-switch ${settings?.general_notifications ? 'active' : ''}`}
                        >
                            <div className={`overview-panel-switch-circle ${settings?.general_notifications ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
                <div className='notification-settings-switch-group-block-item'>
                    <div className='notification-settings-left-side'>
                        <h5 className='notification-settings-title'>{t("feedbackAndSurveys")}</h5>
                        <span className='notification-settings-description'>
                            {t("optInToReceiveNotificationsToProvideUserFeedback")}
                        </span>
                    </div>
                    <div className='notification-settings-right-side'>
                        <div
                            onClick={() => handleChangeNotifications('feedback_and_surveys_notifications')}
                            className={`overview-panel-stats-header-unique-scans-switch ${settings?.feedback_and_surveys_notifications ? 'active' : ''}`}
                        >
                            <div className={`overview-panel-switch-circle ${settings?.feedback_and_surveys_notifications ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
            </div>
            <h6 className='setting-tab-sub-title'>{t("analytics")}</h6>
            <div className='profile-settings-analytics-group'>
                <div style={{ borderBottom: '0px' }} className='notification-settings-switch-group-block-item'>
                    <div className='notification-settings-left-side'>
                        <h5 className='notification-settings-title'>{t("scanActivity")}</h5>
                        <span className='notification-settings-description'>
                            {t("optInToReceiveNotificationsOfScanActivity")}
                        </span>
                    </div>
                    <div className='notification-settings-right-side'>
                        <div
                            onClick={() => handleChangeNotifications('scan_activity')}
                            className={`overview-panel-stats-header-unique-scans-switch ${settings?.scan_activity ? 'active' : ''}`}
                        >
                            <div className={`overview-panel-switch-circle ${settings?.scan_activity ? 'active' : ''}`}></div>
                        </div>
                    </div>
                </div>
                {settings?.scan_activity && (
                    <>
                        <div className='profile-settings-analytics-check-item-block'>
                            <div className='profile-settings-analytics-check-text'>
                                <div
                                    onClick={() => handleAnalyticsItemsChange('allTime')}
                                    className={`profile-settings-checkbox ${!settings?.scheduled_notifications ? 'checked' : ''}`}
                                />
                                <h5 className='notification-settings-title'>{t("notifyOfAllScanActivity")}</h5>
                            </div>
                            <span style={{ paddingLeft: '28px' }} className='notification-settings-description'>
                                {t("receiveANotificationEachTimeAQRCodeIsScanned")}
                            </span>
                        </div>
                        <div className='profile-settings-analytics-check-item-block'>
                            <div className='profile-settings-analytics-check-text'>
                                <div
                                    onClick={() => handleAnalyticsItemsChange('setTime')}
                                    className={`profile-settings-checkbox ${settings?.scheduled_notifications ? 'checked' : ''}`}
                                />
                                <h5 className='notification-settings-title'>{t("scheduledNotifications")}</h5>
                            </div>
                            <span style={{ paddingLeft: '28px' }} className='notification-settings-description'>
                                {t("addASetTimeToReceiveScanNotifications")}
                            </span>
                            {settings?.scheduled_notifications && (
                                <div className='scheduled-notifications-setup'>
                                    <span className='schedule-notifications-text'>{t("sendAllNotificationsOfScanActivityEveryDayAt")}</span>
                                    <div className='schedule-notification-wrapper'>
                                        <input
                                            onChange={(e) => handleChangeNotificationTime(e.target.value)}
                                            value={notificationsTime}
                                            className='schedule-notifications-time-picker'
                                        />
                                        <div ref={timezoneMenuRef} className='schedule-notifications-timezone-wrapper'>
                                            <div onClick={() => setShowTimezoneMenu(!showTimezoneMenu)} className='schedule-notifications-timezone-picker'>
                                                {selectedTimezone}
                                                <img src={ArrowBottom} alt="" />
                                            </div>
                                            {showTimezoneMenu && (
                                                <div className='schedule-notification-timezone-menu'>
                                                    <div
                                                        onClick={() => handleChangeTimezone('AM')}
                                                        className='schedule-notification-timezone-menu-item'
                                                    >
                                                        AM
                                                    </div>
                                                    <div
                                                        onClick={() => handleChangeTimezone('PM')}
                                                        className='schedule-notification-timezone-menu-item'
                                                    >
                                                        PM
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div >
    )
}

export default NotificationSettings