import React, { useEffect, useState } from 'react'

import { AVAILABLE_LANGUAGES_OPTIONS } from '../SettingsComponents/ProfileSettings'

import SettingsSearch from '../../assets/icons/settings/search.svg'

const ProfileMobileSelectLanguage = ({ selectedLanguage, mobileSelectLanguageRef, setExpandLanguages, setSelectedLanguage }) => {
    const [preselectedLanguage, setPreselectedLanguage] = useState(selectedLanguage)
    const [filteredLanguages, setFilteredLanguages] = useState(AVAILABLE_LANGUAGES_OPTIONS)
    const [searchValue, setSearchValue] = useState('')

    const handleClear = () => {
        setPreselectedLanguage(selectedLanguage)
    }

    const handleChangeLanguage = () => {
        if (selectedLanguage !== preselectedLanguage) {
            setSelectedLanguage(preselectedLanguage)
            setExpandLanguages(false)
        }
    }

    useEffect(() => {
        setFilteredLanguages(AVAILABLE_LANGUAGES_OPTIONS.filter(language => language.toLowerCase().includes(searchValue.toLowerCase())))
    }, [searchValue])

    return (
        <div style={{ zIndex: '101' }} className='link-filter-background analytics-mobile-settings'>
            <div ref={mobileSelectLanguageRef} className='link-filter-block'>
                <div onClick={() => setExpandLanguages(false)} className='mobile-tray-horizontal-bar'>
                    <div className='mobile-tray-horizontal-bar-element'></div>
                </div>
                <div className='mobile-filter-expand-block-header'>
                    <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>Language</span>
                    <span onClick={handleClear} className={`analytics-filter-block-header-clear ${selectedLanguage !== preselectedLanguage ? 'active' : ''}`}>
                        Clear
                    </span>
                </div>
                <span className='link-filter-title-underline'></span>
                <div className='profile-mobile-select-language-list'>
                    <div className='profile-settings-select-language-search-block mb-2'>
                        <img src={SettingsSearch} alt="" />
                        <input
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder='Search'
                            className='profile-settings-select-language-search-input'
                            type="text"
                        />
                    </div>
                    {filteredLanguages.map((language, index) => (
                        <div
                            key={index}
                            onClick={() => { setPreselectedLanguage(language) }}
                            className='account-deletion-feedback-modal-reason flex items-center justify-between p-0 hover:bg-transparent'
                        >
                            {language}
                            <span className={`profile-mobile-selected-language-circle ${preselectedLanguage === language ? 'active' : ''}`}>
                                <span className='profile-mobile-selected-language-nested-circle'></span>
                            </span>
                        </div>
                    ))}
                    <button
                        disabled={selectedLanguage !== preselectedLanguage ? false : true}
                        onClick={handleChangeLanguage}
                        className={`analytics-filters-apply-btn mt-2 ${selectedLanguage !== preselectedLanguage ? 'active' : ''}`}
                    >
                        Change Language
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileMobileSelectLanguage