import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'swiper/modules';

import useReturnTestimonials from '../../hooks/useReturnTestimonials';
import { useViewport } from '../../hooks/useViewport'

import TestimonialItem from './TestimonialItem'
import CarouselControl from './CarouselControl'

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';

const Testimonials = () => {
    const prevButtonRef = useRef(null)
    const nextButtonRef = useRef(null)
    const swiperRef = useRef(null)

    const navigate = useNavigate()

    const { TESTIMONIALS_LIST } = useReturnTestimonials()
    const { isMobile } = useViewport()
    const { t } = useTranslation()

    const handleNavigateToCreation = () => {
        navigate('/generate-code')
    }

    return (
        <section className='testimonials-section-wrapper'>
            <h5 className='testimonials-section-title'>{t("customerReviews")}</h5>
            <p className='testimonials-section-description'>
                {t("seeWhatOurCustomersAreSaying")}
            </p>
            <div className='testimonials-section-carousel-wrapper'>
                <Swiper
                    ref={swiperRef}
                    slidesPerView={isMobile ? 1 : 3}
                    spaceBetween={16}
                    pagination={isMobile ? true : false}
                    loop={true}
                    navigation={{
                        enabled: !isMobile,
                        prevEl: prevButtonRef.current,
                        nextEl: nextButtonRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.nextEl = nextButtonRef.current;
                        swiper.params.navigation.prevEl = prevButtonRef.current;
                        swiperRef.current = swiper;
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    {TESTIMONIALS_LIST.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <TestimonialItem testimonial={testimonial} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <CarouselControl onClick={() => swiperRef.current?.slidePrev()} refElement={prevButtonRef} position={"left"} />
                <CarouselControl onClick={() => swiperRef.current?.slideNext()} refElement={nextButtonRef} position={"right"} />
            </div>
            <div className='w-full flex items-center justify-center mt-2 lg:mt-0'>
                <button onClick={handleNavigateToCreation} className='introducing-section-create-button mt-0'>
                    {t("createQRCode")}
                </button>
            </div>
        </section>
    )
}

export default Testimonials