import React, { useEffect } from 'react'
import { ChromePicker } from 'react-color'
import Switch from '../common/Switch/Switch'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import { FaYoutube } from 'react-icons/fa'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import newTwitterPng from '../../assets/icons/new-twitter.png'
import newTwitterInactivePng from '../../assets/icons/new-twitter-inactive.png'
import newTwitterActivePng from '../../assets/icons/new-twitter-active.png'
import facebookSvg from '../../assets/icons/facebook.svg'
import youtubePng from '../../assets/images/youtube-png-icon.png'
import instagramPng from '../../assets/images/instagram-png-icon.png'
import { useViewport } from '../../hooks/useViewport'
import { GoCircleSlash } from 'react-icons/go'
import { BiCircle, BiSquare, BiSquareRounded } from 'react-icons/bi'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export const icons = [
    { id: 1, link: '', selectSvg: <GoCircleSlash fill='#334155' size={40} />, selectSvgActive: <GoCircleSlash fill='#9747FF' size={40} /> },
    { id: 2, link: youtubePng, selectSvg: <FaYoutube fill='#334155' size={40} />, selectSvgActive: <FaYoutube fill='#9747FF' size={40} /> },
    { id: 3, link: facebookSvg, selectSvg: <BsFacebook fill='#334155' size={36} />, selectSvgActive: <BsFacebook fill='#9747FF' size={36} /> },
    { id: 4, link: newTwitterPng, selectSvg: <img width={40} height={40} src={newTwitterInactivePng} alt="" />, selectSvgActive: <img width={40} height={40} src={newTwitterActivePng} alt="" /> },
    { id: 5, link: instagramPng, selectSvg: <BsInstagram fill='#334155' size={36} />, selectSvgActive: <BsInstagram fill='#9747FF' size={36} /> },
]

export const frames = [
    { id: 1, svgDefault: <GoCircleSlash fill='#334155' size={37} />, svgActive: <GoCircleSlash fill='#9747FF' size={37} /> },
    { id: 2, svgDefault: <BiSquare fill='#334155' size={40} />, svgActive: <BiSquare fill='#9747FF' size={40} /> },
    { id: 3, svgDefault: <BiSquareRounded fill='#334155' size={37} />, svgActive: <BiSquareRounded fill='#9747FF' size={37} /> },
    { id: 4, svgDefault: <BiCircle fill='#334155' size={40} />, svgActive: <BiCircle fill='#9747FF' size={40} /> },
]

const CustomizeQrOption = ({ pickerForegroundRef, pickerBackgroundRef }) => {
    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const {
        expandColor, setExpandColor,
        useBackground, setUseBackground,
        expandIcons, setExpandIcons,
        expandFrames, setExpandFrames,
        foregroundColor, backgroundColor,
        setForegroundColor, setBackgroundColor,
        selectForegroundColor, selectBackgroundColor,
        image, setImage,
        handleChangeForegroundColor,
        handleChangeBackgroundColor,
        handleForegroundColor,
        handleBackgroundColor,
        handleResetToDefault,
        frame, setFrame, scanText,
        setScanText, scanColor, setScanColor,
        scanColorRef, selectScanColor, setSelectScanColor,
        scanFont, setScanFont,
    } = useUserCodeCreationContext()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (scanColorRef.current && !scanColorRef.current.contains(event.target)) {
                setSelectScanColor(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [scanColorRef, setSelectScanColor]);

    return (
        <div className='qr-code-properties-block'>
            <div className='qr-code-customize-section'>
                <div className='qr-customize-option-header'>
                    <span className='qr-customize-option-title'>
                        {t("color")}
                        <span className='creation-step-container-default'>{t("optional")}</span>
                    </span>
                    <div className='qr-customize-option-header-actions'>
                        {expandColor && (
                            <button onClick={handleResetToDefault} className='reset-to-default-button'>{isMobile ? t("reset") : t("resetToDefault")}</button>
                        )}
                        <span className='creation-step-container-mobile'>{t("optional")}</span>
                        <div onClick={() => setExpandColor(!expandColor)} className='qr-option-customize-expand-button-with-background'>
                            {expandColor ? (
                                <MdKeyboardArrowUp size={28} fill='#334155' />
                            ) : (
                                <MdKeyboardArrowDown size={28} fill="#334155" />
                            )}
                        </div>
                    </div>
                </div>
                {expandColor && (
                    <div className='qr-colors-customize-content-wrapper'>
                        <div className='qr-colors-customize-block'>
                            <span className='qr-customize-option-label'>{t("foregroundColor")}</span>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-change-input-block'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard'
                                        value={foregroundColor}
                                        onChange={(e) => setForegroundColor(e.target.value)}
                                    />
                                </div>
                                <div
                                    onClick={handleForegroundColor}
                                    className='generator-color-block me-2'
                                    style={{ backgroundColor: `${foregroundColor}` }}
                                ></div>
                                {selectForegroundColor && (
                                    <div ref={pickerForegroundRef} className='color-picker'>
                                        <ChromePicker
                                            onChange={handleChangeForegroundColor}
                                            color={foregroundColor}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='qr-colors-customize-block'>
                            <span className='qr-customize-option-label flex items-center justify-between'>
                                {t("backgroundColor")}
                                <Switch value={useBackground} setValue={setUseBackground} />
                            </span>
                            {useBackground && (
                                <div className='qr-color-picker-block'>
                                    <div className='qr-color-change-input-block'>
                                        <input
                                            type='text'
                                            className='form-input-dashboard'
                                            value={backgroundColor}
                                            onChange={(e) => setBackgroundColor(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className='generator-color-block me-2'
                                        onClick={handleBackgroundColor}
                                        style={{ backgroundColor: `${backgroundColor}` }}
                                    ></div>
                                    {selectBackgroundColor && (
                                        <div ref={pickerBackgroundRef} className='color-picker'>
                                            <ChromePicker
                                                onChange={handleChangeBackgroundColor}
                                                color={backgroundColor}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className='qr-code-customize-section'>
                <div className='qr-customize-option-header'>
                    <span className='qr-customize-option-title'>
                        {t("socialIcons")}
                        <span className='creation-step-container-default'>{t("optional")}</span>
                    </span>
                    <div className='qr-customize-option-header-actions'>
                        <span className='creation-step-container-mobile'>{t("optional")}</span>
                        <div onClick={() => setExpandIcons(!expandIcons)} className='qr-option-customize-expand-button-with-background'>
                            {expandIcons ? (
                                <MdKeyboardArrowUp size={28} fill='#334155' />
                            ) : (
                                <MdKeyboardArrowDown size={28} fill="#334155" />
                            )}
                        </div>
                    </div>
                </div>
                {expandIcons && (
                    <div className='qr-colors-customize-content-wrapper'>
                        <div className='qr-color-picker-block'>
                            {icons && icons.length > 0 && icons.map((el) => (
                                <div
                                    onClick={() => setImage(el.link)}
                                    key={el.id}
                                    className={el.link === image ? 'generator-image-block-selected generator-image-block' : 'generator-image-block'}
                                >
                                    {el.link === image ? el.selectSvgActive : el.selectSvg}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className='qr-code-customize-section'>
                <div className='qr-customize-option-header'>
                    <span className='qr-customize-option-title'>
                        {t("frames")}
                        <span className='creation-step-container-default'>{t("optional")}</span>
                    </span>
                    <div className='qr-customize-option-header-actions'>
                        <span className='creation-step-container-mobile'>{t("optional")}</span>
                        <div onClick={() => setExpandFrames(!expandFrames)} className='qr-option-customize-expand-button-with-background'>
                            {expandFrames ? (
                                <MdKeyboardArrowUp size={28} fill='#334155' />
                            ) : (
                                <MdKeyboardArrowDown size={28} fill="#334155" />
                            )}
                        </div>
                    </div>
                </div>
                {expandFrames && (
                    <div className='qr-colors-customize-content-wrapper'>
                        <div className='flex flex-col gap-6 w-full'>
                            <div className='qr-color-picker-block'>
                                <div className='qr-color-picker-block'>
                                    {frames && frames.length > 0 && frames.map((el) => (
                                        <div
                                            onClick={() => setFrame(el.id)}
                                            key={el.id}
                                            className={el.id === frame ? 'generator-image-block-selected generator-image-block' : 'generator-image-block'}
                                        >
                                            {el.id === frame ? el.svgActive : el.svgDefault}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='content-creation-input-wrapper'>
                                <label className='content-creation-input-label mb-1'>{t("text")}</label>
                                <div className='flex flex-col'>
                                    <input
                                        type='text'
                                        className='form-input-dashboard w-full'
                                        placeholder={t("scanMe")}
                                        value={scanText}
                                        onChange={(e) => setScanText(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='qr-colors-customize-content-wrapper'>
                                <div className='qr-colors-customize-block'>
                                    <span className='qr-customize-option-label'>{t("color")}</span>
                                    <div className='qr-color-picker-block'>
                                        <div className='qr-color-change-input-block'>
                                            <input
                                                type='text'
                                                className='form-input-dashboard'
                                                value={scanColor}
                                                onChange={(e) => setScanColor(e.target.value)}
                                            />
                                        </div>
                                        <div
                                            onClick={() => setSelectScanColor(true)}
                                            className='generator-color-block'
                                            style={{ backgroundColor: `${scanColor}` }}
                                        ></div>
                                        {selectScanColor && (
                                            <div ref={scanColorRef} className='color-picker'>
                                                <ChromePicker
                                                    onChange={(value) => setScanColor(value.hex)}
                                                    color={scanColor}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='qr-colors-customize-block'>
                                    <span className='qr-customize-option-label flex items-center justify-between'>
                                        {t("font")}
                                    </span>
                                    <div className='qr-color-picker-block'>
                                        <div className='qr-color-change-input-block w-full me-0 dropdown-font-wrapper'>
                                            <Dropdown>
                                                <Dropdown.Toggle className='bootstrap-default-dropdown' id="dropdown-basic">
                                                    {scanFont}  <MdKeyboardArrowDown size={28} fill="#334155" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => setScanFont('Sans-Serif')} className='bootstrap-default-item'>Sans-Serif</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setScanFont('Inter')} className='bootstrap-default-item'>Inter</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setScanFont('Roboto')} className='bootstrap-default-item'>Roboto</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
                }
            </div >
        </div >
    )
}

export default CustomizeQrOption