import React, { useMemo } from 'react'

import EditWebsiteUrl from '../EditContentForms/EditWebsiteUrl/EditWebsiteUrl'
import EditVideo from '../EditContentForms/EditVideo/EditVideo'
import EditImage from '../EditContentForms/EditImage/EditImage'
import EditWifi from '../EditContentForms/EditWifi/EditWifi'
import EditText from '../EditContentForms/EditText/EditText'
import EditPdf from '../EditContentForms/EditPdf/EditPdf'
import EditMp3 from '../EditContentForms/EditMp3/EditMp3'

const DashboardEditContent = ({ type, triggerValidation }) => {

    const content = useMemo(() => {
        switch (type) {
            case 'website_url': {
                return <EditWebsiteUrl triggerValidation={triggerValidation} />
            }
            case 'pdf': {
                return <EditPdf triggerValidation={triggerValidation} />
            }
            case 'mp3': {
                return <EditMp3 triggerValidation={triggerValidation} />
            }
            case 'video': {
                return <EditVideo triggerValidation={triggerValidation} />
            }
            case 'images': {
                return <EditImage triggerValidation={triggerValidation} />
            }
            case 'wifi': {
                return <EditWifi triggerValidation={triggerValidation} />
            }
            case 'text': {
                return <EditText triggerValidation={triggerValidation} />
            }
            default: {
                return ''
            }
        }
    }, [type, triggerValidation])

    return (
        <div className='w-full'>{content}</div>
    )
}

export default DashboardEditContent