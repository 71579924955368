import React from 'react'
import { Modal } from 'flowbite-react'

import { useTranslation } from 'react-i18next'

import paymentSuccessSvg from '../../assets/icons/payment-success.svg'
import newLogo from '../../assets/icons/newlogo.svg'

const ResetPasswordSendModal = ({ show, onClose }) => {
    const { t } = useTranslation()

    const handleGoToLogin = () => {
        onClose()
    }

    return (
        <Modal
            show={show}
            size="md"
            popup="false"
            position='center'
            onClose={onClose}
            className='modal-please-signup manage-plan-modal reset-password-sent-modal'
            style={{ height: '100vh' }}
        >
            <Modal.Body className='manage-plan-modal-body justify-center padding-top-24'>
                <div className='reset-header-logo-mobile'>
                    <div onClick={handleGoToLogin} className='flex items-center me-2 md:me-5'>
                        <img src={newLogo} alt="" />
                        <span className='header-logo-text'>QR Code Developer</span>
                    </div>
                </div>
                <div className='reset-password-sent-block'>
                    <div className='mobile-block'></div>
                    <div className='flex flex-col gap-6 items-center'>
                        <img style={{ width: '100px', height: '100px' }} className='upgrade-success-img' src={paymentSuccessSvg} alt="" />
                        <span className='upgrade-success-text m-0'>{t("emailWithResetLinkWasSentToEmail")}</span>
                    </div>
                    <button onClick={handleGoToLogin} className='upgrade-success-button continue-to-login-button'>{t("continueToLogin")}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ResetPasswordSendModal