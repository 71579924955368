import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { trialPeriodEndDate } from "../helpers/functions"

const useTrialDaysLeft = () => {
    const [trialDaysLeft, setTrialDaysLeft] = useState(7)
    const subscription = useSelector(state => state.app.subscription)

    useEffect(() => {
        if (subscription && subscription.plan === 'Free') {
            const result = trialPeriodEndDate(subscription.trial_end)

            if (subscription.new_flow) {
                if (subscription.scans <= 0) {
                    setTrialDaysLeft(0)
                } else {
                    setTrialDaysLeft(7)
                }
            } else {
                setTrialDaysLeft(result)
            }
        }
    }, [subscription])

    return {
        trialDaysLeft
    }
}

export default useTrialDaysLeft