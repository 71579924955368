import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { userSignOut } from '../firebase/auth/googleAuth';
import { setUser, signOut } from '../redux/appSlice';
import { auth } from '../firebase/config';

const BASE_URL = `https://api.qrcodeveloper.com/api/`

export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = getState()?.app?.user?.accessToken;
        const accessTokenStsManager = getState()?.app?.user?.stsTokenManager?.accessToken;

        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        } else if (accessTokenStsManager) {
            headers.set('Authorization', `Bearer ${accessTokenStsManager}`);
        }

        return headers;
    },
});

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const currentUser = auth.currentUser;

        if (currentUser) {
            const newToken = await currentUser.getIdToken(true);

            if (newToken) {
                api.dispatch(
                    setUser({
                        user: {
                            ...currentUser,
                            accessToken: newToken
                        }
                    })
                )

                result = await baseQuery(args, api, extraOptions);
            } else {
                await userSignOut()
                api.dispatch(signOut())
                api.dispatch(api.util.resetApiState());
            }
        }
    }

    return result;
};