import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BiSearch } from 'react-icons/bi'

import { USER_QR_TYPE_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { trialPeriodEndDate } from '../../helpers/functions'
import useReturnCodeSvg from '../../hooks/useReturnCodeSvg'
import { useViewport } from '../../hooks/useViewport'
import { useGlobalSearchQuery } from '../../api/api'

import headerNotificationIconSvg from '../../assets/icons/header-notification-icon.svg'
import recentCloseIconSvg from '../../assets/icons/recent-close-icon.svg'
import activeFilterSvg from '../../assets/icons/activefilter.svg'
import searchCloseSvg from '../../assets/icons/search-close.svg'
import questionMark from '../../assets/icons/question-mark.svg'

const DYNAMIC_KINDS = [
    'URL',
    'Text',
    'Wi-Fi',
    'PDF',
    'MP3',
    'Video',
    'Image',
]

const GlobalSearch = ({ setShowOnboardingModal, setShowNotificationsPanel, settings, isUnreadNotifications }) => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)

    const [searchValue, setSearchValue] = useState('')
    const [searchFilter, setSearchFilter] = useState('')
    const [timeFilter, setTimeFilter] = useState('newest')
    const [kindFilter, setKindFilter] = useState([])
    const [showPeriodFilter, setShowPeriodFilter] = useState(false)
    const [showGlobalSearch, setShowGlobalSearch] = useState(false)
    const [trialDaysLeft, setTrialDaysLeft] = useState(7)
    const [hiddenCodes, setHiddenCodes] = useState([])

    const searchRef = useRef(null)
    const blockSearchRef = useRef(null)
    const periodFilterRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()
    const { staticIcons, dynamicIcons } = useReturnCodeSvg()

    const navigate = useCustomNavigate()

    const { data: searchResult, refetch } = useGlobalSearchQuery({ searchValue: searchFilter, typeFilter: '', timeFilter, kindFilter }, { skip: !user || !showGlobalSearch })

    const handleChangeSearchValue = async (value) => {
        if (!value) {
            await setSearchFilter('')
            await setSearchValue('')
            refetch()
        } else {
            setSearchValue(value)
        }

    }

    const resetSearchValue = () => {
        setSearchValue('')
        searchRef.current.focus()
    }

    const handleShowGlobalSearch = () => {
        setShowGlobalSearch(true)
    }

    const handleClickOnCode = (id) => {
        if (trialDaysLeft > 0) {
            navigate(`/overview-code/${id}`)
            setShowGlobalSearch(false)
            setSearchValue('')
        }
    }

    const handleCancelSearch = () => {
        setShowGlobalSearch(false)
        setSearchValue('')
    }

    const handleChangeTimeFilter = (value) => {
        setTimeFilter(value)
        setShowPeriodFilter(false)
    }

    const handleCloseRecent = (id) => {
        setHiddenCodes([...hiddenCodes, id])
    }

    const handleOpenMobileNotificationsModal = () => {
        setShowNotificationsPanel((prev) => {
            return !prev
        })
    }

    const handleAddKindFilter = (kind) => {
        if (kindFilter.includes(kind)) {
            setKindFilter(kindFilter.filter((item) => item !== kind))
        } else {
            setKindFilter([...kindFilter, kind])
        }
    }

    useEffect(() => {
        if (showGlobalSearch && searchRef.current) {
            searchRef.current.focus()
        }
    }, [showGlobalSearch])

    useEffect(() => {
        if (subscription && subscription.plan === 'Free') {
            const result = trialPeriodEndDate(subscription.trial_end)

            if (subscription.new_flow) {
                if (subscription.scans <= 0) {
                    setTrialDaysLeft(0)
                } else {
                    setTrialDaysLeft(7)
                }
            } else {
                setTrialDaysLeft(result)

                if (result <= 0) {
                    navigate('/dashboard')
                }
            }
        }
    }, [subscription])

    useEffect(() => {
        const searchTimer = setTimeout(() => {
            setSearchFilter(searchValue)
        }, 1000)

        return () => {
            clearTimeout(searchTimer)
        }
    }, [searchValue])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (blockSearchRef.current && !blockSearchRef.current.contains(event.target)) {
                setShowGlobalSearch(false);
                setShowPeriodFilter(false);
            } else if (periodFilterRef.current && !periodFilterRef.current.contains(event.target)) {
                setShowPeriodFilter(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='global-search-wrapper'>
            {isMobile ? (
                <div style={{ gap: '8px' }} className='user-header-input-wrapper'>
                    <div style={{ marginRight: '-3px' }} className='cursor-pointer' onClick={() => setShowOnboardingModal(true)}>
                        <img src={questionMark} alt="" />
                    </div>
                    <div className='navigation-control-icon-block mr-0' onClick={handleOpenMobileNotificationsModal} >
                        {((settings?.pending_notifications && !settings?.scheduled_notifications) ||
                            (settings?.scheduled_notifications && isUnreadNotifications)) && (
                                <div className='header-notifications-active'></div>
                            )}
                        <img src={headerNotificationIconSvg} alt="" />
                    </div>
                    <BiSearch onClick={handleShowGlobalSearch} className='mt-1 cursor-pointer' fill='#E0C8FF' size={26} />
                </div>
            ) : (
                <div style={{ minWidth: '320px' }} className='user-header-input-wrapper justify-start'>
                    <BiSearch className='cursor-pointer' fill='#8F7DA5' size={27} />
                    <input autoComplete="one-time-code" style={{ width: '100%', color: '#8F7DA5' }} value={searchValue} onFocus={handleShowGlobalSearch} onChange={(e) => setSearchValue(e.target.value)} placeholder={t("search")} className='user-header-input' type="text" />
                </div>
            )}
            {showGlobalSearch && (
                <div ref={blockSearchRef} className='user-global-search-container'>
                    <div className='user-global-search-input-wrapper'>
                        <div className='user-global-search-input'>
                            <input
                                ref={searchRef}
                                value={searchValue}
                                onFocus={handleShowGlobalSearch}
                                onChange={(e) => handleChangeSearchValue(e.target.value)}
                                placeholder={t("searchYourCodes")}
                                className='user-header-input user-header-input-diffent-placeholder'
                                type="text"
                            />
                            {searchValue && (
                                <img onClick={resetSearchValue} className='close-search-icon' src={searchCloseSvg} alt="" />
                            )}
                        </div>
                        <div onClick={handleCancelSearch} className='user-global-search-cancel'>{t("cancel")}</div>
                    </div>
                    <div style={{ marginBottom: '-8px', marginTop: '16px' }} className='global-search-no-result-text-left'>{t("addFilters")}</div>
                    <div className='global-search-kind-filter'>
                        {DYNAMIC_KINDS.map((kind, idx) => (
                            <div key={idx} onClick={() => handleAddKindFilter(kind)} className={kindFilter.includes(kind) ? 'global-search-kind-filter-item-active' : 'global-search-kind-filter-item'}>
                                {t(`${USER_QR_TYPE_TRANSLATIONS[kind]}`)}
                            </div>
                        ))}
                    </div>
                    <div className='global-search-codes-list'>
                        {searchResult && searchResult.length > 0 && !searchValue && !kindFilter && searchResult.length > hiddenCodes.length && (
                            <div style={{ paddingLeft: '16px', marginTop: '16px' }} className='global-search-no-result-text-left mb-1'>{t("recents")}</div>
                        )}
                        {searchResult && searchResult.length > 0 ? searchResult.map((code, idx) => {
                            if (hiddenCodes.includes(code.id)) {
                                return null
                            } else {
                                if (code.type === 'static') {
                                    return (
                                        <div key={idx}>
                                            <div className='global-search-codes-item-block'>
                                                <div className='dashboard-code-row-with-gap'>
                                                    <div className='global-search-static-image-wrapper'>
                                                        <img className='global-search-qr-code-icon' src={staticIcons[code.data.kind]} alt="" />
                                                    </div>
                                                    <div className='global-search-qr-code-main-info'>
                                                        <div className='code-main-info-name'>
                                                            <span className='global-search-qr-code-name md:text-truncate'>{code.name}</span>
                                                        </div>
                                                        <span className='global-search-qr-code-date'>{t("static")}</span>
                                                    </div>
                                                </div>
                                                <div className='dashboard-code-row-with-gap'>
                                                    {!searchValue && !kindFilter && (
                                                        <img onClick={() => handleCloseRecent(code.id)} className='recent-close-icon' src={recentCloseIconSvg} alt="" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={idx} className={trialDaysLeft <= 0 ? '' : 'global-search-codes-dynamic-hover cursor-pointer'}>
                                            <div className='global-search-codes-item-block'>
                                                <div onClick={() => handleClickOnCode(code.id)} className='dashboard-code-row-with-gap w-full'>
                                                    <div className='global-search-dynamic-image-gradient-wrapper'>
                                                        <div className='global-search-dynamic-image-wrapper'>
                                                            <img className='global-search-qr-code-icon' src={dynamicIcons[code.data.kind]} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='global-search-qr-code-main-info'>
                                                        <div className='code-main-info-name'>
                                                            <span className='global-search-qr-code-name md:text-truncate'>{code.name}</span>
                                                        </div>
                                                        <span className='global-search-qr-code-date'>{t("dynamic")}</span>
                                                    </div>
                                                </div>
                                                <div className='dashboard-code-row-with-gap'>
                                                    {!searchValue && !kindFilter && (
                                                        <img onClick={() => handleCloseRecent(code.id)} src={recentCloseIconSvg} alt="" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        }) : (
                            <div className='global-search-no-result-text'>
                                {searchValue ? t("noMatchesFound") : t("noRecentSearches")}
                            </div>
                        )}
                        {searchResult && searchResult.length > 0 && !searchValue && !kindFilter && searchResult.length === hiddenCodes.length && (
                            <div className='global-search-no-result-text'>
                                {t("noRecentSearches")}
                            </div>
                        )}
                    </div>
                    {showPeriodFilter && (
                        <div className='global-search-filter-background'>
                            <div ref={periodFilterRef} className='link-filter-block'>
                                <span className='link-filter-block-title'>{t("filterByLowercase")}</span>
                                <span className='link-filter-title-underline'></span>
                                <div onClick={() => handleChangeTimeFilter('newest')} className='link-filter-item'>
                                    <span className={timeFilter === 'newest' ? 'link-filter-active-item' : 'link-filter-default-item'}>{t("newestToOldest")}</span>
                                    {timeFilter === 'newest' && (
                                        <img src={activeFilterSvg} alt="" />
                                    )}
                                </div>
                                <div onClick={() => handleChangeTimeFilter('oldest')} className='link-filter-item'>
                                    <span className={timeFilter === 'oldest' ? 'link-filter-active-item' : 'link-filter-default-item'}>{t("oldestToNewest")}</span>
                                    {timeFilter === 'oldest' && (
                                        <img src={activeFilterSvg} alt="" />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default GlobalSearch