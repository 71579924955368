import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useGetAnalyticsDataQuery, useGetFiltersDataQuery } from '../../api/api'
import { useViewport } from '../../hooks/useViewport'

import AnalyticsContentHeader from '../../components/AnalyticsComponents/AnalyticsContentHeader'
import AnalyticsSelectCode from '../../components/AnalyticsComponents/AnalyticsSelectCode'
import DashboardNavigation from '../../components/DashboardNavigation/DashboardNavigation'
import AnalyticsStatsBlock from '../../components/AnalyticsComponents/AnalyticsStatsBlock'
import AnalyticsTotalInfo from '../../components/AnalyticsComponents/AnalyticsTotalInfo'
import AnalyticsGraph from '../../components/AnalyticsComponents/AnalyticsGraph'

const Analytics = () => {
    const [abTesting, setAbTesting] = useState(false)
    const [uniqueScans, setUniqueScans] = useState(false)
    const [dateFilter, setDateFilter] = useState('All Time')
    const [openCodesDropdown, setOpenCodesDropdown] = useState(false)
    const [openAbCodesDropdown, setOpenAbCodesDropdown] = useState(false)
    const [codesSearch, setCodesSearch] = useState('')
    const [selectedCode, setSelectedCode] = useState(null)
    const [selectedAbCode, setSelectedAbCode] = useState(null)
    const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false)
    const [isVisitedAnalyticsSent, setIsVisitedAnalyticsSent] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState({
        os: null,
        browser: null,
        country: null,
        city: null,
        language: null
    })
    const [appliedFilters, setAppliedFilters] = useState({
        os: null,
        browser: null,
        country: null,
        city: null,
        language: null
    })
    const [expandedStatBlocks, setExpandedStatBlocks] = useState({
        os: true,
        browser: true,
        country: true,
        city: true,
        language: true
    })
    const [searchParams, setSearchParams] = useSearchParams()
    const subscription = useSelector((state) => state.app.subscription)

    const analyticsContentRef = useRef(null)
    const selectAbCodeRef = useRef(null)
    const selectCodeRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const isAnythingApplied = abTesting || uniqueScans || selectedCode || dateFilter !== 'All Time' || appliedFilters?.os?.length > 0 || appliedFilters?.browser?.length > 0 || appliedFilters?.country?.length > 0 || appliedFilters?.city?.length > 0 || appliedFilters?.language?.length > 0 ? true : false

    const { data: analyticsData } = useGetAnalyticsDataQuery({
        unique: uniqueScans,
        firstCode: selectedCode,
        secondCode: selectedAbCode,
        appliedFilters: appliedFilters,
        dateFilter: dateFilter,
    }, { skip: !subscription })
    const { data: filterData } = useGetFiltersDataQuery(undefined, { skip: !subscription })

    const handleExpandStatBlock = (block) => {
        setExpandedStatBlocks({
            ...expandedStatBlocks,
            [block]: !expandedStatBlocks[block]
        })
    }

    const handleSelectCode = (code) => {
        if (selectedCode?.id === code?.id) {
            setSelectedCode(null)
            setOpenCodesDropdown(false)
        } else {
            setSelectedCode(code)
            setOpenCodesDropdown(false)
        }
    }

    const handleSelectAbCode = (code) => {
        if (selectedAbCode?.id === code?.id) {
            setSelectedAbCode(null)
            setOpenCodesDropdown(false)
        } else {
            setSelectedAbCode(code)
            setOpenAbCodesDropdown(false)
        }
    }

    const handleContentScroll = (e) => {
        if (isMobile && analyticsContentRef.current) {
            const contentBlock = analyticsContentRef.current

            if (e.target.scrollTop > 70) {
                contentBlock.classList.add('mobile-scrolled')
            } else {
                contentBlock.classList.remove('mobile-scrolled')
            }
        }
    }

    const handleResetAllChanges = () => {
        localStorage.removeItem('analyticsState')
        setSelectedCode(null)
        setDateFilter('All Time')
        setUniqueScans(false)
        setAbTesting(false)
        setAppliedFilters({
            os: null,
            browser: null,
            country: null,
            city: null,
            language: null
        })
    }

    useEffect(() => {
        setCodesSearch('')
    }, [openCodesDropdown, openAbCodesDropdown])

    useEffect(() => {
        if (!abTesting) {
            setSelectedAbCode(null)
        }
    }, [abTesting])

    useEffect(() => {
        if (subscription && !isVisitedAnalyticsSent) {
            if (subscription?.plan === 'Free') {
                setIsVisitedAnalyticsSent(true)
                window.gtag('event', 'user_visit_analytics', {
                    type: 'free_user',
                })
            } else {
                setIsVisitedAnalyticsSent(true)
                window.gtag('event', 'user_visit_analytics', {
                    type: 'paid_user',
                })
            }
        }
    }, [subscription, isVisitedAnalyticsSent])

    useEffect(() => {
        const code = searchParams.get('code')

        if (filterData && code) {
            const selectedCode = filterData?.qr_codes?.find((el) => el.name === code)

            if (selectedCode) {
                setSelectedCode(selectedCode)
                setAbTesting(false)
            }
        }
    }, [searchParams, setSearchParams, filterData])

    useEffect(() => {
        if (appliedFilters.os || appliedFilters.browser || appliedFilters.country || appliedFilters.city || appliedFilters.language) {
            setIsAnyFilterApplied(true)
        } else {
            setIsAnyFilterApplied(false)
        }
    }, [appliedFilters])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectCodeRef.current && !selectCodeRef.current.contains(event.target)) {
                setOpenCodesDropdown(false)
            }

            if (selectAbCodeRef.current && !selectAbCodeRef.current.contains(event.target)) {
                setOpenAbCodesDropdown(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    useEffect(() => {
        if (localStorage.getItem('analyticsState') && !searchParams.get('code')) {
            const state = JSON.parse(localStorage.getItem('analyticsState'))

            setUniqueScans(state.uniqueScans)
            setAbTesting(state.abTesting)
            setSelectedCode(state.selectedCode)
            setSelectedAbCode(state.selectedAbCode)
            setDateFilter(state.dateFilter)

            if (state.appliedFilters && (state.appliedFilters.os || state.appliedFilters.browser || state.appliedFilters.country || state.appliedFilters.city || state.appliedFilters.language)) {
                setAppliedFilters(state.appliedFilters)
            }
        }
    }, [searchParams])

    useEffect(() => {
        if (isAnythingApplied) {
            localStorage.setItem('analyticsState', JSON.stringify({
                uniqueScans,
                selectedCode,
                selectedAbCode,
                appliedFilters,
                dateFilter,
                abTesting,
            }))
        }
    }, [uniqueScans, selectedCode, selectedAbCode, appliedFilters, dateFilter, isAnythingApplied, abTesting])

    return (
        <div className='analytics-wrapper'>
            <DashboardNavigation setOverviewCode={() => null} />
            <div onScroll={handleContentScroll} ref={analyticsContentRef} className='analytics-content-wrapper'>
                <AnalyticsContentHeader
                    abTesting={abTesting}
                    setAbTesting={setAbTesting}
                    uniqueScans={uniqueScans}
                    setUniqueScans={setUniqueScans}
                    setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters}
                    setAppliedFilters={setAppliedFilters}
                    appliedFilters={appliedFilters}
                    setDateFilter={setDateFilter}
                    dateFilter={dateFilter}
                    isAnyFilterApplied={isAnyFilterApplied}
                    filterData={filterData}
                />
                <AnalyticsTotalInfo
                    totalData={filterData}
                />
                <div className='analytics-content-stats-block-wrapper'>
                    <div style={{ height: `${isMobile ? 'unset' : isAnyFilterApplied ? 'calc(100vh - 318px)' : 'calc(100vh - 262px)'}` }} className='analytics-content-stats-block'>
                        <div className='w-full flex item-start justify-between'>
                            <div className='analytics-content-select-codes-container'>
                                <AnalyticsSelectCode
                                    refElement={selectCodeRef}
                                    abTesting={abTesting}
                                    selectedCode={selectedCode}
                                    openCodesDropdown={openCodesDropdown}
                                    setOpenCodesDropdown={setOpenCodesDropdown}
                                    codesSearch={codesSearch}
                                    setCodesSearch={setCodesSearch}
                                    handleSelectCode={handleSelectCode}
                                    filterData={filterData}
                                    otherSelectedCode={selectedAbCode}
                                    dotColor={'pink'}
                                    codeLetter={'A'}
                                />
                                {abTesting && (
                                    <AnalyticsSelectCode
                                        refElement={selectAbCodeRef}
                                        abTesting={abTesting}
                                        selectedCode={selectedAbCode}
                                        openCodesDropdown={openAbCodesDropdown}
                                        setOpenCodesDropdown={setOpenAbCodesDropdown}
                                        codesSearch={codesSearch}
                                        setCodesSearch={setCodesSearch}
                                        handleSelectCode={handleSelectAbCode}
                                        filterData={filterData}
                                        otherSelectedCode={selectedCode}
                                        dotColor={'purple'}
                                        codeLetter={'B'}
                                    />
                                )}
                            </div>
                            <button onClick={handleResetAllChanges} disabled={isAnythingApplied ? false : true} className='analytics-header-reset-all-changes'>
                                {isMobile ? t("reset") : t("resetAllChanges")}
                            </button>
                        </div>
                        <div className='analytics-content-block'>
                            <h5 className='analytics-content-block-title'>{uniqueScans ? t("unique") : t("total")} {t("scansActivity")}</h5>
                            <div className='analytics-content-activity-block'>
                                {(analyticsData?.graph && analyticsData?.graph?.length > 0 && !abTesting && analyticsData?.graph?.some((el) => el.count > 0)) || (abTesting && (selectedCode || selectedAbCode) && analyticsData?.graph && analyticsData?.graph?.length > 0 && analyticsData?.graph?.some((el) => el?.count > 0 || el?.count_first > 0 || el?.count_second > 0)) ? (
                                    <AnalyticsGraph
                                        graphData={analyticsData?.graph}
                                        uniqueScans={uniqueScans}
                                        abTesting={abTesting}
                                    />
                                ) : (
                                    <span className='analytics-content-no-scans-text'>{abTesting && !selectedCode && !selectedAbCode ? t("noData") : t("noScans")}</span>
                                )}
                            </div>
                        </div>
                        <AnalyticsStatsBlock
                            uniqueScans={uniqueScans}
                            analyticsData={analyticsData}
                            abTesting={abTesting}
                            selectedAbCode={selectedAbCode}
                            selectedCode={selectedCode}
                            handleExpandStatBlock={handleExpandStatBlock}
                            expandedStatBlocks={expandedStatBlocks}
                            title={t("scansByOperatingSystemAnalytics")}
                            element={"os"}
                            tableTitle={"OS"}
                        />
                        <AnalyticsStatsBlock
                            uniqueScans={uniqueScans}
                            analyticsData={analyticsData}
                            abTesting={abTesting}
                            selectedAbCode={selectedAbCode}
                            selectedCode={selectedCode}
                            handleExpandStatBlock={handleExpandStatBlock}
                            expandedStatBlocks={expandedStatBlocks}
                            title={t("scansByBrowserAnalytics")}
                            element={"browser"}
                            tableTitle={t("browser")}
                        />
                        <AnalyticsStatsBlock
                            uniqueScans={uniqueScans}
                            analyticsData={analyticsData}
                            abTesting={abTesting}
                            selectedAbCode={selectedAbCode}
                            selectedCode={selectedCode}
                            handleExpandStatBlock={handleExpandStatBlock}
                            expandedStatBlocks={expandedStatBlocks}
                            title={t("scansByCountryAnalytics")}
                            element={"country"}
                            tableTitle={t("country")}
                        />
                        <AnalyticsStatsBlock
                            uniqueScans={uniqueScans}
                            analyticsData={analyticsData}
                            abTesting={abTesting}
                            selectedAbCode={selectedAbCode}
                            selectedCode={selectedCode}
                            handleExpandStatBlock={handleExpandStatBlock}
                            expandedStatBlocks={expandedStatBlocks}
                            title={t("scansByCityAnalytics")}
                            element={"city"}
                            tableTitle={t("city")}
                        />
                        <AnalyticsStatsBlock
                            uniqueScans={uniqueScans}
                            analyticsData={analyticsData}
                            abTesting={abTesting}
                            selectedAbCode={selectedAbCode}
                            selectedCode={selectedCode}
                            handleExpandStatBlock={handleExpandStatBlock}
                            expandedStatBlocks={expandedStatBlocks}
                            title={t("scansByLanguageAnalytics")}
                            element={"language"}
                            tableTitle={t("language")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics