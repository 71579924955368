import { MdOutlineAnalytics, MdOutlineQrCode } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiArchiveIn } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import { PLAN_NAME_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { PLAN_TO_FULL_NAME } from '../../helpers/constants'
import { useAuthContext } from '../../context/AuthContext'
import { useCustomLoggerMutation } from '../../api/api'
import { useViewport } from '../../hooks/useViewport'
import usePlanName from '../../hooks/usePlanName'

import TrialEndModal from '../TrialEndModal/TrialEndModal'

import upgradeButtomCrownHoverSvg from '../../assets/icons/upgrade-button-crown-hover.svg'
import upgradeButtonCrownSvg from '../../assets/icons/upgrade-button-crown.svg'
import profileSettingsSvg from '../../assets/icons/profile-settings.svg'
import defaultAvatarJpg from '../../assets/images/default-avatar.webp'

const DashboardNavigation = ({ setOverviewCode }) => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)
    const [showTrialEndModal, setShowTrialEndModal] = useState(false)
    const [activeNavigationTab, setActiveNavigationTab] = useState('My Codes')

    const navigate = useCustomNavigate()
    const location = useLocation()

    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const { activeTab, setActiveTab, setDesktopCreation, desktopCreation, mobileCreation, setCodeName } = useUserCodeCreationContext()
    const { expandNavigation, setExpandNavigation } = useAuthContext()
    const { planName } = usePlanName(subscription)

    const [customLogger] = useCustomLoggerMutation()

    const hideMobileNavigation = () => {
        if (isMobile) {
            setExpandNavigation(false)
        }
    }

    const handleNavigateToSettings = () => {
        navigate('/settings')
        customLogger({
            action: 'click',
            description: 'Dashboard Navigation Settings',
        })
    }

    const handleNavigateToAnalytics = () => {
        navigate('/dashboard/analytics')
        hideMobileNavigation()
        customLogger({
            action: 'click',
            description: 'Dashboard Navigation Open Statistics',
        })
    }

    const handleChangeActiveTab = (tab) => {
        setActiveTab(tab)
        setOverviewCode(null)

        if (desktopCreation) {
            setDesktopCreation(false)
            setCodeName('')
        }

        if (window.location.pathname !== '/dashboard') {
            navigate('/dashboard')
        }
        customLogger({
            action: 'click',
            description: `Dashboard Navigation Open ${tab}`,
        })
    }

    const handleNavigateToUpgrade = () => {
        if (subscription?.new_flow) {
            navigate('/upgrade-plan-new')
        } else {
            navigate('/upgrade-plan')
        }
        customLogger({
            action: 'click',
            description: 'Dashboard Navigation Upgrade Plan',
        })
    }

    useEffect(() => {
        if (((!isMobile && desktopCreation) || (isMobile && mobileCreation)) && location.pathname.includes('/dashboard/analytics') && !location.pathname.includes('/settings')) {
            setActiveNavigationTab('Create New Code')
        } else if (activeTab === 'My Codes' && ((!isMobile && !desktopCreation) || (isMobile && !mobileCreation)) && !location.pathname.includes('/dashboard/analytics') && !location.pathname.includes('/settings') && !location.pathname.includes('/upgrade-plan')) {
            setActiveNavigationTab('My Codes')
        } else if (activeTab === 'Archived' && ((!isMobile && !desktopCreation) || (isMobile && !mobileCreation)) && !location.pathname.includes('/dashboard/analytics') && !location.pathname.includes('/settings') && !location.pathname.includes('/upgrade-plan')) {
            setActiveNavigationTab('Archived')
        } else if (location.pathname.includes('/dashboard/analytics') && !location.pathname.includes('/settings')) {
            setActiveNavigationTab('Analytics')
        } else if (location.pathname.includes('/upgrade-plan')) {
            setActiveNavigationTab('Upgrade Plan')
        } else {
            setActiveNavigationTab('Settings')
        }
    }, [isMobile, desktopCreation, mobileCreation, location.pathname, activeTab])

    return (
        <div style={{ paddingBottom: `${location.pathname.includes('analytics') ? '8px' : '16px'}` }} className={expandNavigation ? 'user-dashboard-navigation' : 'user-dashboard-navigation-collapsed'}>
            <div className='navigation-items-block'>
                <div
                    onClick={() => { handleChangeActiveTab('My Codes'); hideMobileNavigation() }}
                    className={`${activeNavigationTab === 'My Codes' ? 'navigation-item-block-active' : 'navigation-item-block'} ${!expandNavigation ? 'nav-item-collapsed' : ''}`}
                >
                    <MdOutlineQrCode fill={activeNavigationTab === 'My Codes' ? '#1B003E' : '#E0C8FF'} size={25} />
                    {expandNavigation && (
                        <span className='navigation-item-text whitespace-nowrap'>{t("myCodes")}</span>
                    )}
                </div>
                <div
                    onClick={handleNavigateToAnalytics}
                    className={`${activeNavigationTab === 'Analytics' ? 'navigation-item-block-active' : 'navigation-item-block'} ${!expandNavigation ? 'nav-item-collapsed' : ''}`}
                >
                    <MdOutlineAnalytics fill={activeNavigationTab === 'Analytics' ? '#1B003E' : '#E0C8FF'} size={25} />
                    {expandNavigation && (
                        <span className='navigation-item-text whitespace-nowrap'>{t("statistics")}</span>
                    )}
                </div>
                <div
                    onClick={() => { handleChangeActiveTab('Archived'); hideMobileNavigation() }}
                    className={`${activeNavigationTab === 'Archived' ? 'navigation-item-block-active' : 'navigation-item-block'} ${!expandNavigation ? 'nav-item-collapsed' : ''}`}
                >
                    <BiArchiveIn fill={activeNavigationTab === 'Archived' ? '#1B003E' : '#E0C8FF'} size={25} />
                    {expandNavigation && (
                        <span className='navigation-item-text whitespace-nowrap'>{t("archived")}</span>
                    )}
                </div>
            </div>
            <div className='dashboard-navigation-bottom-block'>
                {planName === 'Free' && (
                    <div className="dashboard-navigation-bottom-upgrade-block">
                        <span className='dashboard-navigation-bottom-scan-left-text'>
                            {t("youHaveSomeScansLeft", { scans: subscription?.scans })}
                        </span>
                        <button onClick={handleNavigateToUpgrade} className='header-upgrade-new-button w-full'>
                            <img className='default' src={upgradeButtonCrownSvg} alt="" />
                            <img className='hovered' src={upgradeButtomCrownHoverSvg} alt="" />
                            <span className='upgrade-button-text'>
                                {t("upgrade")}
                            </span>
                        </button>
                    </div>
                )}
                <div className='dashboard-navigation-bottom-user-block'>
                    <div className='dashboard-navigation-bottom-user-info'>
                        <img
                            style={{ width: '36px', height: '36px', borderRadius: '8px' }}
                            className='footer-user-personal-image'
                            src={subscription?.profile_image ? subscription?.profile_image : defaultAvatarJpg}
                            alt=""
                        />
                        <div className='dashboard-navigation-bottom-user-base-info'>
                            <span className='dashboard-navigation-bottom-user-info-name'>
                                {user?.displayName && user?.displayName?.length > 0 ? (
                                    <>
                                        {user?.displayName?.split(' ').length > 1 ? `${user?.displayName?.split(' ')[0]} ${user?.displayName?.split(' ')[1][0]}.` : user?.displayName}
                                    </>
                                ) : (
                                    <>{user?.email?.split('@')[0]}</>
                                )}
                            </span>
                            <span className='dashboard-navigation-bottom-user-info-plan'>
                                {PLAN_TO_FULL_NAME[planName] ? t(`${PLAN_NAME_TRANSLATIONS[PLAN_TO_FULL_NAME[planName]]}`) : t("freeTrial")}
                            </span>
                        </div>
                    </div>
                </div>
                {activeNavigationTab !== 'Settings' && (
                    <div onClick={handleNavigateToSettings} className='dashboard-navigation-settings-button'>
                        <img src={profileSettingsSvg} alt="" />
                        <span className='dashboard-navigation-settings-button-text'>
                            {t("profileSettings")}
                        </span>
                    </div>
                )}
            </div>
            {showTrialEndModal && (
                <TrialEndModal show={showTrialEndModal} onClose={() => setShowTrialEndModal(false)} />
            )}
        </div >
    )
}

export default DashboardNavigation