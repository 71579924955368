import { useCallback, useState } from 'react'

const usePagination = (itemsPerPage, scrollElementRef) => {
    const [activePage, setActivePage] = useState('1')
    const [pages, setPages] = useState([])

    const handlePage = useCallback((page) => {
        setActivePage(page)
        if (scrollElementRef.current) {
            scrollElementRef.current.scrollIntoView({ behavior: 'instant' })
        }
    }, [scrollElementRef])

    const handlePagesCount = useCallback((count) => {
        if (count) {
            const pages = Math.ceil(count / Number(itemsPerPage))
            let arrayPages = []

            for (let i = 1; i <= pages; i++) {
                arrayPages.push(String(i))
            }
            setPages(arrayPages)

            if (arrayPages.length < Number(activePage)) {
                setActivePage(String(arrayPages.length))
            }
        } else {
            setPages([])
        }
    }, [itemsPerPage, activePage])

    return { activePage, pages, handlePage, handlePagesCount }
}

export default usePagination