import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

const UploadLogoBlock = ({ title, limit, acceptList, multiple, onChangeFiles, uploadedFiles, isError, errorText }) => {
    const inputRef = useRef()

    const { t } = useTranslation()

    const handleUpload = () => {
        inputRef.current.click()
    }

    return (
        <div className={isError ? 'upload-block-wrapper-error' : 'uplaod-block-wrapper'}>
            <input onChange={(e) => onChangeFiles(e.target.files[0])} multiple={multiple} ref={inputRef} className='hidden' type="file" accept={acceptList} />
            <button onClick={handleUpload} className='uplaod-block-button-gray'>{uploadedFiles > 0 ? `Uploaded ${uploadedFiles} file` : title}</button>
            <p className='upload-block-description-text'>
                {t("uploadOneImage")}
            </p>
            {isError ? (
                <span className='upload-block-error-text'>{errorText}</span>
            ) : (
                <span className='upload-block-limit-text'>{t("maximumSize")} {limit}</span>
            )}
        </div>
    )
}

export default UploadLogoBlock