import { useCallback } from 'react';

import { useLanguageContext } from '../context/LanguageContext';

const useGenerateUrl = () => {
    const { currentLanguage } = useLanguageContext()

    const generateUrl = useCallback((path) => {
        if (typeof path === 'number') {
            return path;
        }

        if (currentLanguage === 'en') {
            return path;
        }
        return `/${currentLanguage}${path}`;
    }, [currentLanguage]);

    return generateUrl
}

export default useGenerateUrl