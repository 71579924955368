import { useTranslation } from "react-i18next";

const useDateTranslations = () => {
    const { t } = useTranslation()

    const months = [ 
        t("january"),
        t("february"),
        t("march"),
        t("april"),
        t("may"),
        t("june"),
        t("july"),
        t("august"),
        t("september"),
        t("october"),
        t("november"),
        t("december")
    ];

    const monthsShort = [
        t("janShort"),
        t("febShort"),
        t("marShort"),
        t("aprShort"),
        t("mayShort"),
        t("junShort"),
        t("julShort"),
        t("augShort"),
        t("sepShort"),
        t("octShort"),
        t("novShort"),
        t("decShort")
    ]

    const translateAnalyticsDate = (date) => {
        if (!date) return ''

        if (date.includes('-')) {
            const [start, end] = date.split('-')

            const [startMonth, startDate, startYear] = start.split(' ')
            const [endMonth, endDate, endYear] = end.slice(1, end.length).split(' ')

            let translatedStartDate = ''
            let translatedEndDate = ''

            if (startYear) {
                translatedStartDate = `${t(`${startMonth.toLowerCase()}Short`)} ${startDate} ${startYear}`
            } else {
                translatedStartDate = `${t(`${startMonth.toLowerCase()}Short`)} ${startDate}`
            }

            if (endYear) {
                translatedEndDate = `${t(`${endMonth.toLowerCase()}Short`)} ${endDate} ${endYear}`
            } else {
                translatedEndDate = `${t(`${endMonth.toLowerCase()}Short`)} ${endDate}`
            }

            return `${translatedStartDate} - ${translatedEndDate}`
        } else if (date?.split(' ')[1]?.length === 4) {
            const [month, year] = date.split(' ')

            return `${t(`${month.toLowerCase()}Short`).toUpperCase()} ${year}`
        } else if (date.includes(',')) {
            const [month, day, year] = date.split(' ')

            return `${t(`${month.toLowerCase()}Short`)} ${day} ${year}`
        } else {
            const [month, day] = date.split(' ')

            return `${t(`${month.toLowerCase()}Short`)} ${day}`
        }
    }

    return {
        months,
        monthsShort,
        translateAnalyticsDate
    }
}

export default useDateTranslations
