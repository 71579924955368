import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import OnboardingVideo from '../assets/icons/onboarding/video.svg'
import OnboardingPhoto from '../assets/icons/onboarding/photo.svg'
import OnboardingText from '../assets/icons/onboarding/text.svg'
import OnboardingWifi from '../assets/icons/onboarding/wifi.svg'
import OnboardingUrl from '../assets/icons/onboarding/url.svg'
import OnboardingMp3 from '../assets/icons/onboarding/mp3.svg'
import OnboardingPdf from '../assets/icons/onboarding/pdf.svg'

const useReturnCodeTypesData = () => {
    const { t } = useTranslation()

    const codesListData = useMemo(() => {
        return [
            {
                id: 1,
                name: t("website"),
                nameEnglish: 'URL',
                icon: OnboardingUrl,
                isStatic: true,
                text: t("guideYourAudienceToAWebsite"),
                staticName: 'link'
            },
            {
                id: 2,
                name: t("text"),
                nameEnglish: 'Text',
                icon: OnboardingText,
                isStatic: true,
                text: t("aConciseWayToShareMessages"),
                staticName: 'text'
            },
            {
                id: 3,
                name: 'Wi-Fi',
                nameEnglish: 'Wi-Fi',
                icon: OnboardingWifi,
                isStatic: true,
                text: t("effortlessConnectivityShareWifi"),
                staticName: 'wi-fi'
            },
            {
                id: 4,
                name: t("image"),
                nameEnglish: 'Image',
                icon: OnboardingPhoto,
                isStatic: false,
                text: t("shareCaptivatingImagesStraightToUser"),
                staticName: ''
            },
            {
                id: 5,
                name: 'PDF',
                nameEnglish: 'PDF',
                icon: OnboardingPdf,
                isStatic: false,
                text: t("shareDocumentsSwiftly"),
                staticName: ''
            },
            {
                id: 6,
                name: t("video"),
                nameEnglish: 'Video',
                icon: OnboardingVideo,
                isStatic: false,
                text: t("engageYourAudienceWithACompellingVideo"),
                staticName: ''
            },
            {
                id: 7,
                name: 'MP3',
                nameEnglish: 'MP3',
                icon: OnboardingMp3,
                isStatic: false,
                text: t("deliverYourFavoriteTunes"),
                staticName: ''
            },
        ]
    }, [t])

    return { codesListData }
}

export default useReturnCodeTypesData