import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { IoMdMore } from 'react-icons/io'

import editCodeDeleteSvg from '../../assets/icons/edit-code-delete.svg'

const EditFileBlock = ({ file, title, onFileChanged, deleteAllowed = false, onDeleteFile }) => {
    const [fileSize, setFileSize] = useState('')

    const uploadFileRef = useRef(null)

    const { t } = useTranslation()

    const handleUploadFile = (e) => {
        if (e.target.files[0]) {
            onFileChanged(e.target.files[0])
        }
    }

    const handleChangeFile = () => {
        uploadFileRef.current.click()
    }

    const handleDeleteCode = () => {
        if (onDeleteFile) {
            onDeleteFile()
        }
    }

    useEffect(() => {
        (async () => {
            if (typeof (file) === 'string') {
                const response = await fetch(file)

                const blob = await response.blob()

                setFileSize((blob.size / 1024).toFixed(2))
            } else {
                setFileSize(file?.size ? (file?.size / 1024).toFixed(2) : '')
            }
        })()
    }, [file])

    return (
        <div className='edit-file-block-wrapper'>
            <div className='edit-file-left-side-block'>
                <img className='edit-file-left-side-img' src={typeof (file) === 'string' ? file : file ? URL.createObjectURL(file) : ''} alt="" />
                <div className='edit-file-left-side-info-block'>
                    <span className='edit-file-left-side-info-name'>{title}</span>
                    <span className='edit-file-left-side-info-size'>{fileSize} KB</span>
                </div>
            </div>
            <Dropdown className='dropdown-edit-list'>
                <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                    <IoMdMore className='cursor-pointer' size={25} fill='#334155' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleChangeFile} className='bootstrap-default-item bootstrap-bold-item'>
                        {t("change")}
                    </Dropdown.Item>
                    {deleteAllowed && (
                        <Dropdown.Item onClick={handleDeleteCode} className='bootstrap-default-item bootstrap-bold-item'>
                            {t("delete")}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <div className='edit-file-right-side-block'>
                <button onClick={handleChangeFile} className='edit-file-right-side-change-btn'>{t("change")}</button>
                {deleteAllowed && (
                    <button onClick={() => handleDeleteCode()} className='edit-file-right-side-delete-btn'>
                        <img src={editCodeDeleteSvg} alt="" />
                    </button>
                )}
            </div>
            <input accept="image/*" onChange={(e) => handleUploadFile(e)} ref={uploadFileRef} multiple={false} type="file" className='hidden' />
        </div>
    )
}

export default EditFileBlock