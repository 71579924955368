import * as yup from 'yup'

import { EMAIL_IS_INCORRECT, PASSWORDS_DO_NOT_MATCH, THIS_FIELD_IS_REQUIRED, URL_IS_NOT_VALID } from './constants';

export const signInSchema = yup.object({
    email: yup.string().required(THIS_FIELD_IS_REQUIRED).email(EMAIL_IS_INCORRECT),
    password: yup.string().required(THIS_FIELD_IS_REQUIRED),
}).required();

export const signUpSchema = yup.object({
    email: yup.string().required(THIS_FIELD_IS_REQUIRED).email(EMAIL_IS_INCORRECT),
    password: yup.string().required(THIS_FIELD_IS_REQUIRED),
    confirmPassword: yup.string().oneOf([yup.ref("password")], PASSWORDS_DO_NOT_MATCH)
}).required();

export const contentLinkSchema = yup.object({
    link: yup.string().required(THIS_FIELD_IS_REQUIRED).url(URL_IS_NOT_VALID)
}).required()

export const textLinkSchema = yup.object({
    text: yup.string().required(THIS_FIELD_IS_REQUIRED)
}).required()

export const sendEmailSchema = yup.object({
    email: yup.string().required(THIS_FIELD_IS_REQUIRED).email(EMAIL_IS_INCORRECT),
    subject_email: yup.string().required(THIS_FIELD_IS_REQUIRED).email(EMAIL_IS_INCORRECT),
    message: yup.string().required(THIS_FIELD_IS_REQUIRED),
}).required()

export const callLinkSchema = yup.object({
    country_code: yup.string().required(THIS_FIELD_IS_REQUIRED).notOneOf(['--'], 'Please select one of the Country codes'),
    phone: yup.string().required(THIS_FIELD_IS_REQUIRED)
}).required()

export const smsLinkSchema = yup.object({
    country_code: yup.string().required(THIS_FIELD_IS_REQUIRED).notOneOf(['--'], 'Please select one of the Country codes'),
    phone: yup.string().required(THIS_FIELD_IS_REQUIRED),
    message: yup.string().required(THIS_FIELD_IS_REQUIRED),
}).required()

export const wifiLinkSchema = yup.object({
    network_name: yup.string().required(THIS_FIELD_IS_REQUIRED),
    network_type: yup.string().required(THIS_FIELD_IS_REQUIRED),
    password: yup.string().required(THIS_FIELD_IS_REQUIRED),
    hidden: yup.boolean(),
}).required()

export const twitterLinkSchema = yup.object({
    link: yup.string().required(THIS_FIELD_IS_REQUIRED)
}).required()

export const contactUsSchema = yup.object({
    email: yup.string().required(THIS_FIELD_IS_REQUIRED).email(EMAIL_IS_INCORRECT),
    subject: yup.string().required(THIS_FIELD_IS_REQUIRED),
    description: yup.string().required(THIS_FIELD_IS_REQUIRED),
}).required();