import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react'

import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import { useViewport } from '../../../hooks/useViewport';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';
import EditListFilesBlock from '../../EditListFilesBlock/EditListFilesBlock';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';

const EditVideo = ({ triggerValidation }) => {
    const { isMobile } = useViewport()

    const { t } = useTranslation()

    const { register, trigger, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const {
        dynamicVideoStyles, setDynamicVideoStyles
    } = useEditQrCodeContext()

    const handleChangeFiles = (files) => {
        setDynamicVideoStyles({
            ...dynamicVideoStyles,
            files
        })
    }

    const handleChangeInput = (name, value) => {
        setDynamicVideoStyles({
            ...dynamicVideoStyles,
            [name]: value,
        })
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicVideoStyles({
            ...dynamicVideoStyles,
            logo: file,
        })
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (dynamicVideoStyles) {
            setValue('link', dynamicVideoStyles.link)
            setValue('company', dynamicVideoStyles.company)
            setValue('title', dynamicVideoStyles.title)
            setValue('description', dynamicVideoStyles.description)
            setValue('button', dynamicVideoStyles.button)
            setValue('redirect', dynamicVideoStyles.redirect)
            setValue('website', dynamicVideoStyles.website)
        }
    }, [dynamicVideoStyles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            {!isMobile && (
                <CreationContentTitle title={t("videos")} withStep={false} />
            )}
            {dynamicVideoStyles.link ? (
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("videoUrl")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.link}
                        placeholder={t("enterVideoUrl")}
                        className={errors.link ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("link", {
                            onChange: (e) => handleChangeInput('link', e.target.value),
                            required: dynamicVideoStyles.files.length === 0 ? true : false,
                        })}
                    />
                    {errors.link && <p className='error-text'>{t("enterVideoUrlsOrUploadVideos")}</p>}
                </div>
            ) : (
                <EditListFilesBlock
                    files={dynamicVideoStyles?.files}
                    onChangeFiles={handleChangeFiles}
                    title={t("videoFile")}
                    type="video"
                    acceptList={"video/mp4,video/x-m4v,video/*"}
                />
            )}
            {!isMobile && (
                <>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                </>
            )}
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("company")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.company}
                        placeholder={t("exTechAndCorp")}
                        className={errors.company ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("company", {
                            onChange: (e) => handleChangeInput('company', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.company && <p className='error-text'>{t("enterCompanyName")}</p>}
                </div>
                <EditFileBlock
                    file={dynamicVideoStyles.logo}
                    onFileChanged={handleSaveUploadedLogoFile}
                    title={t("logoFile")}
                />
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("videoTitle")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.title}
                        placeholder={t("exTechAndCorpManifesto")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterAVideoTitle")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.description}
                        placeholder={t("exLeadingTechnologyCompanyInTheSector")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.button}
                        placeholder={t("exWatchNow")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("buttonRedirectUrl")}</label>
                    <input
                        type='text'
                        value={dynamicVideoStyles.redirect}
                        placeholder={t("exVideoPlaylistUrl")}
                        className={errors.redirect ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("redirect", {
                            onChange: (e) => handleChangeInput('redirect', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.redirect && <p className='error-text'>{t("enterAValidButtonRedirectUrl")}</p>}
                </div>
            </div>
        </form>
    )
}

export default EditVideo