import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IoMdMore } from 'react-icons/io';

import { useViewport } from '../../../hooks/useViewport';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle'
import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import EditFileBlock from '../../EditFileBlock/EditFileBlock';

import editMp3IconSvg from '../../../assets/icons/edit-mp3-icon.svg'
import UploadLogoBlock from '../../common/UploadLogoBlock/UploadLogoBlock';

const EditMp3 = ({ triggerValidation }) => {
    const [fileSize, setFileSize] = useState('')

    const uploadFileRef = useRef(null)

    const { t } = useTranslation()
    const { isMobile } = useViewport()

    const { register, trigger, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const {
        dynamicMP3Styles, setDynamicMP3Styles, codeVersion
    } = useEditQrCodeContext()

    const handleChangeInput = (name, value) => {
        setDynamicMP3Styles({
            ...dynamicMP3Styles,
            [name]: value,
        })
    }

    const handleChangeClick = () => {
        uploadFileRef.current.click()
    }

    const handleUploadFile = (e) => {
        if (e.target.files[0]) {
            setDynamicMP3Styles({
                ...dynamicMP3Styles,
                file: e.target.files[0],
            })
        }
    }

    const handleSaveUploadedLogoFile = (file) => {
        setDynamicMP3Styles({
            ...dynamicMP3Styles,
            logo: file,
        })
    }

    const handleUploadCompanyLogoFile = (file) => {
        setDynamicMP3Styles({
            ...dynamicMP3Styles,
            companyLogo: file,
        })
    }

    useEffect(() => {
        if (triggerValidation) {
            triggerValidation(trigger)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (dynamicMP3Styles && dynamicMP3Styles.file) {
            (async () => {
                if (typeof (dynamicMP3Styles.file) === 'string') {
                    const response = await fetch(dynamicMP3Styles.file)

                    const blob = await response.blob()

                    setFileSize((blob.size / 1024).toFixed(2))
                } else {
                    setFileSize(dynamicMP3Styles.file?.size ? (dynamicMP3Styles.file?.size / 1024).toFixed(2) : '')
                }
            })()
        }
    }, [dynamicMP3Styles])

    useEffect(() => {
        if (dynamicMP3Styles) {
            setValue('title', dynamicMP3Styles.title)
            setValue('description', dynamicMP3Styles.description)
            setValue('website', dynamicMP3Styles.website)
            setValue('button', dynamicMP3Styles.button)
            setValue('redirect', dynamicMP3Styles.redirect)
        }
    }, [dynamicMP3Styles])

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container'>
            {!isMobile && (
                <CreationContentTitle title={"MP3"} withStep={false} />
            )}
            {codeVersion === 2 && (
                <>
                    {dynamicMP3Styles?.companyLogo ? (
                        <EditFileBlock
                            file={dynamicMP3Styles.companyLogo}
                            title={t("logoFile")}
                            onFileChanged={handleUploadCompanyLogoFile}
                            onDeleteFile={() => handleUploadCompanyLogoFile(null)}
                            deleteAllowed={true}
                        />
                    ) : (
                        <UploadLogoBlock
                            title={t("addLogoOptional")}
                            limit={"5MB"}
                            acceptList={"image/*"}
                            multiple={false}
                            onChangeFiles={handleUploadCompanyLogoFile}
                            uploadedFiles={dynamicMP3Styles.companyLogo ? 1 : 0}
                            isError={false}
                            errorText={t("logoFileMustBeUploaded")}
                        />
                    )}
                </>
            )}
            <div className='edit-file-block-wrapper'>
                <div className='edit-file-left-side-block'>
                    <div className='edit-file-left-side-img-block'>
                        <img className='edit-mp3-image' src={editMp3IconSvg} alt="" />
                    </div>
                    <div className='edit-file-left-side-info-block'>
                        <span className='edit-file-left-side-info-name'>{t("mp3File")}</span>
                        <span className='edit-file-left-side-info-size'>{fileSize} KB</span>
                    </div>
                </div>
                <Dropdown className='dropdown-edit-list'>
                    <Dropdown.Toggle className='bootstrap-default-dropdown bootstrap-transparent-dropdown' id="dropdown-basic">
                        <IoMdMore className='cursor-pointer' size={25} fill='#334155' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleChangeClick} className='bootstrap-default-item bootstrap-bold-item'>
                            {t("change")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='edit-file-right-side-block'>
                    <button onClick={handleChangeClick} className='edit-file-right-side-change-btn'>{t("change")}</button>
                </div>
                <input accept="audio/*" onChange={(e) => handleUploadFile(e)} ref={uploadFileRef} multiple={false} type="file" className='hidden' />
            </div>
            {!isMobile && (
                <>
                    <span className='dynamic-form-underline'></span>
                    <CreationContentTitle title={t("landingPageDetails")} withStep={false} />
                </>
            )}
            <div className='content-creation-input-wrapper gap-4'>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("title")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.title}
                        placeholder={t("exNameOfSongOrAudioFile")}
                        className={errors.title ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("title", {
                            onChange: (e) => handleChangeInput('title', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.title && <p className='error-text'>{t("enterMp3Title")}</p>}
                </div>
                <EditFileBlock
                    file={dynamicMP3Styles.logo}
                    title={t("logoFile")}
                    onFileChanged={handleSaveUploadedLogoFile}
                />
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("description")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.description}
                        placeholder={t("exNameOfArtistAndAlbum")}
                        className={errors.description ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("description", {
                            onChange: (e) => handleChangeInput('description', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.description && <p className='error-text'>{t("enterADescription")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("button")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.button}
                        placeholder={t("exPlayNow")}
                        className={errors.button ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("button", {
                            onChange: (e) => handleChangeInput('button', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.button && <p className='error-text'>{t("enterButtonText")}</p>}
                </div>
                <div className='flex flex-col block-inputs-column'>
                    <label className='content-creation-input-label'>{t("buttonRedirectUrl")}</label>
                    <input
                        type='text'
                        value={dynamicMP3Styles.redirect}
                        placeholder={t("exMyMusicUrl")}
                        className={errors.redirect ? 'form-input-error-border form-input-dashboard w-full' : 'form-input-dashboard w-full'}
                        {...register("redirect", {
                            onChange: (e) => handleChangeInput('redirect', e.target.value),
                            required: true,
                        })}
                    />
                    {errors.redirect && <p className='error-text'>{t("enterAValidButtonRedirectUrl")}</p>}
                </div>
            </div>
        </form>
    )
}

export default EditMp3