import React, { useEffect, useRef, useState } from 'react'
import { DateRange, DefinedRange } from 'react-date-range'
import { useTranslation } from 'react-i18next';
import Hammer from 'hammerjs';

import { convertFormattedDateToNormal, convertFormattedDateToRequest, convertRequestDateToFormatted, formatDate } from '../../helpers/functions';
import { ANALYTICS_FILTER_BLOCK_TO_LABEL_TRANSLATIONS } from '../../helpers/translation-constants';
import { ANALYTICS_FILTER_BLOCK_TO_LABEL } from '../../helpers/constants'
import useAnalyticsData from './useAnalyticsData';

import analyticsSettingsArrowRight from '../../assets/icons/analytics-settings-arrow-right.svg'
import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import analyticsSearch from '../../assets/icons/analytics-search.svg'

const AnalyticsMobileSettings = ({
    setShowMobileSettings,
    isAnyFilterSelected,
    handleClearAllFilterOptions,
    handleApplyFilters,
    setAbTesting,
    setUniqueScans,
    abTesting,
    uniqueScans,
    openedFilterBlock,
    setOpenedFilterBlock,
    selectedFilters,
    filtersSearch,
    setFiltersSearch,
    filterData,
    handleChangeFilterOptionSelect,
    setDateFilter,
    dateFilter,
    handleClearFilterSelectedOption,
}) => {
    const [step, setStep] = useState('start')
    const [tempAbTesting, setTempAbTesting] = useState(false)
    const [tempUniqueScans, setTempUniqueScans] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [ranges, setRanges] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const mobileSettingsRef = useRef(null)

    const { t } = useTranslation()
    const { customStaticRanges, ANALYTICS_FILTER_BLOCKS } = useAnalyticsData()

    const handleHideMobileSettings = () => {
        setShowMobileSettings(false)
    }

    const handleClearAllSettings = () => {
        setTempAbTesting(false)
        setTempUniqueScans(false)
        setAbTesting(false)
        setUniqueScans(false)
        setSelectedDate('')
        setDateFilter('All Time')
        handleClearAllFilterOptions()
    }

    const handleApplySettingsFilters = () => {
        handleApplyFilters()
        setAbTesting(tempAbTesting)
        setUniqueScans(tempUniqueScans)
        setShowMobileSettings(false)

        if (selectedDate) {
            setDateFilter(convertFormattedDateToRequest(selectedDate))
        }
    }

    const handleOpenFilterBlock = (blockName) => {
        setOpenedFilterBlock(blockName)
        setStep('filter-block')
    }

    const handleClearDateFilter = () => {
        setRanges([
            {
                startDate: null,
                endDate: null,
                key: 'selection'
            }
        ])
    }

    const handleBackToFiltersAndApplyDate = () => {
        const startDate = ranges[0]?.startDate
        const endDate = ranges[0]?.endDate
        let res = ''

        if (startDate && endDate) {
            res = formatDate(startDate, endDate)
        }

        setStep('filters')
        setSelectedDate(res)
    }

    const handleClearAllSettingsFilters = () => {
        handleClearAllFilterOptions()
        setSelectedDate('')
    }

    useEffect(() => {
        setTempAbTesting(abTesting)
        setTempUniqueScans(uniqueScans)
    }, [abTesting, uniqueScans])

    useEffect(() => {
        if (dateFilter !== 'All Time') {
            const res = convertRequestDateToFormatted(dateFilter)
            setSelectedDate(res)
        }
    }, [dateFilter])

    useEffect(() => {
        if (selectedDate) {
            const { startDate, endDate } = convertFormattedDateToNormal(selectedDate)

            setRanges([
                {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    key: 'selection'
                }
            ])
        } else {
            setRanges([
                {
                    startDate: null,
                    endDate: null,
                    key: 'selection'
                }
            ])
        }
    }, [selectedDate, setDateFilter])

    useEffect(() => {
        const handleClick = (e) => {
            if (mobileSettingsRef.current && !mobileSettingsRef.current.contains(e.target)) {
                setShowMobileSettings(false)
                setStep('filter-block')
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [setShowMobileSettings])

    useEffect(() => {
        const swipeContainer = document.getElementById('mobile-date-range');

        if (!swipeContainer) return;

        const hammer = new Hammer(swipeContainer);

        hammer.on('swipeleft', () => {
            const nextButton = document.querySelector('.rdrNextPrevButton.rdrNextButton');

            nextButton.click();
        });

        hammer.on('swiperight', () => {
            const prevButton = document.querySelector('.rdrNextPrevButton.rdrPprevButton');

            prevButton.click();
        });

        return () => {
            hammer.destroy();
        };
    }, [step]);

    return (
        <div style={{ zIndex: '101' }} className='link-filter-background analytics-mobile-settings'>
            <div ref={mobileSettingsRef} className='link-filter-block'>
                <div onClick={handleHideMobileSettings} className='mobile-tray-horizontal-bar'>
                    <div className='mobile-tray-horizontal-bar-element'></div>
                </div>
                {step === 'start' ? (
                    <>
                        <div className='mobile-filter-expand-block-header'>
                            <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>{t("settings")}</span>
                            <span onClick={handleClearAllSettings} className={`analytics-filter-block-header-clear ${isAnyFilterSelected || tempAbTesting || tempUniqueScans || selectedDate ? 'active' : ''}`}>
                                {t("clearAll")}
                            </span>
                        </div>
                        <span className='link-filter-title-underline'></span>
                        <div className='analytics-mobile-settings-list'>
                            <div className='analytics-mobile-settings-list-item'>
                                <div className='analytics-mobile-settings-item-text'>
                                    <span className='analytics-header-option-label'>{t("uniqueScans")}</span>
                                </div>
                                <div onClick={() => setTempUniqueScans(!tempUniqueScans)} className={`overview-panel-stats-header-unique-scans-switch ${tempUniqueScans ? 'active' : ''}`}>
                                    <div className={`overview-panel-switch-circle ${tempUniqueScans ? 'active' : ''}`}></div>
                                </div>
                            </div>
                            <div className='analytics-mobile-settings-list-item'>
                                <div className='analytics-mobile-settings-item-text'>
                                    <span className='analytics-header-option-label'>{t("abTesting")}</span>
                                </div>
                                <div onClick={() => setTempAbTesting(!tempAbTesting)} className={`overview-panel-stats-header-unique-scans-switch ${tempAbTesting ? 'active' : ''}`}>
                                    <div className={`overview-panel-switch-circle ${tempAbTesting ? 'active' : ''}`}></div>
                                </div>
                            </div>
                            <div onClick={() => setStep('filters')} className='analytics-mobile-settings-list-item'>
                                <span className='analytics-header-option-label'>{t("filters")}</span>
                                <img src={analyticsSettingsArrowRight} alt="" />
                            </div>
                            <button
                                disabled={isAnyFilterSelected || (tempAbTesting !== abTesting) || (tempUniqueScans !== uniqueScans) || selectedDate ? false : true}
                                onClick={handleApplySettingsFilters}
                                className={`analytics-filters-apply-btn ${isAnyFilterSelected || (tempAbTesting !== abTesting) || (tempUniqueScans !== uniqueScans) || selectedDate ? 'active' : ''}`}
                            >
                                {t("apply")}
                            </button>
                        </div>
                    </>
                ) : step === 'filters' ? (
                    <>
                        <div className='mobile-filter-expand-block-header'>
                            <div onClick={() => setStep('start')} className='analytics-mobile-filter-header-with-back'>
                                <img src={overviewPanelMobileBack} alt="" />
                                <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>{t("filters")}</span>
                            </div>
                            <span onClick={handleClearAllSettingsFilters} className={`analytics-filter-block-header-clear ${isAnyFilterSelected || selectedDate ? 'active' : ''}`}>
                                {t("clearAll")}
                            </span>
                        </div>
                        <span className='link-filter-title-underline'></span>
                        <div onClick={() => setStep('date')} className='analytics-mobile-settings-filters-list-item'>
                            <span className='analytics-mobile-settings-filters-label'>{t("date")}</span>
                            <span className='analytics-mobile-settings-filters-selected'>{selectedDate ? selectedDate : t("none")}</span>
                            <img width={10} src={analyticsSettingsArrowRight} alt="" />
                        </div>
                        {ANALYTICS_FILTER_BLOCKS.map((block) => (
                            <div onClick={() => handleOpenFilterBlock(block.name)} key={block.id} className='analytics-mobile-settings-filters-list-item'>
                                <span className='analytics-mobile-settings-filters-label'>{block.label}</span>
                                <span className='analytics-mobile-settings-filters-selected'>{selectedFilters[block.name]?.length > 0 ? selectedFilters[block.name].join(', ') : t("none")}</span>
                                <img width={10} src={analyticsSettingsArrowRight} alt="" />
                            </div>
                        ))}
                    </>
                ) : step === 'filter-block' ? (
                    <>
                        <div className='mobile-filter-expand-block-header'>
                            <div onClick={() => setStep('filters')} className='analytics-mobile-filter-header-with-back'>
                                <img src={overviewPanelMobileBack} alt="" />
                                <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>
                                    {t(`${ANALYTICS_FILTER_BLOCK_TO_LABEL_TRANSLATIONS[ANALYTICS_FILTER_BLOCK_TO_LABEL[openedFilterBlock]]}`)}
                                </span>
                            </div>
                            <span
                                onClick={() => { handleClearFilterSelectedOption(openedFilterBlock); setStep('filters') }}
                                className={`analytics-filter-block-header-clear ${isAnyFilterSelected || tempAbTesting || tempUniqueScans ? 'active' : ''}`}
                            >
                                {t("clear")}
                            </span>
                        </div>
                        <span className='link-filter-title-underline'></span>
                        {(openedFilterBlock === 'country' || openedFilterBlock === 'city' || openedFilterBlock === 'language') && (
                            <div style={{ marginTop: '16px' }} className='analytics-select-codes-search-block mb-0'>
                                <img src={analyticsSearch} alt="" />
                                <input
                                    onChange={(e) => setFiltersSearch(e.target.value)}
                                    value={filtersSearch}
                                    className='analytics-select-codes-search-input'
                                    placeholder='Search'
                                />
                            </div>
                        )}
                        <div style={{ marginTop: '16px' }} className='analytics-selected-filter-options-list'>
                            {filterData?.filters[openedFilterBlock]?.map((option, idx) => {
                                if (option.toLowerCase().includes(filtersSearch.toLowerCase())) {
                                    return (
                                        <div className='analytics-selected-filter-options-item-block' key={idx}>
                                            <span className='analytics-selected-filter-options-item-text'>{option}</span>
                                            <input
                                                className='analytics-selected-filter-options-item-select'
                                                onChange={(e) => handleChangeFilterOptionSelect(e, option)}
                                                checked={selectedFilters[openedFilterBlock]?.includes(option)}
                                                type="checkbox"
                                            />
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </>
                ) : step === 'date' ? (
                    <>
                        <div className='mobile-filter-expand-block-header'>
                            <div onClick={handleBackToFiltersAndApplyDate} className='analytics-mobile-filter-header-with-back'>
                                <img src={overviewPanelMobileBack} alt="" />
                                <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>{t("date")}</span>
                            </div>
                            <span onClick={handleClearDateFilter} className={`analytics-filter-block-header-clear ${ranges[0]?.startDate || ranges[0]?.endDate ? 'active' : ''}`}>
                                {t("clear")}
                            </span>
                        </div>
                        <span className='link-filter-title-underline'></span>
                        <div className='mobile-analytics-date-range'>
                            <DefinedRange
                                staticRanges={customStaticRanges}
                                inputRanges={[]}
                                ranges={ranges}
                                onChange={item => setRanges([item.selection])}
                            />
                        </div>
                        <div id="mobile-date-range">
                            <div className={`mobile-analytics-date-range ${!ranges[0]?.startDate && !ranges[0]?.endDate ? 'empty-range' : ''}`}>
                                <DateRange
                                    onChange={item => setRanges([item.selection])}
                                    ranges={ranges}
                                    direction="horizontal"
                                    inputRanges={[]}
                                    rangeColors={["#141315"]}
                                    showDateDisplay={false}
                                    showSelectionPreview={false}
                                    showPreview={false}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </div >
    )
}

export default AnalyticsMobileSettings