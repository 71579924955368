import { useEffect, useMemo, useRef, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import { useUserCodeCreationContext } from '../context/UserQrCreationContext'
import { base64ToBlob, convertQrCodeToImage } from '../helpers/functions'
import { useHandleConvertCodeMutation } from '../api/api'
import useToasts from './useToasts'

const useConvertToDynamic = () => {
    const [codeToConvert, setCodeToConvert] = useState({})
    const [isConverting, setIsConverting] = useState(false)
    const [canvasStyles, setCanvasStyles] = useState(null)

    const canvasRef = useRef(null)

    const { convertToDynamicToast } = useToasts()
    const { convertCodeData, setConvertCodeData, setShowConvert, publicId } = useUserCodeCreationContext()

    const [convertCode] = useHandleConvertCodeMutation()

    const convertCodeJsx = useMemo(() => {
        return (
            <>
                {
                    convertCodeData?.id && canvasStyles && (
                        <div style={{ position: 'absolute', top: '-2111px' }} className='p-2 mt-6 w-fit h-fit' ref={canvasRef}>
                            <div style={{ ...canvasStyles, backgroundColor: convertCodeData?.styles?.background, color: convertCodeData?.styles?.scanColor, fontFamily: convertCodeData?.styles?.scanFont }} className='qr-code-result-wrapper'>
                                <QRCodeSVG
                                    value={`${window.location.origin}/code/${publicId}`}
                                    bgColor={convertCodeData?.styles?.background}
                                    fgColor={convertCodeData?.styles?.foreground}
                                    level='L'
                                    size={256}
                                    imageSettings={{
                                        src: convertCodeData?.styles?.icon ? convertCodeData?.styles?.icon : '',
                                        height: 48,
                                        width: 48,
                                        excavate: false,
                                    }}
                                />
                                {convertCodeData?.styles?.scanText}
                            </div>
                        </div>
                    )
                }
            </>
        )
    }, [canvasRef, convertCodeData, canvasStyles, publicId])

    useEffect(() => {
        if (!isConverting && convertCodeData?.id && canvasStyles) {
            (async () => {
                setShowConvert(false)
                const url = await convertQrCodeToImage(canvasRef, {
                    src: convertCodeData?.styles?.icon ? convertCodeData?.styles?.icon : '',
                    height: 48,
                    width: 48,
                    excavate: false,
                })
                const blobImage = base64ToBlob(url, 'image/png')

                const formData = new FormData()
                let payloadData;

                formData.append('image', blobImage)

                if (convertCodeData.data.kind === 'website_url') {
                    payloadData = JSON.stringify({
                        styles: {
                            ...convertCodeData?.styles,
                        },
                        public_id: publicId,
                        name: convertCodeData?.name,
                        data: {
                            content: {
                                website_url: convertCodeData?.data?.content,
                                primary_color: '',
                                secondary_color: '',
                                text_color: '',
                                button_color: '',
                            },
                            kind: convertCodeData.data.kind
                        }
                    })
                } else if (convertCodeData.data.kind === 'text') {
                    payloadData = JSON.stringify({
                        styles: {
                            ...convertCodeData?.styles,
                        },
                        public_id: publicId,
                        name: convertCodeData?.name,
                        data: {
                            content: {
                                text: convertCodeData?.data?.content,
                                primary_color: '',
                                secondary_color: '',
                                text_color: '',
                                button_color: '',
                            },
                            kind: convertCodeData.data.kind
                        }
                    })
                } else if (convertCodeData.data.kind === 'wifi') {
                    const wifi = convertCodeData?.data?.content
                    const typeWifi = wifi.split(':')[2].replace(';S', '')
                    const network_name = wifi.split(':')[3].replace(';P', '')
                    const password = wifi.split(':')[4].replace(';H', '')
                    const hidden = wifi.split(':')[5].replace(';;', '')

                    payloadData = JSON.stringify({
                        styles: {
                            ...convertCodeData?.styles,
                        },
                        public_id: publicId,
                        name: convertCodeData?.name,
                        data: {
                            content: {
                                network_name,
                                type: typeWifi,
                                password,
                                hidden: hidden && hidden !== 'undefined' ? hidden : false,
                                primary_color: '',
                                secondary_color: '',
                                text_color: '',
                                button_color: '',
                            },
                            kind: convertCodeData.data.kind
                        }
                    })
                }

                formData.append('payload', payloadData)

                convertCode({ data: formData, id: convertCodeData.id }).then((res) => {
                    if ('error' in res) {
                        setConvertCodeData({})
                        setCanvasStyles(null)
                        setShowConvert(false)
                        setIsConverting(false)
                        setCodeToConvert({})
                    } else {
                        setConvertCodeData({})
                        setCanvasStyles(null)
                        setShowConvert(false)
                        convertToDynamicToast()
                        setIsConverting(false)
                        setCodeToConvert({})
                    }
                })
            })()
        }
    }, [convertCodeData, canvasStyles, convertCode, publicId, setConvertCodeData, setCanvasStyles, setShowConvert, canvasRef, convertToDynamicToast, isConverting])

    useEffect(() => {
        if (convertCodeData?.styles?.frame === 1) {
            setCanvasStyles({})
        } else if (convertCodeData?.styles?.frame === 2) {
            setCanvasStyles({
                border: '8px solid black',
            })
        } else if (convertCodeData?.styles?.frame === 3) {
            setCanvasStyles({
                border: '8px solid black',
                borderRadius: '25px'
            })
        } else if (convertCodeData?.styles?.frame === 4) {
            setCanvasStyles({
                border: '8px solid black',
                width: '306px',
                height: '306px',
                borderRadius: '100%',
                padding: convertCodeData?.styles?.scanText ? '40px' : '50px',
            })
        }
    }, [convertCodeData])

    useEffect(() => {
        if (codeToConvert) {
            setConvertCodeData(codeToConvert)
        }
    }, [codeToConvert, setConvertCodeData])

    return { isConverting, setCodeToConvert, convertCodeJsx, setIsConverting, codeToConvert }
}

export default useConvertToDynamic