import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { TRANSLATIONS_LANGUAGES } from "../helpers/translation-constants";
import i18next from '../i18n/config'

const LanguageContext = React.createContext();

export const useLanguageContext = () => React.useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState('')

    const navigate = useNavigate()

    i18next.on("languageChanged", (lng) => {
        setCurrentLanguage(lng);

        const languages = TRANSLATIONS_LANGUAGES
        const path = window.location.pathname;
        const codePath = '/code/';

        // If it is a code page, do not change the language
        if (path.includes(codePath)) return;

        // Detecting current language specified in the URL
        const currentLang = languages.find(lang => path.includes(`/${lang}`));

        let newPath = path;

        if (currentLang && lng !== currentLang) {
            // Replace the current language with the new language
            newPath = path.replace(`/${currentLang}`, `/${lng}`);
        } else if (!currentLang && lng !== 'en') {
            // Add the new language to the path if there's no current language and the new language is not English
            newPath = `/${lng}${path}`;
        } else if (currentLang && lng === 'en') {
            // Remove the current language from the path if the new language is English
            newPath = path.replace(`/${currentLang}`, '');
        }

        if (newPath !== path) {
            navigate(newPath);
        }
    });

    const value = {
        currentLanguage
    };

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};
