import { createSlice } from '@reduxjs/toolkit'
import { api } from '../api/api'

const initialState = {
    user: null,
    subscription: null,
    settings: null,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.user = payload.user
        },
        signOut: (state) => {
            state.user = null
            state.settings = null
            state.subscription = null
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.getUserSubscription.matchFulfilled,
            (state, { payload }) => {
                state.subscription = payload
            }
        )
        builder.addMatcher(
            api.endpoints.getUserSettings.matchFulfilled,
            (state, { payload }) => {
                state.settings = payload
            }
        )
    }
})

export const { setUser, signOut } = appSlice.actions

export const selectUser = (state) => state.app.user

export default appSlice.reducer